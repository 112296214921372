import {IAppState} from "../../state/app.state";
import {createSelector} from "@ngrx/store";

export const selectResourcesNamespaceState = (state: IAppState) => state.resourcesNamespaceState;

export const MetaNoImageProfileSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.meta.no_image_profile
);

export const MetaNoConnectionSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.meta.no_connection
);

export const DiscoversIconSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.discovers.icon
);

export const DiscoversCheckSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.discovers.check
);

export const ChatLoadMoreGallerySelect = createSelector(
    selectResourcesNamespaceState,
    state => state.chat.load_more_gallery
);

export const ChatEditGroupSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.chat.edit_group
);

export const MessagesNotSendedNotSynchronizedIconSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.messages.not_sended_not_synchronized_icon
);

export const MessagesSendedNotSynchronizedIconSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.messages.sended_not_synchronized_icon
);

export const MessagesSendedSynchronizedIconSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.messages.sended_synchronized_icon
);
export const MessagesErrorIconSelect = createSelector(
    selectResourcesNamespaceState,
    s => s.messages.error_icon
);
export const MessagesPdfPlaceHolderSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.messages.pdf_placeholder
);

export const ScriptsNotificationSidebarSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.scripts.notification_sidebar
);

export const MessagesVideoPlaceHolderSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.messages.videos_placeholder
)

export const RetryIconSelect = createSelector(
    selectResourcesNamespaceState,
    state => state.meta.retry_icon
)
