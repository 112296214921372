import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MetaRepository } from "../../../../repositories/meta/meta.repository";
import { UsersRepository } from "../../../../repositories/users/users.repository";
import { Action, Store } from "@ngrx/store";
import { IAppState } from "../../state/app.state";
import { SocketFacade } from "../../../../realtime/socket.facade";
import { AppFacade } from "../../facade/app.facade";
import { FcmFacade } from "../../../../../fcm/fcm.facade";
import { HttpHeadersManager } from "../../../remote/core/headers/HttpHeadersManager";
import { RoomsRepository } from "../../../../repositories/rooms/rooms.repository";
import { DiscoversRepository } from "../../../../repositories/discovers/discovers.repository";
import { MetaFacade } from "../../facade/generic/meta.facade";
import { Injectable } from "@angular/core";
import {
    LoadUserListAllRoomsAction,
    LoadUserListAllRoomsFailAction,
    LoadUserListAllRoomsSuccessAction,
    LoadUserListSelectedRoomAction,
    LoadUserListSelectedRoomFailAction,
    LoadUserListSelectedRoomSuccessAction,
    ReloadAppAction,
    ReloadAppSuccessAction,
    ReloadListDiscoversAction,
    ReloadListDiscoversErrorAction,
    ReloadListDiscoversSuccessAction,
    ReloadListRoomsAction,
    ReloadListRoomsErrorAction,
    ReloadListRoomsSuccessAction,
    ReloadListUsersAction,
    ReloadListUsersErrorAction,
    ReloadListUsersSuccessAction,
    ReloadOpenSocketAction,
    ReloadOpenSocketErrorAction,
    ReloadOpenSocketSucccessAction,
    ReloadPersonalInfoAction,
    ReloadPersonalInfoErrorAction,
    ReloadPersonalInfoSuccessAction,
} from "../../actions/app_with_connection/meta.actions";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import // ConnectionChangedAction,
// SetAllDataLoadedAction,
// SetAppWithConnectionStateAction
"../../actions/generic/app_state.actions";
import { AppWithConnectionState } from "../../state/namespaces/app_state_namespace.state";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import * as A from "fp-ts/lib/Array";
import { SavePersonalInfoAction } from "../../actions/generic/meta.actions";
import { DatesUpdatesNamespaceFacade } from "../../facade/namespaces/dates_updates_namespace.facade";
import { RefreshListUsersAction } from "../../actions/generic/users.actions";
import {
    SaveDateUpdateDiscoversAction,
    SaveDateUpdateRoomsAction,
    SaveDateUpdateUsersAction,
} from "../../actions/generic/dates_updates.actions";
import {
    AddListenerChatMessageRoomAction,
    AddListenerChatMessageUserAction,
    AddListenerConfirmationFileAction,
    AddListenerDisconnectedAction,
    AddListenerDiscoverDeletedAction,
    AddListenerDiscoverModifiedAction,
    AddListenerJoinedRoomAction,
    AddListenerNewDiscoverAction,
    AddListenerNewUserAction,
    AddListenerOneToOneDeletedAction,
    AddListenerOtoMessageModifiedAction,
    AddListenerOTOReadedMessagesAction,
    AddListenerQuitRoomAction,
    AddListenerRoomAdminsAction,
    AddListenerRoomMessageModifiedAction,
    AddListenerRoomModifiedAction,
    AddListenerRoomReadedMessagesAction,
    AddListenerUserDeletedAction,
    AddListenerUserModifiedAction,
} from "../../actions/sockets/sockets.actions";
import { RefreshListRoomsAction } from "../../actions/generic/rooms.actions";
import { RefreshListDiscoversAction } from "../../actions/generic/discovers.actions";
import {
    ChangedConnectionOfflineServerAction,
    ChangedConnectionOnlineServerAction,
    ResetAppStateReconnectingTimeAction,
    SetAllDataLoadedAction,
    SetAppWithConnectionStateAction,
} from "../../actions/namespaces/app_state_namespace.actions";
import {
    LoadPersonalInfoAction,
    LoadPersonalInfoErrorAction,
    LoadPersonalInfoSuccessAction,
} from "../../actions/first_app_with_connection/meta.actions";
import { Observable, of, zip } from "rxjs";
import { ExecuteInitialAllProcessesAction } from "../../actions/principal_screen_app/initial_processes_execution.actions";
import { RoomsFacade } from "../../facade/rooms.facade";
import { RoomType } from "../../../../domain/RoomType";
import { pipe } from "fp-ts/es6/pipeable";
import { getUsersRoomSuccess } from "../../actions/room.actions";
import { logout } from "../../actions/app.actions";
import { AST } from "@angular/compiler";
import { UserRoom } from "app/shared/core/domain/UserRoom";
import { StandardError } from "app/shared/core/domain/StandardError";

@Injectable({
    providedIn: "root",
})
export class MetaEffects {
    reloadApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadAppAction),
            switchMap((_) => [
                ReloadPersonalInfoAction(),
                ResetAppStateReconnectingTimeAction(),
            ])
        )
    );

    reloadAppSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadAppSuccessAction),
            tap((_) => console.log({ text: "ReloadAppSuccessAction" })),
            switchMap((_) => [
                SetAllDataLoadedAction({ all_data_loaded: true }),
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.COMPLETED,
                    error: O.none,
                }),
                ExecuteInitialAllProcessesAction(),
            ])
        )
    );

    reloadPersonalInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadPersonalInfoAction),
            switchMap((_) =>
                this.metaRepository.getOwnData().pipe(
                    switchMap(
                        E.fold(
                            (err) => of(E.left(err)),
                            (own) =>
                                this.userRepository
                                    .getImageProfileFromUserByIdUser(own.idUser)
                                    .pipe(
                                        map((opt) =>
                                            E.right({
                                                ownData: own,
                                                image: opt,
                                            })
                                        )
                                    )
                        )
                    )
                )
            ),
            switchMap(
                E.fold(
                    (err) =>
                        [
                            ReloadPersonalInfoErrorAction({ error: err }),
                        ] as Action[],
                    ({ ownData, image }) => [
                        ReloadPersonalInfoSuccessAction({
                            info: ownData,
                            image: image,
                        }),
                    ]
                )
            )
        )
    );

    reloadPersonalInfoError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadPersonalInfoErrorAction),
            switchMap(({ error }) => [
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.PERSONAL_INFO_RELOADED,
                    error: O.some(error),
                }),
                logout(),
            ])
        )
    );

    reloadPersonalInfoSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadPersonalInfoSuccessAction),
            switchMap(({ info, image }) => [
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.PERSONAL_INFO_RELOADED,
                    error: O.none,
                }),
                SavePersonalInfoAction({ info: info, image: image }),
                ReloadOpenSocketAction(),
            ])
        )
    );

    //     ofType(LoadPersonalInfoAction),
    //     switchMap(_ => this.metaRepository.getOwnData().pipe(
    //         switchMap(E.fold(
    //         err => of(E.left(err)),
    //     own => this.userRepository.getImageProfileFromUserByIdUser(own.idUser).pipe(
    //         map(opt => E.right({ownData: own, image: opt}))
    // )
    // ))
    // )),
    //     switchMap(E.fold(
    //         err => [LoadPersonalInfoErrorAction({error: err})] as Action[],
    // ({ownData, image}) => [LoadPersonalInfoSuccessAction({info: ownData, image: image})]
    // )),

    reloadOpenSocket$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadOpenSocketAction),
            switchMap((_) => this.metaFacade.getOwnDataUser()),
            switchMap((ownDataUser) =>
                this.socketFacade.startSocket(
                    ownDataUser.idClient,
                    ownDataUser.idUser
                )
            ),
            switchMap(
                E.fold(
                    (err) => [ReloadOpenSocketErrorAction({ error: err })],
                    (_) => [ReloadOpenSocketSucccessAction()]
                )
            )
        )
    );

    reloadOpenSocketError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadOpenSocketErrorAction),
            switchMap(({ error }) => [
                // ConnectionChangedAction({with_connection: false}),
                ChangedConnectionOfflineServerAction(),
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.SOCKET_OPEN,
                    error: O.some(error),
                }),
            ])
        )
    );

    reloadOpenSocketSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadOpenSocketSucccessAction),
            switchMap((_) => [
                // ConnectionChangedAction({with_connection: true}),
                ChangedConnectionOnlineServerAction(),
                ReloadListUsersAction(),
                AddListenerDisconnectedAction(),
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.SOCKET_OPEN,
                    error: O.none,
                }),
            ])
        )
    );

    reloadListUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListUsersAction),
            switchMap((_) =>
                this.datesUpdatesNamespaceFacade.getDatesUpdatesUsers()
            ),
            switchMap((date) => this.userRepository.getUsersFromDate(date)),
            switchMap(
                E.fold(
                    (err) =>
                        [
                            ReloadListUsersErrorAction({ error: err }),
                        ] as Action[],
                    (allUsers) => [ReloadListUsersSuccessAction(allUsers)]
                )
            )
        )
    );

    reloadListUsersError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListUsersErrorAction),
            switchMap(({ error }) => [
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.USERS_RELOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    reloadListUsersSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListUsersSuccessAction),
            switchMap(({ all, deleted }) => [
                RefreshListUsersAction({ all: all, deleted: deleted }),
                SaveDateUpdateUsersAction({ date: new Date() }),
                AddListenerUserModifiedAction(),
                AddListenerNewUserAction(),
                AddListenerUserDeletedAction(),
                ReloadListRoomsAction(),
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.USERS_RELOADED,
                    error: O.none,
                }),
            ])
        )
    );

    reloadListRooms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListRoomsAction),
            switchMap((_) =>
                this.datesUpdatesNamespaceFacade.getDatesUpdatesListRooms()
            ),
            switchMap((date) => this.roomRepository.getRoomsFromDate(date)),
            tap((eth) => console.log({ text: "GETROOMSFROMDATE", et: eth })),
            switchMap(
                E.fold(
                    (err) =>
                        [
                            ReloadListRoomsErrorAction({ error: err }),
                        ] as Action[],
                    ({ all, deleted }) => [
                        ReloadListRoomsSuccessAction({
                            all: all,
                            deleted: deleted,
                        }),
                    ]
                )
            )
        )
    );

    reloadListRoomsError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListRoomsErrorAction),
            switchMap(({ error }) => [
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.ROOMS_RELOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    reloadListRoomsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListRoomsSuccessAction),
            switchMap(({ all, deleted }) => [
                RefreshListRoomsAction({ all: all, deleted: deleted }),
                LoadUserListAllRoomsAction(),
                SaveDateUpdateRoomsAction({ date: new Date() }),
                AddListenerChatMessageRoomAction(),
                AddListenerChatMessageUserAction(),
                AddListenerJoinedRoomAction(),
                AddListenerQuitRoomAction(),
                AddListenerRoomModifiedAction(),
                AddListenerRoomReadedMessagesAction(),
                AddListenerOTOReadedMessagesAction(),
                AddListenerRoomAdminsAction(),
                AddListenerOneToOneDeletedAction(),
                AddListenerRoomMessageModifiedAction(),
                AddListenerOtoMessageModifiedAction(),
                AddListenerConfirmationFileAction(),
                ReloadListDiscoversAction(),
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.ROOMS_RELOADED,
                    error: O.none,
                }),
            ])
        )
    );

    reloadListDiscovers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListDiscoversAction),
            switchMap((_) =>
                this.datesUpdatesNamespaceFacade.getDatesUpdatesDiscovers()
            ),
            switchMap((date) =>
                this.discoverRepository.getDiscoversFromDate(date)
            ),
            switchMap(
                E.fold(
                    (error) =>
                        [
                            ReloadListDiscoversErrorAction({ error: error }),
                        ] as Action[],
                    ({ all, deleted }) => [
                        ReloadListDiscoversSuccessAction({
                            all: all,
                            deleted: deleted,
                        }),
                    ]
                )
            )
        )
    );

    reloadListDiscoversError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListDiscoversErrorAction),
            switchMap(({ error }) => [
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.DISCOVERS_RELOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    reloadListDiscoversSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReloadListDiscoversSuccessAction),
            switchMap(({ all, deleted }) => [
                RefreshListDiscoversAction({ all: all, deleted: deleted }),
                SaveDateUpdateDiscoversAction({ date: new Date() }),
                AddListenerNewDiscoverAction(),
                AddListenerDiscoverModifiedAction(),
                AddListenerDiscoverDeletedAction(),
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.DISCOVERS_RELOADED,
                    error: O.none,
                }),
                LoadUserListSelectedRoomAction(),
            ])
        )
    );

    loadUserListAllRooms$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadUserListAllRoomsAction),
            tap(() => console.log({ text: "LoadUserListAllRoomsAction" })),
            switchMap(() =>
                this.roomsFacade.getAllRoomsObservable().pipe(
                    take(1),
                    map(([groupRooms, otoRooms]) => groupRooms),
                    switchMap((rooms) => {
                        const observables = A.array.map(rooms, (r) => {
                            const userList =
                                this.roomRepository.getUserRoomListByIdRoom(
                                    r.roomGroup.idRoom
                                );
                            return userList.pipe(
                                map(
                                    E.fold(
                                        (err) => E.left(err),
                                        (list) =>
                                            E.right({
                                                idRoom: r.roomGroup.idRoom,
                                                userList: list,
                                            })
                                    )
                                )
                            );
                        });
                        return zip(...observables);
                    }),
                    map(
                        (
                            eitherRooms: Array<
                                E.Either<
                                    StandardError,
                                    { idRoom: number; userList: UserRoom[] }
                                >
                            >
                        ) => {
                            /*
                            return eitherRooms.forEach((eitherRoom) => {
                                eitherRoom.fold(
                                    (err) => LoadUserListSelectedRoomFailAction({ error: err }),
                                    (r) => LoadUserListSelectedRoomSuccessAction({ room: O.some(r) })
                                );
                            }
                            */
                            const successRooms = eitherRooms.filter(E.isRight);
                            if (successRooms.length === eitherRooms.length) {
                                // All rooms loaded successfully
                                const roomsData = successRooms.map(
                                    (roomEither) => roomEither.right
                                );
                                return LoadUserListAllRoomsSuccessAction({
                                    rooms: roomsData,
                                });
                            } else {
                                // Some rooms failed to load
                                const error = eitherRooms.find(E.isLeft).left;
                                return LoadUserListAllRoomsFailAction({
                                    error: error,
                                });
                            }
                        }
                    ),
                    catchError((err: any) =>
                        of(LoadUserListAllRoomsFailAction({ error: err }))
                    )
                )
            )
        )
    );

    loadUserListAllRoomsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadUserListAllRoomsSuccessAction),
            switchMap(({ rooms }) => [
                ...rooms.map((r) =>
                    getUsersRoomSuccess({
                        idRoom: r.idRoom,
                        usersRoom: r.userList,
                        dateLoaded: new Date(),
                    })
                ),
            ])
        )
    );

    loadUserListSelectedRoom$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadUserListSelectedRoomAction),
            switchMap((_) =>
                this.roomsFacade.getSelectedRoomObservable().pipe(
                    take(1),
                    switchMap(
                        O.fold(
                            () => [
                                LoadUserListSelectedRoomSuccessAction({
                                    room: O.none,
                                }),
                            ],
                            ({ id, type }) => {
                                if (type === RoomType.OTO) {
                                    return [
                                        LoadUserListSelectedRoomSuccessAction({
                                            room: O.none,
                                        }),
                                    ];
                                } else {
                                    return this.roomsFacade
                                        .getRoomByIdRoomOption(id)
                                        .pipe(
                                            switchMap(
                                                O.fold(
                                                    () =>
                                                        of([
                                                            LoadUserListSelectedRoomSuccessAction(
                                                                { room: O.none }
                                                            ),
                                                        ] as Action[]).pipe(
                                                            switchMap((a) => a)
                                                        ),
                                                    (r) => {
                                                        return this.roomRepository
                                                            .getUserRoomListByIdRoom(
                                                                r.roomGroup
                                                                    .idRoom
                                                            )
                                                            .pipe(
                                                                switchMap(
                                                                    E.fold(
                                                                        (err) =>
                                                                            [
                                                                                LoadUserListSelectedRoomFailAction(
                                                                                    {
                                                                                        error: err,
                                                                                    }
                                                                                ),
                                                                            ] as Action[],
                                                                        (
                                                                            listRoomUsers
                                                                        ) => [
                                                                            LoadUserListSelectedRoomSuccessAction(
                                                                                {
                                                                                    room: O.some(
                                                                                        {
                                                                                            idRoom: id,
                                                                                            userList:
                                                                                                listRoomUsers,
                                                                                        }
                                                                                    ),
                                                                                }
                                                                            ),
                                                                        ]
                                                                    )
                                                                )
                                                            );
                                                    }
                                                )
                                            )
                                        );
                                }
                            }
                        )
                    )
                )
            )
        )
    );

    loadUserListSelectedRoomFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadUserListSelectedRoomFailAction),
            switchMap(({ error }) => [
                SetAppWithConnectionStateAction({
                    state: AppWithConnectionState.SELECTED_ROOM_USER_LIST_LOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadUserListSelectedRoomSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadUserListSelectedRoomSuccessAction),
            tap((r) =>
                console.log({
                    text: "LoadUserListSelectedRoomSuccessAction",
                    m: r,
                })
            ),
            switchMap(({ room }) =>
                pipe(
                    room,
                    O.fold(
                        () => [
                            SetAppWithConnectionStateAction({
                                state: AppWithConnectionState.SELECTED_ROOM_USER_LIST_LOADED,
                                error: O.none,
                            }),
                            ReloadAppSuccessAction(),
                        ],
                        ({ idRoom, userList }) => [
                            getUsersRoomSuccess({
                                idRoom: idRoom,
                                usersRoom: userList,
                                dateLoaded: new Date(),
                            }),
                            SetAppWithConnectionStateAction({
                                state: AppWithConnectionState.SELECTED_ROOM_USER_LIST_LOADED,
                                error: O.none,
                            }),
                            ReloadAppSuccessAction(),
                        ]
                    )
                )
            )
        )
    );

    // RELOAD_APP: TAG + 'Reload App',
    // RELOAD_APP_FAIL: TAG + 'Reload App Fail',
    // RELOAD_APP_SUCCESS: TAG + 'Reload App Success',
    // // Activar:
    // //  - GenericAppStateSetAllDataLoaded a true
    //
    //
    // RELOAD_PERSONAL_INFO: TAG + 'Reload Personal Info',
    // RELOAD_PERSONAL_INFO_ERROR: TAG + 'Reload Personal Info Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction amb el state de personal i el error
    // RELOAD_PERSONAL_INFO_SUCCESS: TAG + 'Reload Personal Info Success', // <-- aquest activa SAVE_PERSONAL_INFO i modifica progrés de l'aplicació
    // // Activar:
    // //  - SAVE_PERSONAL_INFO
    // //  - SetFirstAppWithConnectionStateAction
    //
    // RELOAD_OPEN_SOCKET: TAG + 'Reload Open Socket',
    // RELOAD_OPEN_SOCKET_ERROR: TAG + 'Reload Open Socket Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // RELOAD_OPEN_SOCKET_SUCCESS: TAG + 'Reload Open Socket Success',// <-- aquest activa les altres tres accions i modifica progrés aplicació, llença també següent estat
    // // Activar:
    // //  - GenericConnectionConnectionChangedAction a true
    // //  - SetFirstAppWithConnectionStateAction
    //
    // RELOAD_LIST_USERS: TAG + 'Reload List Users',
    // RELOAD_LIST_USERS_ERROR: TAG + 'Reload List Users Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // RELOAD_LIST_USERS_SUCCESS: TAG + 'Reload List Users Success',
    // // Activar:
    // //  - RefreshListUsersAction
    // //  - GenericDatesUpdatesSaveDateUpdateUsersAction
    // //  - ADD_LISTENER_USER_MODIFIED
    // //  - ADD_LISTENER_NEW_USER
    // //  - SetFirstAppWithConnectionStateAction
    //
    //
    // RELOAD_LIST_ROOMS: TAG + 'Reload List Rooms',
    // RELOAD_LIST_ROOMS_ERROR: TAG + 'Reload List Rooms Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // RELOAD_LIST_ROOMS_SUCCESS: TAG + 'Reload List Rooms Success',
    // // Activar:
    // //  - RefreshListRoomsAction
    // //  - GenericDatesUpdatesSaveDataUpdateRoomsAction
    // //  - ADD_LISTENER_CHAT_MESSAGE_ROOM
    // //     ADD_LISTENER_CHAT_MESSAGE_USER
    // //     ADD_LISTENER_JOINED_ROOM
    // //     ADD_LISTENER_QUIT_ROOM
    // //     ADD_LISTENER_ROOM_MODIFIED
    // //     ADD_LISTENER_ROOM_READED_MESSAGES
    // //     ADD_LISTENER_OTO_READED_MESSAGES
    // //  - SetFirstAppWithConnectionStateAction
    //
    // RELOAD_LIST_DISCOVERS: TAG + 'Reload List Discovers',
    // RELOAD_LIST_DISCOVERS_ERROR: TAG + 'Reload List Discovers Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // RELOAD_LIST_DISCOVERS_SUCCESS: TAG + 'Reload List Discovers Success',
    // // Activar:
    // //  - RefreshListDiscoversAction
    // //  - GenericDatesUpdatesSaveDataUpdateDiscoversAction
    // //  - SetFirstAppWithConnectionStateAction

    constructor(
        private actions$: Actions,
        private metaRepository: MetaRepository,
        private userRepository: UsersRepository,
        private store: Store<IAppState>,
        private socketFacade: SocketFacade,
        private appFacade: AppFacade,
        private fcmFacade: FcmFacade,
        private httpHeadersManager: HttpHeadersManager,
        private roomRepository: RoomsRepository,
        private discoverRepository: DiscoversRepository,
        private metaFacade: MetaFacade,
        private datesUpdatesNamespaceFacade: DatesUpdatesNamespaceFacade,
        private roomsFacade: RoomsFacade
    ) {}
}
