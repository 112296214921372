import {Injectable} from "@angular/core";
import {NetworkClient} from "../core/NetworkClient";
import {Observable} from "rxjs";
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../../domain/StandardError";
import {TGetUsersMeSessionsApiModel} from "./models/TGetUsersMeSessionsApiModel";
import {RequestType} from "../core/RequestType";

@Injectable({
    providedIn: 'root',
})
export class SessionsApihelper {
    constructor(
        private networkClient: NetworkClient
    ) {}

    getUsersMeSessions() : Observable<Either<StandardError, TGetUsersMeSessionsApiModel>> {
        return this.networkClient.setRequest<{}, TGetUsersMeSessionsApiModel>(
            '/users/me/sessions',
            {},
            RequestType.GET
        );
    }
}
