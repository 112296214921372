import {Injectable} from '@angular/core';
import {LinkGenerators} from "../core/domain/images/link.generators";
import {HttpClient} from "@angular/common/http";
import {SocketsAuthService} from "../sockets-auth/sockets-auth.service";
import * as uuid from 'uuid';
import {Observable, of} from "rxjs";
import {UrlResourceModel} from "./UrlResourceModel";
import {StandardError} from "../core/domain/StandardError";
import {Either} from "fp-ts/lib/Either";
import {NetworkClient} from "../core/sources/remote/core/NetworkClient";
import {map, switchMap, tap} from "rxjs/operators";
import {either as E, option as O} from "fp-ts";
import {RequestType} from "../core/sources/remote/core/RequestType";

@Injectable({
    providedIn: 'root'
})
export class ResourceGetterService {

    constructor(
        private readonly networkClient: NetworkClient,
        private readonly linkGenerators: LinkGenerators,
        private readonly httpService: HttpClient,
        private readonly socketsAuthService: SocketsAuthService
    ) {
    }


    downloadAndCheckResourceMessageRoom(idRoom: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        return this.downloadAndCheckResource(
            this.getUrlResourceMessageRoom(idRoom, idMessage, true),
            this.downloadResourceMessageRoom(idRoom, idMessage)
        );
    }
    downloadAndCheckResourceMessageOTO(idUser: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        return this.downloadAndCheckResource(
            this.getUrlResourceMessageOTO(idUser, idMessage, true),
            this.downloadResourceMessageOTO(idUser, idMessage)
        );
    }

    private downloadAndCheckResource(
        resourceModelGetter: Observable<UrlResourceModel>,
        resourceGetter: Observable<Either<StandardError, Blob>>
    ) : Observable<Either<StandardError, Blob>> {
        return resourceModelGetter.pipe(
            switchMap(url => this.networkClient.setPlainRequest(url.url, RequestType.GET).pipe(
                switchMap(E.fold(
                    exc => of(E.left(exc)),
                    _ => resourceGetter
                ))
            ))
        )
    }


    private downloadResourceMessageRoom(idRoom: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        return this.createUrlAndDownload(
            (requestId, deviceId) => this.linkGenerators.getLinkResourceRoomFromMessage(
                idRoom,
                idMessage,
                E.right({deviceId: deviceId, requestId: requestId})
            )
        );
    }

    private downloadResourceMessageOTO(idUser: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        return this.createUrlAndDownload(
            (requestId, deviceId) => this.linkGenerators.getLinkResourceOneToOneFromMessage(
                idUser,
                idMessage,
                E.right({deviceId: deviceId, requestId: requestId})
            )
        )
    }

    checkUrlResourceAndGetUrlMessageRoom(idRoom: number, idMessage: string) : Observable<Either<StandardError, UrlResourceModel>> {
        return this.getUrlResourceMessageRoom(idRoom, idMessage, true).pipe(
            switchMap(url => this.networkClient.setPlainRequest<{}>(url.url, RequestType.GET).pipe(
                switchMap(E.fold(
                    exc => of(E.left(exc)),
                    _ => this.getUrlResourceMessageRoom(idRoom, idMessage, false).pipe(
                        map(url => E.right(url))
                    )
                ))
            ))
        )
    }

    checkUrlResourceAndGetUrlMessageOTO(idUser: number, idMessage: string) : Observable<Either<StandardError, UrlResourceModel>> {
        return this.getUrlResourceMessageOTO(idUser, idMessage, true).pipe(
            switchMap(url => this.networkClient.setPlainRequest<{}>(url.url, RequestType.GET).pipe(
                switchMap(E.fold(
                    exc => of(E.left(exc)),
                    _ => this.getUrlResourceMessageOTO(idUser, idMessage, false).pipe(
                        map(url => E.right(url))
                    )
                ))
                // map(E.map(_ => url))
            ))
        )
    }

    private getUrlResourceMessageRoom(idRoom: number, idMessage: string, headInfo: boolean) : Observable<UrlResourceModel> {
        const deviceId = uuid.v4();
        const requestId = uuid.v4();
        return this.linkGenerators.getLinkResourceRoomFromMessage(
            idRoom,
            idMessage,
            headInfo ? E.left({}) : E.right({deviceId: deviceId, requestId: requestId})
        ).pipe(
            map(url => new UrlResourceModel(url, O.some({deviceId: deviceId, requestId: requestId})))
        );
    }

    private getUrlResourceMessageOTO(idUser: number, idMessage: string, headInfo: boolean) : Observable<UrlResourceModel> {
        const deviceId = uuid.v4();
        const requestId = uuid.v4();
        return this.linkGenerators.getLinkResourceOneToOneFromMessage(
            idUser,
            idMessage,
            headInfo ? E.left({}) : E.right({deviceId: deviceId, requestId: requestId})
        ).pipe(
            map(url => new UrlResourceModel(url, O.some({deviceId: deviceId, requestId: requestId})))
        );
    }

    private createUrlAndDownload(urlGetter: (requestId: string, deviceId: string) => Observable<string>) : Observable<Either<StandardError, Blob>> {
        const deviceId = uuid.v4();
        const requestId = uuid.v4();
        return urlGetter(requestId, deviceId).pipe(
            switchMap(url => {
                this.socketsAuthService.addDeviceCode(requestId, deviceId)
                return this.networkClient.setPlainRequestWithFileEither(url).pipe(
                    tap(_ => this.socketsAuthService.removeDeviceCode(requestId))
                )
            })
        );
    }

}
