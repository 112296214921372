import {Option} from "fp-ts/lib/Option";
import {ErrorMessageFile, Message, MessageLinkPreview, MessageReply, ModifiedState} from './Message';
import {SynchronizationState} from './SynchronizationState';
import {FileType} from './FileType';
import {option as O} from "fp-ts";


export class MessageOTO extends Message {

    readonly idUserOTO: number;


    constructor(idMessage: string,
                idUser: number,
                date: Date,
                text: string,
                idUserOTO: number,
                internalId: string,
                syncState: SynchronizationState,
                thumbnail: Blob | undefined,
                fileType: FileType | undefined,
                // localLinkResource: string | undefined,
                durationMedia: number | undefined,
                extension: string,
                messageReply: Option<MessageReply>,
                modifiedState: ModifiedState,
                linkPreview: Option<MessageLinkPreview>,
                error: Option<ErrorMessageFile>,
                retriesSent: Option<number>) {
        super(idMessage, idUser, date, text, internalId, syncState, thumbnail, fileType, extension, durationMedia, messageReply, modifiedState, linkPreview, error, retriesSent);
        this.idUserOTO = idUserOTO;
    }

    changeSynchronizationState(state: SynchronizationState, id: Option<string>, error: Option<ErrorMessageFile>, retriesSent: Option<number>) : MessageOTO {
        return new MessageOTO(
            O.getOrElse(() => this.idMessage)(id),
            this.idUser,
            this.date,
            this.text,
            this.idUserOTO,
            this.internalId,
            state,
            this.thumbnail,
            this.fileType,
            // this.localLinkResource,
            this.durationMedia,
            this.extension,
            this.messageReply,
            this.modifiedState,
            this.linkPreview,
            error,
            retriesSent
        );
    }
}
