import {createFeatureSelector, createSelector} from "@ngrx/store";
import {IPagedRoomGroupMessagesState} from "../../state/message.state";
import {IFileMessageNotSendedState} from "../../state/messages/filemessagenotsended.state";
import {adapterPagedRoomGroupMessages} from "../../adapters/messages.adapters";
import {adapterFileMessageNotSended} from "../../adapters/filemessagenotsended.adapters";


export const selectFileMessageNotSendedState = createFeatureSelector<IFileMessageNotSendedState>('fileMessageNotSendedState');


const PagedMessageRoomsSelectors = adapterFileMessageNotSended.getSelectors();

export const getFileMessageNotSendedByInternalIdSelect = (internalId: string) => createSelector(
    selectFileMessageNotSendedState,
    PagedMessageRoomsSelectors.selectEntities,
    entities => entities.entities[internalId]
);
