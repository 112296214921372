import {Injectable} from '@angular/core';
import {Option} from "fp-ts/lib/Option";
import {CookieService} from 'ngx-cookie-service';
import {Observable, of} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {LocalStorageKeyNotFoundException} from './LocalStorageKeyNotFoundException';
import {option as O} from "fp-ts";

@Injectable({
    providedIn: 'root',
})
export class CookieStoreService {

    constructor(
        private cookieService : CookieService
    ) {

    }

    putById(id: string, token: string) : Observable<never> {
        return new Observable<never>(subscriber => {
            this.cookieService.set(id, token);
            // console.log({text: "ALL COOKIES AFTER PUT BY ID", c: this.cookieService.getAll()});
            subscriber.complete();
        });
    }

    existsById(id: string) : Observable<boolean> {
        return new Observable<boolean>(subscriber => {
            // subscriber.next(!this.cookieService.get(id));
            subscriber.next(this.cookieService.check(id));
            subscriber.complete();
        })
    }

    getById(id: string) : Observable<string> {
        return new Observable<string>(subscriber => {
            // console.log(id);
            if (this.cookieService.check(id)) {
                subscriber.next(this.cookieService.get(id));
            } else {
                subscriber.error(new LocalStorageKeyNotFoundException(id))
            }

            subscriber.complete();
        })
    }

    getByIdOption(id: string) : Observable<Option<string>> {
        return this.getById(id).pipe(
            map(c => O.some(c)),
            catchError(_ => of(O.none))
        );
    }

    removeById(id: string) : Observable<never> {
        return new Observable<never>(subscriber => {
            this.cookieService.delete(id);
            // subscriber.next({});
            subscriber.complete();
        });
    }

}
