import {Injectable} from '@angular/core';
import {IPushMessaging} from './IPushMessaging';
import {Observable, of} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class StubmessagingService implements IPushMessaging{

    deleteToken(token: string): Observable<boolean> {
        return of(true);
    }

    onNewMessage(): Observable<any> {
        return of({});
    }

    onTokenChanges(): Observable<string> {
        return of(" ");
    }

    requestToken(): Observable<string> {
        return of(" ");
    }

}
