import {SessionApiModel} from "../../sources/remote/sessions/models/TGetUsersMeSessionsApiModel";
import {DeviceType, Session} from "../../domain/Session";
import {getDateFromUnixDate} from "./dates.mapper";
import {pipe} from "fp-ts/lib/pipeable";
import {fromNullable} from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";


function getDeviceTypeFromString(s: "Android"|"IOS"|"Web") : DeviceType {
    switch (s) {
        case "Web": return DeviceType.Web;
        case "IOS": return DeviceType.IOS;
        case "Android": return DeviceType.Android
    }
}

export function getSessionFromSessionApiModel(s: SessionApiModel) : Session {
    return new Session(
        s.deviceId,
        s.systemVersion,
        s.appVersion,
        s.deviceModel,
        getDeviceTypeFromString(s.deviceType),
        getDateFromUnixDate(s.createdAt),
        pipe(
            fromNullable(s.lastActive),
            O.map(getDateFromUnixDate)
        ),
        s.active === 1
    );
}
