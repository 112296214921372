


export enum LastEvent {
    NEW_MESSAGE_RECEIVED,
    MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
    MESSAGE_SENDED_NOT_SYNCHRONIZED,
    LOADED_PAGE,
    LOADED_LAST_MESSAGES,
    SELECTED_ROOM,
    LOADED_MESSAGE_FROM_DATE,
    NO_OP
}
