import {from, Observable, of} from "rxjs";
import {default as E, Either} from "fp-ts/lib/Either";
import {StandardError} from "../../../domain/StandardError";
import {catchError, flatMap, map, switchMap} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {RequestType} from "../../remote/core/RequestType";
import {Option} from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import {AjaxCaller} from "../../remote/core/ajax/AjaxCaller";

@Injectable({
    providedIn: 'root',
})
export class LocalClient {

    constructor(
        // private httpClient: HttpClient
        private ajaxCaller : AjaxCaller
    ) {}

    // private errorCatcher<T>(obs: Observable<T>) : Observable<Either<StandardError, T>> {
    //     return obs.pipe(
    //         map(elem => E.right(elem)),
    //         catchError(error => {
    //             if (error instanceof HttpErrorResponse) {
    //                 return of(E.left(this.errorsTransformers.getStandardErrorFromHttpErrorResponse(error)));
    //             } else {
    //                 return of(E.left(this.errorsTransformers.getStandardErrorFromAny(error)));
    //             }
    //
    //         })
    //     )
    // }
    setRequestWithFile(address: string) : Observable<Option<Blob>> {
        // URL.createObjectURL(address)
        // return this.httpClient.get(address, {})
        return this.ajaxCaller.getFile(address, {}).pipe(
            map(f => O.some(f)),
            catchError(err => of(O.none))
        );

        // return from(fetch(address)).pipe(
        //     switchMap(res => from(res.blob())),
        //     map(blob => O.some(blob)),
        //     catchError(_ => of(O.none))
        // );

        // return this.httpHeadersManager.getCurrentHeaders().pipe(
        //     flatMap(headers => this.ajaxCaller.getFile(this.getUrl() + endpoint, headers).pipe(
        //         map(f => O.some(f))
        //     )),
        //     catchError(err => of(O.none))
        // );
    }
}
