
export class MessageRoomSocketModelOut {
    readonly idRoom: number;
    readonly message: string;
    readonly internalId: string;
    readonly idMessageReply: string|undefined;


    constructor(idRoom: number, message: string, internalId: string, idMessageReply: string|undefined) {
        this.idRoom = idRoom;
        this.message = message;
        this.internalId = internalId;
        this.idMessageReply = idMessageReply;
    }
}
