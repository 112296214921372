import {AppError} from "../../sources/remote/globalmodels/AppError";


export class AckMsgSocketModel {
    response: "OK" | "ERROR";
    internalId: string;
    appError: AppError | undefined;


    constructor(response: "OK" | "ERROR", internalId: string, appError?: AppError) {
        this.response = response;
        this.internalId = internalId;
        this.appError = appError;
    }
}
