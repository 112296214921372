

export interface DatesUpdatesStateNamespaceState {
    readonly dates_updates_users: Date;
    readonly dates_updates_list_rooms: Date;
    readonly dates_updates_discovers: Date;
}

const d = new Date();
export const initialDatesUpdatesNamespaceState : DatesUpdatesStateNamespaceState = {
    dates_updates_users: d,
    dates_updates_discovers: d,
    dates_updates_list_rooms: d
};

// import {AppError} from "../../../remote/globalmodels/AppError";
//
// export interface ResponsesState {
//     readonly createRoomLastResponse: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly getImageMessageRoom: {fail: boolean, image: Blob | undefined, error: AppError | undefined} | undefined,
//     readonly addUsersToRoomLastResponse: {fail: boolean, error: AppError | undefined } | undefined,
//     readonly leaveRoomGroupLastResponse: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly leaveRoomOTOLastResponse: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly changeOwnDataUser: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly openOneToOneResponse: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly sendFileRoomGroupResponse: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly sendFileRoomOTOResponse: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly loadNextPageFilesResponse: {fail: boolean, complete: boolean, error: AppError | undefined} | undefined,
//     readonly logoutResponse: {fail: boolean, error: AppError | undefined} | undefined,
//     readonly modifyRoomInfoResponse: {fail: boolean, error: AppError | undefined} | undefined
// }
//
//
// export const initialResponsesState : ResponsesState = {
//     createRoomLastResponse: undefined,
//     getImageMessageRoom: undefined,
//     addUsersToRoomLastResponse: undefined,
//     leaveRoomGroupLastResponse: undefined,
//     leaveRoomOTOLastResponse: undefined,
//     changeOwnDataUser: undefined,
//     openOneToOneResponse: undefined,
//     sendFileRoomGroupResponse: undefined,
//     sendFileRoomOTOResponse: undefined,
//     loadNextPageFilesResponse: undefined,
//     logoutResponse: undefined,
//     modifyRoomInfoResponse: undefined
// };
