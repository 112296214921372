


export class StatusSocketModel {

    readonly idRoom: number;
    readonly fromUser: number;
    readonly byIdUser: number;
    readonly date: string;
    readonly _id: string;


    constructor(idRoom: number, fromUser: number, byIdUser: number, date: string, id: string) {
        this.idRoom = idRoom;
        this.fromUser = fromUser;
        this.byIdUser = byIdUser;
        this.date = date;
        this._id = id;
    }
}
