import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BehaviorSubject} from "rxjs";
import {LANGUAGE} from "../../../environments/resources.theme";
import {LanguageStoreService} from "../languagestore/language-store.service";
import {LocaleService} from "../locales/locale.service";

@Component({
    selector: 'app-language-editor',
    templateUrl: './language-editor.component.html',
    styleUrls: ['./language-editor.component.scss']
})
export class LanguageEditorComponent implements OnInit, AfterViewInit {

    constructor(
        public activeModal: NgbActiveModal,
        private localeService: LocaleService,
        private languageStoreService: LanguageStoreService
    ) {
    }

    readonly loadingSubj = new BehaviorSubject(true);

    readonly languageEsActivated = new BehaviorSubject(false);
    readonly languageCaActivated = new BehaviorSubject(false);
    readonly languageEnActivated = new BehaviorSubject(false);
    readonly languagePtActivated = new BehaviorSubject(false);

    ngOnInit() {}

    ngAfterViewInit(): void {
        this.languageStoreService.getLanguage().subscribe(
            lang => {
                switch (lang) {
                    case LANGUAGE.ES:
                        this.languageEsActivated.next(true);
                        break;
                    case LANGUAGE.CA:
                        this.languageCaActivated.next(true);
                        break;
                    case LANGUAGE.EN:
                        this.languageEnActivated.next(true);
                        break;
                    case LANGUAGE.PT:
                        this.languagePtActivated.next(true);
                        break;
                }
                this.loadingSubj.next(false);
            }
        )
    }

    onClickLanguageEs() {
        this.onClickLanguage(LANGUAGE.ES)
    }
    onClickLanguageCa() {
        this.onClickLanguage(LANGUAGE.CA);
    }
    onClickLanguageEn() {
        this.onClickLanguage(LANGUAGE.EN);
    }
    onClickLanguagePt() {
        this.onClickLanguage(LANGUAGE.PT);
    }

    onClickLanguage(lang: LANGUAGE) {
        this.languageStoreService.putLanguage(lang).subscribe(
            _ => {},
            _ => {},
            () => {
                window.location.reload();
            }
        )
    }

}
