import { Component, OnInit } from '@angular/core';
import {AppService} from "../../chatmodule/app.service";
import {LocaleService} from "../../shared/locales/locale.service";

@Component({
  selector: 'app-not-connection',
  templateUrl: './not-connection.component.html',
  styleUrls: ['./not-connection.component.scss']
})
export class NotConnectionComponent implements OnInit {

  constructor(
      private appService: AppService,
      private localeService: LocaleService
  ) { }

  ngOnInit() {
  }

    onClickRetry($event) {
      this.appService.loadApp().subscribe();
    }
    onLogoutClick() {
      this.appService.logout().subscribe(
          _ => {},
          _ => {},
          () => window.location.href = '/login'
      );
    }
}
