import { Injectable, isDevMode } from "@angular/core";
import { Option } from "fp-ts/lib/Option";
import { LANGUAGE } from "../../../environments/resources.theme";
import { existsFirebaseProject, selectedTheme, version } from "./theme";
import { AppError } from "../core/sources/remote/globalmodels/AppError";
import { LoginType, ThemeType } from "../../../environments/ThemeType";

@Injectable({
    providedIn: "root",
})
export class ThemeService {
    mainTheme: ThemeType = selectedTheme;
    version: string = version;

    getNameTheme(): string {
        return this.mainTheme.name;
    }

    getPathLoginLogoTheme(): string {
        return this.mainTheme.login_logo;
    }

    getPathHeaderLogoTheme(): string {
        return this.mainTheme.header_logo;
    }

    getPathFaviconLogoTheme(): string {
        return this.mainTheme.favicon_logo;
    }

    // getMainColorTheme() : string {
    //     return this.mainTheme.main_color;
    // }
    //
    // getSecColorTheme() : string {
    //     return this.mainTheme.sec_color;
    // }

    getColors(): { high: string; medium: string; low: string } {
        return this.mainTheme.colors;
    }

    isEncryptedPassword(): boolean {
        return this.mainTheme.encrypted_password;
    }

    getTitlePostregister(): string {
        return this.mainTheme.title_postregister;
    }

    getDescriptionPostregister(): string {
        return this.mainTheme.description_postregister;
    }

    getLoginFieldShadow(): string {
        return this.mainTheme.login_field_shadow;
    }

    getBackendUrlComplete(): string {
        return this.mainTheme.backend_url_complete;
    }

    getBackendUrlApiComplete(): string {
        if (isDevMode() && window.location.hostname === "localhost")
            return this.mainTheme.api_path;
        return this.mainTheme.backend_url_complete + this.mainTheme.api_path;
    }

    getMessageFromIAppError(error: AppError): string {
        return error.detail[this.mainTheme.lang];
    }

    getForgottenPasswordInfo(): {
        activated: boolean;
        text_forgot_password: string;
        link: string;
    } {
        return this.mainTheme.forgotten_password;
    }

    getVersion(): string {
        return this.version;
    }

    isDiscoverActivated(): boolean {
        return this.mainTheme.activated_discovers;
    }

    getTitle(): string {
        return this.mainTheme.page_title;
    }

    existsFirebaseProject(): boolean {
        return existsFirebaseProject();
    }

    getHeaderLogos(): { icon: string; url: string; with_lightbox: boolean }[] {
        return this.mainTheme.header_logos;
    }

    getLang(): string {
        return this.mainTheme.lang;
    }

    getCompanyNameTitle(): string {
        return this.mainTheme.companyNameTitle;
    }

    getDiscoverIcon(): string {
        return this.mainTheme.discover_icon;
    }

    isPolicyActivated(): boolean {
        return this.mainTheme.showPolicy;
    }

    getWelcomeVideo(): { url: string } | undefined {
        return this.mainTheme.welcomeVideo;
    }

    getUserNotFoundString(): string {
        return this.mainTheme.user_not_found_string;
    }

    showFormLogin(): boolean {
        return this.mainTheme.showFormLogin;
    }

    getExternalLogins(): LoginType[] {
        return this.mainTheme.externalLogins;
    }

    withSessionsButton(): boolean {
        return this.mainTheme.withSessionsButton;
    }

    showInactiveSessions(): boolean {
        return this.mainTheme.showInactiveSessions;
    }

    getDefaultLanguage(): Option<LANGUAGE> {
        console.log(this.mainTheme);
        return this.mainTheme.defaultLanguage;
    }

    isReplyActivated(): boolean {
        return this.mainTheme.replyActivated;
    }

    changePasswordActivated(): boolean {
        return this.mainTheme.changePasswordActivated;
    }

    textLoginActivated(): boolean {
        return this.mainTheme.showTextLogin;
    }

    getLoginText(): string {
        return this.mainTheme.textLogin;
    }

    getiOSBadge(): { badge: string; url: string } | undefined {
        return {
            badge: "https://resources.zonetacts.com/stores/apple/Download-on-the-App-Store/en/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge.svg",
            url: `https://apps.apple.com/es/app/${this.mainTheme.stores.AppStore}`,
        };
    }

    getAndroidBadge(): { badge: string; url: string } | undefined {
        return {
            badge: "https://resources.zonetacts.com/stores/google/download/en/svg/google-play-badge.svg",
            url: `https://play.google.com/store/apps/details?id=${this.mainTheme.stores.GooglePlay}`,
        };
    }
}
