import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as io from 'socket.io-client';
import {HttpHeadersManager} from '../sources/remote/core/headers/HttpHeadersManager';
import {flatMap} from 'rxjs/operators';
import {ThemeService} from '../../themes/theme.service';
import {SocketNotConnectedException} from './exceptions/SocketNotConnectedException';

@Injectable({
    providedIn: 'root'
})
export class SocketClient {

    private socket: SocketIOClient.Socket;

    private getURLByIdClient(idClient: number) : string {
        return this.themeService.getBackendUrlComplete() + '/' + idClient;
    }

    constructor(
        private themeService: ThemeService
    ) {}

    initSocket(idClient: number, eventsAssigns: {event: string, f: (object: any) => void}[], headers: any) : void {

        const options = {
            transports: ['websocket'],
            query: headers,
            forceNew: true,
            reconnection: false,
            autoConnect: false
            // secure: true
        };

        this.socket = io(this.getURLByIdClient(idClient), options);
        this.socket.connect();
        for(let i = 0; i < eventsAssigns.length; i++) {
            this.socket.on(eventsAssigns[i].event, eventsAssigns[i].f);
        }

    }

    assignEventSocket(event: string, f: (object: any) => void) : void {
        this.socket.on(event, f);
    }

    sendObjToSocket(obj: any, event: string) : void {
        this.socket.emit(event, obj);
    }

    invalidateSocketInstance() : void {
        try {
            this.socket.disconnect();
            this.socket.removeAllListeners();
            this.socket.close();
            // this.socket.conn.close();
        } catch (e) {
            console.log({text: "socket error when closing", error: e});
        }
        this.socket = undefined;
    }

}
