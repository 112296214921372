import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {from, Observable, of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> {
        return this.authService.isAuthenticated().pipe(
            flatMap(isAuthenticated => {
                if (!isAuthenticated) {
                    return from(this.router.navigate(['/login'])).pipe(map(_ => false));
                } else {
                    // return from(this.router.navigate(['/chat'])).pipe(map(_ => false));
                }
                return of(true);
            })
            // map(isAuthenticated => {
            //     if (isAuthenticated)
            // })
            // flatMap(isAuthenticated => {
            //     if (isAuthenticated) {
            //         return from(this.router.navigate(['/chat']));
            //     } else {
            //         return from(this.router.navigate(['/login']));
            //     }
            // }),
            // map(_ => true)
        );
    }
}
