import {Update} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {MessageOTO} from "../../../domain/MessageOTO";
import {MessageRoom} from "../../../domain/MessageRoom";
import {RefreshListRoomsAction, SaveListRoomsAction} from "../actions/generic/rooms.actions";
import {RefreshListUsersAction} from "../actions/generic/users.actions";
import {
    cleanNextPageMessagesOTO,
    LoadFromDateMessageOTOSuccessAction,
    LoadFromDateMessageRoomSuccessAction,
    loadNextPageMessagesOTO,
    loadNextPageMessagesOTOSuccess,
    loadNextPageMessagesRoom,
    loadNextPageMessagesRoomSuccess, onDeleteMessageOTOLocalAction,
    onDeleteMessageOTOSuccessAction, onDeleteMessageRoomLocalAction,
    onDeleteMessageRoomSuccessAction,
    onNewMessageOTO,
    onNewMessageRoom,
    onNewModificationMessageOTOAction,
    onNewModificationMessageRoomAction,
    onSendMessageOTO,
    onSendMessageOTOFail,
    onSendMessageOTOFile,
    onSendMessageOTOFileFail,
    onSendMessageOTOSuccess,
    onSendMessageRoom,
    onSendMessageRoomFail,
    onSendMessageRoomFile,
    onSendMessageRoomFileFail,
    onSendMessageRoomSuccess
} from '../actions/messages.actions';
import {
    NewMessagesRoomGroupAction,
    NewMessagesRoomOTOAction
} from "../actions/principal_screen_app/synchronize_messages.actions";
import {
    leaveRoomGroupSuccess,
    leaveRoomOTOSuccess,
    newRoomGetted,
    onOpenOTOSuccess,
    selectRoomSuccess
} from '../actions/room.actions';
import {
    adapterMessageOTO,
    adapterMessageRoom,
    adapterPagedRoomGroupMessages,
    adapterPagedRoomOTOMessages
} from '../adapters/messages.adapters';
import {PagedRoomGroupMessages} from '../entities/PagedRoomGroupMessages';
import {PagedRoomOTOMessages} from '../entities/PagedRoomOTOMessages';
import {
    initialMessageOTOState,
    initialMessageRoomState,
    initialPagedRoomGroupMessages,
    initialPagedRoomOTOMessages,
    IPagedOTOGroupMessagesState,
    IPagedRoomGroupMessagesState
} from '../state/message.state';
import {LastEvent} from '../state/utils/LastEvent';


// function changeMessageRoomState(
//     state: IPagedRoomGroupMessagesState,
//     idRoom: number,
//     idMessage: string,
//     stateMessage: SynchronizationState
// ) : IPagedRoomGroupMessagesState {
//     const et = state.entities[idRoom];
//     const msg : MessageRoom = {
//         ...et.messagesRoomState.entities[idMessage],
//         syncState: stateMessage
//     };
//     return adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
//         id: idRoom,
//         changes: {
//             // ...et,
//             lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
//             lastMessages: [msg],
//             messagesRoomState: adapterMessageRoom.updateOne(<Update<MessageRoom>>{
//                 id: idMessage,
//                 changes: {
//                     ...msg
//                 }
//             }, et.messagesRoomState)
//         }
//     }, state);
// }

// function changeReplyFromReferenceDeleted<T extends Message>(
//     msgDeleted: T,
//     msgOrigin: T
// ) : T {
//     return <T> {
//         ...msgOrigin,
//         messageReply: pipe(
//             msgOrigin.messageReply,
//             O.map(({idUser, idMessage, text, date, file}) => ({
//                 idUser: idUser,
//                 idMessage: idMessage,
//                 text: msgDeleted.text,
//                 date: date,
//                 file: O.none,
//                 modifiedState: ModifiedState.DELETED
//             }))
//         )
//     }
// }

const messageRoomReducer = createReducer(
    initialPagedRoomGroupMessages,

    on(newRoomGetted, (state, {newRoom}) => {
        return adapterPagedRoomGroupMessages.addOne(new PagedRoomGroupMessages(
            initialMessageRoomState,
            newRoom.roomGroup.idRoom,
            newRoom.roomGroup.messageCount,
            LastEvent.NO_OP,
            [],
        ), state)
    }),
    on(onNewMessageRoom, (state, {message}) => {
        const pr = state.entities[message.idRoom];
        if (pr !== undefined) {
            let msgState;
            if (message.internalId !== undefined) {
                msgState = adapterMessageRoom.addOne(message, adapterMessageRoom.removeOne(message.internalId, state.entities[message.idRoom].messagesRoomState));
            } else {
                msgState = adapterMessageRoom.addOne(message, state.entities[message.idRoom].messagesRoomState);
            }
            return adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>> {
                id: message.idRoom,
                changes: {
                    // ...state.entities[message.idRoom],
                    messagesRoomState: msgState,
                    lastEvent: LastEvent.NEW_MESSAGE_RECEIVED,
                    lastMessages: [message],
                    messagesToRead: state.entities[message.idRoom].messagesToRead + 1
                }
            }, state);
        } else {
            return adapterPagedRoomGroupMessages.addOne(
                new PagedRoomGroupMessages(
                    adapterMessageRoom.addOne(message, initialMessageRoomState),
                    message.idRoom,
                    1,
                    LastEvent.NEW_MESSAGE_RECEIVED,
                    [message],
                ),
                state
            );

        }
    }),
    on(onNewModificationMessageRoomAction, onDeleteMessageRoomSuccessAction, (state, {message}) => {
        const et = state.entities[message.idRoom];
        if (et && et.messagesRoomState.entities[message.idMessage]) {
            return adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
                id: message.idRoom,
                changes: {
                    // ...et,
                    lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
                    lastMessages: [message],
                    messagesRoomState: adapterMessageRoom.updateOne(<Update<MessageRoom>>{
                        id: message.idMessage,
                        changes: {
                            ...message
                        }
                    }, et.messagesRoomState)
                }
            }, state);
        } else {
            return state;
        }
    }),
    on(onDeleteMessageRoomLocalAction, (state, {idRoom, idMessage}) => {
        const et = state.entities[idRoom];
        if (et && et.messagesRoomState.entities[idMessage]) {
            return adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
                id: idRoom,
                changes: {
                    // ...et,
                    messagesRoomState: adapterMessageRoom.removeOne(idMessage, et.messagesRoomState)
                }
            }, state)
        }
        return state;
    }),
    // on(onDeleteMessageRoomAction, (state, {idRoom, idMessage}) => changeMessageRoomState(state, idRoom, idMessage, SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED)),
    // on(onDeleteMessageRoomFailAction, (state, {idRoom, idMessage}) => changeMessageRoomState(state, idRoom, idMessage, SynchronizationState.FAIL)),
    on(onSendMessageRoom, (state, {message}) => {
        return adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
            id: message.idRoom,
            changes: {
                // ...state.entities[message.idRoom],
                messagesRoomState: adapterMessageRoom.addOne(message, state.entities[message.idRoom].messagesRoomState),
                lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
                lastMessages: [message]
            }
        }, state);
    }),
    on(onSendMessageRoomSuccess, (state, {message}) => {
        return adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
            id: message.idRoom,
            changes: {
                // ...state.entities[message.idRoom],
                messagesRoomState: adapterMessageRoom.addOne(message, adapterMessageRoom.removeOne(message.idMessage, state.entities[message.idRoom].messagesRoomState)),
                lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
                lastMessages: [message]
            }
        }, state);
    }),
    on(onSendMessageRoomFail, (state, {error, message}) => adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
        id: message.idRoom,
        changes: {
            messagesRoomState: adapterMessageRoom.addOne(message, adapterMessageRoom.removeOne(message.idMessage, state.entities[message.idRoom].messagesRoomState)),
            lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
            lastMessages: [message]
        }
    }, state)),
    on(onSendMessageRoomFile, (state, {message}) => adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
        id: message.idRoom,
        changes: {
            // ...state.entities[message.idRoom],
            messagesRoomState: adapterMessageRoom.addOne(message, adapterMessageRoom.removeOne(message.idMessage, state.entities[message.idRoom].messagesRoomState)),
            lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
            lastMessages: [message]
        }
    }, state)),
    on(onSendMessageRoomFileFail, (state, {error, message}) =>
        adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
            id: message.idRoom,
            changes: {
                messagesRoomState: adapterMessageRoom.addOne(message, adapterMessageRoom.removeOne(message.idMessage, state.entities[message.idRoom].messagesRoomState)),
                lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
                lastMessages: [message]
            }
        }, state)
    ),
    // on(onSendMessageRoomFileSuccess, (state, {message}) => adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
    //     id: message.idRoom,
    //     changes: {
    //         ...state.entities[message.idRoom],
    //         messagesRoomState: adapterMessageRoom.addOne(message, adapterMessageRoom.removeOne(message.internalId, state.entities[message.idRoom].messagesRoomState)),
    //         lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
    //         lastMessages: [message]
    //     }
    // }, state)),
    on(loadNextPageMessagesRoom, state => state),
    on(loadNextPageMessagesRoomSuccess, (state, {idRoom, messages}) => adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
        id: idRoom,
        changes: {
            // ...state.entities[idRoom],
            messagesRoomState: adapterMessageRoom.addMany(messages, state.entities[idRoom].messagesRoomState),
            lastEvent: LastEvent.LOADED_PAGE,
            lastMessages: messages
        }
    }, state)),
    on(leaveRoomGroupSuccess, (state, {idRoom}) =>
        adapterPagedRoomGroupMessages.removeOne(idRoom, state)
    ),
    // NEW
    on(SaveListRoomsAction, (state, {rooms}) => adapterPagedRoomGroupMessages.addAll(
        rooms.map(room => {
            const stateMessages = room.roomGroup.lastMessage ?
                adapterMessageRoom.addOne(room.roomGroup.lastMessage, initialMessageRoomState) :
                initialMessageRoomState;
            return new PagedRoomGroupMessages(
                stateMessages,
                room.roomGroup.idRoom,
                0,
                LastEvent.NEW_MESSAGE_RECEIVED,
                [room.roomGroup.lastMessage]
            )
        }),
        state
    )),
    on(RefreshListRoomsAction, (state, {all, deleted}) => {
        const st = adapterPagedRoomGroupMessages.removeMany(deleted.rooms, state);
        return adapterPagedRoomGroupMessages.addMany(all.rooms.map(room => {
            const stateMessages = room.roomGroup.lastMessage ?
                adapterMessageRoom.addOne(room.roomGroup.lastMessage, initialMessageRoomState) :
                initialMessageRoomState;
            return new PagedRoomGroupMessages(
                stateMessages,
                room.roomGroup.idRoom,
                0,
                LastEvent.NEW_MESSAGE_RECEIVED,
                [room.roomGroup.lastMessage]
            )
        }), st)
    }),
    on(NewMessagesRoomGroupAction, (state, {messages, idRoom}) => adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
        id: idRoom,
        changes: {
            // ...state.entities[idRoom],
            messagesRoomState: adapterMessageRoom.upsertMany(messages, state.entities[idRoom].messagesRoomState),
            lastEvent: LastEvent.LOADED_LAST_MESSAGES,
            lastMessages: messages,
            messagesToRead: state.entities[idRoom].messagesToRead + messages.length
        }
    }, state)),
    on(selectRoomSuccess, (state, {idRoom, idUser}) => {
        if (idRoom !== undefined) {
            return adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
                id: idRoom,
                changes: {
                    lastEvent : LastEvent.SELECTED_ROOM
                }
            }, state);
        }
        return state;

    }),
    on(LoadFromDateMessageRoomSuccessAction, (state, {idRoom, messages}) => adapterPagedRoomGroupMessages.updateOne(<Update<PagedRoomGroupMessages>>{
        id: idRoom,
        changes: {
            lastEvent: LastEvent.LOADED_MESSAGE_FROM_DATE,
            messagesRoomState: adapterMessageRoom.addMany(messages, state.entities[idRoom].messagesRoomState)
        }
    }, state))
);


export function messageRoomReducerFunction(state: IPagedRoomGroupMessagesState | undefined, action: Action) {
    return messageRoomReducer(state, action);
}

// function changeMessageOTOState(
//     state: IPagedOTOGroupMessagesState,
//     idUser: number,
//     idMessage: string,
//     stateMessage: SynchronizationState
// ) : IPagedOTOGroupMessagesState {
//     const et = state.entities[idUser];
//     const msg : MessageOTO = {
//         ...et.messagesOTOState.entities[idMessage],
//         syncState: stateMessage
//     };
//     return adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
//         id: idUser,
//         changes: {
//             // ...et,
//             lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
//             lastMessages: [msg],
//             messagesOTOState: adapterMessageOTO.updateOne(<Update<MessageOTO>>{
//                 id: idMessage,
//                 changes: {
//                     ...msg
//                 }
//             }, et.messagesOTOState)
//         }
//     }, state);
// }

const messageOTOReducer = createReducer(
    initialPagedRoomOTOMessages,
    on(onOpenOTOSuccess, (state, {newOTO}) =>
        adapterPagedRoomOTOMessages.addOne(new PagedRoomOTOMessages(
            initialMessageOTOState,
            newOTO.roomOTO.idUser,
            LastEvent.NO_OP,
            []
        ), state)
    ),
    on(onNewMessageOTO, (state, {message}) => {
        const pr = state.entities[message.idUserOTO];
        if (pr !== undefined) {
            let msgState;
            if (message.internalId !== undefined) {
                msgState = adapterMessageOTO.addOne(message, adapterMessageOTO.removeOne(message.internalId, state.entities[message.idUserOTO].messagesOTOState));
            } else {
                msgState = adapterMessageOTO.addOne(message, state.entities[message.idUserOTO].messagesOTOState);
            }
            return adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
                id: message.idUserOTO,
                changes: {
                    // ...state.entities[message.idUser],
                    messagesOTOState: msgState,
                    lastEvent: LastEvent.NEW_MESSAGE_RECEIVED,
                    lastMessages: [message]
                }
            }, state);
        } else {
            return adapterPagedRoomOTOMessages.addOne(
                new PagedRoomOTOMessages(
                    adapterMessageOTO.addOne(message, initialMessageOTOState),
                    message.idUserOTO,
                    LastEvent.NEW_MESSAGE_RECEIVED,
                    [message]
                ),
                state
            );
        }
    }),
    on(onNewModificationMessageOTOAction, onDeleteMessageOTOSuccessAction, (state, {message}) => {
        const et = state.entities[message.idUserOTO];
        if (et && et.messagesOTOState.entities[message.idMessage]) {
            return adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
                id: message.idUserOTO,
                changes: {
                    // ...et,
                    lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
                    messagesOTOState: adapterMessageOTO.updateOne(<Update<MessageOTO>>{
                        id: message.idMessage,
                        changes: {
                            ...message
                        }
                    }, et.messagesOTOState),
                    lastMessages: [message]
                }
            }, state);
        } else {
            return state;
        }
    }),
    on(onDeleteMessageOTOLocalAction, (state, {idUser, idMessage}) => {
        const et = state.entities[idUser];
        if (et && et.messagesOTOState.entities[idMessage]) {
            return adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
                id: idUser,
                changes: {
                    // ...et,
                    messagesOTOState: adapterMessageOTO.removeOne(idMessage, et.messagesOTOState)
                }
            }, state);
        } else {
            return state;
        }
    }),
    // on(onDeleteMessageOTOAction, (state, {idUser, idMessage}) => changeMessageOTOState(state, idUser, idMessage, SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED)),
    // on(onDeleteMessageOTOFailAction, (state, {idUser, idMessage}) => changeMessageOTOState(state, idUser, idMessage, SynchronizationState.FAIL)),
    on(onSendMessageOTO, (state, {message}) =>
        adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
            id: message.idUserOTO,
            changes: {
                // ...state.entities[message.idUser],
                messagesOTOState: adapterMessageOTO.addOne(message, state.entities[message.idUserOTO].messagesOTOState),
                lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
                lastMessages: [message]
            }
        }, state)
    ),
    on(onSendMessageOTOSuccess, (state, {message}) =>
        adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
            id: message.idUserOTO,
            changes: {
                // ...state.entities[message.idUser],
                messagesOTOState: adapterMessageOTO.addOne(message, adapterMessageOTO.removeOne(message.idMessage, state.entities[message.idUserOTO].messagesOTOState)),
                lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
                lastMessages: [message]
            },

        }, state)
    ),
    on(onSendMessageOTOFail, (state, {message}) =>
        adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
            id: message.idUserOTO,
            changes: {
                messagesOTOState: adapterMessageOTO.addOne(message, adapterMessageOTO.removeOne(message.idMessage, state.entities[message.idUserOTO].messagesOTOState)),
                lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
                lastMessages: [message]
            }
        }, state)
    ),
    on(onSendMessageOTOFile, (state, {message}) => adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
        id: message.idUserOTO,
        changes: {
            // ...state.entities[message.idUser],
            messagesOTOState: adapterMessageOTO.addOne(message, adapterMessageOTO.removeOne(message.idMessage, state.entities[message.idUserOTO].messagesOTOState)),
            lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
            lastMessages: [message]
        }
    }, state)),
    on(onSendMessageOTOFileFail, (state, {error, message}) =>
        adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
            id: message.idUserOTO,
            changes: {
                messagesOTOState: adapterMessageOTO.addOne(message, adapterMessageOTO.removeOne(message.idMessage, state.entities[message.idUserOTO].messagesOTOState)),
                lastEvent: LastEvent.MESSAGE_NOT_SENDED_NOT_SYNCHRONIZED,
                lastMessages: [message]
            }
        }, state)
    ),
    // on(onSendMessageOTOFileSuccess, (state, {message}) => {
    //     const pr = state.entities[message.idUser];
    //     if (pr !== undefined) {
    //         let msgState;
    //         if (message.internalId !== undefined) {
    //             const origMsg = pr.messagesOTOState.entities[message.internalId];
    //             if (origMsg) {
    //                 if (origMsg.syncState === SynchronizationState.SYNCHRONIZED)
    //                     return state;
    //                 else
    //                     msgState = adapterMessageOTO.addOne(message, adapterMessageOTO.removeOne(message.internalId, state.entities[message.idUser].messagesOTOState));
    //             } else {
    //                 msgState = adapterMessageOTO.addOne(message, state.entities[message.idUser].messagesOTOState);
    //             }
    //         } else {
    //             msgState = adapterMessageOTO.addOne(message, state.entities[message.idUser].messagesOTOState);
    //         }
    //         return adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
    //             id: message.idUser,
    //             changes: {
    //                 ...state.entities[message.idUser],
    //                 messagesOTOState: msgState,
    //                 lastEvent: LastEvent.MESSAGE_SENDED_NOT_SYNCHRONIZED,
    //                 lastMessages: [message]
    //             }
    //         }, state)
    //     }
    //     return state;
    // }),
    on(loadNextPageMessagesOTO, state => state),
    on(loadNextPageMessagesOTOSuccess, (state, {idUser, messages}) => {
        return adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
            id: idUser,
            changes: {
                // ...state.entities[idUser],
                messagesOTOState: adapterMessageOTO.addMany(messages, state.entities[idUser].messagesOTOState),
                lastEvent: LastEvent.LOADED_PAGE,
                lastMessages: messages
            }
        }, state);
    }),
    on(cleanNextPageMessagesOTO, (state, {idUser}) => adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
        id: idUser,
        changes: {
            // ...state.entities[idUser],
            lastPageMessages: undefined
        }
    }, state)),
    on(leaveRoomOTOSuccess, (state, {idUser}) =>
        adapterPagedRoomOTOMessages.removeOne(idUser, state)
    ),
    // NEW
    on(SaveListRoomsAction, (state, {otos}) => adapterPagedRoomOTOMessages.addAll(
        otos.map(oto => {
            const stateMessages = oto.roomOTO.lastMessage ?
                adapterMessageOTO.addOne(oto.roomOTO.lastMessage, initialMessageOTOState) :
                initialMessageOTOState;
            return new PagedRoomOTOMessages(
                stateMessages,
                oto.roomOTO.idUser,
                LastEvent.NEW_MESSAGE_RECEIVED,
                [oto.roomOTO.lastMessage]
            )
        }),
        state
    )),
    on(RefreshListUsersAction, (state, {deleted}) => adapterPagedRoomOTOMessages.removeMany(deleted, state)),
    on(RefreshListRoomsAction, (state, {all, deleted}) => {
        const st = adapterPagedRoomOTOMessages.removeMany(deleted.otos, state);
        return adapterPagedRoomOTOMessages.addMany(all.otos.map(oto => {
            const stateMessages = oto.roomOTO.lastMessage ?
                adapterMessageOTO.addOne(oto.roomOTO.lastMessage, initialMessageOTOState) :
                initialMessageOTOState;
            return new PagedRoomOTOMessages(
                stateMessages,
                oto.roomOTO.idUser,
                LastEvent.NEW_MESSAGE_RECEIVED,
                [oto.roomOTO.lastMessage]
            )
        }), st)
    }),
    on(NewMessagesRoomOTOAction, (state, {messages, idUser}) => adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
        id: idUser,
        changes: {
            // ...state.entities[idUser],
            messagesOTOState: adapterMessageOTO.upsertMany(messages, state.entities[idUser].messagesOTOState),
            lastEvent: LastEvent.LOADED_LAST_MESSAGES,
            lastMessages: messages
        }
    }, state)),
    on(selectRoomSuccess, (state, {idRoom, idUser}) => {
        if (idUser !== undefined) {
            return adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
                id: idUser,
                changes: {
                    lastEvent : LastEvent.SELECTED_ROOM
                }
            }, state);
        }
        return state;

    }),
    on(LoadFromDateMessageOTOSuccessAction, (state, {idUserOTO, messages}) => adapterPagedRoomOTOMessages.updateOne(<Update<PagedRoomOTOMessages>>{
        id: idUserOTO,
        changes: {
            messagesOTOState: adapterMessageOTO.addMany(messages, state.entities[idUserOTO].messagesOTOState),
            lastEvent: LastEvent.LOADED_MESSAGE_FROM_DATE
        }
    }, state))
);

export function messageOTOReducerFunction(state: IPagedOTOGroupMessagesState | undefined, action : Action) {
    return messageOTOReducer(state, action);
}


