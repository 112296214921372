

export class LocalStorageKeyNotFoundException extends Error{

    public constructor(
        private key: string
    ) {
        super();
    }
}
