import {Injectable} from '@angular/core';
import {IMessageOTOState, IMessageRoomState} from '../state/message.state';
import {Store} from '@ngrx/store';
import {Observable, zip} from 'rxjs';
import {MessageRoom} from '../../../domain/MessageRoom';
import {
    getAllIdsAndInternalIdsMessageRoomByIdRoomSelect, getAllIdsAndInternalIdsMessageRoomByIdUserSelect,
    getAllMessagesOTOByIdUserSelect,
    getAllMessagesRoomByIdSelect, getLastMessageByIdRoom, getLastMessageByIdUserOTO,
    getMessageOTOByIdUserIdMessage,
    getMessageRoomByIdRoomIdMessage, getNextMessageToSend,
    getTripletMessagesOTOByIdUserSelect,
    getTripletMessagesRoomByIdRoomSelect
} from '../selectors/messages.selectors';
import {defaultIfEmpty, filter, finalize, map, switchMap, take, tap} from 'rxjs/operators';
import {MessageOTO} from '../../../domain/MessageOTO';
import {
    CancelSendFileMessageOTOAction,
    CancelSendFileMessageRoomAction,
    LoadFromDateMessageOTOAction,
    LoadFromDateMessageOTOSuccessAction,
    LoadFromDateMessageRoomAction,
    loadNextPageMessagesOTO,
    loadNextPageMessagesRoom, onDeleteMessageOTOAction, onDeleteMessageRoomAction,
    onMessageOTOImageToLoad,
    onMessageRoomImageToLoad,
    onNewMessageOTO,
    onNewMessageRoom, onNewModificationMessageOTOAction, onNewModificationMessageRoomAction,
    onSendMessageOTO,
    onSendMessageOTOFile, onSendMessageOTOFileSuccess,
    onSendMessageOTOSuccess,
    onSendMessageRoom,
    onSendMessageRoomFile,
    onSendMessageRoomFileSuccess,
    onSendMessageRoomSuccess, RetrySendMessageOTOAction, RetrySendMessageRoomAction
} from '../actions/messages.actions';
import {getImageMessageRoomResponseSelect,} from '../selectors/responses.selectors';
import {cleanResponseGetImageMessageRoom,} from '../actions/responses.actions';
import {IAppState} from '../state/app.state';
import {LastEvent} from '../state/utils/LastEvent';
import {fromNullable, Option} from "fp-ts/lib/Option";
import {StandardError} from "../../../domain/StandardError";
import {getRoomsGroupSelect, getRoomsOTOSelect} from "../selectors/rooms.selectors";
import * as A from "fp-ts/lib/Array";
import {SynchronizationState} from "../../../domain/SynchronizationState";
import {Either} from "fp-ts/lib/Either";


@Injectable({
    providedIn: 'root'
})
export class MessagesFacade {

    constructor(
        private store: Store<IMessageRoomState>,
        private messageOTOStore: Store<IMessageOTOState>,
        private appStore: Store<IAppState>,
    ) {}

    onNewMessageRoom(messageRoom: MessageRoom) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(onNewMessageRoom({message:messageRoom}));
            subscriber.complete();
        });
    }

    onNewMessageOTO(messageOTO: MessageOTO) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(onNewMessageOTO({message: messageOTO}));
            subscriber.complete();
        });
    }

    onNewModificationMessageRoom(m: MessageRoom) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onNewModificationMessageRoomAction({message: m}));
            s.complete();
        })
    }
    onNewModificationMessageOTO(m: MessageOTO) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onNewModificationMessageOTOAction({message: m}));
            s.complete();
        })
    }

    dispatchDeleteMessageRoomAction(idRoom: number, idMessage: string) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onDeleteMessageRoomAction({idRoom: idRoom, idMessage: idMessage}));
            s.complete();
        })
    }
    dispatchDeleteMessageOTOAction(idUser: number, idMessage: string) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onDeleteMessageOTOAction({idUser: idUser, idMessage: idMessage}));
            s.complete();
        });
    }

    getAllMessagesNotSended() : Observable<{messagesRoom: MessageRoom[], messagesOTO: MessageOTO[]}> {
        const obs1 = this.store.select(getRoomsGroupSelect).pipe(
            map(A.map(r => this.store.select(getAllMessagesRoomByIdSelect(r.roomGroup.idRoom)).pipe(
                map(A.filter(msg => msg.syncState === SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED))
            ))),
            switchMap(obss => zip(...obss)),
            map(A.flatten),
            defaultIfEmpty([])
        );

        const obs2 = this.store.select(getRoomsOTOSelect).pipe(
            map(A.map(r => this.store.select(getAllMessagesOTOByIdUserSelect(r.roomOTO.idUser)).pipe(
                map(A.filter(msg => msg.syncState === SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED))
            ))),
            switchMap(obss => zip(...obss)),
            map(A.flatten),
            defaultIfEmpty([])
        );

        return zip(obs1, obs2).pipe(
            map(([msgGroups, msgOTOs]) => ({messagesRoom: msgGroups, messagesOTO: msgOTOs})),
            take(1)
        );
    }

    getNextMessageToSend(omitInternalIds: string[]) : Observable<Option<Either<MessageOTO, MessageRoom>>> {
        return this.store.select(getNextMessageToSend(omitInternalIds)).pipe(
            take(1)
        )
    }

    dispatchSendMessageRoomSuccess(message: MessageRoom) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onSendMessageRoomSuccess({message: message}));
            s.complete();
        })
    }

    dispatchSendMessageOTOSuccess(message: MessageOTO) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onSendMessageOTOSuccess({message: message}));
            s.complete();
        })
    }

    dispatchSendMessageRoomFileSuccess(message: MessageRoom) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onSendMessageRoomFileSuccess({message: message}));
            s.complete();
        });
    }

    dispatchSendMessageOTOFileSuccess(message: MessageOTO) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onSendMessageOTOFileSuccess({message: message}));
            s.complete();
        })
    }

    getTripletMessagesRoomByIdRoomObservable(idRoom: number) : Observable<{lastEvent: LastEvent, lastMessages: MessageRoom[], allMessages: MessageRoom[], messagesToRead: number}> {
        return this.store.select(getTripletMessagesRoomByIdRoomSelect(idRoom));
    }

    getTripletMessagesOTOByIdUserObservable(idUser: number) : Observable<{lastEvent: LastEvent, lastMessages: MessageOTO[], allMessages: MessageOTO[], messagesToRead: number}> {
        return this.store.select(getTripletMessagesOTOByIdUserSelect(idUser)).pipe(map(event => ({...event, messagesToRead: 0})));
    }

    loadNextPageMessagesByIdRoom(idRoom: number, nbPages: number) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(loadNextPageMessagesRoom({idRoom: idRoom, nbPages: nbPages}));
            subscriber.complete();
        })
    }

    sendNewMessageRoom(message: MessageRoom) : Observable<void> {
        return new Observable<void>(subscriber => {
            console.log("new message emitted 3")
            this.store.dispatch(onSendMessageRoom({message: message}));
            subscriber.complete();
        });
    }

    sendNewMessageFileRoom(message: MessageRoom, file: Blob, base64Thumbnail: string) : Observable<never> {
        return new Observable<never> (subscriber => {
            this.store.dispatch(onSendMessageRoomFile({message: message, file: file, base64Thumbnail: base64Thumbnail}));
            subscriber.complete();
        });
    }

    sendNewMessageFileOTO(message: MessageOTO, file: Blob, base64Thumbnail: string) : Observable<never> {
        return new Observable<never>(subscriber => {
            this.store.dispatch(onSendMessageOTOFile({message: message, file: file, base64Thumbnail: base64Thumbnail}));
            // this.store.dispatch(onSendMessageOTOFile({text: text, thumbnail: thumbnail, idUser: idUser, file: image}));
            subscriber.complete();
        });
    }

    loadNextPageMessagesByIdUser(idUser: number, nbPages: number) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.messageOTOStore.dispatch(loadNextPageMessagesOTO({idUser: idUser, nbPages: nbPages}));
            subscriber.complete();
        });
    }

    sendNewMessageOTO(message: MessageOTO) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.messageOTOStore.dispatch(onSendMessageOTO({message: message}));
            subscriber.complete();
        });
    }

    getImageMessageRoomFailOrSuccess() : Observable<{image: Option<Blob>, error: Option<StandardError>}> {
        return this.appStore.select(getImageMessageRoomResponseSelect).pipe(
            filter(r => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseGetImageMessageRoom()))
        );
    }

    dispatchGetImageMessageRoom(idMessage: string, idRoom: number) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(onMessageRoomImageToLoad({idMessage: idMessage, idRoom: idRoom}));
            subscriber.complete();
        });
    }

    dispatchGetImageMessageOTO(idUser: number, idMessage: string) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(onMessageOTOImageToLoad({idMessage: idMessage, idUser: idUser}));
            subscriber.complete();
        });
    }

    getMessageRoomByIdRoomIdMessage(idRoom: number, idMessage: string) : Observable<MessageRoom> {
        return this.store.select(getMessageRoomByIdRoomIdMessage(idRoom, idMessage)).pipe(
            take(1)
        );
    }
    getMessageRoomByIdRoomIdMessageOption(idRoom: number, idMessage: string) : Observable<Option<MessageRoom>> {
        return this.store.select(getMessageRoomByIdRoomIdMessage(idRoom, idMessage)).pipe(
            take(1),
            map(fromNullable)
        );
    }


    getMessageOTOByIdUserIdMessage(idUser: number, idMessage: string) : Observable<MessageOTO> {
        return this.store.select(getMessageOTOByIdUserIdMessage(idUser, idMessage)).pipe(
            take(1)
        );
    }
    getMessageOTOByIdUserIdMessageOption(idUser: number, idMessage: string) : Observable<Option<MessageOTO>> {
        return this.store.select(getMessageOTOByIdUserIdMessage(idUser, idMessage)).pipe(
            take(1),
            map(fromNullable)
        );
    }

    getAllIdsAndInternalIdsMessageRoomByIdRoom(idRoom: number) : Observable<{idMessage: string, internalId: string}[]> {
        return this.store.select(getAllIdsAndInternalIdsMessageRoomByIdRoomSelect(idRoom)).pipe(
            take(1)
        );
    }

    getAllIdsAndInternalIdsMessageRoomByIdUser(idUser: number) : Observable<{idMessage: string, internalId: string}[]> {
        return this.store.select(getAllIdsAndInternalIdsMessageRoomByIdUserSelect(idUser)).pipe(
            take(1)
        );
    }

    dispatchLoadMessagesRoomFromDate(idRoom: number, date: Date) : Observable<never> {
        return new Observable(s => {
            this.store.dispatch(LoadFromDateMessageRoomAction({idRoom: idRoom, date: date}));
            s.complete();
        })
    }
    dispatchLoadMessagesOTOFromDate(idUserOTO: number, date: Date) : Observable<never> {
        return new Observable(s => {
            this.messageOTOStore.dispatch(LoadFromDateMessageOTOAction({idUserOTO: idUserOTO, date: date}));
            s.complete();
        })
    }
    dispatchRetrySendMessageFileRoom(idRoom: number, internalId: string) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(RetrySendMessageRoomAction({idRoom: idRoom, internalId: internalId}));
            s.complete();
        })
    }
    dispatchRetrySendMessageFileOTO(idUserOTO: number, internalId: string) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(RetrySendMessageOTOAction({idUserOTO: idUserOTO, internalId: internalId}));
            s.complete();
        })
    }
    dispatchCancelMessageFileRoom(idRoom: number, internalId: string) : void {
        this.store.dispatch(CancelSendFileMessageRoomAction({idRoom: idRoom, internalId: internalId}))
    }
    dispatchCancelMessageFileOTO(idUserOTO: number, internalId: string) : void {
        this.store.dispatch(CancelSendFileMessageOTOAction({idUserOTO: idUserOTO, internalId: internalId}))
    }

    getLastMessageRoom(idRoom: number) : Observable<Option<MessageRoom>> {
        return this.store.select(getLastMessageByIdRoom(idRoom));
    }
    getLastMessageOTO(idUserOTO: number) : Observable<Option<MessageOTO>> {
        return this.store.select(getLastMessageByIdUserOTO(idUserOTO));
    }
}

