import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LanguageStoreService} from "../../../languagestore/language-store.service";
import {ConnectErrorSocketException} from "../../realtime/exceptions/ConnectErrorSocketException";
import {AppError} from "../../sources/remote/globalmodels/AppError";
import {StandardError} from "../StandardError";
import {Injectable} from "@angular/core";
import {LocaleService} from "../../../locales/locale.service";
import {ThemeService} from "../../../themes/theme.service";
import {HttpErrorResponse} from "@angular/common/http";

// const AppErrorConnectErrorSocketException_Code = "APP100";

@Injectable({
    providedIn: 'root'
})
export class ErrorsTransformers {
    constructor(
        private localeService : LocaleService,
        // private themeService: ThemeService,
        private languageStoreService: LanguageStoreService
    ) {}

    getStandardErrorFromAppError(error: AppError) : Observable<StandardError> {
        return this.languageStoreService.getLanguage().pipe(
            map(l => new StandardError(
                error.code+'',
                error.detail[l],
                error
            ))
        );
    }

    getStandardErrorFromHttpErrorResponse(error: HttpErrorResponse) : StandardError {
        return new StandardError(
            "APP101",
            this.localeService.translate("APP101", {})
            // error.error,
            // error.message
        );
    }

    getStandardErrorFromAny(error: any) : StandardError {
        const code = "APP001";
        return new StandardError(
            code,
            this.localeService.translate(code, {error: JSON.stringify(error)})
        );
    }

    getStandardErrorFromConnectErrorSocketException(error: ConnectErrorSocketException) : StandardError {
        const code = "APP100";
        return new StandardError(
            code,
            this.localeService.translate(code, {})
        );
    }

    getStandardErrorNotFound() : StandardError {
        const code = "APP404";
        return new StandardError(
            code,
            this.localeService.translate(code, {})
        );
    }

    getStandardErrorConnectionServer() : StandardError {
        const code = "APP102";
        return new StandardError(
            code,
            this.localeService.translate(code, {})
        )
    }
}

// export const getStandardErrorFromConnectErrorSocketException(error: ConnectErrorSocketException) : StandardError {
//     return StandardError(
//         AppErrorConnectErrorSocketException_Code,
//
//     )
// }
