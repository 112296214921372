import {Injectable} from '@angular/core';
import {Option} from "fp-ts/lib/Option";
import {option as O} from "fp-ts";

@Injectable({
    providedIn: 'root'
})
export class SocketsAuthService {

    private requestDeviceCodes : {[key in string]: string} = {}

    constructor() {}

    addDeviceCode(requestId: string, deviceId: string) {
        console.log({module: "SocketsAuthService", text: "addDeviceCode", requestId: requestId, deviceId: deviceId})
        this.requestDeviceCodes[requestId] = deviceId
    }
    getDeviceCode(requestId: string) : Option<string> {
        console.log({module: "SocketsAuthService", text: "getDeviceCode", requestId: requestId, dic: this.requestDeviceCodes})
        return O.fromNullable(this.requestDeviceCodes[requestId])
    }
    removeDeviceCode(requestId: string) {
        this.requestDeviceCodes[requestId] = undefined
    }
}
