import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {IAppState} from "../../state/app.state";
import {Observable} from "rxjs";
import {
    ChatEditGroupSelect,
    ChatLoadMoreGallerySelect,
    DiscoversCheckSelect,
    DiscoversIconSelect, MessagesErrorIconSelect,
    MessagesNotSendedNotSynchronizedIconSelect, MessagesPdfPlaceHolderSelect,
    MessagesSendedNotSynchronizedIconSelect,
    MessagesSendedSynchronizedIconSelect, MessagesVideoPlaceHolderSelect,
    MetaNoConnectionSelect,
    MetaNoImageProfileSelect, RetryIconSelect, ScriptsNotificationSidebarSelect
} from "../../selectors/namespaces/resources_namespace.selectors";
import {take} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ResourcesNamespaceFacade {

    constructor(
        private store: Store<IAppState>
    ) {}

    getMetaNoImageProfile() : Observable<Blob> {
        return this.store.select(MetaNoImageProfileSelect).pipe(
            take(1)
        );
    }
    getMetaNoConnection() : Observable<Blob> {
        return this.store.select(MetaNoConnectionSelect).pipe(
            take(1)
        );
    }
    getDiscoversIcon() : Observable<Blob> {
        return this.store.select(DiscoversIconSelect).pipe(
            take(1)
        );
    }
    getDiscoversCheck() : Observable<Blob> {
        return this.store.select(DiscoversCheckSelect).pipe(
            take(1)
        );
    }
    getChatLoadMoreGallery() : Observable<Blob> {
        return this.store.select(ChatLoadMoreGallerySelect).pipe(
            take(1)
        );
    }
    getChatEditGroup() : Observable<Blob> {
        return this.store.select(ChatEditGroupSelect).pipe(
            take(1)
        );
    }
    getMessagesNotSendedNotSynchronizedIcon() : Observable<Blob> {
        return this.store.select(MessagesNotSendedNotSynchronizedIconSelect).pipe(
            take(1)
        );
    }
    getMessagesSendedNotSynchronizedIcon() : Observable<Blob> {
        return this.store.select(MessagesSendedNotSynchronizedIconSelect).pipe(
            take(1)
        );
    }
    getMessagesSendedSynchronizedIcon() : Observable<Blob> {
        return this.store.select(MessagesSendedSynchronizedIconSelect).pipe(
            take(1)
        );
    }
    getMessagesErrorIcon() : Observable<Blob> {
        return this.store.select(MessagesErrorIconSelect).pipe(take(1));
    }
    getMessagesPdfPlaceholder() : Observable<Blob> {
        return this.store.select(MessagesPdfPlaceHolderSelect).pipe(take(1));
    }
    getScriptsNotificationSidebar() : Observable<Blob> {
        return this.store.select(ScriptsNotificationSidebarSelect).pipe(take(1));
    }
    getMessagesVideoPlaceholder() : Observable<Blob> {
        return this.store.select(MessagesVideoPlaceHolderSelect).pipe(take(1));
    }
    getRetryIcon() : Observable<Blob> {
        return this.store.select(RetryIconSelect).pipe(take(1));
    }
}
