
import * as moment from 'moment';

export function getDateStringOfListByDate(date: Date) : string {
    const n = new Date();
    if(date.getFullYear() === n.getFullYear() && date.getMonth() === n.getMonth()
        && date.getDate() === n.getDate())
        return moment(date).format("HH:mm");
    else
        return moment(date).format("DD/MM/YY");
}

export function getDateStringOfMessageUserStatus(d: Date) : string {
    return moment(d).format("HH:mm") + ' ' + moment(d).format("DD/MM/YY");
}

export function getDateStringHourOfMessageByDate(date: Date) : string {
    return moment(date).format("HH:mm");
}

export function getDateStringOfSeparatorMessageByDate(date: Date) : string {
    return moment(date).format("DD/MM/YY");
}

export function getDateStringForMessageFileFooterByDate(date: Date) : string {
    return moment(date).format("HH:mm") + ' ' + moment(date).format("DD/MM/YY");
}
