import {Action, createReducer, on} from '@ngrx/store';
import {initialPagedFileMessagesState} from '../state/filemessages.state';
import {selectRoomSuccess} from '../actions/room.actions';
import {onLoadNextPageFilesSuccess, onNewMessageOnFileSelectedRoom} from '../actions/filemessages.actions';
// import {PagedFileMessages} from '../entities/PagedFileMessages';
import {adapterFileMessage} from '../adapters/filemessages.adapters';
import {IPagedFileMessages, MESSAGES_BY_PAGE} from '../entities/PagedFileMessages';
import {
    onDeleteMessageOTOAction,
    onDeleteMessageOTOSuccessAction,
    onDeleteMessageRoomSuccessAction, onNewModificationMessageOTOAction, onNewModificationMessageRoomAction
} from "../actions/messages.actions";


const fileMessagesReducer = createReducer(
    initialPagedFileMessagesState(),
    on(selectRoomSuccess, (__, _) =>  initialPagedFileMessagesState()),
    on(onLoadNextPageFilesSuccess, (state, {fileMessages}) => {
        const fullLoadedPages : boolean = fileMessages.length < MESSAGES_BY_PAGE;
        const numMsgs : number = fileMessages.length % MESSAGES_BY_PAGE;
        return ({
            ...state,
            fileMessagesState: adapterFileMessage.addMany(fileMessages, state.fileMessagesState),
            nextLastPageToLoad: state.nextLastPageToLoad + 1,
            numberMessagesLastPage: numMsgs,
            fullPagesLoaded: fullLoadedPages
        });
    }),
    on(onNewMessageOnFileSelectedRoom, (state, {fileMessage}) => {
        let currMsgs = state.numberMessagesLastPage + 1;
        let nextPage = state.nextLastPageToLoad;
        if ((currMsgs % MESSAGES_BY_PAGE) === 0) {
            nextPage++;
            currMsgs = 0;
        }
        return ({
            ...state,
            fileMessagesState: adapterFileMessage.addOne(fileMessage, state.fileMessagesState),
            numberMessagesLastPage: currMsgs,
            nextLastPageToLoad: nextPage,
        })
    }),
    on(onDeleteMessageRoomSuccessAction, onDeleteMessageOTOSuccessAction, (state, {message}) => {
        return ({
            ...state,
            fileMessagesState: adapterFileMessage.removeOne(message.idMessage, state.fileMessagesState)
        })
    }),
    on(onNewModificationMessageRoomAction, onNewModificationMessageOTOAction, (state, {message}) => {

        return ({
            ...state,
            fileMessagesState: adapterFileMessage.removeOne(message.idMessage, state.fileMessagesState)
        })
    })
);

export function fileMessageReducerFunction(state: IPagedFileMessages | undefined, action: Action) {
    return fileMessagesReducer(state, action);
}
