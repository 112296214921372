import {IPagedOTOGroupMessagesState} from "../../state/message.state";
import {Action, createAction, createReducer, on, props} from "@ngrx/store";
import {AppError} from "../../../remote/globalmodels/AppError";
// import {DatesUpdatesState, initialDatesUpdatesState} from "../state/dates_updates.state";
import {
    GenericDatesUpdatesActions, SaveDateUpdateDiscoversAction,
    SaveDateUpdateRoomsAction,
    SaveDateUpdateUsersAction
} from "../../actions/generic/dates_updates.actions";
import {onCreateRoomSuccess} from "../../actions/room.actions";
import {
    DatesUpdatesStateNamespaceState,
    initialDatesUpdatesNamespaceState
} from "../../state/namespaces/dates_updates_namespace.state";

const dates_updates_namespaceReducer = createReducer(
    initialDatesUpdatesNamespaceState,
    on(SaveDateUpdateUsersAction, (state, {date}) => ({
        ...state,
        dates_updates_users: date
    })),
    on(SaveDateUpdateRoomsAction, (state, {date}) => ({
        ...state,
        dates_updates_list_rooms: date
    })),
    on(SaveDateUpdateDiscoversAction, (state, {date}) => ({
        ...state,
        dates_updates_discovers: date
    }))
);


export function datesUpdatesNamespaceReducerFunction(state: DatesUpdatesStateNamespaceState | undefined, action : Action) {
    return dates_updates_namespaceReducer(state, action);
}
