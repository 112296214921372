import {Action, createReducer, on} from "@ngrx/store";
import {
    initialResourcesNamespaceState,
    ResourcesNamespaceState
} from "../../state/namespaces/resources_namespace.state";
import {
    SaveBlobChatEditGroupAction,
    SaveBlobChatLoadMoreGalleryAction,
    SaveBlobDiscoversCheckAction,
    SaveBlobDiscoversIconAction,
    SaveBlobMessagesErrorIconAction,
    SaveBlobMessagesNotSendedNotSynchronizedIconAction,
    SaveBlobMessagesPdfPlaceholderAction,
    SaveBlobMessagesSendedNotSynchronizedIconAction,
    SaveBlobMessagesSendedSynchronizedIconAction,
    SaveBlobMessagesVideosPlaceholderAction,
    SaveBlobMetaNoConnectionAction,
    SaveBlobMetaNoImageProfileAction, SaveBlobRetryIconAction,
    SaveBlobScriptsNotificationSidebarAction
} from "../../actions/namespaces/resources_namespace.action";


const resources_namespaceReducer = createReducer(
    initialResourcesNamespaceState,
    on(SaveBlobMetaNoImageProfileAction, (state, {image}) => ({
        ...state,
        meta: {
            ...state.meta,
            no_image_profile: image
        }
    })),
    on(SaveBlobMetaNoConnectionAction, (state, {image}) => ({
        ...state,
        meta: {
            ...state.meta,
            no_connection: image
        }
    })),
    on(SaveBlobDiscoversIconAction, (state, {image}) => ({
        ...state,
        discovers: {
            ...state.discovers,
            icon: image
        }
    })),
    on(SaveBlobDiscoversCheckAction, (state, {image}) => ({
        ...state,
        discovers: {
            ...state.discovers,
            check: image
        }
    })),
    on(SaveBlobChatEditGroupAction, (state, {image}) => ({
        ...state,
        chat: {
            ...state.chat,
            edit_group: image
        }
    })),
    on(SaveBlobChatLoadMoreGalleryAction, (state, {image}) => ({
        ...state,
        chat: {
            ...state.chat,
            load_more_gallery: image
        }
    })),
    on(SaveBlobMessagesNotSendedNotSynchronizedIconAction, (state, {image}) => ({
        ...state,
        messages: {
            ...state.messages,
            not_sended_not_synchronized_icon: image
        }
    })),
    on(SaveBlobMessagesSendedNotSynchronizedIconAction, (state, {image}) => ({
        ...state,
        messages: {
            ...state.messages,
            sended_not_synchronized_icon: image
        }
    })),
    on(SaveBlobMessagesSendedSynchronizedIconAction, (state, {image}) => ({
        ...state,
        messages: {
            ...state.messages,
            sended_synchronized_icon: image
        }
    })),
    on(SaveBlobMessagesErrorIconAction, (state, {image}) => ({
        ...state,
        messages: {
            ...state.messages,
            error_icon: image
        }
    })),
    on(SaveBlobMessagesPdfPlaceholderAction, (state, {image}) => ({
        ...state,
        messages: {
            ...state.messages,
            pdf_placeholder: image
        }
    })),
    on(SaveBlobScriptsNotificationSidebarAction, (state, {image}) => ({
        ...state,
        scripts: {
            notification_sidebar: image
        }
    })),
    on(SaveBlobMessagesVideosPlaceholderAction, (state, {image}) => ({
        ...state,
        messages: {
            ...state.messages,
            videos_placeholder: image
        }
    })),
    on(SaveBlobRetryIconAction, (state, {image}) => ({
        ...state,
        meta: {
            ...state.meta,
            retry_icon: image
        }
    }))
);

export function resourcesNamespaceReducerFunction(state: ResourcesNamespaceState, action: Action) {
    return resources_namespaceReducer(state, action);
}
