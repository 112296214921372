import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation,
    ViewChild,
    ElementRef,
    AfterViewInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, from, Observable, of, Subject} from 'rxjs';
import {UploadImageComponent} from '../upload-image/upload-image.component';
import {Router} from '@angular/router';
import {ThemeService} from '../themes/theme.service';
import {NgxSpinner} from 'ngx-spinner/lib/ngx-spinner.enum';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertsService} from '../utils/alerts.service';
import {flatMap, map} from 'rxjs/operators';
import {CompreserService} from '../utils/compreser.service';
import {LocaleService} from "../locales/locale.service";
import {fromNullable, Option} from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import {AppStateNamespaceFacade} from "../core/sources/storage/facade/namespaces/app_state_namespace.facade";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit, AfterViewInit {


    @Output() passEntry: EventEmitter<{
        additionalInfo: Option<string>,
        image: Option<Blob>,
        emailContact: Option<Option<string>>,
        emailNotifications: Option<boolean>
    }> = new EventEmitter();

    @Input() esPostRegistro: boolean = false;

    @Input() name: string;
    @Input() surname: string;
    @Input() bio: string;
    @Input() emailContact: string|null;
    @Input() emailNotifications: boolean;
    @Input() emailNotificationsActivated: boolean;
    // @Input() image: Blob | undefined = undefined;
    @Input() image: string | undefined;
    @Input() legalText: string | undefined;
    @Input() showSpinner: EventEmitter<boolean>;
    @Input() hideSpinner: EventEmitter<boolean>;

    @ViewChild("nameField", {static: false}) nameField: ElementRef;
    @ViewChild("surnameField", {static: false}) surnameField: ElementRef;
    @ViewChild("status", {static: false}) statusField: ElementRef;
    @ViewChild("emailContactField", {static: false}) emailContactField: ElementRef;
    @ViewChild("emailNotificationsField", {static: false}) emailNotificationsField: ElementRef;

    emailNotificationsModel : boolean;

    // @ViewChild("policy") policyCheckBoc: ElementRef;
    policyCheckBoxSelected : boolean = false;

    imageSelected : string | ArrayBuffer = undefined;

    inputImageTransformed : string | undefined;
    isInputImageTransformed : Subject<boolean> = new BehaviorSubject(false);


    @ViewChild("uploadImageComponent", {static: false})
    uploadImageComponent: UploadImageComponent;

    titlePostregister: string;
    descriptionPostregister: string;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private themeService : ThemeService,
        private spinner: NgxSpinnerService,
        private alertsService: AlertsService,
        private compresser: CompreserService,
        private localeService: LocaleService,
        private appStateNamespaceFacade: AppStateNamespaceFacade
    ) {}

    ngOnInit() {
        this.titlePostregister = this.localeService.translate(this.themeService.getTitlePostregister(), {companyName: this.themeService.getCompanyNameTitle()});
        this.descriptionPostregister = this.localeService.translate(this.themeService.getDescriptionPostregister(), {});

        this.showSpinner.subscribe(n => this.spinner.show());
        this.hideSpinner.subscribe(n => this.spinner.hide());

        this.emailNotificationsModel = this.emailNotifications;

        // this.inputImageTransformed = this.image;
    }

    ngAfterViewInit(): void {
    }

    onChangeCheckbox() {
        this.policyCheckBoxSelected = !this.policyCheckBoxSelected;
    }

    onImageSelected(img : string | ArrayBuffer) {
        this.imageSelected = img;
    }

    onCroppedImage(img: string) {
        this.compresser.getOrientationFromBase64(img).pipe(
            flatMap(or => {
                const t = this.compresser.getMimeTypeFromBase64(img);
                return this.compresser.compress(
                    img,
                    or,
                    t,
                    true,
                    undefined,
                    true
                );
            }),
            flatMap(({image, thumbnail}) => {
                return from(fetch(image)).pipe(
                    flatMap(res => from(res.blob())),
                )
            })
        ).subscribe(
            blobImg => this.emitObjWithImage(blobImg)
        );
    }

    passBack() { //submit y cerrar modal
        // console.log("close!");
        if (this.esPostRegistro && this.themeService.isPolicyActivated() && !this.policyCheckBoxSelected) {
            this.alertsService.showAlertWithTextError('Has d\'acceptar la política de privacitat!').then(a => {});
        } else {
            if (this.imageSelected !== undefined) {
                this.uploadImageComponent.convertImage();
            } else {
                this.emitObjWithImage(undefined);
            }
        }
    }


    emitObjWithImage(image: Blob | undefined) {
        const img = image === undefined ? O.none : O.some(image);
        // console.log(this.emailNotificationsModel);
        this.passEntry.emit({
            additionalInfo: fromNullable(this.statusField.nativeElement.value),
            image: img,
            emailContact: fromNullable(this.emailContactField.nativeElement.value ? (this.emailContactField.nativeElement.value === "" ? O.none : O.some(this.emailContactField.nativeElement.value)) : null),
            emailNotifications: fromNullable(this.emailNotificationsModel)
        });
    }
}
