import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {fromNullable, Option} from "fp-ts/lib/Option";
import {BehaviorSubject, from, fromEvent, Subject} from "rxjs";
import {UploadImageComponent} from "../upload-image/upload-image.component";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {ThemeService} from "../themes/theme.service";
import {NgxSpinnerService} from "ngx-spinner";
import {AlertsService} from "../utils/alerts.service";
import {CompreserService} from "../utils/compreser.service";
import {LocaleService} from "../locales/locale.service";
import {AppStateNamespaceFacade} from "../core/sources/storage/facade/namespaces/app_state_namespace.facade";
import {flatMap} from "rxjs/operators";
import * as O from "fp-ts/lib/Option";
import {HttpHeadersManager} from "../core/sources/remote/core/headers/HttpHeadersManager";
import {ProfilemodalService} from "../utils/profilemodal.service";
import {SecprofilemodalService} from "../utils/secprofilemodal.service";

@Component({
  selector: 'app-profile-postregister',
  templateUrl: './profile-postregister.component.html',
  styleUrls: ['./profile-postregister.component.scss']
})
export class ProfilePostregisterComponent implements OnInit {

    @Output() passEntry: EventEmitter<{additionalInfo: Option<string>, image: Option<Blob>}> = new EventEmitter();

    @Input() name: string;
    @Input() surname: string;
    @Input() bio: string;
    // @Input() image: Blob | undefined = undefined;
    @Input() image: string | undefined;
    @Input() legalText: string | undefined;
    @Input() showSpinner: EventEmitter<boolean>;
    @Input() hideSpinner: EventEmitter<boolean>;

    @ViewChild("nameField", {static: false}) nameField: ElementRef;
    @ViewChild("surnameField", {static: false}) surnameField: ElementRef;
    @ViewChild("status", {static: false}) statusField: ElementRef;

    // @ViewChild("policy") policyCheckBoc: ElementRef;
    policyCheckBoxSelected : boolean = false;

    imageSelected : string | ArrayBuffer = undefined;

    @ViewChild("uploadImageComponent", {static: false})
    uploadImageComponent: UploadImageComponent;

    titlePostregister: string;
    descriptionPostregister: string;

    offline = new BehaviorSubject(false);
    textButton = new BehaviorSubject(this.localeService.translate('Savee',{}));

    // inputImageTransformed : Observable<string>;

    constructor(
        private router: Router,
        private themeService : ThemeService,
        private spinner: NgxSpinnerService,
        private alertsService: AlertsService,
        private compresser: CompreserService,
        private localeService: LocaleService,
        private profileModalService: SecprofilemodalService
        // private httpHeadersManager: HttpHeadersManager,

    ) {}

    ngOnInit() {
        this.titlePostregister = this.localeService.translate(this.themeService.getTitlePostregister(), {companyName: this.themeService.getCompanyNameTitle()});
        this.descriptionPostregister = this.localeService.translate(this.themeService.getDescriptionPostregister(), {});

        this.showSpinner.subscribe(n => this.spinner.show());
        this.hideSpinner.subscribe(n => this.spinner.hide());

        fromEvent(window, 'online').subscribe(
            _ => {
                this.offline.next(false);
                this.textButton.next(this.localeService.translate('Savee',{}));
            }
        );
        fromEvent(window, 'offline').subscribe(
            _ => {
                this.offline.next(true);
                this.textButton.next(this.localeService.translate('Savee',{}) + ' (Offline)');
            }
        )

    }

    ngAfterViewInit(): void {
    }


    onChangeCheckbox() {
        this.policyCheckBoxSelected = !this.policyCheckBoxSelected;
    }

    onImageSelected(img : string | ArrayBuffer) {
        this.imageSelected = img;
    }

    onPrivacyPolicyClicked() {
        // this.httpHeadersManager.getAuthorizationToken().subscribe(
        //     token => {
        //         const url = this.router.serializeUrl(this.router.createUrlTree(['/politicaprivacidad'], {queryParams:{token: token}}));
        //         // const url = this.router.serializeUrl(this.router.createUrlTree(['/politicaprivacidad'], {state: {policy: this.legalText}}));
        //         window.open(url, '_blank');
        //     }
        // )
        // const url = this.router.serializeUrl(this.router.createUrlTree(['/politicaprivacidad'], {queryParams:{policy: this.legalText}}));
        this.profileModalService.openLegalTextModal(this.legalText);
    }

    onCroppedImage(img: string) {

        this.compresser.getOrientationFromBase64(img).pipe(
            flatMap(or => {
                const t = this.compresser.getMimeTypeFromBase64(img);
                return this.compresser.compress(
                    img,
                    or,
                    t,
                    true,
                    undefined,
                    true
                );
            }),
            flatMap(({image, thumbnail}) => {
                return from(fetch(image)).pipe(
                    flatMap(res => from(res.blob())),
                )
            })
        ).subscribe(
            blobImg => this.emitObjWithImage(blobImg)
        );
    }

    passBack() { //submit y cerrar modal
        console.log("close!");
        if (this.themeService.isPolicyActivated() && !this.policyCheckBoxSelected) {
            this.alertsService.showAlertWithTextError(this.localeService.translate("You must accept the privacy policy", {})).then(a => {});
        // } else if (this.statusField.nativeElement.value === "") {
        //     this.alertsService.showAlertWithTextError(this.localeService.translate("You must fill the state field.", {})).then(a => {});
        } else {
            if (this.imageSelected !== undefined) {
                this.uploadImageComponent.convertImage();
            } else {
                this.emitObjWithImage(undefined);
            }
        }
    }


    emitObjWithImage(image: Blob | undefined) {
        const change = this.statusField.nativeElement.value;

        const img = image === undefined ? O.none : O.some(image);
        // const add = change === this.bio ? O.none : O.some(change);

        // if (O.isNone(img) && O.isNone(add)) {
        //     this.alertsService.showAlertWithTextError(this.localeService.translate("To change the profile you have to fill in a field",{})).then(_ => {});
        // } else {
        this.passEntry.emit({
            additionalInfo: fromNullable(this.statusField.nativeElement.value),
            image: img
        });
        // }
    }

}
