import {Injectable} from '@angular/core';
import {DiscoversApihelper} from '../../sources/remote/discovers/discovers.apihelper';
import {from, Observable, of} from 'rxjs';
import {Discover} from '../../domain/Discover';
import {flatMap, map, toArray} from 'rxjs/operators';
import {getDiscoverFromDiscoverApiModelAndImage} from '../mappers/discovers.mapper';
import {RoomGroup} from '../../domain/RoomGroup';
import {RoomsMapper} from '../mappers/rooms.mapper';
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../domain/StandardError";
import * as E from "fp-ts/lib/Either";
import {Option} from "fp-ts/lib/Option";


@Injectable({
    providedIn: 'root',
})
export class DiscoversRepository {

    constructor(
        private discoversApiHelper: DiscoversApihelper,
        private roomsMapper : RoomsMapper
    ) {}

    getAllDiscovers() : Observable<Either<StandardError, Discover[]>> {
        return this.discoversApiHelper.getDiscoversList().pipe(
            flatMap(E.fold(
                error => of(E.left(error)),
                list => from(list.all).pipe(
                    flatMap(d => this.discoversApiHelper.getDiscoversIddImageprofile(d.idDiscover).pipe(
                        map(i => getDiscoverFromDiscoverApiModelAndImage(d, i))
                    )),
                    toArray(),
                    map(l => E.right(l))
                )
            ))
        )
    }

    getDiscoversFromDate(date: Date) : Observable<Either<StandardError, {all: Discover[], deleted: number[]}>> {
        return this.discoversApiHelper.getDiscoversListChanges(date).pipe(
            flatMap(E.fold(
                error => of(E.left(error)),
                ({all, deleted}) => from(all).pipe(
                    flatMap(d => this.discoversApiHelper.getDiscoversIddImageprofile(d.idDiscover).pipe(
                        map(i => getDiscoverFromDiscoverApiModelAndImage(d, i))
                    )),
                    toArray(),
                    map(l => E.right({all: l, deleted: deleted}))
                )
            ))
        )
    }

    joinDiscover(idDiscover: number) : Observable<Either<StandardError, RoomGroup>> {
        return this.discoversApiHelper.postDiscoversIddJoin(idDiscover).pipe(
            flatMap(E.fold(
                err => of(E.left(err)),
                r => this.roomsMapper.getRoomGroupByIRoomAlllistRoomApiModel(r).pipe(
                    map(r => E.right(r))
                )
            ))
        );
    }

    quitDiscover(idDiscover: number) : Observable<Either<StandardError, {}>> {
        return this.discoversApiHelper.deleteDiscoversIddQuit(idDiscover);
    }

    getDiscoverImageProfile(idDiscover: number) : Observable<Option<Blob>> {
        return this.discoversApiHelper.getDiscoversIddImageprofile(idDiscover);
    }


}
