import {Action, createReducer, on} from "@ngrx/store";
import {
    IFileMessageNotSendedState,
    initialFileMessageNotSendedState
} from "../../state/messages/filemessagenotsended.state";
import {
    onSendMessageOTOFile, onSendMessageOTOSuccess,
    onSendMessageRoomFile,
    onSendMessageRoomFileSuccess
} from "../../actions/messages.actions";
import {adapterFileMessageNotSended} from "../../adapters/filemessagenotsended.adapters";
import {FileMessageNotSended} from "../../../../domain/FileMessageNotSended";


const fileMessagesNotSendedReducer = createReducer(
    initialFileMessageNotSendedState,
    on(onSendMessageRoomFile, (state, {message, file, base64Thumbnail}) => adapterFileMessageNotSended.addOne(new FileMessageNotSended(message.internalId, file,base64Thumbnail), state)),
    on(onSendMessageRoomFileSuccess, (state, {message}) => adapterFileMessageNotSended.removeOne(message.internalId, state)),
    on(onSendMessageOTOFile, (state, {message, file, base64Thumbnail}) => adapterFileMessageNotSended.addOne(new FileMessageNotSended(message.internalId, file,base64Thumbnail), state)),
    on(onSendMessageOTOSuccess, (state, {message}) => adapterFileMessageNotSended.removeOne(message.internalId, state))
);


export function fileMessagesNotSendedReducerFunction(state: IFileMessageNotSendedState, action: Action) {
    return fileMessagesNotSendedReducer(state, action);
}
