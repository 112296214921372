import {Injectable} from '@angular/core';
import {NetworkClient} from '../core/NetworkClient';
import {Observable} from 'rxjs';
import {IPostLoginUserRequestModel} from './models/IPostLoginUserRequestModel';
import {IPostLoginUserResponseModel} from './models/IPostLoginUserResponseModel';
import {RequestType} from '../core/RequestType';
import {StandardError} from "../../../domain/StandardError";
import {Either} from "fp-ts/lib/Either";


@Injectable({
    providedIn: 'root',
})
export class APILoginHelper {

    constructor(
        private networkClient: NetworkClient
    ) {

    }


    login(request: IPostLoginUserRequestModel) : Observable<Either<StandardError, IPostLoginUserResponseModel>> {
        return this.networkClient.setRequest<IPostLoginUserRequestModel, IPostLoginUserResponseModel>(
            "/login/user",
            request,
            RequestType.POST
        );
    }
}
