import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from "rxjs";
import {catchError, distinct, map, switchMap, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ResourcesService} from "../resources/resources.service";

@Component({
  selector: 'app-image-visualizer',
  templateUrl: './image-visualizer.component.html',
  styleUrls: ['./image-visualizer.component.scss']
})
export class ImageVisualizerComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() image: string;
    @Input() classes: string;
    @Input() width: number;
    @Input() withHeight: boolean = false;
    @Input() height: number;

    @ViewChild('imgSrc', {static: false})
    imgSrc: ElementRef;

    @Output() srcEmitter = new EventEmitter<ElementRef>();

    image_conv2: BehaviorSubject<string>;
    image_conv: Observable<string>;
    image_converted: ReplaySubject<string>;



    constructor(
        private http: HttpClient,
        private resourcesService: ResourcesService
    ) { }

    ngOnInit() {
        this.image_converted = new ReplaySubject<string>();
        this.resourcesService.getMetaNoImageProfile().subscribe(
            str => {
                this.image_converted.next(str);
            }
        );
    }


    initSubjs() : boolean {
        if (!this.image_conv2) {
            this.image_conv2 = new BehaviorSubject<string>(this.image);
            this.image_conv = this.image_conv2.asObservable();
            return false;
        } else return true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.initSubjs()) {
            this.image_conv2.next(this.image);
        }
    }
    onError() {
        this.image_converted.asObservable().subscribe(
            src => {
                this.image_conv2.next(src);
            }
        )
    }

    ngAfterViewInit(): void {
        this.initSubjs();
        this.srcEmitter.emit(this.imgSrc);

    }

}
