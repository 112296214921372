

// export interface IMessageFile


import {EntityState} from '@ngrx/entity';
import {FileMessage} from '../../../domain/FileMessage';
import {adapterFileMessage} from '../adapters/filemessages.adapters';
import {IPagedFileMessages} from '../entities/PagedFileMessages';
// import {PagedFileMessages} from '../entities/PagedFileMessages';

export interface IFileMessagesState extends EntityState<FileMessage> {}
const initialFileMessageState : IFileMessagesState = adapterFileMessage.getInitialState({});

export const initialPagedFileMessagesState = () => <IPagedFileMessages>({
    fileMessagesState: initialFileMessageState,
    nextLastPageToLoad: 0,
    numberMessagesLastPage: 0,
    fullPagesLoaded: false
});
