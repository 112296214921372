


export class UserModifiedSocketModel {
    readonly idUser: number;
    readonly date: string;
    readonly _id: string;
    readonly fields : {
        additionalInfo?: string;
        image?: "true";
        name?: string;
        surname?: string;
    };


    constructor(idUser: number, date: string, id: string, fields: { additionalInfo?: string; image?: "true"; name?: string; surname?: string }) {
        this.idUser = idUser;
        this.date = date;
        this._id = id;
        this.fields = fields;
    }
}
