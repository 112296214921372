import {createAction, props} from '@ngrx/store';
import {Discover} from '../../../domain/Discover';
import {RoomGroup} from '../../../domain/RoomGroup';
import {AppError} from '../../remote/globalmodels/AppError';
import {StandardError} from "../../../domain/StandardError";
import {Option} from "fp-ts/lib/Option";
import {DiscoverStatus} from "../../../domain/DiscoverStatus";


export const DiscoverActions = {
    // LOAD_DISCOVERS: '[Discover] Load Discovers',
    // LOAD_DISCOVERS_SUCCESS: '[Discover] Load Discovers Success',

    JOIN_DISCOVER: '[Discover] Join Discover',
    JOIN_DISCOVER_SUCCESS: '[Discover] Join Discover Success',
    JOIN_DISCOVER_FAIL: '[Discover] Join Discover Fail',

    QUIT_DISCOVER: '[Discover] Quit Discover',
    QUIT_DISCOVER_SUCCESS: '[Discover] Quit Discover Success',
    QUIT_DISCOVER_FAIL: '[Discover] Quit Discover Fail',

    ON_NEW_DISCOVER: '[Discover] On New Discover',
    ON_DISCOVER_MODIFIED: '[Discover] On Discover Modified',
    ON_DISCOVER_DELETED: '[Discover] On Discover Deleted',

    ON_NEW_DISCOVER_PROCESSED: '[Discover] On New Discover Processed',
    ON_DISCOVER_MODIFIED_PROCESSED: '[Discover] On Discover Modified Processed',
    ON_DISCOVER_DELETED_PROCESSED: '[Discover] On Discover Deleted Processed'
};


// export const loadDiscovers = createAction(DiscoverActions.LOAD_DISCOVERS);
// export const loadDiscoversSuccess = createAction(
//     DiscoverActions.LOAD_DISCOVERS_SUCCESS,
//     props<{discovers: Discover[]}>()
// );

export const joinDiscover = createAction(
    DiscoverActions.JOIN_DISCOVER,
    props<{idDiscover: number}>()
);
export const joinDiscoverSuccess = createAction(
    DiscoverActions.JOIN_DISCOVER_SUCCESS,
    props<{room: RoomGroup, idDiscover: number}>()
);
export const joinDiscoverFail = createAction(
    DiscoverActions.JOIN_DISCOVER_FAIL,
    props<{error: StandardError}>()
);

export const quitDiscover = createAction(
    DiscoverActions.QUIT_DISCOVER,
    props<{idDiscover: number}>()
);
export const quitDiscoverSuccess = createAction(
    DiscoverActions.QUIT_DISCOVER_SUCCESS,
    props<{idDiscover: number, idRoom: number}>()
);
export const quitDiscoverFail = createAction(
    DiscoverActions.QUIT_DISCOVER_FAIL,
    props<{error: StandardError}>()
);
export const OnNewDiscoverAction = createAction(
    DiscoverActions.ON_NEW_DISCOVER,
    props<{discover: Discover, room: Option<RoomGroup>}>()
);
export const OnDiscoverModifiedAction = createAction(
    DiscoverActions.ON_DISCOVER_MODIFIED,
    props<{status: DiscoverStatus}>()
);
export const OnDiscoverDeletedAction = createAction(
    DiscoverActions.ON_DISCOVER_DELETED,
    props<{idDiscover: number}>()
);
export const OnNewDiscoverProcessedAction = createAction(
    DiscoverActions.ON_NEW_DISCOVER_PROCESSED,
    props<{discover: Discover, room: Option<RoomGroup>}>()
);
export const OnDiscoverModifiedProcessedAction = createAction(
    DiscoverActions.ON_DISCOVER_MODIFIED_PROCESSED,
    props<{status: DiscoverStatus, discoverField: Option<{discoverImage: Option<Blob>}>, roomField: Option<{profileImage: string}>}>()
);
export const OnDiscoverDeletedProcessedAction = createAction(
    DiscoverActions.ON_DISCOVER_DELETED_PROCESSED,
    props<{idDiscover: number, idRoom: number}>()
);

// ON_NEW_DISCOVER_PROCESSED: '[Discover] On New Discover Processed',
//     ON_DISCOVER_MODIFIED_PROCESSED: '[Discover] On Discover Modified Processed',
    // ON_DISCOVER_DELETED_PROCESSED: '[Discover] On Discover Deleted Processed'//
