import {UsersApihelper} from '../../sources/remote/users/users.apihelper';
import {colorAssigner, getNumRandomColors, User} from '../../domain/User';
import {from, Observable, of, zip} from 'rxjs';
import {flatMap, map, switchMap, tap, toArray} from 'rxjs/operators';
import {UsersMapper} from '../mappers/users.mapper';
import {Injectable} from '@angular/core';
import {StandardError} from "../../domain/StandardError";
import {Either} from "fp-ts/lib/Either";
import * as E from "fp-ts/lib/Either";
import {PartialUser} from "../../sources/storage/entities/PartialUser";
import {Option} from "fp-ts/lib/Option";
import {PartialUserApiModel} from "../../sources/remote/users/models/PartialUserApiModel";

@Injectable({
    providedIn: 'root',
})
export class UsersRepository {
    constructor(
        private usersApiHelper : UsersApihelper,
        private usersMapper: UsersMapper
    ) {}

    getAllUsers() : Observable<Either<StandardError, PartialUser[]>> {
        return this.usersApiHelper.getAllUsers().pipe(
            flatMap(E.fold(
                error => of(E.left(error)),
                ({all}) => this.getPartialUsersFromApiModel(all).pipe(
                    map(u => E.right(u))
                ),
            )),
        );
    }

    getUsersFromDate(date: Date) : Observable<Either<StandardError, {all: PartialUser[], deleted: number[]}>> {
        return this.usersApiHelper.getUsersFromDate(date).pipe(
            flatMap(E.fold(
                error => of(E.left(error)),
                ({all, deleted}) => this.getPartialUsersFromApiModel(all).pipe(
                    map(users => E.right({all: users, deleted: deleted}))
                )
            ))
        )
    }

    private getPartialUsersFromApiModel(users: PartialUserApiModel[]) : Observable<PartialUser[]> {
        return zip(
            from(users),
            from(getNumRandomColors(users.length))
        ).pipe(
            switchMap(([user, color]) => this.usersMapper.getUserByPartialUserApiModel(user, color)),
            map(user => this.usersMapper.getPartialUserFromUser(user)),
            toArray()
        );
    }

    getImageProfileFromUserByIdUser(idUser: number) : Observable<Option<Blob>> {
        return this.usersApiHelper.getUsersIdImageprofile(idUser)
            .pipe(
            );
    }
}
