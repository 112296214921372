

export class DiscoverElement {
    readonly name: string;
    readonly image: string;
    readonly showTitle: boolean;
    readonly vinculated: boolean;
    readonly idDiscover: number;


    constructor(name: string, image: string, showTitle: boolean, vinculated: boolean, idDiscover: number) {
        this.name = name;
        this.image = image;
        this.showTitle = showTitle;
        this.vinculated = vinculated;
        this.idDiscover = idDiscover;
    }
}
