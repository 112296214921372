import {switchMap} from "rxjs/operators";
import {MessageRoomApiModel} from '../../sources/remote/messages/models/MessageRoomApiModel';
import {MessageRoom} from '../../domain/MessageRoom';
import {MessagesMappersUtils} from "../../utils/messages.mappers.utils";
import {getDateFromBackendDate, getDateFromUnixDate} from './dates.mapper';
import {MessageOTOApiModel} from '../../sources/remote/messages/models/MessageOTOApiModel';
import {MessageOTO} from '../../domain/MessageOTO';
import {OwnDataUser} from '../../domain/OwnDataUser';
import {SynchronizationState} from '../../domain/SynchronizationState';
import {IMessageAlllistRoomApiModel} from '../../sources/remote/rooms/models/IRoomAlllistRoomApiModel';
import {Observable, of, zip} from 'rxjs';
import {BlobTransformers} from '../../utils/blob.transformers';
import {MessageFileApiModel} from '../../sources/remote/messages/models/MessageFileApiModel';
import {FileMessage} from '../../domain/FileMessage';
import {FileType, getFileTypeFromString} from '../../domain/FileType';
import {Injectable} from '@angular/core';
import {getModifiedStateByNumber} from "../../utils/commom.mappers";
import {GetRoomsIdrIdmInfoApiModel} from "../../sources/remote/messages/models/GetRoomsIdrIdmInfoApiModel";
import {MessageUserStatus} from "../../domain/MessageUserStatus";
import {pipe} from "fp-ts/lib/pipeable";
import * as A from "fp-ts/lib/Array";
import {LinkGenerators} from "../../domain/images/link.generators";
import {option as O} from "fp-ts";


@Injectable({
    providedIn: 'root'
})
export class MessagesMapper {

    public constructor(
        private blobTransformers: BlobTransformers,
        private messagesMappersUtils: MessagesMappersUtils,
        private linkGenerators: LinkGenerators
    ) {}

    getMessageRoomByMessageRoomApiModel(messageRoomApiModel: MessageRoomApiModel, syncState: SynchronizationState) : Observable<MessageRoom> {
        console.log({text: "Message parsed", msg: messageRoomApiModel});
        return zip(
            this.messagesMappersUtils.getLinkPreviewMessageMonad(messageRoomApiModel.linkPreview),
            this.messagesMappersUtils.getReplyMessageMonad(messageRoomApiModel.replyMessage),
            // this.linkGenerators.getLinkResourceRoomFromMessage(messageRoomApiModel.idRoom, messageRoomApiModel._id)
        ).pipe(
            switchMap(([l, r/*, resourceLink*/]) => this.blobTransformers.getObjectWithBlobOptionalFromBase64<MessageRoom>(messageRoomApiModel.thumbnail, (blob => new MessageRoom(
                messageRoomApiModel._id,
                messageRoomApiModel.idUser,
                getDateFromBackendDate(messageRoomApiModel.date),
                messageRoomApiModel.message,
                messageRoomApiModel.idRoom,
                messageRoomApiModel.internalId,
                syncState,
                blob,
                getFileTypeFromString(messageRoomApiModel.fileType),
                // null,
                // resourceLink,
                messageRoomApiModel.fileDuration,
                messageRoomApiModel.extension,
                r,
                getModifiedStateByNumber(messageRoomApiModel.modifiedState),
                l,
                O.none,
                O.none
            ))))
        )

    }


    getMessageOTOByMessageOTOApiModel(messageOTOApiModel: MessageOTOApiModel, ownDataUser: OwnDataUser, syncState: SynchronizationState) : Observable<MessageOTO> {
        const idUserOTO = ownDataUser.idUser+"" === ""+messageOTOApiModel.idUser ? parseInt(messageOTOApiModel.toIdUser, 10) : parseInt(messageOTOApiModel.idUser, 10);
        return zip(
            this.messagesMappersUtils.getLinkPreviewMessageMonad(messageOTOApiModel.linkPreview),
            this.messagesMappersUtils.getReplyMessageMonad(messageOTOApiModel.replyMessage),
            // this.linkGenerators.getLinkResourceOneToOneFromMessage(idUserOTO, messageOTOApiModel._id)
        ).pipe(
            switchMap(([l, r/*, resourceLink*/]) =>
                this.blobTransformers.getObjectWithBlobOptionalFromBase64<MessageOTO>(messageOTOApiModel.thumbnail, (blob => new MessageOTO(
                    messageOTOApiModel._id,
                    parseInt(messageOTOApiModel.idUser, 10),
                    getDateFromBackendDate(messageOTOApiModel.date),
                    messageOTOApiModel.message,
                    idUserOTO,
                    messageOTOApiModel.internalId,
                    syncState,
                    blob,
                    getFileTypeFromString(messageOTOApiModel.fileType),
                    // null,
                    // resourceLink,
                    messageOTOApiModel.fileDuration,
                    messageOTOApiModel.extension,
                    r,
                    getModifiedStateByNumber(messageOTOApiModel.modifiedState),
                    l,
                    O.none,
                    O.none
                )))
            )
        );

    }


    getMessageRoomByIRoomAlllistRoomApiModel(roomAlllistRoomApiModel: IMessageAlllistRoomApiModel) : Observable<MessageRoom|undefined> {
        if (roomAlllistRoomApiModel._id === undefined) {
            return of(undefined);
        } else {
            return zip(
                this.messagesMappersUtils.getLinkPreviewMessageMonad(roomAlllistRoomApiModel.linkPreview),
                this.messagesMappersUtils.getReplyMessageMonad(roomAlllistRoomApiModel.replyMessage),
                // this.linkGenerators.getLinkResourceRoomFromMessage(roomAlllistRoomApiModel.idRoom, roomAlllistRoomApiModel._id)
            ).pipe(
                switchMap(([l, r/*, resourceLink*/]) => this.blobTransformers.getObjectWithBlobOptionalFromBase64<MessageRoom>(roomAlllistRoomApiModel.thumbnail, (blob => new MessageRoom(
                    roomAlllistRoomApiModel._id,
                    roomAlllistRoomApiModel.idUser,
                    getDateFromBackendDate(roomAlllistRoomApiModel.date),
                    roomAlllistRoomApiModel.message,
                    roomAlllistRoomApiModel.idRoom,
                    roomAlllistRoomApiModel.internalId,
                    SynchronizationState.SYNCHRONIZED,
                    blob,
                    getFileTypeFromString(roomAlllistRoomApiModel.fileType),
                    // null,
                    // resourceLink,
                    roomAlllistRoomApiModel.fileDuration,
                    roomAlllistRoomApiModel.extension,
                    r,
                    getModifiedStateByNumber(roomAlllistRoomApiModel.modifiedState),
                    l,
                    O.none,
                    O.none
                ))))
            );
        }
    }

    getMessageOTOByIRoomAlllistRoomApiModel(roomAlllistRoomApiModel: IMessageAlllistRoomApiModel, ownDataUser: OwnDataUser) : Observable<MessageOTO> {
        const idUserOTO = ownDataUser.idUser+"" === ""+roomAlllistRoomApiModel.idUser ? roomAlllistRoomApiModel.toIdUser : roomAlllistRoomApiModel.idUser;
        return zip(
            this.messagesMappersUtils.getLinkPreviewMessageMonad(roomAlllistRoomApiModel.linkPreview),
            this.messagesMappersUtils.getReplyMessageMonad(roomAlllistRoomApiModel.replyMessage),
            // this.linkGenerators.getLinkResourceOneToOneFromMessage(idUserOTO, roomAlllistRoomApiModel._id)
        ).pipe(
            switchMap(([l, r/*, resourceLink*/]) => this.blobTransformers.getObjectWithBlobOptionalFromBase64<MessageOTO>(roomAlllistRoomApiModel.thumbnail, (blob => new MessageOTO(
                roomAlllistRoomApiModel._id,
                roomAlllistRoomApiModel.idUser,
                getDateFromBackendDate(roomAlllistRoomApiModel.date),
                roomAlllistRoomApiModel.message,
                idUserOTO,
                roomAlllistRoomApiModel.internalId,
                SynchronizationState.SYNCHRONIZED,
                blob,
                getFileTypeFromString(roomAlllistRoomApiModel.fileType),
                // null,
                // resourceLink,
                roomAlllistRoomApiModel.fileDuration,
                roomAlllistRoomApiModel.extension,
                r,
                getModifiedStateByNumber(roomAlllistRoomApiModel.modifiedState),
                l,
                O.none,
                O.none
            ))))
        );
    }



    getMessageFileFromMessageFileApiModel(messageFileApiModel: MessageFileApiModel) : Observable<FileMessage> {
        return this.blobTransformers.getObjectWithBlobOptionalFromBase64<FileMessage>(messageFileApiModel.thumbnail, (blob => new FileMessage(
            messageFileApiModel._id,
            getDateFromBackendDate(messageFileApiModel.date),
            messageFileApiModel.idUser,
            messageFileApiModel.extension,
            messageFileApiModel.message,
            blob,
            getFileTypeFromString(messageFileApiModel.fileType),
        )));
    }


    getMessageStatusUsersFromApiModel(c: GetRoomsIdrIdmInfoApiModel) : MessageUserStatus {
        return new MessageUserStatus(
            c.notReceived,
            pipe(
                c.received,
                A.map(({idUser, date}) => ({idUser: idUser, date: getDateFromUnixDate(date)}))
            ),
            pipe(
                c.readed,
                A.map(({idUser, date}) => ({idUser: idUser, date: getDateFromUnixDate(date)}))
            )
        )
    }


}

