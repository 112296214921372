// import randomColor = require("randomcolor");
// import randomColor = require("randomcolor");

// import * as randomColor from "randomcolor";

// import randomColor = require("randomcolor");

import randomColor from "randomcolor";

const colors : string[] = [
    "B90707",
    "B9072D",
    "B90753",
    "B107B9",
    "6807B9",
    "071AB9",
    "0783B9",
    "07B9B6",
    "07B97B",
    "07B932",
    "3AB907",
    "91B907",
    "B96007",
    "B92F07"
];

export function colorAssigner(numUsers: number) : string[] {



    const newV : string[] = [];
    let i = 1;
    while(i*colors.length <= numUsers) {
        newV.push(...colors);
        i++;
        console.log({text: "color array generated .."})
    }
    newV.push(...colors.slice(0, numUsers%colors.length));

    console.log({text: "color array generated", colors: newV, numUsers: numUsers, old: colors});

    if (newV.length !== numUsers)
        throw new Error("ERROR, ON COLOR ASSIGNER!! NOT SAME LENGTH VECTORS");


    return newV;
}

const seed = 1234;
export function getNumRandomColors(n: number) : string[] {
    return randomColor({
        // hue: 'monochrome',
        count: n,
        luminosity: "dark",
        seed: seed,
        // alpha: 0.1
    }).map(v => v.substring(1,v.length));
}



export class User {
    readonly name: string;
    readonly surname: string;
    readonly idUser: number;
    readonly color: string;
    readonly additionalInfo: string;

    readonly imageProfileUrl: string;


    constructor(name: string,
                surname: string,
                idUser: number,
                color: string,
                additionalInfo: string,
                imageProfileUrl: string) {
        this.name = name;
        this.surname = surname;
        this.idUser = idUser;
        this.color = color;
        this.additionalInfo = additionalInfo;
        this.imageProfileUrl = imageProfileUrl;
    }
}

export function getNameForUser(u: User) : string {
    return `${u.name} ${u.surname}`
}
