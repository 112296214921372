import {createAction, props} from "@ngrx/store";
import {PartialUser} from "../../entities/PartialUser";

const TAG = '[Generic Users Actions] ';

export const GenericUsersActions = {
    SAVE_LIST_USERS: TAG + 'Save List Users',
    REFRESH_LIST_USERS: TAG + 'Refresh List Users',
};

export const SaveListUsersAction = createAction(
    GenericUsersActions.SAVE_LIST_USERS,
    props<{users: PartialUser[]}>()
);

export const RefreshListUsersAction = createAction(
    GenericUsersActions.REFRESH_LIST_USERS,
    props<{all: PartialUser[], deleted: number[]}>()
);
