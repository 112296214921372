import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Message} from "../../shared/core/domain/Message";
import {ResourcesService} from "../../shared/resources/resources.service";
import {SynchronizationState} from "../../shared/core/domain/SynchronizationState";

export function blobToSrcImage<T>(blob : Blob | undefined, provider: (src: undefined | string | ArrayBuffer) => T) : Observable<T> {
    if (blob === undefined)
        return of(provider(undefined));
    return new Observable<string|ArrayBuffer>(subscriber => {
        const r = new FileReader();
        r.onload = _ => {
            subscriber.next(r.result);
            subscriber.complete();
        };
        r.readAsDataURL(blob);
    }).pipe(
        map(r => provider(r))
    );
}

export function getResourceIconFromMessage(message: Message, resourcesService: ResourcesService) : Observable<string> {
    if (message.syncState === SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED)
        return resourcesService.getMessagesNotSendedNotSynchronizedIcon();
    if (message.syncState === SynchronizationState.NOT_SYNCHRONIZED_SENDED)
        return resourcesService.getMessagesSendedNotSynchronizedIcon();
    if (message.syncState === SynchronizationState.FAIL)
        return resourcesService.getMessagesErrorIcon();
    return resourcesService.getMessagesSendedSynchronizedIcon();
}

export function isDifferentDay(d1: Date, d2: Date) : boolean {
    // console.log({text: "day getter", d1: d1.getDay(), d2: d2.getDay()});
    // return d1.getDay() !== d2.getDay();
    return d1.getDate() !== d2.getDate() ||
        d1.getMonth() !== d2.getMonth() ||
        d1.getFullYear() !== d2.getFullYear();
}
