import {Injectable} from '@angular/core';
import {RoomsApihelper} from '../../sources/remote/rooms/rooms.apihelper';
import {Room} from '../../domain/Room';
import {combineLatest, concat, from, Observable, Observer, of, partition, zip} from 'rxjs';
import {defaultIfEmpty, flatMap, map, mergeMap, reduce, scan, switchMap, take, tap, toArray} from 'rxjs/operators';
import {
    RoomsMapper
    // getRoomByRoomApiModel, getRoomGroupByIRoomAlllistRoomApiModel, getRoomOTOByIRoomAlllistRoomApiModel,
    // getRoomOTOByOneToOneApiModel,
    // getRoomsByRoomsApiModel,
    // getUserRoomByUserRoomResponseModel
} from '../mappers/rooms.mapper';
import {RoomGroup} from '../../domain/RoomGroup';
import {UsersApihelper} from '../../sources/remote/users/users.apihelper';
import {RoomOTO} from '../../domain/RoomOTO';
import {User} from '../../domain/User';
// import {getUserByUserApiModel} from '../mappers/users.mapper';
import {UserRoom} from '../../domain/UserRoom';
import {AppFacade} from '../../sources/storage/facade/app.facade';
import {Option} from "fp-ts/lib/Option";
import {Either} from "fp-ts/lib/Either";
import {These} from "fp-ts/lib/These";
import {StandardError} from "../../domain/StandardError";
import * as E from "fp-ts/lib/Either";
import {PartialRoomGroup} from "../../sources/storage/entities/PartialRoomGroup";
import {PartialRoomOTO} from "../../sources/storage/entities/PartialRoomOTO";
import {UsersMapper} from "../mappers/users.mapper";
import {MetaFacade} from "../../sources/storage/facade/generic/meta.facade";
import {pipe} from "fp-ts/lib/pipeable";
import * as A from "fp-ts/lib/Array";
import {IGetUsersMeAlllistroomResponseModel} from "../../sources/remote/rooms/models/IGetUsersMeAlllistroomResponseModel";
import {IRoomAlllistRoomApiModel} from "../../sources/remote/rooms/models/IRoomAlllistRoomApiModel";
import {OwnDataUser} from "../../domain/OwnDataUser";

@Injectable({
    providedIn: 'root',
})
export class RoomsRepository {

    constructor(
        private roomsApiHelper: RoomsApihelper,
        private appFacade: AppFacade,
        private roomsMapper : RoomsMapper,
        private usersMapper: UsersMapper,
        private metaFacade: MetaFacade
        // private usersApiHelper: UsersApihelper
    ) {}


    private getRoomsOtosFromResponseList(all: IRoomAlllistRoomApiModel[], ownDataUser: OwnDataUser) : Observable<{rooms: PartialRoomGroup[], otos: PartialRoomOTO[]}> {
        return pipe(
            all,
            A.map(room => {
                let ret : Observable<{rooms: PartialRoomGroup[], otos: PartialRoomOTO[]}>;
                if (room.type === 1 || room.type === 3) {
                    const r = this.roomsMapper.getRoomGroupByIRoomAlllistRoomApiModel(room).pipe(
                        map(r => this.roomsMapper.getPartialRoomFromRoomGroup(r)),
                        map(r => [r])
                    );
                    ret = r.pipe(
                        map(r => ({rooms: r, otos: [] as PartialRoomOTO[]}))
                    )
                } else {
                    const r = this.roomsMapper.getRoomOTOByIRoomAlllistRoomApiModel(room, ownDataUser).pipe(
                        map(r => this.roomsMapper.getPartialOtoFromRoomOTO(r)),
                        map(r => [r])
                    );
                    ret = r.pipe(
                        map(r => ({rooms: [] as PartialRoomGroup[], otos: r}))
                    )
                }
                return ret;
            }),
            A.reduce(of({rooms: [] as PartialRoomGroup[], otos: [] as PartialRoomOTO[]}), (acc, elem) => {
                return zip(...[acc, elem]).pipe(
                    map(([acc, elem]) => ({rooms: [...acc.rooms, ...elem.rooms], otos: [...acc.otos, ...elem.otos]}))
                )
            })
        );
    }

    getRoomsAll() : Observable<Either<StandardError,{rooms: PartialRoomGroup[], otos: PartialRoomOTO[]}>> {
        return this.roomsApiHelper.getUsersMeAlllistroom().pipe(
            flatMap(E.fold(
                error => of(E.left(error)),
                response => this.metaFacade.getOwnDataUser().pipe(
                    switchMap(ownDataUser => this.getRoomsOtosFromResponseList(response.list, ownDataUser)),
                    map(e => E.right(e))
                )
            ))
        );
    }

    getRoomsFromDate(date: Date) : Observable<Either<StandardError,{all: {rooms: PartialRoomGroup[], otos: PartialRoomOTO[]}, deleted: {rooms: number[], otos: number[]}}>> {
        return this.roomsApiHelper.getUsersMeAlllistroomChanges(date).pipe(
            flatMap(E.fold(
                error => of(E.left(error)),
                response => this.metaFacade.getOwnDataUser().pipe(
                    switchMap(ownDataUser => this.getRoomsOtosFromResponseList(response.all, ownDataUser).pipe(
                        map(rooms => ({all: rooms, deleted: pipe(
                            response.deleted,
                            A.map(del => {
                                if (del.type === 1 || del.type === 3) return ({rooms: [del.id], otos: [] as number[]});
                                return ({rooms: [] as number[], otos: [del.id]});
                            }),
                            A.reduce({rooms: [] as number[], otos: [] as number[]}, (acc, elem) => ({rooms: [...acc.rooms, ...elem.rooms], otos: [...acc.otos, ...elem.otos]}))
                        )}))
                    )),
                    map(e => E.right(e))
                )
            ))
        )
    }

    getImageProfileRoomGroupByIdRoom(idRoom: number) : Observable<Option<Blob>> {
        return this.roomsApiHelper.getImageProfileRoomByIdRoom(idRoom);
    }

    getUserRoomListByIdRoom(idRoom: number) : Observable<Either<StandardError, UserRoom[]>> {
        return this.roomsApiHelper.getRoomUserListByIdRoom(idRoom).pipe(
            flatMap(E.fold(
                err => of(E.left(err)),
                response => from(response.all).pipe(
                    map(userRoomResponse => this.roomsMapper.getUserRoomByUserRoomResponseModel(userRoomResponse)),
                    toArray(),
                    map(r => E.right(r))
                )
            )),
        );
    }

    readMessagesRoomGroupByIdRoom(idRoom: number, dateAction: Date, messagesToClean: number) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.putRoomsIdrReadMessages(idRoom, dateAction, messagesToClean);
    }

    readMessagesRoomOTOByIdUser(idUser: number, dateAction: Date, messagesToClean: number) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.putUsersIdReadMessages(idUser, dateAction, messagesToClean);
    }

    getRoomByIdRoom(idRoom: number) : Observable<Either<StandardError, RoomGroup>> {
        return this.roomsApiHelper.getRoomsIdr(idRoom).pipe(
            flatMap(E.fold(
                err => of(E.left(err)),
                apiModel => this.roomsMapper.getRoomGroupByIRoomAlllistRoomApiModel(apiModel).pipe(
                    map(e => E.right(e))
                )
            ))
        );
    }

    createRoomSetImageAndAddUsers(name: string, description: string,
                                  image: Option<Blob>,
                                  idUsers: number[]) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.postRoomsUserCreateroom(name, description, idUsers, image).pipe(
            map(E.map(_ => ({})))
            // map(idRoom => ({}))
        );
    }

    openOneToOneByIdUser(idUser: number) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.postUsersIdOpenchat(idUser);
    }

    // addUsersToRoomByIdRoom(idRoom: number, idUsers: number[]) : Observable<{}> {
    //     return from(idUsers).pipe(
    //         flatMap(idUser => this.roomsApiHelper.postRoomsIdrAdduserId(idRoom, idUser)),
    //         toArray(),
    //         map(_ =>  ({}))
    //     );
    // }
    addUsersToRoomByIdRoom(idRoom: number, idUsers: number[]) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.postRoomsIdrAddusers(idRoom, idUsers);
    }

    leaveRoomGroupByIdRoom(idRoom: number) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.deleteRoomsLeaveroomIdr(idRoom);
    }

    clearOneToOneConversationByIdUser(idUser: number) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.deleteUsersIdOnetoone(idUser);
    }

    modifyRoomInfo(idRoom: number, info: These<{name: string, description: string}, Blob>) : Observable<Either<StandardError, {}>> {
        return this.roomsApiHelper.putRoomsIdr(idRoom, info);
    }

    getRoomImageLinkFromIdRoom(idRoom: number) : Observable<string> {
        return this.roomsMapper.getRoomImageLinkFromIdRoom(idRoom);
    }
}
