import {LanguageEditorComponent} from "../language-editor/language-editor.component";
import {NgbmodalService} from './ngbmodal.service';
import {ProfileComponent} from '../profile/profile.component';
import {EventEmitter} from '@angular/core';
import {Option} from "fp-ts/lib/Option";
import {VideoViewerComponent} from "../video-viewer/video-viewer.component";
import {ProfilePostregisterComponent} from "../profile-postregister/profile-postregister.component";
import {SessionsComponent} from "../sessions/sessions.component";
import {TextEditorComponent} from "../text-editor/text-editor.component";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {VideoVisualizerComponent} from "../video-visualizer/video-visualizer.component";
import {VoiceVisualizerComponent} from "../voice-visualizer/voice-visualizer.component";
import {VisualizerType} from "../video-visualizer/VisualizerType";
import {ResendComponent} from "../resend/resend.component";
import {UrlResourceModel} from "../url-resource-generator/UrlResourceModel";
import {SafeUrl} from "@angular/platform-browser";


export class ProfilemodalService extends NgbmodalService {



    openPostregisterModal(name: string, surname: string, additionalInfo: string, legalText: string,
                          showSpinner: EventEmitter<boolean>, hideSpinner: EventEmitter<boolean>,
                          callback: (result: {additionalInfo: Option<string>, image: Option<Blob>}) => void) {
        const r = super.openBlockableModal(ProfilePostregisterComponent, true, [
            // {key: 'esPostRegistro', value: true},
            {key: 'name', value: name},
            {key: 'surname', value: surname},
            {key: 'bio', value: additionalInfo},
            {key: 'legalText', value: legalText},
            {key: 'showSpinner', value: showSpinner},
            {key: 'hideSpinner', value: hideSpinner}
            // {key: 'passEntry', value: callback}
        ]);
        r.componentInstance.passEntry.subscribe(v => callback(v));
        return r;
    }

    openProfileEditingModal(name: string, surname: string, additionalInfo: string, image: string,
                            emailContact: string|null, emailNotifications: boolean,
                            emailNotificationsActivated: boolean,
                            showSpinner: EventEmitter<boolean>, hideSpinner: EventEmitter<boolean>,
                            callback: (result: {
                                additionalInfo: Option<string>,
                                image: Option<Blob>,
                                emailContact: Option<Option<string>>,
                                emailNotifications: Option<boolean>
                            }) => void) {
        const r =  super.openBlockableModal(ProfileComponent, false, [
            // {key: 'esPostRegistro', value: false},
            {key: 'name', value: name},
            {key: 'surname', value: surname},
            {key: 'bio', value: additionalInfo},
            {key: 'image', value: image},
            {key: 'emailContact', value: emailContact},
            {key: 'emailNotifications', value: emailNotifications},
            {key: 'emailNotificationsActivated', value: emailNotificationsActivated},
            {key: 'showSpinner', value: showSpinner},
            {key: 'hideSpinner', value: hideSpinner}
        ]);

        r.componentInstance.passEntry.subscribe(v => callback(v));
        return r;
    }

    openVideoViewerModal(url: string) {
        return super.openDefaultModal(VideoViewerComponent, true, [
            {key: "urlVideo", value: url}
        ]);
    }

    openSessionModal() {
        return super.openBlockableModal(SessionsComponent, true, []);
    }

    openTextEditorModal(
        defaultText: string,
        // onPaste: (e) => void
    ) : {textConfirmed: EventEmitter<string>, ref: NgbModalRef} {
        const r = super.openMediumModal(TextEditorComponent, false, [
            {key: 'defaultText', value: defaultText},
            // {key: 'onPaste', value: onPaste}
        ]);
        return {
            textConfirmed: r.componentInstance.textConfirmed,
            ref: r
        }
    }

    openLanguagesModal() {
        return super.openBlockableModal(LanguageEditorComponent, true, []);
    }

    openVideoVisualizer(
        title: string,
        videoResource: UrlResourceModel,
        format?: string,
        confirmDialog: boolean = false,
        typeFile: VisualizerType = "video"
    ) : {confirmOrCancel: EventEmitter<boolean>, ref: NgbModalRef} {
        const ref = super.openDefaultModal(VideoVisualizerComponent, true, [
            {key: 'title', value: title},
            {key: 'videoResource', value: videoResource},
            {key: 'format', value: format},
            {key: 'confirmDialog', value: confirmDialog},
            {key: 'typeFile', value: typeFile}
        ]);
        return {
            confirmOrCancel: ref.componentInstance.confirmOrCancel,
            ref: ref
        }
    }

    openVoiceVisualizer(
        voiceUrl: SafeUrl
    ) {
        return super.openSmallModal(VoiceVisualizerComponent, false, [
            {key: 'voiceUrl', value: voiceUrl}
        ])
    }

    openResendMessage(
        fromIdRoom: number,
        oneToOne: boolean,
        fromIdMessage: string
    ) {
        return super.openMediumModal(ResendComponent, true, [
            {key: 'fromIdRoom', value: fromIdRoom},
            {key: 'oneToOne', value: oneToOne},
            {key: 'fromIdMessage', value: fromIdMessage}
        ]);
    }

}
