import {Injectable} from "@angular/core";
import {LocalClient} from "./core/LocalClient";
import {Observable} from "rxjs";
import {Option} from "fp-ts/lib/Option";
import {ThemeService} from "../../../themes/theme.service";


@Injectable({
    providedIn: 'root'
})
export class ImageretrieverLocal {
    constructor(
        private localClient: LocalClient,
        private themeService: ThemeService
    ) {}

    getNoImageProfile() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/portrait/sinpersona.jpg'
        );
    }
    getNoConnection() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/no-connection.png'
        );
    }
    getDiscoversIcon() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            this.themeService.getDiscoverIcon()
        );
    }
    getDiscoversCheck() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/tick.png'
        );
    }
    getChatLoadMoreGallery() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/plus-circle-512.png'
        );
    }
    getChatEditGroup() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/image-pencil.png'
        );
    }
    getMessagesNotSendedNotSynchronizedIcon() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/clock-512.png'
        );
    }
    getMessagesSendedNotSynchronizedIcon() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/checkmark-24-512.png'
        );
    }
    getMessagesSendedSynchronizedIcon() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/double_check.png'
        );
    }
    getMessagesSendedErrorIcon() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/cross-small-01-512.webp'
        );
    }
    getMessagesPdfPlaceHolder() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/pdf_placeholder.png'
        );
    }
    getScriptsNotificationSidebar() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/js/notification-sidebar.js'
        );
    }
    getMessagesVideoPlaceholder() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/video_placeholder.png'
        )
    }
    getMetaRetryIcon() : Observable<Option<Blob>> {
        return this.localClient.setRequestWithFile(
            './assets/img/retry.png'
        )
    }
}

