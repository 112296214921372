import {MessageRoom} from "../../../domain/MessageRoom";
import {IMessageRoomState} from "../state/message.state";
import {LastEvent} from '../state/utils/LastEvent';



export class PagedRoomGroupMessages {
    readonly messagesRoomState: IMessageRoomState;

    readonly idRoom: number;

    readonly messagesToRead: number;
    readonly lastEvent: LastEvent;
    readonly lastMessages: MessageRoom[];

    constructor(
        messagesRoomState: IMessageRoomState,
        idRoom: number,
        messagesToRead: number,
        lastEvent: LastEvent,
        lastMessages: MessageRoom[]
    ) {
        this.messagesRoomState = messagesRoomState;
        this.idRoom = idRoom;
        this.lastEvent = lastEvent;
        this.lastMessages = lastMessages;
        this.messagesToRead = messagesToRead;
    }
}
