import {Injectable} from '@angular/core';
import {Option} from "fp-ts/lib/Option";
import {pipe} from "fp-ts/lib/pipeable";
import {EMPTY, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {LANGUAGE} from "../../../environments/resources.theme";
import {CookieStoreService} from "../localStorage/cookie-store.service";
import {option as O} from "fp-ts";
import {ThemeService} from "../themes/theme.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageStoreService {

    private static ID_COOKIE = "STORED_LANGUAGE";

    constructor(
        private cookieStore: CookieStoreService,
        private themeService: ThemeService
    ) { }

    defineLanguageI18n(language: string) : Observable<never> {
        return this.hasLanguage().pipe(
            switchMap(has => {
                if (has) return EMPTY;

                return pipe(
                    this.themeService.getDefaultLanguage(),
                    O.fold(
                        () => this.putLanguageI18n(language),
                        lang => this.putLanguage(lang)
                    )
                )
            })
        )
    }
    putLanguage(lang: LANGUAGE) : Observable<never> {
        return this.cookieStore.putById(LanguageStoreService.ID_COOKIE, lang);
    }

    hasLanguage() : Observable<boolean> {
        return this.cookieStore.existsById(LanguageStoreService.ID_COOKIE);
    }
    private putLanguageI18n(lang: string) : Observable<never> {
        const l = this.parseLanguageFromI18n(lang);
        return this.cookieStore.putById(LanguageStoreService.ID_COOKIE, l);
    }
    getLanguage() : Observable<LANGUAGE> {
        return this.cookieStore.getByIdOption(LanguageStoreService.ID_COOKIE).pipe(
            map(O.fold(
                () => pipe(
                    this.themeService.getDefaultLanguage(),
                    O.fold(
                        () => {
                            const lang = navigator.language;
                            console.log("Navigator language" + lang);
                            return this.parseLanguageFromI18n(lang);
                        },
                        lang => lang
                    )
                ),
                c => this.parseLanguageFromCookie(c)
            ))
        );
    }

    private parseLanguageFromI18n(l: string) : LANGUAGE {
        try {
            const [prefix] = l.split("-");
            switch (prefix) {
                case LANGUAGE.ES:
                default:
                    return LANGUAGE.ES;
                case LANGUAGE.CA:
                    return LANGUAGE.CA;
                case LANGUAGE.PT:
                    return LANGUAGE.PT;
                case LANGUAGE.EN:
                    return LANGUAGE.EN;
            }
        } catch(e) {
            console.warn(`Error when parsing the navigator language ${l} choosing default language 'es'`);
            return LANGUAGE.ES;
        }

    }
    private parseLanguageFromCookie(c: string) : LANGUAGE {
        switch (c) {
            case LANGUAGE.ES:
            default:
                return LANGUAGE.ES;
            case LANGUAGE.CA:
                return LANGUAGE.CA;
            case LANGUAGE.PT:
                return LANGUAGE.PT;
            case LANGUAGE.EN:
                return LANGUAGE.EN;
        }
    }

}
