import {concat, EMPTY, fromEvent, Observable, of, OperatorFunction, Subscription} from "rxjs";
import {Injectable} from "@angular/core";
import {AppStateNamespaceFacade} from "../sources/storage/facade/namespaces/app_state_namespace.facade";
import {expand, filter, flatMap, mapTo, switchMap, delay, endWith, catchError, tap, distinct} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {MetaApihelper} from "../sources/remote/meta/meta.apihelper";
import * as E from "fp-ts/lib/Either";
import {AppFacade} from "../sources/storage/facade/app.facade";

@Injectable({providedIn: 'root'})
export class NetworkService {

    // online: boolean;
    // isNetworkStopped = false;
    subscriptions: Subscription[];

    constructor(
        private appStateNamespaceFacade: AppStateNamespaceFacade,
        private appFacade: AppFacade,
        private http : HttpClient,
        private metaApiHelper: MetaApihelper
    ) {
        // const s1 = fromEvent(window, 'online').pipe(
        //     switchMap(_ => this.appStateNamespaceFacade.dispatchChangedConnectionOnlineLocalAction())
        // ).subscribe();
        //
        // const s2 = fromEvent(window, 'offline').pipe(
        //     switchMap(_ => this.appStateNamespaceFacade.dispatchChangedConnectionOfflineLocalAction())
        // ).subscribe();
    }

    initNetworkHandler() : Observable<never> {




        return new Observable(s => {
            if (this.subscriptions) {
                this.subscriptions.map(s => {
                    s.unsubscribe();
                });
            }

            const s1 = fromEvent(window, 'online').pipe(
                switchMap(_ => concat(
                    this.appStateNamespaceFacade.dispatchChangedConnectionOnlineLocalAction(),
                    this.metaApiHelper.getUsersMe().pipe(
                        tap(res => console.log({text: "PROVA A FER UNA REQUEST AL SERVER", res: res})),
                        switchMap(E.fold(
                            err => {
                                if (err.appError && err.appError.code === 203.3)
                                    return this.appFacade.dispatchLogout();
                                return this.appStateNamespaceFacade.dispatchChangedConnectionOfflineServerAction()
                            },
                            res => this.appStateNamespaceFacade.dispatchChangedConnectionOnlineServerAction(),
                        ))
                    )
                )),
            ).subscribe();

            const s2 = fromEvent(window, 'offline').pipe(
                switchMap(_ => this.appStateNamespaceFacade.dispatchChangedConnectionOfflineLocalAction())
            ).subscribe();


            const s3 = this.appStateNamespaceFacade.getAppWithConnectionObservable().pipe(
                // tap(conn => console.log({text: "app with connection obs", app_conn: conn})),
                // distinct(),
                filter(conn => !conn),
                // tap(conn => console.log({text: "app with connection obs 2", app_conn: conn})),
                switchMap(_ => this.appStateNamespaceFacade.getAppStateReconnectingTimeObservable().pipe(
                    switchMap(time => of({}).pipe(
                        delay(time*1000),
                        switchMap(_ => this.metaApiHelper.getUsersMe().pipe(
                            switchMap(E.fold(
                                err => {
                                    if (err.appError && err.appError.code === 203.3)
                                        return this.appFacade.dispatchLogout();
                                    return this.appStateNamespaceFacade.dispatchAddAppStateReconnectingTimeAction()
                                },
                                res => this.appStateNamespaceFacade.dispatchChangedConnectionOnlineServerAction(),
                            ))
                        ))
                    ))
                ))
            ).subscribe();


            this.subscriptions = [s1, s2, s3];

            s.complete();
        })

    }

    /**
     * Like a while loop for RxJS,
     * while (condition()) { doFn() }
     *
     * @param condition
     * @param doFn
     * @param interval
     * @param initialValue
     */
    // private runWhile<T = any> (
    //     condition : () => boolean,
    //     doFn : () => Observable<T>,
    //     interval = 0,
    //     initialValue = null,
    // ) : OperatorFunction<T, T> {
    //     return flatMap<T, T>((initial) => {
    //         return of(condition())
    //             .pipe(
    //                 expand((cond) => {
    //                     if (cond === false) {
    //                         return EMPTY;
    //                     }
    //
    //                     return doFn().pipe(delay(interval), mapTo(condition()));
    //                 }),
    //                 filter(cond => cond === false),
    //                 mapTo(initial),
    //             );
    //     });
    // }
}
