import {DiscoversFacade} from '../shared/core/sources/storage/facade/discovers.facade';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {DiscoverElement} from './models/DiscoverElement';
import {flatMap, map, toArray} from 'rxjs/operators';
import {getDiscoverElementFromDiscover} from './mappers/discovers.mappers';
import * as A from "fp-ts/lib/Array";

@Injectable({
    providedIn: 'root'
})
export class DiscoversService {

    constructor(
        private discoversFacade : DiscoversFacade
    ) {}


    getDiscoverElementListObservable() : Observable<DiscoverElement[]> {
        return this.discoversFacade.getAllDiscoversObservable().pipe(
            map(discs => discs.sort((a, b) => a.position - b.position)),
            flatMap(l => from(l).pipe(
                flatMap(d => getDiscoverElementFromDiscover(d)),
                toArray()
            )),

        );
    }


    joinDiscover(idDiscover: number) : Observable<void> {
        return this.discoversFacade.joinDiscoverByIdDiscover(idDiscover);
    }

    quitDiscover(idDiscover: number) : Observable<void> {
        return this.discoversFacade.quitDiscoverByIdDiscover(idDiscover);
    }


}
