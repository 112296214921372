import {Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileSaverService} from "ngx-filesaver";
import {Guid} from "guid-typescript";
import {BlobTransformers} from "../shared/core/utils/blob.transformers";

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    public constructor(
        private sanitizer: DomSanitizer,
        // private _http: Http,
        private _FileSaverService: FileSaverService,
        private blobTransformers: BlobTransformers
    ) {}

    generateDownloadLinkHTML(link: string) {

        const san_url : SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(link);

        // console.log({text: "sanitized url", u: san_url});
        // return this.sanitizer.bypassSecurityTrustHtml(
        //     "<a target='_blank' href='" + san_url + "'>Click per visualitzar.</a>"
        // );
        return "<a target='_blank' href='" + san_url + "'>Click per visualitzar.</a>"
    }

    generateDownloadLinkFromBlob(f: Blob) : string {
        //@ts-ignore
        // return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(f));

        console.log({text: "sanitizing blob", f: f})
        return this.blobTransformers.getUrlFromBlob(f);
        // return URL.createObjectURL(f);
    }

    sanitizeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }


    openResourceInNewWindow(f: Blob) : void {
        const downloader = document.createElement('a');
        downloader.href = URL.createObjectURL(f);
        downloader.target = '_blank';
        downloader.click();
    }

    downloadResource(f: Blob) : void {
        const downloader = document.createElement('a');
        downloader.href = URL.createObjectURL(f);
        downloader.download = Guid.raw();
        downloader.click();
    }

    downloadResourceUrl(url: string) : void {
        const downloader = document.createElement('a');
        downloader.href = url;
        console.log({text: "url sanitized", url: downloader.href})
        downloader.download = Guid.raw();
        downloader.click();
    }


}
