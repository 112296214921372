import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
    SendAllMessagesNotSendedAction,
    SendAllMessagesNotSendedFailAction, SendAllMessagesNotSendedSuccessAction
} from "../../actions/principal_screen_app/send_messages_not_sended.actions";
import {MessagesFacade} from "../../facade/messages.facade";
import {concatMap, defaultIfEmpty, delay, filter, map, switchMap, tap, toArray} from "rxjs/operators";
import {pipe} from "fp-ts/lib/pipeable";
import * as A from "fp-ts/lib/Array";
import {MessagesRepository} from "../../../../repositories/messages/messages.repository";
import {FileMessagesNotSendedFacade} from "../../facade/messages/filemessagesnotsended.facade";
import * as E from "fp-ts/lib/Either";
import {concat, of} from "rxjs";
import {
    onSendMessageRoomFileSuccess,
    RetrySendMessageOTOAction,
    RetrySendMessageRoomAction
} from "../../actions/messages.actions";
import {
    // changeSynchronizationStateToSendedNotSynchronizedWithIdMessageByMessageOTO,
    // changeSynchronizationStateToSendedNotSynchronizedWithIdMessageByMessageRoom
} from "../../mappers/messages.mappers";
import {StandardError} from "../../../../domain/StandardError";
import {option as O} from "fp-ts";
import {SynchronizationState} from "../../../../domain/SynchronizationState";
import {nilAction} from "../../actions/app.actions";
import {Action} from "@ngrx/store";
import {AppStateNamespaceFacade} from "../../facade/namespaces/app_state_namespace.facade";

@Injectable({
    providedIn: 'root'
})
export class SendMessagesNotSendedEffects {

    sendAllMessagesNotSendedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SendAllMessagesNotSendedAction),
            switchMap(({sentInternalIds}) => this.appStateNamespaceFacade.getAppWithConnection().pipe(
                switchMap(withConnection => {
                    console.log({text: "SendAllMessagesNotSendedAction conn", conn: withConnection})
                    if (!withConnection) return [SendAllMessagesNotSendedSuccessAction()];
                    return this.messagesFacade.getNextMessageToSend(sentInternalIds).pipe(
                        tap(m => console.log({text: "SendAllMessagesNotSendedAction", msg: m})),
                        delay(300),
                        switchMap(O.fold(
                            () => {
                                if (sentInternalIds.length === 0) return [SendAllMessagesNotSendedSuccessAction()]
                                return of(sentInternalIds).pipe(
                                    delay(10000),
                                    map(_ => SendAllMessagesNotSendedAction({sentInternalIds: []}))
                                );
                            },
                            eth => pipe(
                                eth,
                                E.fold(
                                    msgOto => [
                                        RetrySendMessageOTOAction({idUserOTO: msgOto.idUserOTO, internalId: msgOto.internalId}),
                                        SendAllMessagesNotSendedAction({sentInternalIds: [...sentInternalIds, msgOto.internalId]})
                                    ] as Action[],
                                    msgRoom => [
                                        RetrySendMessageRoomAction({idRoom: msgRoom.idRoom, internalId: msgRoom.internalId}),
                                        SendAllMessagesNotSendedAction({sentInternalIds: [...sentInternalIds, msgRoom.internalId]})
                                    ]
                                )
                            ),
                        ))
                    )
                })
                // filter(withConnection => withConnection)
            )),
            // switchMap(_ => this.messagesFacade.getNextMessageToSend()),
            // concatMap(O.fold(
            //     () => [SendAllMessagesNotSendedSuccessAction()],
            //     eth => [
            //         pipe(
            //             eth,
            //             E.fold(
            //                 msgOto => RetrySendMessageOTOAction({idUserOTO: msgOto.idUserOTO, internalId: msgOto.internalId}) as Action,
            //                 msgRoom => RetrySendMessageRoomAction({idRoom: msgRoom.idRoom, internalId: msgRoom.internalId})
            //             )
            //         ),
            //         SendAllMessagesNotSendedAction()
            //     ]
            // ))
            // switchMap(_ => this.messagesFacade.getAllMessagesNotSended()),
            // map(({messagesRoom, messagesOTO}) => ({
            //     messagesRoom: messagesRoom.sort((a, b) => a.date.getTime() - b.date.getTime()),
            //     messagesOTO: messagesOTO.sort((a, b) => a.date.getTime() - b.date.getTime())
            // })),
            // map(({messagesRoom, messagesOTO}) => [
            //     pipe(
            //         messagesRoom,
            //         A.map(msg => {
            //             if (msg.thumbnail) {
            //                 return this.fileMessagesNotSendedFacade.getFileMessageNotSendedByInternalId(msg.internalId).pipe(
            //                     switchMap(fileMessage => {
            //                         const reply = pipe(
            //                             msg.messageReply,
            //                             O.map(({idMessage}) => idMessage)
            //                         );
            //                         return this.messagesRepository.sendNewMessageRoomFile(msg.text, fileMessage.file, fileMessage.thumbnail, msg.idRoom, msg.internalId, reply)
            //                     }),
            //                     switchMap(E.fold(
            //                         err => of(err),
            //                         idMsg => this.messagesFacade.dispatchSendMessageRoomFileSuccess(msg.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.some(idMsg), O.none))
            //                     ))
            //                 );
            //             } else {
            //                 return this.messagesRepository.sendNewMessageRoom(msg).pipe(
            //                     switchMap(E.fold(
            //                         err => of(err),
            //                         _ => this.messagesFacade.dispatchSendMessageRoomSuccess(msg)
            //                     ))
            //                 );
            //             }
            //         })
            //     ),
            //     pipe(
            //         messagesOTO,
            //         A.map(msg => {
            //             if (msg.thumbnail) {
            //                 return this.fileMessagesNotSendedFacade.getFileMessageNotSendedByInternalId(msg.internalId).pipe(
            //                     switchMap(fileMessage => {
            //                         const reply = pipe(
            //                             msg.messageReply,
            //                             O.map(({idMessage}) => idMessage)
            //                         );
            //                         return this.messagesRepository.sendNewMessageOTOFile(msg.text, fileMessage.file, fileMessage.thumbnail, msg.idUserOTO, msg.internalId, reply);
            //                     }),
            //                     switchMap(E.fold(
            //                         err => of(err),
            //                         idMsg => this.messagesFacade.dispatchSendMessageOTOFileSuccess(msg.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.some(idMsg), O.none))
            //                     ))
            //                 );
            //             } else {
            //                 return this.messagesRepository.sendNewMessageOTO(msg).pipe(
            //                     switchMap(E.fold(
            //                         err => of(err),
            //                         _ => this.messagesFacade.dispatchSendMessageOTOSuccess(msg)
            //                     ))
            //                 )
            //             }
            //         })
            //     )
            // ]),
            // switchMap(([msgsRoomObs, msgsOTOsObs]) => concat(...msgsRoomObs, ...msgsOTOsObs)),
            // toArray(),
            // defaultIfEmpty([] as StandardError[]),
            // map(errors => {
            //     if (errors.length === 0) {
            //         return SendAllMessagesNotSendedFailAction({errors: errors});
            //     } else {
            //         return SendAllMessagesNotSendedSuccessAction();
            //     }
            // })
        )
    );

    // sendAllMessagesNotSendedEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(SendAllMessagesNotSendedAction),
    //         switchMap(_ => this.messagesFacade.getAllMessagesNotSended()),
    //         map(({messagesRoom, messagesOTO}) => ({
    //             messagesRoom: messagesRoom.sort((a, b) => a.date.getTime() - b.date.getTime()),
    //             messagesOTO: messagesOTO.sort((a, b) => a.date.getTime() - b.date.getTime())
    //         })),
    //         map(({messagesRoom, messagesOTO}) => [
    //             pipe(
    //                 messagesRoom,
    //                 A.map(msg => {
    //                     if (msg.thumbnail) {
    //                         return this.fileMessagesNotSendedFacade.getFileMessageNotSendedByInternalId(msg.internalId).pipe(
    //                             switchMap(fileMessage => {
    //                                 const reply = pipe(
    //                                     msg.messageReply,
    //                                     O.map(({idMessage}) => idMessage)
    //                                 );
    //                                 return this.messagesRepository.sendNewMessageRoomFile(msg.text, fileMessage.file, fileMessage.thumbnail, msg.idRoom, msg.internalId, reply)
    //                             }),
    //                             switchMap(E.fold(
    //                                 err => of(err),
    //                                 idMsg => this.messagesFacade.dispatchSendMessageRoomFileSuccess(msg.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.some(idMsg), O.none))
    //                             ))
    //                         );
    //                     } else {
    //                         return this.messagesRepository.sendNewMessageRoom(msg).pipe(
    //                             switchMap(E.fold(
    //                                 err => of(err),
    //                                 _ => this.messagesFacade.dispatchSendMessageRoomSuccess(msg)
    //                             ))
    //                         );
    //                     }
    //                 })
    //             ),
    //             pipe(
    //                 messagesOTO,
    //                 A.map(msg => {
    //                     if (msg.thumbnail) {
    //                         return this.fileMessagesNotSendedFacade.getFileMessageNotSendedByInternalId(msg.internalId).pipe(
    //                             switchMap(fileMessage => {
    //                                 const reply = pipe(
    //                                     msg.messageReply,
    //                                     O.map(({idMessage}) => idMessage)
    //                                 );
    //                                 return this.messagesRepository.sendNewMessageOTOFile(msg.text, fileMessage.file, fileMessage.thumbnail, msg.idUserOTO, msg.internalId, reply);
    //                             }),
    //                             switchMap(E.fold(
    //                                 err => of(err),
    //                                 idMsg => this.messagesFacade.dispatchSendMessageOTOFileSuccess(msg.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.some(idMsg), O.none))
    //                             ))
    //                         );
    //                     } else {
    //                         return this.messagesRepository.sendNewMessageOTO(msg).pipe(
    //                             switchMap(E.fold(
    //                                 err => of(err),
    //                                 _ => this.messagesFacade.dispatchSendMessageOTOSuccess(msg)
    //                             ))
    //                         )
    //                     }
    //                 })
    //             )
    //         ]),
    //         switchMap(([msgsRoomObs, msgsOTOsObs]) => concat(...msgsRoomObs, ...msgsOTOsObs)),
    //         toArray(),
    //         defaultIfEmpty([] as StandardError[]),
    //         map(errors => {
    //             if (errors.length === 0) {
    //                 return SendAllMessagesNotSendedFailAction({errors: errors});
    //             } else {
    //                 return SendAllMessagesNotSendedSuccessAction();
    //             }
    //         })
    //     )
    // );

    constructor(
        private actions$: Actions,
        private messagesFacade: MessagesFacade,
        private messagesRepository: MessagesRepository,
        private fileMessagesNotSendedFacade: FileMessagesNotSendedFacade,
        private appStateNamespaceFacade: AppStateNamespaceFacade,
    ) {}
}
