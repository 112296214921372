import {HttpHeaders} from './HttpHeaders';
import {CookieStoreService} from '../../../../../localStorage/cookie-store.service';
import {Observable, of} from 'rxjs';
import {LoggedState} from './LoggedState';

export class UnloggedHttpHeaders extends HttpHeaders {


    // private static UNLOGGED_TAG_TOKEN = "UNLOGGED_TOKEN_ZONETACTS";

    constructor() {
        super();
    }

    getHeaders(): Observable<JSON> {
        return of(<JSON>{});
    }

    getLoggedState(): LoggedState {
        return LoggedState.UNLOGGED;
    }

    getAuthorizationToken(): Observable<string> {
        return of("");
    }

//     getHeaders() : Observable<JSON> {
//         return of(<JSON>{});
//     }
// }
}
