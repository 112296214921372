import {Option} from "fp-ts/lib/Option";


export class UrlResourceModel {

    constructor(
        readonly url: string,
        readonly codes: Option<{
            requestId: string,
            deviceId: string
        }>
    ) {
    }
}
