import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VideoViewerComponent implements OnInit {

    @Input() urlVideo: string;

    private sanitizedUrl;

    constructor(
        private sanitizer: DomSanitizer,
        public activeModal: NgbActiveModal,
    ) { }

    ngOnInit() {
        this.sanitizedUrl = this.videoURL();
    }

    videoURL() {
        let video, results;

        if (this.urlVideo === null) {
            return '';
        }
        results = this.urlVideo.match('[\\?&]v=([^&#]*)');
        video   = (results === null) ? this.urlVideo : results[1];

        return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
        // this.sanitizer.bypassSecurityTrustResourceUrl(this.urlVideo);
    }

}
