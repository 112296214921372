import {OwnDataUser} from "../../../../domain/OwnDataUser";
import {Option} from "fp-ts/lib/Option";


export interface MetaState {
    readonly ownDataUser: OwnDataUser;
    readonly ownImageProfile: Option<Blob>;
}

export const initialMetaState : MetaState = {
    ownDataUser: undefined,
    ownImageProfile: undefined,
};
