import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {MessageRoom} from '../../../domain/MessageRoom';
import {MessageOTO} from '../../../domain/MessageOTO';
import {Message} from '../../../domain/Message';
import {PagedRoomGroupMessages} from "../entities/PagedRoomGroupMessages";
import {PagedRoomOTOMessages} from '../entities/PagedRoomOTOMessages';


export function sortByDateMessage(a: Message, b: Message) : number {
    return -a.date.getTime() + b.date.getTime();
}

// --------------------------------------------------
// -------------- Messages Room ---------------------
// --------------------------------------------------
// --------------------------------------------------

export const adapterMessageRoom : EntityAdapter<MessageRoom> = createEntityAdapter<MessageRoom>({
    selectId: (m : MessageRoom) => m.idMessage,
    sortComparer: sortByDateMessage
});

// export const adapterNotSynchronizedMessagesRoom : EntityAdapter<MessageRoom> = createEntityAdapter<MessageRoom>({
//     selectId: (m : MessageRoom) => m.internalId,
//     sortComparer: sortByDateMessage
// });

export const adapterPagedRoomGroupMessages: EntityAdapter<PagedRoomGroupMessages> = createEntityAdapter<PagedRoomGroupMessages>({
    selectId: (pagedRoom: PagedRoomGroupMessages) => pagedRoom.idRoom
});




// --------------------------------------------------
// -------------- Messages OTO ----------------------
// --------------------------------------------------
// --------------------------------------------------


export const adapterMessageOTO : EntityAdapter<MessageOTO> = createEntityAdapter<MessageOTO>({
    selectId: (m : MessageOTO) => m.idMessage,
    sortComparer: sortByDateMessage
});


// export const adapterNotSynchronizedMessagesOTO : EntityAdapter<MessageOTO> = createEntityAdapter<MessageOTO>({
//     selectId: (m : MessageOTO) => m.internalId,
//     sortComparer: sortByDateMessage
// });

export const adapterPagedRoomOTOMessages : EntityAdapter<PagedRoomOTOMessages> = createEntityAdapter<PagedRoomOTOMessages>({
    selectId: (pagedRoom : PagedRoomOTOMessages) => pagedRoom.idUser
});

// export function selectMessageOTOIdMessage(m: MessageOTO) : string {
//     return m.idMessage;
// }

// export const adapterMessageOTO : EntityAdapter<MessageOTO> = createEntityAdapter<MessageOTO>({
//     selectId: selectMessageOTOIdMessage
// });


