import {Injectable} from '@angular/core';
import {MessagesApihelper} from '../../sources/remote/messages/messages.apihelper';
import {from, Observable, of, zip} from 'rxjs';
import {MessageRoom} from '../../domain/MessageRoom';
import {flatMap, map, switchMap, tap, toArray} from 'rxjs/operators';
import {MessagesMapper} from '../mappers/messages.mapper';
import {MessageOTO} from '../../domain/MessageOTO';
import {AppFacade} from '../../sources/storage/facade/app.facade';
import {SocketFacade} from '../../realtime/socket.facade';
import {SynchronizationState} from '../../domain/SynchronizationState';
import {FileMessage} from '../../domain/FileMessage';
import {MessageFileApiModel} from '../../sources/remote/messages/models/MessageFileApiModel';
import * as E from "fp-ts/lib/Either";
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../domain/StandardError";
import {Option} from "fp-ts/lib/Option";
import {MetaFacade} from "../../sources/storage/facade/generic/meta.facade";
import * as A from "fp-ts/lib/Array";
import {pipe} from "fp-ts/lib/pipeable";
import {MessageUserStatus} from "../../domain/MessageUserStatus";
import {getUnixDateFromDate} from "../../../utils/dates.transformers";


@Injectable({
    providedIn: 'root',
})
export class MessagesRepository {

    constructor(
        private messagesApiHelper: MessagesApihelper,
        private appFacadeStore : AppFacade,
        private socketFacade: SocketFacade,
        private messagesMapper : MessagesMapper,
        private metaFacade: MetaFacade
    ) {}

    sendNewMessageRoom(message: MessageRoom) : Observable<Either<StandardError, {}>> {
        return this.socketFacade.sendMessageRoom(message);
    }

    sendNewMessageOTO(message: MessageOTO) : Observable<Either<StandardError, {}>> {
        return this.socketFacade.sendMessageOTO(message);
    }



    sendNewMessageRoomFile(text: string, file: Blob, thumbnail: string, idRoom: number, internalId: string, idMessageOption: Option<string>) : Observable<Either<StandardError, string>> {
        return this.messagesApiHelper.postRoomsIdrSendfile(idRoom, file, text, thumbnail, internalId, idMessageOption).pipe(
            map(E.map(({_id}) => _id))
            // map(({_id}) => _id)
        );
    }

    sendNewMessageOTOFile(text: string, file: Blob, thumbnail: string, idUser: number, internalId: string, idMessageOption: Option<string>) : Observable<Either<StandardError, string>> {
        return this.messagesApiHelper.postUsersIdSendfile(idUser, file, text, thumbnail, internalId, idMessageOption).pipe(
            map(E.map(({_id}) => _id))
        );
    }

    getPageMessagesByIdRoomNumberPage(idRoom: number, pageNumber: number) : Observable<Either<StandardError, MessageRoom[]>> {
        return this.messagesApiHelper.getMessagePageFromRoomByIdRoomNumberPage(idRoom, pageNumber).pipe(
            flatMap(E.fold(
                err => of(E.left(err)),
                messages => from(messages.messages).pipe(
                    flatMap(message => this.messagesMapper.getMessageRoomByMessageRoomApiModel(message, SynchronizationState.SYNCHRONIZED)),
                    toArray(),
                    // tap(msgs => console.log({text: "Messages parsed", msgs: msgs})),
                    map(msgs => E.right(msgs))
                )
            ))
        );
    }

    getNumberMessagesByIdRoom(idRoom: number, messages: number) : Observable<Either<StandardError, MessageRoom[]>> {
        return this.messagesApiHelper.getNumberMessagesFromRoomByIdRoomNumberMessages(idRoom, messages).pipe(
            switchMap(E.fold(
                err => of(E.left(err)),
                messages => zip(...pipe(
                    messages.messages,
                    A.map(m => this.messagesMapper.getMessageRoomByMessageRoomApiModel(m, SynchronizationState.SYNCHRONIZED))
                )).pipe(
                    map(ms => E.right(ms))
                )
            ))
        )
    }

    getPageMessagesByIdUserNumberPage(idUser: number, pageNumber: number) : Observable<Either<StandardError, MessageOTO[]>> {
        return this.messagesApiHelper.getMessagePageFromOTOByIdUserNumberPage(idUser, pageNumber).pipe(
            flatMap(E.fold(
                err => of(E.left(err)),
                messages => this.metaFacade.getOwnDataUser().pipe(
                    flatMap(ownDataUser => from(messages.messages).pipe(
                        flatMap(message => this.messagesMapper.getMessageOTOByMessageOTOApiModel(message, ownDataUser, SynchronizationState.SYNCHRONIZED)),
                        toArray()
                    )),
                    map(msgs => E.right(msgs))
                )
            ))
        );
    }

    private getObservableConversionFileMessageApi(messages: MessageFileApiModel[]) : Observable<FileMessage[]> {
        return from(messages).pipe(
            flatMap(message => this.messagesMapper.getMessageFileFromMessageFileApiModel(message)),
            toArray()
        );
    }

    getPageFileMessageRoomByIdRoomNumberPage(idRoom: number, pageNumber: number) : Observable<Either<StandardError, FileMessage[]>> {
        return this.messagesApiHelper.getRoomsIdrFiles(idRoom, pageNumber).pipe(
            flatMap(E.fold(
                err => of(E.left(err)),
                messages => this.getObservableConversionFileMessageApi(messages.files).pipe(
                    map(msgs => E.right(msgs))
                )
            ))
        );
    }

    getPageFileMessageOneToOneByIdUserNumberPage(idUser: number, pageNumber: number) : Observable<Either<StandardError, FileMessage[]>> {
        return this.messagesApiHelper.getUsersIdFiles(idUser, pageNumber).pipe(
            flatMap(E.fold(
                err => of(E.left(err)),
                messages => this.getObservableConversionFileMessageApi(messages.files).pipe(
                    map(msgs => E.right(msgs))
                )
            ))
        );
    }


    getFileFromMessageByIdRoomIdMessage(idRoom: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        return this.messagesApiHelper.getRoomsIdGetfilemessageIdm(idRoom, idMessage);
    }

    getFileFromMessageByIdUserIdMessage(idUser: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        return this.messagesApiHelper.getUsersIdGetfilemessageIdm(idUser, idMessage);
    }

    getMessagesHistoricRoomGroupFromDate(idRoom: number, fromDate: Date) : Observable<Either<StandardError, MessageRoom[]>> {
        return this.messagesApiHelper.getRoomsIdrHistoricFrom(idRoom, fromDate).pipe(
            switchMap(E.fold(
                err => of(E.left(err)),
                ({messages}) => zip(...pipe(
                    messages,
                    A.map(m => this.messagesMapper.getMessageRoomByMessageRoomApiModel(m, SynchronizationState.SYNCHRONIZED))
                )).pipe(
                    tap(msgs => console.log({text: "messages getMessagesHistoricRoomGroupFromDate", msgs: msgs})),
                    map(msgs => E.right(msgs))
                )
            ))
        )


    }

    getMessagesHistoricRoomOTOFromDate(idUser: number, fromDate: Date) : Observable<Either<StandardError, MessageOTO[]>> {
        return this.messagesApiHelper.getUsersIduHistoricFrom(idUser, fromDate).pipe(
            switchMap(E.fold(
                err => of(E.left(err) as Either<StandardError, MessageOTO[]>) ,
                ({messages}) => this.metaFacade.getOwnDataUser().pipe(
                    switchMap(ownData => zip(
                            ...pipe(
                                messages,
                                A.map(m => this.messagesMapper.getMessageOTOByMessageOTOApiModel(m, ownData, SynchronizationState.SYNCHRONIZED))
                            )
                        ).pipe(
                            map(msgs => E.right(msgs))
                        )
                    )
                )
            ))
        )
    }

    deleteMessageRoom(idRoom: number, idMessage: string) : Observable<Either<StandardError, MessageRoom>> {
        return this.messagesApiHelper.deleteMessageRoom(idRoom, idMessage).pipe(
            switchMap(E.fold(
                err => of(E.left(err) as Either<StandardError, MessageRoom>),
                msg => this.messagesMapper.getMessageRoomByMessageRoomApiModel(msg, SynchronizationState.SYNCHRONIZED).pipe(
                    map(msg => E.right(msg))
                )
            ))
        )
    }

    deleteMessageOTO(idUser: number, idMessage: string) : Observable<Either<StandardError, MessageOTO>> {
        return this.messagesApiHelper.deleteMessageOTO(idUser, idMessage).pipe(
            switchMap(E.fold(
                err => of(E.left(err) as Either<StandardError, MessageOTO>),
                msg => this.metaFacade.getOwnDataUser().pipe(
                    switchMap(owndata => this.messagesMapper.getMessageOTOByMessageOTOApiModel(msg, owndata, SynchronizationState.SYNCHRONIZED).pipe(
                        map(m => E.right(m))
                    ))
                )
            ))
        )
    }

    getMessageStatusUsers(idRoom: number, idMessage: string) : Observable<Either<StandardError, MessageUserStatus>> {
        return this.messagesApiHelper.getMessageUserStatus(idRoom, idMessage).pipe(
            map(E.map(this.messagesMapper.getMessageStatusUsersFromApiModel))
        );
    }

    notifyUpperPointer(idRoom: number, date: Date) : Observable<Either<StandardError, {}>> {
        return this.messagesApiHelper.putRoomsIdrUpper(idRoom, getUnixDateFromDate(date));
    }

}
