

export class UserRoom {
    readonly idUser: number;
    readonly authorized: boolean;

    // readonly color: string;


    constructor(idUser: number, authorized: boolean) {
        this.idUser = idUser;
        this.authorized = authorized;
        // this.color = color;
    }
}
