import {ModifiedState} from "../domain/Message";


export function getModifiedStateByNumber(n: number) : ModifiedState {
    switch (n) {
        case 1:
            return ModifiedState.MODIFIED;
        case 2:
            return ModifiedState.DELETED;
        default:
            return ModifiedState.NOTHING;
    }
}

