import {ReplyMessageModel} from "../../sources/remote/globalmodels/ReplyMessageModel";
import {MessageRoomSocketModel} from '../models/MessageRoomSocketModel';
import {MessageOTOSocketModel} from '../models/MessageOTOSocketModel';
import {StatusSocketModel} from '../models/StatusSocketModel';
import {RoomModifiedSocketModel} from "../models/RoomModifiedSocketModel";
import {UserModifiedSocketModel} from "../models/UserModifiedSocketModel";
import {NewUserSocketModel} from "../models/NewUserSocketModel";
import {AckMsgSocketModel} from "../models/AckMsgSocketModel";
import {AppError} from "../../sources/remote/globalmodels/AppError";
import {NewDiscoverSocketModel} from "../models/NewDiscoverSocketModel";
import * as O from "fp-ts/lib/Option";
import {DiscoverModifiedSocketModel} from "../models/DiscoverModifiedSocketModel";
import {RoomAdminsSocketModel} from "../models/RoomAdminsSocketModel";
import {OTOReadedMessagesSocketModel} from "../models/OTOReadedMessagesSocketModel";
import {RoomReadedMessagesSocketModel} from "../models/RoomReadedMessagesSocketModel";
import {IDiscoverApiModel} from "../../sources/remote/discovers/models/IDiscoverApiModel";
import {LinkPreviewMessageModel} from "../../sources/remote/globalmodels/LinkPreviewMessageModel";
import {ConfirmationFileSocketModel} from "../models/ConfirmationFileSocketModel";


function getReplyMessageFromAny(m: any) : ReplyMessageModel {
    return  {
        idMessage: m.idMessage,
        idUser: m.idUser,
        date: m.date,
        text: m.text,
        file: m.file,
        modifiedState: m.modifiedState
    }
}

function getLinkPreviewFromAny(m: any) : LinkPreviewMessageModel {
    return {
        title: m.title,
        videoUrl: m.videoUrl,
        url: m.url,
        type: m.type,
        description: m.description,
        imageBase64: m.imageBase64,
    }
}

export function getMessageRoomSocketModelFromObject(object: any) : MessageRoomSocketModel {
    return new MessageRoomSocketModel(
        parseInt(object.idUser, 10),
        object.date,
        object._id,
        parseInt(object.idRoom, 10),
        object.message,
        object.internalId,
        object.thumbnail,
        object.fileType,
        object.extension,
        object.fileDuration,
        object.replyMessage ? getReplyMessageFromAny(object.replyMessage) : undefined,
        object.modifiedState,
        object.linkPreview ? getLinkPreviewFromAny(object.linkPreview) : undefined
    );
}

export function getMessageOTOSocketModelFromObject(object: any) : MessageOTOSocketModel {
    return new MessageOTOSocketModel(
        parseInt(object.idUser, 10),
        object.date,
        object._id,
        parseInt(object.toIdUser, 10),
        object.message,
        object.internalId,
        object.thumbnail,
        object.fileType,
        object.extension,
        object.fileDuration,
        object.replyMessage ? getReplyMessageFromAny(object.replyMessage) : undefined,
        object.modifiedState,
        object.linkPreview ? getLinkPreviewFromAny(object.linkPreview) : undefined
    );
}


export function getStatusSocketModelFromObject(object: any) : StatusSocketModel {
    return new StatusSocketModel(
        parseInt(object.idRoom, 10),
        parseInt(object.fromUser, 10),
        parseInt(object.byIdUser, 10),
        object.date,
        object._id
    );
}

export function getRoomModifiedSocketModel(object: any) : RoomModifiedSocketModel {
    return new RoomModifiedSocketModel(
        parseInt(object.idRoom, 10),
        parseInt(object.byIdUser, 10),
        object.date,
        object._id,
        object.fields
    );
}


export function getUserModifiedSocketModel(object: any) : UserModifiedSocketModel {
    return new UserModifiedSocketModel(
        parseInt(object.idUser, 10),
        object.date,
        object._id,
        object.fields
    );
}

export function getNewuserSocketModel(object: any) : NewUserSocketModel {
    return new NewUserSocketModel(
        parseInt(object.idUser, 10),
        parseInt(object.active, 10) as 0 | 1,
        object.lastActivity,
        object.name,
        object.surname,
        object.additionalInfo
    );
}

export function getAckMsgSocketModel(msg: any) : AckMsgSocketModel {
    return new AckMsgSocketModel(
        msg.response,
        msg.internalId,
        msg.response === "ERROR" ? new AppError(JSON.stringify(msg.appError)) : undefined
    );
}

export function getNewDiscoverSocketModel(msg: any) : NewDiscoverSocketModel {
    return new NewDiscoverSocketModel(
        {
            idDiscover: parseInt(msg.discover.idDiscover, 10),
            name: msg.discover.name,
            description: msg.discover.description,
            position: parseInt(msg.discover.position, 10),
            idRoom: parseInt(msg.discover.idRoom, 10),
            vinculated: msg.discover.vinculated
        },
        !msg.room ? O.none :
            O.some(msg.room)
    );
}

export function getDiscoverModifiedSocketModel(msg: any) : DiscoverModifiedSocketModel {
    return new DiscoverModifiedSocketModel(
        parseInt(msg.idDiscover, 10),
        msg.date,
        msg.fields
    );
}

export function getRoomAdminsSocketModel(msg: any) : RoomAdminsSocketModel {
    return new RoomAdminsSocketModel(
        parseInt(msg.idRoom, 10),
        msg.administrators
    );
}

export function getOTOReadedMessagesSocketModel(msg: any) : OTOReadedMessagesSocketModel {
    return new OTOReadedMessagesSocketModel(
        parseInt(msg.idUser, 10),
        msg.messagesToClean
    );
}

export function getRoomReadedMessagesSocketModel(msg: any) : RoomReadedMessagesSocketModel {
    return new RoomReadedMessagesSocketModel(
        parseInt(msg.idRoom, 10),
        msg.messagesToClean
    );
}

export function getConfirmationFileSocketModel(msg: any) : ConfirmationFileSocketModel {
    return new ConfirmationFileSocketModel(
        msg.idRequest,
        msg.idBackendRequest
    )
}
