import {Injectable} from '@angular/core';
import {MetaApihelper} from '../../sources/remote/meta/meta.apihelper';
import {OwnDataUser} from '../../domain/OwnDataUser';
import {Observable} from 'rxjs';
import {flatMap, map, tap} from 'rxjs/operators';
import {getOwnDataUserFromOwnDataUserApiModel} from '../mappers/meta.mapper';
import {IPutUsersMeRequestModel} from '../../sources/remote/meta/models/IPutUsersMeRequestModel';
import {Option} from "fp-ts/lib/Option";
import {Either} from "fp-ts/lib/Either";
import {AppError} from "../../sources/remote/globalmodels/AppError";
import * as E from "fp-ts/lib/Either";
import {StandardError} from "../../domain/StandardError";
import * as O from "fp-ts/lib/Option";

@Injectable({
    providedIn: 'root',
})
export class MetaRepository {


    constructor(
        private metaApiHelper: MetaApihelper
    ) {}

    getOwnData() : Observable<Either<StandardError,OwnDataUser>> {
        return this.metaApiHelper.getUsersMe().pipe(
            map(E.map(response => getOwnDataUserFromOwnDataUserApiModel(response))),
        );
    }

    changeOwnData(bio: Option<string>, image: Option<Blob>,
                  emailContact: Option<Option<string>>,
                  emailNotifications: Option<boolean>) : Observable<Either<StandardError, {}>> {
        return this.metaApiHelper.putUsersMeModifydata(bio, image, emailContact, emailNotifications);
    }

    logout() : Observable<Either<StandardError, {}>> {
        return this.metaApiHelper.postUsersLogout();
    }

    refreshFirebaseToken(deviceId: string, fcmToken: string) : Observable<Either<StandardError, {}>> {
        return this.metaApiHelper.postUsersRefreshfirebasetoken(deviceId, fcmToken);
    }

    checkToken() : Observable<Either<StandardError, Option<string>>> {
        return this.metaApiHelper.getUsersChecktoken().pipe(
            map(E.map(({oldExpired, token}) => oldExpired === 1 ? O.some(token) : O.none))
            // map(E.map(({token}) => token))
        );
    }
}
