



export class ConfirmationFileSocketModel {
    constructor(
        readonly idRequest: string,
        readonly idBackendRequest: string
    ) {
    }
}
