import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Action, createAction} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {
    NewMessagesCountRoomGroupAction, NewMessagesCountRoomOTOAction,
    NewMessagesRoomGroupAction, NewMessagesRoomOTOAction, ProcessFinalSynchronizationAction,
    SynchronizeMessagesRoomsAction, SynchronizeMessagesRoomsFailAction,
    SynchronizeMessagesRoomsSuccessAction, SynchronizeNextRoomAction
} from "../../actions/principal_screen_app/synchronize_messages.actions";
import {map, switchMap, take, tap} from "rxjs/operators";
import {RoomsFacade} from "../../facade/rooms.facade";
import * as A from "fp-ts/lib/Array";
import {pipe} from "fp-ts/lib/pipeable";
import {PartialRoomGroup} from "../../entities/PartialRoomGroup";
import {PartialRoomOTO} from "../../entities/PartialRoomOTO";
import {Observable, of} from "rxjs";
import {Option} from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import {MessagesRepository} from "../../../../repositories/messages/messages.repository";
import {RoomGroup} from "../../../../domain/RoomGroup";
import * as E from "fp-ts/lib/Either";
import {MessagesFacade} from "../../facade/messages.facade";
import {data} from "../../../../../data/smart-data-table";
import {AppStateNamespaceFacade} from "../../facade/namespaces/app_state_namespace.facade";
import {onNewMessageOnFileSelectedRoom} from "../../actions/filemessages.actions";
import {getFileMessageFromMessage} from "../../mappers/messages.mappers";

@Injectable({
    providedIn: 'root'
})
export class SynchronizeMessagesEffects {

    private getNextRoom(list_errors: [number, 0|1][], data_inici_proces: Date) : Observable<Option<PartialRoomOTO|PartialRoomGroup>> {
        return this.roomsFacade.getAllRooms().pipe(
            // take(1),
            map(([roomsGr, roomsOto]) => {
                const l1 = pipe(
                    roomsGr,
                    A.filter(r =>
                        !list_errors.some(([id, type]) => type === 0 && id === r.roomGroup.idRoom) &&
                        r.roomGroup.last_messages_updated.getTime() <= data_inici_proces.getTime() &&
                        r.roomGroup.last_messages_updated.getTime() <= r.roomGroup.lastActivity.getTime()
                    )
                );
                const l2 = pipe(
                    roomsOto,
                    A.filter(r =>
                        !list_errors.some(([id, type]) => type === 1 && id === r.roomOTO.idUser) &&
                        r.roomOTO.last_messages_updated.getTime() <= data_inici_proces.getTime() &&
                        r.roomOTO.last_messages_updated.getTime() <= r.roomOTO.lastActivity.getTime()
                    )
                );


                const ldef = [...l1, ...l2];
                console.log({text: "ROOMS!", r: ldef});
                console.log({text: "otos!", otos: roomsOto});
                return A.head(
                    ldef.sort((a, b) => {
                        // console.log({text: "a vs b", a: a, b: b});
                        const d1 =
                            this.deterimeIfPartialRoomGroupOrPartialRoomOTO(a) ?
                                a.roomGroup.lastClick :
                                a.roomOTO.lastClick;
                        const d2 =
                            this.deterimeIfPartialRoomGroupOrPartialRoomOTO(b) ?
                                b.roomGroup.lastClick :
                                b.roomOTO.lastClick;
                        return - d1.getTime() + d2.getTime();
                    })
                );
            })
        );
    }

    private getNumberRoomsToRefresh(data_inici_proces: Date) : Observable<number> {
        return this.roomsFacade.getAllRoomsObservable().pipe(
            take(1),
            map(([roomsGr, roomsOto]) => {
                const l1 = pipe(
                    roomsGr,
                    A.filter(r =>
                        r.roomGroup.last_messages_updated.getTime() <= data_inici_proces.getTime() &&
                        r.roomGroup.last_messages_updated.getTime() <= r.roomGroup.lastActivity.getTime()
                    )
                );
                const l2 = pipe(
                    roomsOto,
                    A.filter(r =>
                        r.roomOTO.last_messages_updated.getTime() <= data_inici_proces.getTime() &&
                        r.roomOTO.last_messages_updated.getTime() <= r.roomOTO.lastActivity.getTime()
                    )
                );
                return l1.length + l2.length;
            })
        )
    }

    synchronizeMessagesRoomsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SynchronizeMessagesRoomsAction),
            switchMap(_ => {
                const data_inici_proces = new Date();
                const list_errors : [number, 0|1][] = []; // 0 groups, 1 otos
                return [
                    SynchronizeNextRoomAction({data_inici_proces: data_inici_proces, LIST_ERRORS: list_errors})
                ]
            })
        )
    );

    processFinalSynchronizationActionEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessFinalSynchronizationAction),
            switchMap(({data_inici_proces, LIST_ERRORS}) => this.getNumberRoomsToRefresh(data_inici_proces).pipe(
                map(tuples => {
                    if (tuples > 0) return SynchronizeNextRoomAction({data_inici_proces: data_inici_proces, LIST_ERRORS: []});
                    return SynchronizeMessagesRoomsSuccessAction();
                })
            ))
        )
    );

    deterimeIfPartialRoomGroupOrPartialRoomOTO(toBeDetermined: PartialRoomGroup|PartialRoomOTO): toBeDetermined is PartialRoomGroup {
        if ((toBeDetermined as {roomGroup: RoomGroup}).roomGroup) {
            return true
        }
        return false
    }

    synchronizeNextRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SynchronizeNextRoomAction),
            switchMap(({data_inici_proces, LIST_ERRORS}) => this.appStateNamespaceFacade.getAppWithConnectionStateObservable().pipe(
                take(1),
                switchMap(withConnection => {
                    if (!withConnection) {
                        return of([
                            SynchronizeMessagesRoomsFailAction({errors: []})
                        ]).pipe(
                            switchMap(as => as)
                        )
                    } else {
                        console.log("Getting next Room.");
                        return this.getNextRoom(LIST_ERRORS, data_inici_proces).pipe(
                            tap(nextRoom => console.log({text: "next room", room: nextRoom})),
                            switchMap(O.fold(
                                () => of([
                                    ProcessFinalSynchronizationAction({data_inici_proces: data_inici_proces, LIST_ERRORS: LIST_ERRORS})
                                ]).pipe(
                                    switchMap(as => as)
                                ),
                                room => {
                                    const lastMessagesUpdated = new Date();
                                    let obsActs : Observable<Action>;
                                    if (this.deterimeIfPartialRoomGroupOrPartialRoomOTO(room)) {
                                        obsActs = this.messagesRepository.getMessagesHistoricRoomGroupFromDate(room.roomGroup.idRoom, room.roomGroup.last_messages_updated).pipe(
                                            switchMap(E.fold(
                                                err => of([
                                                    SynchronizeNextRoomAction({data_inici_proces: data_inici_proces, LIST_ERRORS: [...LIST_ERRORS, [room.roomGroup.idRoom, 0]]})
                                                ]).pipe(
                                                    switchMap(as => as)
                                                ),
                                                messages => this.messagesFacade.getAllIdsAndInternalIdsMessageRoomByIdRoom(room.roomGroup.idRoom).pipe(
                                                    switchMap(ids => {
                                                        const messagesCount = messages.filter(m => !ids.some(mm => m.idMessage === mm.idMessage || m.internalId === mm.internalId)).length;
                                                        const msgsOrdered = messages.sort((a, b) => a.date.getTime() - b.date.getTime());
                                                        const lastMessage = A.head(msgsOrdered);
                                                        return [
                                                            NewMessagesRoomGroupAction({idRoom: room.roomGroup.idRoom, messages: messages}),
                                                            NewMessagesCountRoomGroupAction({idRoom: room.roomGroup.idRoom, uniqueNewMessages: messagesCount, lastMessage: lastMessage, lastMessagesUpdated: lastMessagesUpdated}),
                                                            ...pipe(
                                                                messages,
                                                                A.map(m => {
                                                                    if (!m.thumbnail)
                                                                        return [];
                                                                    return [onNewMessageOnFileSelectedRoom({fileMessage: getFileMessageFromMessage(m)})]
                                                                }),
                                                                A.flatten
                                                            ),
                                                            SynchronizeNextRoomAction({data_inici_proces: data_inici_proces, LIST_ERRORS: LIST_ERRORS})
                                                        ];
                                                    })
                                                )
                                            ))
                                        );
                                    } else {
                                        obsActs = this.messagesRepository.getMessagesHistoricRoomOTOFromDate(room.roomOTO.idUser, room.roomOTO.last_messages_updated).pipe(
                                            switchMap(E.fold(
                                                err => of([
                                                    SynchronizeNextRoomAction({data_inici_proces: data_inici_proces, LIST_ERRORS: [...LIST_ERRORS, [room.roomOTO.idUser, 1] as [number, 0|1]]as [number, 0|1][]})
                                                ]).pipe(
                                                    switchMap(as => as)
                                                ),
                                                messages => this.messagesFacade.getAllIdsAndInternalIdsMessageRoomByIdUser(room.roomOTO.idUser).pipe(
                                                    switchMap(ids => {
                                                        const messagesCount = messages.filter(m => !ids.some(mm => m.idMessage === mm.idMessage || m.internalId === mm.internalId)).length;
                                                        const msgsOrdered = messages.sort((a, b) => a.date.getTime() - b.date.getTime());
                                                        const lastMessage = A.head(msgsOrdered);
                                                        return [
                                                            NewMessagesRoomOTOAction({idUser: room.roomOTO.idUser, messages: messages}),
                                                            NewMessagesCountRoomOTOAction({idUser: room.roomOTO.idUser, uniqueNewMessages: messagesCount, lastMessage: lastMessage, lastMessagesUpdated: lastMessagesUpdated}),
                                                            ...pipe(
                                                                messages,
                                                                A.map(m => {
                                                                    if (!m.thumbnail)
                                                                        return [];
                                                                    return [onNewMessageOnFileSelectedRoom({fileMessage: getFileMessageFromMessage(m)})]
                                                                }),
                                                                A.flatten
                                                            ),
                                                            SynchronizeNextRoomAction({data_inici_proces: data_inici_proces, LIST_ERRORS: LIST_ERRORS})
                                                        ];
                                                    })
                                                )
                                            ))
                                        )
                                    }
                                    return obsActs;
                                }
                            ))
                        );
                    }
                })
            ))
            // switchMap(({data_inici_proces, LIST_ERRORS}) =>
            // ))
        )
    );


    constructor(
        private actions$ : Actions,
        private roomsFacade: RoomsFacade,
        private messagesRepository: MessagesRepository,
        private messagesFacade: MessagesFacade,
        private appStateNamespaceFacade: AppStateNamespaceFacade
    ) {}
}
