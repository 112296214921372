import {HttpHeaders} from './HttpHeaders';
import {CookieStoreService} from '../../../../../localStorage/cookie-store.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoggedState} from './LoggedState';
import {LoggedHttpHeaders} from './LoggedHttpHeaders';

export class PostregisterHttpHeaders extends HttpHeaders {

    private static POSTREGISTER_TAG_TOKEN = "POSTTOKENZONETACTS";
    // private tokenPostregister: string;

    constructor(
        private tokenService: CookieStoreService,
        // private tokenPostregister: string
    ) {
        super();
    }

    getHeaders() : Observable<JSON> {
        return this.tokenService.getById(PostregisterHttpHeaders.POSTREGISTER_TAG_TOKEN)
            .pipe(map(token => <JSON><unknown>{authorization: token}));
        // return of(<JSON><unknown>{authorization: this.tokenPostregister});
    }

    static getTokenTag() : string {
        return this.POSTREGISTER_TAG_TOKEN;
        // return "";
    }

    getLoggedState(): LoggedState {
        return LoggedState.POSTREGISTER;
    }

    getAuthorizationToken(): Observable<string> {
        // return of(this.tokenPostregister);
        return this.tokenService.getById(PostregisterHttpHeaders.POSTREGISTER_TAG_TOKEN);
    }
}
