import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LocaleService} from "../../shared/locales/locale.service";

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent implements OnInit {

    @Input() progress: Observable<number>;

    progressMapped: Observable<number>;

    constructor(
        private localeService: LocaleService
    ) { }

    ngOnInit() {
        this.progressMapped = this.progress.pipe(
            map(number => Math.round(number))
        )
    }

}
