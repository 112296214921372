import {createAction, props} from '@ngrx/store';
import {OwnDataUser} from '../../../domain/OwnDataUser';
import {AppError} from '../../remote/globalmodels/AppError';
import {Option} from "fp-ts/lib/Option";
import {StandardError} from "../../../domain/StandardError";


export const MetaActions = {
    // LOAD_APP: '[Meta] Load App',
    // LOAD_APP_SUCCESS: '[Meta] Load App Success',
    // LOAD_OWN_USER_DATA: '[Meta] Load Own User Data',
    // LOAD_OWN_USER_DATA_SUCCESS: '[Meta] Load Own User Data Success',

    // OPEN_SOCKET: '[Meta] Open Socket',
    // OPEN_SOCKET_SUCCESS: '[Meta] Open Socket Success',
    // OPEN_SOCKET_ERROR: '[Meta] Open Socket Error',
    ON_DISCONNECT_SOCKET: '[Meta] On Disconnect Socket',

    // LOAD_OWN_IMAGE_PROFILE: '[Meta] Load Own Image Profile',
    // LOAD_OWN_IMAGE_PROFILE_SUCCESS: '[Meta] Load Own Image Profile Success',

    CHANGE_OWN_DATA: '[Meta] Change Own Data',
    CHANGE_OWN_DATA_SUCCESS: '[Meta] Change Own Data Success',
    CHANGE_OWN_DATA_FAIL: '[Meta] Change Own Data Fail',

    LOGOUT: '[Meta] Logout',
    LOGOUT_SUCCESS: '[Meta] Logout Success',
    LOGOUT_FAIL: '[Meta] Logout Fail',

    REFRESH_FIREBASE_TOKEN: '[Meta] Refresh Firebase Token',
    REFRESH_FIREBASE_TOKEN_SUCCESS: '[Meta] Refresh Firebase Token Success',
    REFRESH_FIREBASE_TOKEN_FAIL: '[Meta] Refresh Firebase Token Fail',

    // INIT_FIREBASE: '[Meta] Init Firebase',
    // INIT_FIREBASE_SUCCESS: '[Meta] Init Firebase Success',
    // INIT_FIREBASE_FAIL: '[Meta] Init Firebase Fail',

    NIL_ACTION: '[Meta] NIL ACTION'
};

export const logout = createAction(MetaActions.LOGOUT);
export const logoutSuccess = createAction(
    MetaActions.LOGOUT_SUCCESS,
);
export const logoutFail = createAction(
    MetaActions.LOGOUT_FAIL,
    props<{error: StandardError}>()
);

export const refreshFirebaseToken = createAction(
    MetaActions.REFRESH_FIREBASE_TOKEN,
    props<{fcmToken: string, deviceId: string}>()
);
export const refreshFirebaseTokenSuccess = createAction(
    MetaActions.REFRESH_FIREBASE_TOKEN_SUCCESS
);
export const refreshFirebaseTokenFail = createAction(
    MetaActions.REFRESH_FIREBASE_TOKEN_FAIL,
    props<{error: StandardError}>()
);

// export const initFirebase = createAction(
//     MetaActions.INIT_FIREBASE
// );
// export const initFirebaseSuccess = createAction(
//     MetaActions.INIT_FIREBASE_SUCCESS
// );
// export const initFirebaseFail = createAction(
//     MetaActions.INIT_FIREBASE_FAIL,
//     props<{error: StandardError}>()
// );



// export const loadApp = createAction(MetaActions.LOAD_APP);
// export const loadAppSuccess = createAction(
//     MetaActions.LOAD_APP_SUCCESS,
//     props<{loaded: boolean}>()
// );
//
//
// export const loadOwnUserData = createAction(MetaActions.LOAD_OWN_USER_DATA);
// export const loadOwnUserDataSuccess = createAction(
//     MetaActions.LOAD_OWN_USER_DATA_SUCCESS,
//     props<{ownDataUser: OwnDataUser}>()
// );
//
//
// export const loadOwnImageProfile = createAction(MetaActions.LOAD_OWN_IMAGE_PROFILE);
// export const loadOwnImageProfileSuccess = createAction(
//     MetaActions.LOAD_OWN_IMAGE_PROFILE_SUCCESS,
//     props<{ownImageProfile: Blob}>()
// );
//
// export const openSocket = createAction(MetaActions.OPEN_SOCKET);
// export const openSocketSuccess = createAction(MetaActions.OPEN_SOCKET_SUCCESS);
// export const openSocketError = createAction(MetaActions.OPEN_SOCKET_ERROR);
export const onDisconnectSocket = createAction(MetaActions.ON_DISCONNECT_SOCKET);

export const changeOwnData = createAction(
    MetaActions.CHANGE_OWN_DATA,
    // props<{name: string, surname: string, bio: string, image: Blob | undefined}>()
    props<{
        bio: Option<string>,
        image: Option<Blob>,
        emailContact: Option<Option<string>>,
        emailNotifications: Option<boolean>
    }>()
);
export const changeOwnDataSuccess = createAction(
    MetaActions.CHANGE_OWN_DATA_SUCCESS,
    props<{
        bio: Option<string>,
        image: Option<Blob>,
        name: Option<string>,
        surname: Option<string>,
        emailContact: Option<Option<string>>,
        emailNotifications: Option<boolean>
    }>()
);
export const changeOwnDataFail = createAction(
    MetaActions.CHANGE_OWN_DATA_FAIL,
    props<{error: StandardError}>()
);

export const nilAction = createAction(MetaActions.NIL_ACTION);
