import {Injectable} from "@angular/core";
import {createSelector, Store} from "@ngrx/store";
import {IAppState} from "../../state/app.state";
import {Observable} from "rxjs";
import {AppWithConnectionState, FirstAppWithConnectionState} from "../../state/namespaces/app_state_namespace.state";
import {Option} from "fp-ts/lib/Option";
import {StandardError} from "../../../../domain/StandardError";
import {
    ActiveProcessUserValidationSelect,
    AppStateAllDataLoadedSelect, AppStateReconnectingTimeSelect,
    AppStateWithLocalConnectionSelect, AppStateWithServerConnectionSelect,
    AppWithConnectionSelect, AppWithConnectionStateSelect,
    FirstAppWithConnectionSelect, selectAppStateNamespaceState
} from "../../selectors/namespaces/app_state_namespace.selectors";
import {
    AddAppStateReconnectingTimeAction,
    ChangedConnectionOfflineLocalAction, ChangedConnectionOfflineServerAction,
    ChangedConnectionOnlineLocalAction, ChangedConnectionOnlineServerAction, ResetAppStateReconnectingTimeAction
} from "../../actions/namespaces/app_state_namespace.actions";
import {take} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class AppStateNamespaceFacade {
    constructor(
        private store: Store<IAppState>
    ) {}

    getFirstAppWithConnectionStateObservable() : Observable<{state: FirstAppWithConnectionState, error: Option<StandardError>}> {
        return this.store.select(FirstAppWithConnectionSelect);
    }

    getAppWithConnectionStateObservable() : Observable<{state: AppWithConnectionState, error: Option<StandardError>}> {
        return this.store.select(AppWithConnectionStateSelect);
    }

    //AppStateAllDataLoadedSelect
    getAppStateAllDataLoadedObservable() : Observable<boolean> {
        return this.store.select(AppStateAllDataLoadedSelect);
    }

    dispatchChangedConnectionOnlineLocalAction() : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(ChangedConnectionOnlineLocalAction());
            s.complete();
        });
    }

    dispatchChangedConnectionOfflineLocalAction() : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(ChangedConnectionOfflineLocalAction());
            s.complete();
        })
    }

    dispatchChangedConnectionOnlineServerAction() : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(ChangedConnectionOnlineServerAction());
            s.complete();
        });
    }

    dispatchChangedConnectionOfflineServerAction() : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(ChangedConnectionOfflineServerAction());
            s.complete();
        })
    }

    getAppWithConnectionObservable() : Observable<boolean> {
        return this.store.select(AppWithConnectionSelect);
    }
    getAppWithConnection() : Observable<boolean> {
        return this.store.select(AppWithConnectionSelect).pipe(
            take(1)
        )
    }

    getAppStateWithLocalConnection() : Observable<boolean> {
        return this.store.select(AppStateWithLocalConnectionSelect).pipe(
            take(1)
        );
    }
    getAppStateWithLocalConnectionObservable() : Observable<boolean> {
        return this.store.select(AppStateWithLocalConnectionSelect).pipe(
        );
    }
    getAppStateWithServerConnection() : Observable<boolean> {
        return this.store.select(AppStateWithServerConnectionSelect).pipe(
            take(1)
        );
    }
    getAppStateReconnectingTimeObservable() : Observable<number> {
        return this.store.select(AppStateReconnectingTimeSelect);
    }
    dispatchAddAppStateReconnectingTimeAction() : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(AddAppStateReconnectingTimeAction());
            s.complete();
        });
    }
    dispatchResetAppStateReconnectingTimeAction() : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(ResetAppStateReconnectingTimeAction());
            s.complete();
        });
    }
    getActiveProcessUserValidation() : Observable<boolean> {
        return this.store.select(ActiveProcessUserValidationSelect).pipe(
            take(1)
        );
    }

    // export const AppStateWithLocalConnectionSelect = createSelector(
    //     selectAppStateNamespaceState,
    //     state => state.app_state_with_local_connection
    // );
    //
    // export const AppStateWithServerConnectionSelect = createSelector(
    //     selectAppStateNamespaceState,
    //     state => state.app_state_with_server_connection
    // );
}
