import {Component, EventEmitter, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from '../../shared/auth/auth.service';
import {AppService} from '../../chatmodule/app.service';
import {BehaviorSubject, combineLatest, concat, EMPTY, from, Observable, of, Subject} from 'rxjs';
import {endWith, flatMap, map, switchMap, tap} from 'rxjs/operators';
import {ThemeService} from '../../shared/themes/theme.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from '../../shared/profile/profile.component';
import {DiscoverComponent} from '../../shared/discover/discover.component';
import {ProfilemodalService} from '../../shared/utils/profilemodal.service';
import {AlertsService} from '../../shared/utils/alerts.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SweetAlertResult} from 'sweetalert2';
import {LocaleService} from "../../shared/locales/locale.service";
import {IAlbum, Lightbox} from "ngx-lightbox";
import * as O from "fp-ts/lib/Option";
import {pipe} from "fp-ts/lib/pipeable";
import {AppStateNamespaceFacade} from "../../shared/core/sources/storage/facade/namespaces/app_state_namespace.facade";
import {ResourcesService} from "../../shared/resources/resources.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

    currentLang = 'en';
    toggleClass = 'ft-maximize';
    public isCollapsed = true;
    private showDiscovers: boolean;
    private showButtonDiscovers: Observable<boolean>;
    private imageDiscovers: Observable<string>;
    private imageOwn : Observable<string>;




    // convertedImageProfile: Subject<boolean> = new BehaviorSubject<boolean>(false);
    // srcImageConverted: Subject<any> = new BehaviorSubject("");

    // headerLogos : Subject<{icon: string, url: string}[]> = new BehaviorSubject([]);

    constructor(
        // public translate: TranslateService,
        private authService: AuthService,
        private appService: AppService,
        private themeService: ThemeService,
        private modalService: NgbModal,
        private profileModalService: ProfilemodalService,
        private alertsService: AlertsService,
        private spinner: NgxSpinnerService,
        private localeService: LocaleService,
        private resourcesService: ResourcesService,
        private appStateNamespaceFacade: AppStateNamespaceFacade,
        // private lightbox: Lightbox,
    ) {
        // const browserLang: string = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    }

    ChangeLanguage(language: string) {
        // this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else
            this.toggleClass = 'ft-maximize'
    }

    ngOnInit(): void {

        this.showButtonDiscovers = combineLatest([
            of(this.themeService.isDiscoverActivated()),
            this.appStateNamespaceFacade.getAppStateAllDataLoadedObservable()
        ]).pipe(
            map(([b1, b2]) => b1 && b2),
        );

        this.imageDiscovers = this.resourcesService.getDiscoversIcon();
        this.imageOwn = this.appService.getOwnImageProfileObservable().pipe(
            tap(img => console.log({text: "own img", img: img}))
        );

        // this.appService.getOwnImageProfileObservable().pipe(
        // ).subscribe(
        //     image => {
        //         this.srcImageConverted.next(image);
        //         this.convertedImageProfile.next(true);
        //         // TODO ficar com a estat inicial la imatge per defecte i no el undefined!
        //         // console.log({text: "on get own image profile observable", imageBlob: imageBlob});
        //         // if (imageBlob !== undefined) {
        //         //
        //         //     const r = new FileReader();
        //         //     r.onload = e => {
        //         //         this.srcImageConverted.next(r.result);
        //         //         this.convertedImageProfile.next(true);
        //         //         console.log({text: "image converted!"});
        //         //     };
        //         //     r.readAsDataURL(imageBlob);
        //         // }
        //
        //     }
        // );
        // this.headerLogos.next(this.themeService.getHeaderLogos());

    }

    logoutClick() {
        this.appStateNamespaceFacade.getAppWithConnection().pipe(
            switchMap(withConnection => {
                if (!withConnection) {
                    return from(this.alertsService.showAlertWithTextError(this.localeService.translate("No connection! Try again.", {})));
                } else {
                    return concat(
                        from(this.spinner.show()),
                        this.appService.logout().pipe(
                            flatMap(({error}) => this.authService.logout().pipe(
                                flatMap( _ => {
                                    this.spinner.hide();
                                    return pipe(
                                        error,
                                        O.fold(
                                            () => of({}),
                                            error => from(this.alertsService.showAlertFromStandardError(error)).pipe(
                                                map(resultAlert => ({}))
                                            )
                                        )
                                    )
                                })
                            ))
                        )
                    ).pipe(
                        switchMap(_ => EMPTY),
                        endWith({}),
                        tap(_ => window.location.href ='/login')
                    )
                }
            })
        ).subscribe();
    }



    openProfile() {

        this.appService.getOwnProfileDataBundle().subscribe(
            next => {
                const subj = new Subject();
                const showSpinnerE = new EventEmitter<boolean>();
                const hideSpinnerE = new EventEmitter<boolean>();
                console.log({text: "owndatabundle", next: next});
                const modal = this.profileModalService.openProfileEditingModal(
                    next.name,
                    next.surname,
                    next.bio,
                    next.image,
                    pipe(
                        next.emailContact,
                        O.fold(
                            () => "",
                            e => e
                        )
                    ),
                    next.emailNotifications,
                    next.checkEmailNotificationsActivated,
                    showSpinnerE,
                    hideSpinnerE,
                    (({additionalInfo, image, emailContact, emailNotifications}) => {
                        showSpinnerE.emit(true);
                        this.appService.modifyOwnDataUser(additionalInfo, image, emailContact, emailNotifications).subscribe(
                            ({error}) => {
                                hideSpinnerE.emit(true);
                                pipe(
                                    error,
                                    O.fold(
                                        () => {
                                            subj.next(true)
                                        },
                                        error => this.alertsService.showAlertFromStandardError(error).then(e => {})
                                    )
                                )

                            }
                        )
                    })
                );
                subj.asObservable().subscribe(_ => modal.close());
            }
        );
        // this.profileModalService.openProfileEditingModal()
    }

    openSessions() {
        this.profileModalService.openSessionModal();
    }

    openLanguages() {
        this.profileModalService.openLanguagesModal();

    }

    seeDiscover() {
        console.log("mostrando discover!");
        this.showDiscovers = !this.showDiscovers;
        const modalRef = this.modalService.open(DiscoverComponent,{
            ariaLabelledBy:'modal-basic-title',
            size:'lg',
            windowClass:'modal right fade in',backdropClass:'transparent'
        });

        /*modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            console.log(receivedEntry);
        });*/

    }

    openLightBox(src: string) {
        // this.lightbox.openModel
        // this.lightbox.open([<IAlbum>{src: src, caption: ""}], 0)
        this.profileModalService.openVideoViewerModal(src);
    }
}
