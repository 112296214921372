import {Room} from './Room';
import {Message} from './Message';
import {User} from './User';
import {MessageOTO} from "./MessageOTO";


export class RoomOTO extends Room<MessageOTO> {

    readonly idUser: number;

    constructor(
        lastActivity: Date,
        messageCount: number,
        lastMessage: MessageOTO,
        idUser: number,
        last_messages_updated: Date,
        lastClick: Date
    ) {
        super(lastActivity, messageCount, lastMessage, last_messages_updated, lastClick);
        this.idUser = idUser;
    }
}
