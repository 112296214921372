

export class RoomReadedMessagesSocketModel {
    readonly idRoom: number;
    readonly messagesToClean: number;


    constructor(idRoom: number, messagesToClean: number) {
        this.idRoom = idRoom;
        this.messagesToClean = messagesToClean;
    }
}
