import {EntityState} from '@ngrx/entity';
import {MessageRoom} from '../../../domain/MessageRoom';
import {
    adapterMessageOTO,
    adapterMessageRoom,
    adapterPagedRoomGroupMessages, adapterPagedRoomOTOMessages
} from '../adapters/messages.adapters';
import {MessageOTO} from '../../../domain/MessageOTO';
import {PagedRoomGroupMessages} from "../entities/PagedRoomGroupMessages";
import {PagedRoomOTOMessages} from '../entities/PagedRoomOTOMessages';


// --------------------------------------------------
// -------------- Messages Room ---------------------
// --------------------------------------------------
// --------------------------------------------------


export interface IMessageRoomState extends EntityState<MessageRoom> {}
export const initialMessageRoomState : IMessageRoomState = adapterMessageRoom.getInitialState(undefined);
// export const initialNotSynchronizedNotSendedMessageRoomState : IMessageRoomState = adapterNotSynchronizedMessagesRoom.getInitialState(undefined);
// export const initialNotSynchronizedSendedMessageRoomState : IMessageRoomState = adapterNotSynchronizedMessagesRoom.getInitialState(undefined);

export interface IPagedRoomGroupMessagesState extends EntityState<PagedRoomGroupMessages> {}
export const initialPagedRoomGroupMessages : IPagedRoomGroupMessagesState = adapterPagedRoomGroupMessages.getInitialState({});





// --------------------------------------------------
// -------------- Messages OTO ----------------------
// --------------------------------------------------
// --------------------------------------------------

export interface IMessageOTOState extends EntityState<MessageOTO> {}
export const initialMessageOTOState : IMessageOTOState = adapterMessageOTO.getInitialState(undefined);
// export const initialNotSynchronizedNotSendedMessageOTOState : IMessageOTOState = adapterNotSynchronizedMessagesOTO.getInitialState(undefined);
// export const initialNotSynchronizedSendedMessageOTOState : IMessageOTOState = adapterNotSynchronizedMessagesOTO.getInitialState(undefined);


export interface IPagedOTOGroupMessagesState extends EntityState<PagedRoomOTOMessages> {}
export const initialPagedRoomOTOMessages : IPagedOTOGroupMessagesState = adapterPagedRoomOTOMessages.getInitialState({});
