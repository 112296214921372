import {PartialRoomGroup} from "../../core/sources/storage/entities/PartialRoomGroup";
import {PartialRoomOTO} from "../../core/sources/storage/entities/PartialRoomOTO";
import {User} from "../../core/domain/User";
import {Ord, ordDate} from "fp-ts/lib/Ord";
import {Ordering} from "fp-ts/lib/Ordering";


export class ResendElementViewModel {
    constructor(
        readonly isHeader: boolean,
        readonly headerName: string,
        readonly idRoom: number,
        readonly oneToOne: boolean,
        readonly name: string,
        readonly description: string,
        readonly imageUrl: string,
        readonly isUser: boolean,
        public selected: boolean,
        readonly lastActivity: Date
    ) {
    }

    setSelected() {
        this.selected = !this.selected
    }

    compare(e: ResendElementViewModel) : Ordering {
        return ordDate.compare(this.lastActivity, e.lastActivity);
    }

    static fromHeader(headerName: string) : ResendElementViewModel {
        return new ResendElementViewModel(
            true,
            headerName,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        )
    }

    static fromPartialRoomGroup(p: PartialRoomGroup) : ResendElementViewModel {
        return new ResendElementViewModel(
            false,
            null,
            p.roomGroup.idRoom,
            false,
            p.roomGroup.name,
            "",
            p.roomGroup.imageProfileUrl,
            false,
            false,
            p.roomGroup.lastActivity
        )
    }
    static fromPartialRoomOTO(p: PartialRoomOTO, u: User) : ResendElementViewModel {
        return new ResendElementViewModel(
            false,
            null,
            p.roomOTO.idUser,
            true,
            `${u.name} ${u.surname}`,
            u.additionalInfo,
            u.imageProfileUrl,
            false,
            false,
            p.roomOTO.lastActivity
        )
    }
    static fromUser(u: User) : ResendElementViewModel {
        return new ResendElementViewModel(
            false,
            null,
            u.idUser,
            true,
            `${u.name} ${u.surname}`,
            u.additionalInfo,
            u.imageProfileUrl,
            true,
            false,
            new Date()
        )
    }
}
