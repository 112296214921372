import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {FileMessageNotSended} from "../../../domain/FileMessageNotSended";


// export function sortByDateMessage(a: FileMessage, b: FileMessage) : number {
//     return -a.date.getTime() + b.date.getTime();
// }

export const adapterFileMessageNotSended : EntityAdapter<FileMessageNotSended> = createEntityAdapter<FileMessageNotSended>({
    selectId: (m : FileMessageNotSended) => m.internalId,
    // sortComparer: sortByDateMessage
});
