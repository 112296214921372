import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(
        @Inject(DOCUMENT)
        private _document: HTMLDocument,
    ) {

    }

    setAppFavicon(path: string) {
        this._document.getElementById('appFavicon').setAttribute('href', path);
    }
}
