

export class OTOReadedMessagesSocketModel {
    readonly idUser: number;
    readonly messagesToClean: number;


    constructor(idUser: number, messagesToClean: number) {
        this.idUser = idUser;
        this.messagesToClean = messagesToClean;
    }
}
