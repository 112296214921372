import {Injectable} from '@angular/core';
import {IUserState} from '../state/user.state';
import {Store} from '@ngrx/store';
import {PartialUser} from '../entities/PartialUser';
import {Observable} from 'rxjs';
import {
    getAllUsersSelect,
    getFilteredUsersSelect,
    getUserByIdSelect,
    getUsersByIdUsersSelect
} from '../selectors/users.selectors';
import {map, take} from 'rxjs/operators';
import {UserStatus} from "../../../domain/UserStatus";
import {onModifyUserStatus, onNewUser, onUserDeleted} from "../actions/user.actions";
import {accomplishCriteriaNamePartialUser} from "../../../../../chatmodule/mappers/users.mappers";
import {fromNullable, Option} from "fp-ts/lib/Option";


@Injectable({
    providedIn: 'root'
})
export class UsersFacade {

    constructor(
        private store: Store<IUserState>
    ) {}

    getUserByIdObservableOption(idUser: number) : Observable<Option<PartialUser>> {
        return this.getUserByIdObservable(idUser).pipe(
            map(fromNullable)
        )
    }

    getUserByIdObservable(idUser: number) : Observable<PartialUser> {
        return this.store.select(getUserByIdSelect, {idUser: idUser});
    }

    getUserById(idUser: number) : Observable<PartialUser> {
        return this.getUserByIdObservable(idUser).pipe(take(1));
    }

    getUserByIdOption(idUser: number) : Observable<Option<PartialUser>> {
        return this.getUserById(idUser).pipe(
            map(fromNullable)
        );
    }

    // getUserByIdObservable(idUser: number) : Observable<PartialUser> {
    //     return this.getUserByIdObservable(idUser);
    // }

    getAllUsers() : Observable<PartialUser[]> {
        return this.store.select(getAllUsersSelect).pipe(take(1));
    }

    getAllUsersObservable() : Observable<PartialUser[]> {
        return this.store.select(getAllUsersSelect);
    }

    getUsersObservableByCriteria(filter: (pu: PartialUser) => boolean) : Observable<PartialUser[]> {
        return this.store.select(getFilteredUsersSelect(filter))
    }

    // getUsersByCriteria(filter: (pu: PartialUser) => boolean) : Observable<PartialUser[]> {
    //     return this.getUsersObservableByCriteria(filter).pipe(
    //         take(1)
    //     );
    // }

    statusUserModifiedNotify(status: UserStatus) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onModifyUserStatus({status: status}));
            s.complete();
        })
    }

    dispatchOnNewUser(user: PartialUser) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onNewUser({partialUser: user}));
            s.complete();
        })
    }

    dispatchOnUserDeleted(idUser: number) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(onUserDeleted({idUser: idUser}));
            s.complete();
        });
    }

    getUsersObservableByIdUsers(idUsers: number[]) : Observable<{[x: number]: PartialUser}> {
        return this.store.select(getUsersByIdUsersSelect, idUsers);
    }
    getUsersByIdUsers(idUsers: number[]) : Observable<{[x: number]: PartialUser}> {
        return this.getUsersObservableByIdUsers(idUsers).pipe(
            take(1)
        );
    }
}
