

export class RoomAdminsSocketModel {
    readonly idRoom: number;
    readonly administrators: number[];


    constructor(idRoom: number, administrators: number[]) {
        this.idRoom = idRoom;
        this.administrators = administrators;
    }
}
