
export class RoomStatusReadedMessages {
    
    readonly messagesToClean: number;


    constructor(messagesToClean: number) {
        this.messagesToClean = messagesToClean;
    }
}
