import {createAction, props} from "@ngrx/store";
import {StandardError} from "../../../../domain/StandardError";

const TAG = '[Send Messages Not Sended] ';

export const SendMessagesNotSendedActions = {
    SEND_ALL_MESSAGES_NOT_SENDED: TAG + 'Send All Messages Not Sended',
    SEND_ALL_MESSAGES_NOT_SENDED_FAIL: TAG + 'Send All Messages Not Sended Fail',
    SEND_ALL_MESSAGES_NOT_SENDED_SUCCESS: TAG + 'Send All Messages Not Sended Success',
    // SEND_MESSAGE_FILE_SUCCESS: TAG + 'Send Message File Success',

};

export const SendAllMessagesNotSendedAction = createAction(
    SendMessagesNotSendedActions.SEND_ALL_MESSAGES_NOT_SENDED,
    props<{sentInternalIds: string[]}>()
);

export const SendAllMessagesNotSendedFailAction = createAction(
    SendMessagesNotSendedActions.SEND_ALL_MESSAGES_NOT_SENDED_FAIL,
    props<{errors: StandardError[]}>()
);

export const SendAllMessagesNotSendedSuccessAction = createAction(
    SendMessagesNotSendedActions.SEND_ALL_MESSAGES_NOT_SENDED_SUCCESS
);

// export const SendMessageFileSuccessAction = createAction(
//     SendMessagesNotSendedActions.SEND_MESSAGE_FILE_SUCCESS,
//     props<{idMessage}
// );
