import {Room} from '../../../domain/Room';
import {RoomGroup} from '../../../domain/RoomGroup';
import {RoomOTO} from '../../../domain/RoomOTO';
import {EntityState} from '@ngrx/entity';
import {adapterRoomGroup, adapterRoomOTO, adapterUserRoom} from '../adapters/rooms.adapters';
import {PartialRoomGroup} from '../entities/PartialRoomGroup';
import {UserRoom} from '../../../domain/UserRoom';
import {PartialRoomOTO} from '../entities/PartialRoomOTO';


// export interface IRoomState {
//     groupRooms : RoomGroup[],
//     otoRooms: RoomOTO[],
//     selectedRoom : RoomGroup | RoomOTO;
// }
//
// export const initialRoomState: IRoomState = {
//     groupRooms: [],
//     otoRooms: [],
//     selectedRoom: null
// };



export interface IRoomGroupState extends EntityState<PartialRoomGroup> {}
export const initialRoomGroupState : IRoomGroupState = adapterRoomGroup.getInitialState({});

export interface IUserRoomGroupState extends EntityState<UserRoom> {}
export const initialUserRoomGroupState : IUserRoomGroupState = adapterUserRoom.getInitialState({});




export interface IRoomOTOState extends EntityState<PartialRoomOTO> {}
export const initialRoomOTOState: IRoomOTOState = adapterRoomOTO.getInitialState({});

