import {User} from '../../../domain/User';
import {EntityState} from '@ngrx/entity';
import {adapterUser} from '../adapters/users.adapters';
import {PartialUser} from '../entities/PartialUser';

//
// export interface IUserState {
//     users: User[]
// }
//
//
// export const initialUserState : IUserState = {
//     users: []
// };
//

export interface IUserState extends EntityState<PartialUser> {

}

export const initialUserState : IUserState = adapterUser.getInitialState({});


