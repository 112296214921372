import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    joinDiscover,
    joinDiscoverFail,
    joinDiscoverSuccess, OnDiscoverDeletedAction, OnDiscoverDeletedProcessedAction,
    OnDiscoverModifiedAction,
    OnDiscoverModifiedProcessedAction,
    OnNewDiscoverAction,
    OnNewDiscoverProcessedAction,
    // loadDiscovers,
    // loadDiscoversSuccess,
    quitDiscover,
    quitDiscoverFail,
    quitDiscoverSuccess
} from '../actions/discovers.actions';
import {catchError, concatMap, flatMap, map, switchMap, take} from 'rxjs/operators';
import {DiscoversRepository} from '../../../repositories/discovers/discovers.repository';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, of, zip} from 'rxjs';
import {AppError} from '../../remote/globalmodels/AppError';
import {leaveRoomGroupSuccess, newRoomGetted} from '../actions/room.actions';
import {PartialRoomGroup} from '../entities/PartialRoomGroup';
import {initialUserRoomGroupState} from '../state/room.state';
import {DiscoversFacade} from '../facade/discovers.facade';
import {getRoomGroupWithImageProfileByRoomGroup} from '../mappers/rooms.mappers';
import * as E from "fp-ts/lib/Either";
import {Action} from "@ngrx/store";
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import {RoomsFacade} from "../facade/rooms.facade";
import {RoomsRepository} from "../../../repositories/rooms/rooms.repository";
import {Option} from "fp-ts/lib/Option";



@Injectable({
    providedIn: 'root'
})
export class DiscoversEffects {

    joinDiscoverEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(joinDiscover),
            concatMap(({idDiscover}) => this.discoversRepository.joinDiscover(idDiscover).pipe(
                switchMap(E.fold(
                    err => [joinDiscoverFail({error: err})] as Action[],
                    res => [
                        joinDiscoverSuccess({room: res, idDiscover: idDiscover}),
                        newRoomGetted({newRoom: new PartialRoomGroup(
                            getRoomGroupWithImageProfileByRoomGroup(res),
                            0,
                            0,
                            false,
                            initialUserRoomGroupState,
                            ""
                        )})
                    ]
                ))
            ))
        )
    );

    quitDiscoverEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(quitDiscover),
            concatMap(({idDiscover}) => this.discoversRepository.quitDiscover(idDiscover).pipe(
                switchMap(E.fold(
                    err => of({}).pipe(
                        switchMap(_ => [quitDiscoverFail({error: err})] as Action[])
                    ),
                    _ => this.discoversFacade.getDiscoverByIdDiscover(idDiscover).pipe(
                        flatMap(d => [
                            quitDiscoverSuccess({idDiscover: idDiscover, idRoom: d.idRoom}),
                            leaveRoomGroupSuccess({idRoom: d.idRoom})
                        ])
                    )
                ))
            ))
        )
    );

    onNewDiscoverEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OnNewDiscoverAction),
            concatMap(({discover, room}) => this.discoversRepository.getDiscoverImageProfile(discover.idDiscover).pipe(
                switchMap(opt => {
                    return pipe(
                        room,
                        O.fold(
                            () => [OnNewDiscoverProcessedAction({
                                discover: {
                                    ...discover,
                                    image: opt
                                },
                                room: room
                            })] as Action[],
                            roomGroup => [
                                OnNewDiscoverProcessedAction({
                                    discover: {
                                        ...discover,
                                        image: opt
                                    },
                                    room: room
                                }),
                                newRoomGetted({newRoom: new PartialRoomGroup(
                                        getRoomGroupWithImageProfileByRoomGroup(roomGroup),
                                        0,
                                        0,
                                        false,
                                        initialUserRoomGroupState,
                                        ""
                                    )})
                            ] as Action[]
                        )
                    )
                })
            ))
        )
    );

    private getObservableStringForRoom(opt: Option<{
        idRoom: number;
        fields: {
            name: Option<string>;
            image: Option<"true">;
        }
    }>) : Observable<Option<{profileImage: string}>> {
        return pipe(
            opt,
            O.fold(
                () => of(O.none),
                room => pipe(
                    room.fields.image,
                    O.fold(
                        () => of(O.none),
                        _ => this.roomsRepository.getRoomImageLinkFromIdRoom(room.idRoom).pipe(
                            map(O.some)
                        )
                    )
                )
            )
        ).pipe(
            map(O.map(link => ({profileImage: link})))
        );
    }

    onDiscoverModifiedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OnDiscoverModifiedAction),
            concatMap(({status}) => pipe(
                status.fields.image,
                O.fold(
                    () => this.getObservableStringForRoom(status.fields.room).pipe(
                        switchMap(roomImg => [OnDiscoverModifiedProcessedAction({status: status, discoverField: O.none, roomField: roomImg})])
                    ),
                    _ => zip(
                        this.discoversRepository.getDiscoverImageProfile(status.idDiscover),
                        this.getObservableStringForRoom(status.fields.room)
                    ).pipe(
                        switchMap(([discoverImg, roomImg]) => [OnDiscoverModifiedProcessedAction({status: status, discoverField: O.some({discoverImage: discoverImg}), roomField: roomImg})])
                    )
                )
            ))
        )
    );

    onDiscoverDeletedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OnDiscoverDeletedAction),
            concatMap(({idDiscover}) => this.discoversFacade.getDiscoverByIdDiscover(idDiscover).pipe(
                switchMap(discover => this.roomsFacade.getRoomByIdRoomOption(discover.idRoom).pipe(
                    switchMap(O.fold(
                        () => [OnDiscoverDeletedProcessedAction({idDiscover: idDiscover, idRoom: discover.idRoom})],
                        room => [
                            quitDiscoverSuccess({idDiscover: idDiscover, idRoom: discover.idRoom}),
                            leaveRoomGroupSuccess({idRoom: discover.idRoom}),
                            OnDiscoverDeletedProcessedAction({idDiscover: idDiscover, idRoom: discover.idRoom})
                        ]
                    ))
                ))
            ))
        )
    );


    constructor(
        private actions$ : Actions,
        private discoversRepository: DiscoversRepository,
        private discoversFacade: DiscoversFacade,
        private roomsFacade: RoomsFacade,
        private roomsRepository: RoomsRepository
    ) {}
}
