


export function getCompleteUrlFileRoomFromParams(baseUrl: string, idRoom: number, idMessage: string) : string {
    return baseUrl + getUrlFileRoomFromParams(idRoom, idMessage);
}
export function getCompleteUrlFileOneToOneFromParams(baseUrl: string, idUser: number, idMessage: string) : string {
    return baseUrl + getUrlFileOneToOneFromParams(idUser, idMessage);
}

export function getUrlFileRoomFromParams(idRoom: number, idMessage: string) : string {
    return '/rooms/' + idRoom + '/getfilemessage/' + idMessage;
}
export function getUrlFileOneToOneFromParams(idUser: number, idMessage: string) : string {
    return '/users/' + idUser + '/getfilemessage/' + idMessage;
}
