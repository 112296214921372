import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgxSummernoteDirective} from "ngx-summernote";
import {LocaleService} from "../locales/locale.service";
import {AlertsService} from "../utils/alerts.service";
import {plainTextEditorConfig, textEditorConfig} from "./text-editor-config";
import {AngularEditorComponent} from "@kolkov/angular-editor";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit, AfterViewInit {

    // @Input()
    // onPaste : (e) => void;

    @Input()
    showBottomHelpBar = true;

    @Input()
    plainTextEditorMode = false;

    @Input()
    defaultText: string;

    @Input()
    enableAcceptEmitter = false;

    @Output()
    textConfirmed = new EventEmitter<string>();

    // showEditor = false;
    @ViewChild(AngularEditorComponent, {static: false}) editor: AngularEditorComponent;
    // @ViewChild('ngxsummernote', {static: false, read: NgxSummernoteDirective}) editor: NgxSummernoteDirective;

    onPaste(e) {
        console.log({text: "summernote", event: e});
        const bufferText = ((e.originalEvent || e).clipboardData/* || window.clipboardData*/).getData('Text');
        console.log({text: "summernote", event: bufferText});
        e.preventDefault();
        setTimeout(() => {
            document.execCommand('insertText', false, bufferText);
        }, 10);
    }

    // htmlOutput= new BehaviorSubject("");
    htmlOutput = "";

    // htmlText = "";

    constructor(
        private alertsService: AlertsService,
        private localeService: LocaleService
    ) {
    }

    private editorConfigSubject = new BehaviorSubject(plainTextEditorConfig);

    toggleEmojiMart = false;

    // private path = "summernote/src/js/base/module/";

    // config = {
    //     placeholder: '',
    //     tabsize: 2,
    //     height: 500,
    //     showDomainOnlyForAutolink: false,
    //     shortcuts: false,
    //     // defaultProtocol: '',"
    //     useProtocol: false,
    //     // keyMap: {},
    //
    //     // uploadImagePath: '/api/upload',
    //     toolbar: [
    //         ['misc', ['undo', 'redo']],
    //         ['style', ['bold', 'italic', 'underline', 'clear']],
    //         // ['view', ['help']]
    //         // ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
    //         // ['fontsize', ['fontname', 'fontsize', 'color']],
    //         // ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
    //         // ['insert', ['table', 'picture', 'link', 'video', 'hr']]
    //     ],
    //     modules: {
    //         'editor': require(this.path + 'Editor'),
    //         'clipboard': require(this.path + 'Clipboard'),
    //         'dropzone': require(this.path + 'Editor'),
    //         'codeview': require(this.path + 'Codeview'),
    //         'statusbar': require(this.path + 'Statusbar'),
    //         'fullscreen': require(this.path + 'Fullscreen'),
    //         'handle': require(this.path + 'Handle'),
    //         // // FIXME: HintPopover must be front of autolink
    //         // //  - Script error about range when Enter key is pressed on hint popover
    //         'hintPopover': require(this.path + 'HintPopover'),
    //         'autoLink': null,
    //         'autoSync': require(this.path + 'AutoSync'),
    //         'autoReplace': require(this.path + 'AutoReplace'),
    //         'placeholder': require(this.path + 'Placeholder'),
    //         'buttons': require(this.path + 'Buttons'),
    //         'toolbar': require(this.path + 'Toolbar'),
    //         'linkDialog': require(this.path + 'LinkDialog'),
    //         'linkPopover': require(this.path + 'LinkPopover'),
    //         'imageDialog': require(this.path + 'ImageDialog'),
    //         'imagePopover': require(this.path + 'ImagePopover'),
    //         'tablePopover': require(this.path + 'TablePopover'),
    //         'videoDialog': require(this.path + 'VideoDialog'),
    //         'helpDialog': require(this.path + 'HelpDialog'),
    //         'airPopover': require(this.path + 'AirPopover'),
    //     },
    //     //     // 'clipboard': Clipboard,
    //     //     // 'dropzone': Dropzone,
    //     //     // 'codeview': Codeview,
    //     //     // 'statusbar': Statusbar,
    //     //     // 'fullscreen': Fullscreen,
    //     //     // 'handle': Handle,
    //     //     // // FIXME: HintPopover must be front of autolink
    //     //     // //  - Script error about range when Enter key is pressed on hint popover
    //     //     'hintPopover': HintPopover,
    //     //     'autoLink': null,
    //     //     // 'autoSync': AutoSync,
    //     //     // 'autoReplace': AutoReplace,
    //     //     // 'placeholder': Placeholder,
    //     //     // 'buttons': Buttons,
    //     //     // 'toolbar': Toolbar,
    //     //     // 'linkDialog': LinkDialog,
    //     //     // 'linkPopover': LinkPopover,
    //     //     // 'imageDialog': ImageDialog,
    //     //     // 'imagePopover': ImagePopover,
    //     //     // 'tablePopover': TablePopover,
    //     //     // 'videoDialog': VideoDialog,
    //     //     // 'helpDialog': HelpDialog,
    //     //     // 'airPopover': AirPopover,
    //     // },
    //     callbacks: {
    //         onPaste: this.onPaste,
    //         onCreateLink : function(originalLink) {
    //             console.log({text: "summernote", link: originalLink});
    //             return originalLink; // return original link
    //         },
    //         createLink : function(link) {
    //             console.log({text: "summernote", link: link});
    //             return link; // return original link
    //         },
    //         onImageUpload: _ => {},
    //         onImageUploadError: _ => {},
    //         onImageLinkInsert: _ => {}
    //         // onPaste: function (e) {
    //         //     setTimeout(function () {
    //         //         console.log($this.summernote('code'));
    //         //         $('.note-editable').children().css("font-family", "");
    //         //         console.log($this.summernote('code'));
    //         //     }, 10);
    //         // }
    //
    //         // fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    //     }
    // };

    // private editorConfig = this.plainTextEditorMode ? plainTextEditorConfig : textEditorConfig;

    //https://stackoverflow.com/questions/29869/regex-to-match-all-html-tags-except-p-and-p
    ngOnInit() {
        console.log("OPENED EDITOR")
        //(<([^>][^br]+?)([^>]*?)>|<\/\1>)
    }

    // setHtmlOutput(html: string) {
    //     // this.htmlOutput = html;
    // }

    onChange($event) {
        this.htmlOutput = $event;
        if (this.htmlOutput.length > 100000)
            this.alertsService.showAlertWithTextError("ERROR, MÁXIMO 5000 CARÁCTERES PERMITIDOS").then();

    }

    // private deleteDivs(s: string) : string {
    //     console.log({text: "BEFORE OUTPUT TEXT", outputText: s});
    //     const firstDiv = /(<div[^>]*>)/;
    //     const regex = /(<div[^>]*>)/gm;
    //     const regex2 = /(<\/div>)/gm;
    //     return s
    //         .replace(firstDiv, "<br>")
    //         .replace(regex, "")
    //         .replace(regex2, "<br>");
    // }

    onClickAccept() {

        // this.textConfirmed.emit(this.deleteDivs(this.htmlOutput));
        this.textConfirmed.emit(this.htmlOutput);
    }

    // onKeyAcceptedPressed() {
    //     this.onClickAccept();
    // }
    addEmoji($event) {
        this.htmlOutput += $event.emoji.native;
        this.toggleEmojiMart = !this.toggleEmojiMart;
    }

    onClickEmojiButton() {
        this.toggleEmojiMart = !this.toggleEmojiMart;
    }

    ngAfterViewInit(): void {
        // console.log({text: "summernote", ref: this.editor})

        // this.editor.summernote("removemodule", "autoLink");
        //@ts-ignore
        // $("#ngxsummernote").summernote("removeModule", "autoLink");
        //@ts-ignore
        // $("#ngxsummernote").summernote("removeModule", "linkPopover");
        //@ts-ignore
        // $.summernote("removeModule", "autoLink");
        //@ts-ignore
        // $.summernote("removeModule", "linkPopover");
        // document.execCommand("AutoUrlDetect", false, "");
        this.htmlOutput = this.defaultText;
        if (!this.plainTextEditorMode)
            this.editorConfigSubject.next(textEditorConfig)
        this.editor.textArea.nativeElement.style.height = "500px";
    }

    getText(): string {
        // return this.deleteDivs(this.htmlOutput);
        return this.htmlOutput;
    }

    setText(text: string) {
        this.htmlOutput = text;
    }

    addTextRight(text: string) {
        this.htmlOutput += text;
    }


}
