export const title_postregister = "Welcome To The Chat Of {{companyName}}";

export const default_login_field_shadow = 'E-Mail';
export const user_login_field_shadow = 'User';

export const default_description_postregister = 'Please, upload a photo, add a state and accept the privacy policy to start chatting.';

export const default_text_forgot_password = "Can't remember your password?";

export const default_user_not_found = "Unsuscribed user";
export const user_inactive = "Inactive user";

export enum LANGUAGE {
    EN = "en",
    ES = "es",
    CA = "ca",
    PT = "pt"
}


