import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPagedOTOGroupMessagesState, IPagedRoomGroupMessagesState} from '../state/message.state';
import {
    adapterMessageOTO,
    adapterMessageRoom,
    adapterPagedRoomGroupMessages,
    adapterPagedRoomOTOMessages
} from '../adapters/messages.adapters';
import {LastEvent} from '../state/utils/LastEvent';
import {MessageRoom} from '../../../domain/MessageRoom';
import {MessageOTO} from '../../../domain/MessageOTO';
import * as A from "fp-ts/lib/Array";
import {pipe} from "fp-ts/lib/pipeable";
import {Message} from "../../../domain/Message";
import {SynchronizationState} from "../../../domain/SynchronizationState";
import {option as O, either as E} from "fp-ts";


// --------------------------------------------------------------------------------------------
// -------------------------------------Rooms selectors----------------------------------------
// --------------------------------------------------------------------------------------------


export const selectMessageRoomState = createFeatureSelector<IPagedRoomGroupMessagesState>('messageRooms');


const PagedMessageRoomsSelectors = adapterPagedRoomGroupMessages.getSelectors();


const MessageRoomsSelectors = adapterMessageRoom.getSelectors();
// const NotSynchronizedMessageRoomsSelectors = adapterNotSynchronizedMessagesRoom.getSelectors();


export const selectMessageRoomsSelect = createSelector(
    selectMessageRoomState,
    PagedMessageRoomsSelectors.selectAll
);

export const selectPagedMessageRoomsDic = createSelector(
    selectMessageRoomState,
    PagedMessageRoomsSelectors.selectEntities
);

export const getPagedMessagesRoomGroupByIdRoomSelect = (idRoom: number) => createSelector(
    selectPagedMessageRoomsDic,
    entities => entities[idRoom]
);

// export const getNextPagePagedMessagesRoomGroupByIdRoomSelect = (idRoom: number) => createSelector(
//     getPagedMessagesRoomGroupByIdRoomSelect(idRoom),
//     pagedMessagesRoom => pagedMessagesRoom.lastPageMessages
// );
//
// export const getLastMessageRoomGroupByIdRoomSelect = (idRoom : number) => createSelector(
//     getPagedMessagesRoomGroupByIdRoomSelect(idRoom),
//     pagedMessagesRoom => pagedMessagesRoom.lastMessageRoom
// );

export const getAllMessagesRoomByIdRoomState = (idRoom: number) => createSelector(
    getPagedMessagesRoomGroupByIdRoomSelect(idRoom),
    pagedMessagesRoom => pagedMessagesRoom.messagesRoomState
);

export const getLastEventAndMessagesRoomByIdRoom = (idRoom: number) => createSelector(
    getPagedMessagesRoomGroupByIdRoomSelect(idRoom),
    pagedMessagesRoom => ({lastEvent: pagedMessagesRoom.lastEvent, lastMessages: pagedMessagesRoom.lastMessages.filter(m => m), messagesToRead: pagedMessagesRoom.messagesToRead})
);

// export const getAllNotSynchronizedNotSendedMessagesRoomByIdRoomState = (idRoom: number) => createSelector(
//     getPagedMessagesRoomGroupByIdRoomSelect(idRoom),
//     pagedMessagesRoom => pagedMessagesRoom.notSynchronizedNotSendedMessagesRoomState
// );
//
// export const getAllNotSynchronizedSendedMessagesRoomByIdRoomState = (idRoom: number) => createSelector(
//     getPagedMessagesRoomGroupByIdRoomSelect(idRoom),
//     pagedMessagesRoom => pagedMessagesRoom.notSynchronizedSendedMessagesRoomState
// );

export const getAllMessagesRoomByIdSelect = (idRoom: number) => createSelector(
    getAllMessagesRoomByIdRoomState(idRoom),
    MessageRoomsSelectors.selectAll
);

export const getTripletMessagesRoomByIdRoomSelect = (idRoom: number) => createSelector(
    getLastEventAndMessagesRoomByIdRoom(idRoom),
    getAllMessagesRoomByIdSelect(idRoom),
    (event: {lastEvent: LastEvent, lastMessages: MessageRoom[], messagesToRead: number}, messages: MessageRoom[]) => ({
        ...event,
        allMessages: messages
    })
);

export const getMessageRoomByIdRoomIdMessage = (idRoom: number, idMessage: string) => createSelector(
    getAllMessagesRoomByIdRoomState(idRoom),
    MessageRoomsSelectors.selectEntities,
    entities => entities.entities[idMessage]
);

const getAllMessageRoomByIdRoom = (idRoom: number) => createSelector(
    getAllMessagesRoomByIdRoomState(idRoom),
    MessageRoomsSelectors.selectAll
);

export const getAllIdsAndInternalIdsMessageRoomByIdRoomSelect = (idRoom: number) => createSelector(
    getAllMessageRoomByIdRoom(idRoom),
    mss => pipe(
        mss,
        A.map(m => ({idMessage: m.idMessage, internalId: m.internalId}))
    )
);

// https://stackoverflow.com/questions/68448980/how-to-fold-reduce-a-map
export const getLastMessageByIdRoom = (idRoom: number) => createSelector(
    getPagedMessagesRoomGroupByIdRoomSelect(idRoom),
    r => {

        let maxDateMessage : MessageRoom = null;

        for (const item of r.messagesRoomState.ids) {
            const m = r.messagesRoomState.entities[item];
            if (maxDateMessage !== null) {
                if (maxDateMessage.date.getTime() <= m.date.getTime()) {
                    maxDateMessage = m;
                }
            } else {
                maxDateMessage = m;
            }
        }

        return O.fromNullable(maxDateMessage);
    }
)

export const getLastMessageByIdUserOTO = (idUserOTO: number) => createSelector(
    getPagedMessagesRoomOTOByIdUserSelect(idUserOTO),
    r => {
        let maxDateMessage : MessageOTO = null;
        for (const item of r.messagesOTOState.ids) {
            const m = r.messagesOTOState.entities[item];
            if (maxDateMessage !== null) {
                if (maxDateMessage.date.getTime() <= m.date.getTime()) {
                    maxDateMessage = m;
                }
            } else {
                maxDateMessage = m;
            }
        }

        return O.fromNullable(maxDateMessage);
    }
)


// export const getMessagesRoomNotSendedByIdRoom = (idRoom: number) => createSelector(
//     getAllMessagesRoomByIdRoomState(idRoom),
//     MessageRoomsSelectors.selectAll,
// )

// export const getAllMessagesRoomNotSendedSelect = createSelector(
//     selectMessageRoomsSelect,
//     ents => ents.map(ent => createSelector(
//         ent.messagesRoomState,
//         MessageRoomsSelectors.selectAll
//     )),
// );

//
// export const getAllNotSynchronizedNotSendedMessagesRoomByIdSelect = (idRoom: number) => createSelector(
//     getAllNotSynchronizedNotSendedMessagesRoomByIdRoomState(idRoom),
//     NotSynchronizedMessageRoomsSelectors.selectAll
// );
//
// export const getAllNotSynchronizedSendedMessagesRoomByIdSelect = (idRoom: number) => createSelector(
//     getAllNotSynchronizedSendedMessagesRoomByIdRoomState(idRoom),
//     NotSynchronizedMessageRoomsSelectors.selectAll
// );

// --------------------------------------------------------------------------------------------
// -------------------------------------One to One selectors-----------------------------------
// --------------------------------------------------------------------------------------------

export const selectMessageOTOState = createFeatureSelector<IPagedOTOGroupMessagesState>('messageOTOs');
const PagedMessageOTOsSelectors = adapterPagedRoomOTOMessages.getSelectors();

const MessageOTOsSelectors = adapterMessageOTO.getSelectors();
// const NotSynchronizedMessageOTOsSelectors = adapterNotSynchronizedMessagesOTO.getSelectors();

export const selectMessageOTOsSelect = createSelector(
    selectMessageOTOState,
    PagedMessageOTOsSelectors.selectAll
);

export const selectPagedMessageOTOsDic = createSelector(
    selectMessageOTOState,
    PagedMessageOTOsSelectors.selectEntities
);

export const getPagedMessagesRoomOTOByIdUserSelect = (idUser: number) => createSelector(
    selectPagedMessageOTOsDic,
    entities => entities[idUser]
);

// export const getNextPagePagesMessagesRoomOTOByIdUserSelect = (idUser: number) => createSelector(
//     getPagedMessagesRoomOTOByIdUserSelect(idUser),
//     pagedMessagesOTO => pagedMessagesOTO.lastPageMessages
// );
//
// export const getLastMessageRoomOTOByIdUserSelect = (idUser: number) => createSelector(
//     getPagedMessagesRoomOTOByIdUserSelect(idUser),
//     pagedMessagesOTO => pagedMessagesOTO.lastMessageOTO
// );

export const getAllMessagesOTOByIdUserState = (idUser: number) => createSelector(
    getPagedMessagesRoomOTOByIdUserSelect(idUser),
    pagedMessagesOTO => pagedMessagesOTO.messagesOTOState
);

export const getLastEventAndMessagesOTOByIdUser = (idUser: number) => createSelector(
    getPagedMessagesRoomOTOByIdUserSelect(idUser),
    pagedMessagesOTO => ({lastEvent: pagedMessagesOTO.lastEvent, lastMessages: pagedMessagesOTO.lastMessages.filter(m => m)})
);

// export const getAllNotSynchronizedNotSendedMessagesOTOByIdUserState = (idUser: number) => createSelector(
//     getPagedMessagesRoomOTOByIdUserSelect(idUser),
//     pagedMessagesOTO => pagedMessagesOTO.notSynchronizedNotSendedMessagesOTOState
// );
//
// export const getAllNotSynchronizedSendedMessagesOTOByIdUserState = (idUser: number) => createSelector(
//     getPagedMessagesRoomOTOByIdUserSelect(idUser),
//     pagedMessagesOTO => pagedMessagesOTO.notSynchronizedSendedMessagesOTOState
// );

export const getAllMessagesOTOByIdUserSelect = (idUser : number) => createSelector(
    getAllMessagesOTOByIdUserState(idUser),
    MessageOTOsSelectors.selectAll
);

export const getTripletMessagesOTOByIdUserSelect = (idUser: number) => createSelector(
    getLastEventAndMessagesOTOByIdUser(idUser),
    getAllMessagesOTOByIdUserSelect(idUser),
    (event: {lastEvent: LastEvent, lastMessages: MessageOTO[]}, messages: MessageOTO[]) => ({
        ...event,
        allMessages: messages
    })
);

export const getMessageOTOByIdUserIdMessage = (idUser: number, idMessage: string) => createSelector(
    getAllMessagesOTOByIdUserState(idUser),
    MessageOTOsSelectors.selectEntities,
    // (s: IMessageRoomState, msgs : Dictionary<MessageRoom>) => msgs[idMessage]
    entities => entities.entities[idMessage]
);

const getAllMessageRoomByIdUser = (idUser: number) => createSelector(
    getAllMessagesOTOByIdUserState(idUser),
    MessageOTOsSelectors.selectAll
);

export const getAllIdsAndInternalIdsMessageRoomByIdUserSelect = (idUser: number) => createSelector(
    getAllMessageRoomByIdUser(idUser),
    mss => pipe(
        mss,
        A.map(m => ({idMessage: m.idMessage, internalId: m.internalId}))
    )
);

// export const getAllNotSynchronizedNotSendedMessagesOTOByIdUserSelect = (idUser: number) => createSelector(
//     getAllNotSynchronizedNotSendedMessagesOTOByIdUserState(idUser),
//     NotSynchronizedMessageOTOsSelectors.selectAll
// );
//
// export const getAllNotSynchronizedSendedMessagesOTOByIdUserSelect = (idUser: number) => createSelector(
//     getAllNotSynchronizedSendedMessagesOTOByIdUserState(idUser),
//     NotSynchronizedMessageOTOsSelectors.selectAll
// );



// export const selectMessagesRoomsByIdRoom = (idRoom: number) => createSelector(
//     selectMessageRoomState,
//     // MessageRoomsSelectors.selectAll,
//     (messages) => messages.
// )



// export const getAllMessagesRoomByIdSelect = (idRoom: number) => createSelector(
//     getAllMessagesRoomByIdRoomState(idRoom),
//     MessageRoomsSelectors.selectAll
// );
//
// export const getAllNotSynchronizedNotSendedMessagesRoomByIdSelect = (idRoom: number) => createSelector(
//     getAllNotSynchronizedNotSendedMessagesRoomByIdRoomState(idRoom),
//     NotSynchronizedMessageRoomsSelectors.selectAll
// );
//
// export const getAllNotSynchronizedSendedMessagesRoomByIdSelect = (idRoom: number) => createSelector(
//     getAllNotSynchronizedSendedMessagesRoomByIdRoomState(idRoom),
//     NotSynchronizedMessageRoomsSelectors.selectAll
// );


// --------------------------------------------------------------------------------------------
// -------------------------------------Combo selectors----------------------------------------
// --------------------------------------------------------------------------------------------


export const getNextMessageToSend = (omitInternalIds: string[]) => createSelector(
    selectMessageRoomsSelect,
    selectMessageOTOsSelect,
    (msgsRooms, msgsOtos) => {

        const filter = <T extends Message>(message: T) => message.syncState === SynchronizationState.FAIL && pipe(
            message.retriesSent,
            O.fold(
                () => false,
                retr => retr <= 3
            )
        ) && pipe(
            omitInternalIds,
            A.findFirst(internalId => message.internalId === internalId),
            O.isNone
        );
        const sort = <T extends Message>(messages: T[]) => messages.sort((a, b) => a.date.getTime() - b.date.getTime());

        const msgFilteredRooms = pipe(
            msgsRooms,
            A.map(r => pipe(
                r.messagesRoomState.ids,
                A.map(id => r.messagesRoomState.entities[id]),
                A.filter(filter)
            )),
            A.flatten,
            m => sort(m),
            A.head
        );

        const msgFilteredOto = pipe(
            msgsOtos,
            A.map(r => pipe(
                r.messagesOTOState.ids,
                A.map(id => r.messagesOTOState.entities[id]),
                A.filter(filter)
            )),
            A.flatten,
            m => sort(m),
            A.head
        );

        return pipe(
            msgFilteredRooms,
            O.fold(
                () => pipe(
                    msgFilteredOto,
                    O.map(m => E.left(m))
                ),
                mR => pipe(
                    msgFilteredOto,
                    O.fold(
                        () => O.some(E.right(mR)),
                        mO => {
                            if (mR.date.getTime() < mO.date.getTime()) return O.some(E.right(mR));
                            return O.some(E.left(mO))
                        }
                    )
                )
            )
        )
    }
)

// export const getMessageByInternalId = (internalId: string) => createSelector(
//     selectMessageRoomsSelect,
//     selectMessageOTOsSelect,
//     (msgsRooms, msgsOtos) => {
//
//         A.
//         pipe(
//             msgsRooms,
//             A.findFirst(r => pipe(
//                 r.messagesRoomState.ids,
//                 A.findFirst(id => r.messagesRoomState.entities[id].internalId === internalId)
//                 // O.map(id => r.messagesRoomState.entities[id])
//             ))
//         )
//     }
// )
