import {pipe} from "fp-ts/lib/pipeable";
import {MessagesMappersUtils} from "../../utils/messages.mappers.utils";
import {MessageRoomSocketModel} from '../models/MessageRoomSocketModel';
import {MessageRoom} from '../../domain/MessageRoom';
import {getDateFromBackendDate} from '../../repositories/mappers/dates.mapper';
import {MessageRoomSocketModelOut} from '../models/MessageRoomSocketModelOut';
import {SynchronizationState} from '../../domain/SynchronizationState';
import {MessageOTOSocketModel} from '../models/MessageOTOSocketModel';
import {MessageOTO} from '../../domain/MessageOTO';
import {MessageOTOSocketModelOut} from '../models/MessageOTOSocketModelOut';
import {StatusSocketModel} from '../models/StatusSocketModel';
import {RoomStatusJoined, RoomStatusModified, RoomStatusQuit} from '../../domain/RoomStatus';
import {Observable, of, zip} from 'rxjs';
import {BlobTransformers} from '../../utils/blob.transformers';
import {FileType, getFileTypeFromString} from '../../domain/FileType';
import {Injectable} from '@angular/core';
import {RoomModifiedSocketModel} from "../models/RoomModifiedSocketModel";
import * as O from "fp-ts/lib/Option";
import {UserModifiedSocketModel} from "../models/UserModifiedSocketModel";
import {UserStatus} from "../../domain/UserStatus";
import {NewUserSocketModel} from "../models/NewUserSocketModel";
import {PartialUser} from "../../sources/storage/entities/PartialUser";
import {getNumRandomColors, User} from "../../domain/User";
import {LinkGenerators} from "../../domain/images/link.generators";
import {map, switchMap} from "rxjs/operators";
import {DiscoverModifiedSocketModel} from "../models/DiscoverModifiedSocketModel";
import {DiscoverStatus} from "../../domain/DiscoverStatus";
import {RoomReadedMessagesSocketModel} from "../models/RoomReadedMessagesSocketModel";
import {RoomGroupStatusReadedMessages} from "../../domain/RoomGroupStatusReadedMessages";
import {OTOReadedMessagesSocketModel} from "../models/OTOReadedMessagesSocketModel";
import {RoomOTOStatusReadedMessages} from "../../domain/RoomOTOStatusReadedMessages";
import {RoomAdminsSocketModel} from "../models/RoomAdminsSocketModel";
import {RoomStatusAdmins} from "../../domain/RoomStatusAdmins";
import {getModifiedStateByNumber} from "../../utils/commom.mappers";
import {ConfirmationFileSocketModelOut} from "../models/ConfirmationFileSocketModelOut";

@Injectable({
    providedIn: 'root'
})
export class DomainmodelMappers {


    public constructor(
        private blobTransformers : BlobTransformers,
        private linkGenerators : LinkGenerators,
        private messagesMappersUtils: MessagesMappersUtils
    ) {}

    getMessageRoomFromMessageRoomSocketModel(messageRoomSocketModel : MessageRoomSocketModel, syncState: SynchronizationState) : Observable<MessageRoom> {

        return zip(
            this.messagesMappersUtils.getReplyMessageMonad(messageRoomSocketModel.replyMessage),
            this.messagesMappersUtils.getLinkPreviewMessageMonad(messageRoomSocketModel.linkPreview),
            // this.linkGenerators.getLinkResourceRoomFromMessage(messageRoomSocketModel.idRoom, messageRoomSocketModel._id)
        ).pipe(
            switchMap(([r, l/*, resourceLink*/]) => this.blobTransformers.getObjectWithBlobOptionalFromBase64(messageRoomSocketModel.thumbnail, (blob => new MessageRoom(
                messageRoomSocketModel._id,
                messageRoomSocketModel.idUser,
                getDateFromBackendDate(messageRoomSocketModel.date),
                messageRoomSocketModel.message,
                messageRoomSocketModel.idRoom,
                messageRoomSocketModel.internalId,
                syncState,
                blob,
                getFileTypeFromString(messageRoomSocketModel.fileType),
                // null,
                // resourceLink,
                messageRoomSocketModel.fileDuration,
                messageRoomSocketModel.extension,
                r,
                getModifiedStateByNumber(messageRoomSocketModel.modifiedState),
                l,
                O.none,
                O.none
            ))))
        )


    }


    getMessageOTOFromMessageOTOSocketModel(messageOTOSocketModel : MessageOTOSocketModel,
                                                           syncState: SynchronizationState,
                                                           ownIdUser: number) : Observable<MessageOTO> {
        const idUserOTO = ownIdUser+"" === ""+messageOTOSocketModel.idUser ? messageOTOSocketModel.toIdUser : messageOTOSocketModel.idUser;
        return zip(
            this.messagesMappersUtils.getReplyMessageMonad(messageOTOSocketModel.replyMessage),
            this.messagesMappersUtils.getLinkPreviewMessageMonad(messageOTOSocketModel.linkPreview),
            // this.linkGenerators.getLinkResourceOneToOneFromMessage(idUserOTO, messageOTOSocketModel._id)
        ).pipe(
            switchMap(([r, l/*, resourceLink*/]) => this.blobTransformers.getObjectWithBlobOptionalFromBase64(messageOTOSocketModel.thumbnail, (blob => new MessageOTO(
                messageOTOSocketModel._id,
                messageOTOSocketModel.idUser,
                getDateFromBackendDate(messageOTOSocketModel.date),
                messageOTOSocketModel.message,
                // messageOTOSocketModel.toIdUser,
                idUserOTO,
                messageOTOSocketModel.internalId,
                syncState,
                blob,
                getFileTypeFromString(messageOTOSocketModel.fileType),
                // null,
                // resourceLink,
                messageOTOSocketModel.fileDuration,
                messageOTOSocketModel.extension,
                r,
                getModifiedStateByNumber(messageOTOSocketModel.modifiedState),
                l,
                O.none,
                O.none
            ))))
        )

    }

    getRoomStatusJoinedFromStatusSocketModel(statusSocketModel: StatusSocketModel) : RoomStatusJoined {
        return new RoomStatusJoined(
            statusSocketModel.idRoom,
            statusSocketModel.fromUser,
            statusSocketModel.byIdUser,
            getDateFromBackendDate(statusSocketModel.date),
            statusSocketModel._id,
        );
    }
    getRoomStatusQuitFromStatusSocketModel(statusSocketModel: StatusSocketModel) : RoomStatusQuit {
        return new RoomStatusQuit(
            statusSocketModel.idRoom,
            statusSocketModel.fromUser,
            statusSocketModel.byIdUser,
            getDateFromBackendDate(statusSocketModel.date),
            statusSocketModel._id,
        );
    }


    getMessageRoomSocketModelOutFromMessageRoom(messageRoom: MessageRoom) : MessageRoomSocketModelOut {
        return new MessageRoomSocketModelOut(
            messageRoom.idRoom,
            messageRoom.text,
            messageRoom.internalId,
            pipe(
                messageRoom.messageReply,
                O.fold(
                    () => undefined,
                    ({idMessage}) => idMessage
                )
            )
        );
    }

    getMessageOTOSocketModelOutFromMessageOTO(messageOTO: MessageOTO) : MessageOTOSocketModelOut {
        return new MessageOTOSocketModelOut(
            messageOTO.idUserOTO,
            messageOTO.text,
            messageOTO.internalId,
            pipe(
                messageOTO.messageReply,
                O.fold(
                    () => undefined,
                    ({idMessage}) => idMessage
                )
            )
        );
    }

    getRoomStatusModifiedFromRoomModifiedSocketModel(state: RoomModifiedSocketModel) : RoomStatusModified {
        const fields = {
            name: state.fields.name === undefined ? O.none : O.some(state.fields.name),
            image: state.fields.image === undefined ? O.none : O.some(true)
        };
        return new RoomStatusModified(
            state.idRoom,
            state.byIdUser,
            new Date(state.date),
            state._id,
            fields
        );
    }

    getUserStatusFromUserModifiedSocketModel(state: UserModifiedSocketModel) : UserStatus {
        const fields = {
            additionalInfo: state.fields.additionalInfo === undefined ? O.none : O.some(state.fields.additionalInfo),
            image: state.fields.image === undefined ? O.none : O.some(true),
            name: state.fields.name === undefined ? O.none : O.some(state.fields.name),
            surname: state.fields.surname === undefined ? O.none : O.some(state.fields.surname)
        };
        return new UserStatus(
            state.idUser,
            new Date(state.date),
            state._id,
            fields
        );
    }

    getPartialUserFromNewUserSocketModel(model: NewUserSocketModel) : Observable<PartialUser> {
        const [color] = getNumRandomColors(1);
        return this.linkGenerators.getUserImageLinkFromIdUser(model.idUser).pipe(
            map(link => new PartialUser(
                new User(
                    model.name,
                    model.surname,
                    model.idUser,
                    color,
                    model.additionalInfo,
                    link
                ),
            ))
        )
    }

    getDiscoverStatusFromDiscoverModifiedSocketModel(model: DiscoverModifiedSocketModel) : Observable<DiscoverStatus> {
        return of(new DiscoverStatus(
            model.idDiscover,
            getDateFromBackendDate(model.date),
            {
                name: model.fields.name ? O.some(model.fields.name) : O.none,
                position: model.fields.position ? O.some(model.fields.position) : O.none,
                image: model.fields.image ? O.some(model.fields.image) : O.none,
                room: !model.fields.room ? O.none : O.some({
                    idRoom: model.fields.room.idRoom,
                    fields: {
                        name: model.fields.room.fields.name ? O.some(model.fields.room.fields.name) : O.none,
                        image: model.fields.room.fields.image ? O.some(model.fields.room.fields.image) : O.none
                    }
                })
            }
        ));
    }

    getRoomGroupStatusReadedMessagesFromRoomReadedMessagesSocketModel(model: RoomReadedMessagesSocketModel) : RoomGroupStatusReadedMessages {
        return new RoomGroupStatusReadedMessages(
            model.messagesToClean,
            model.idRoom
        )
    }
    getRoomOTOStatusReadedMessagesFromOTOReadedMessagesSocketModel(model: OTOReadedMessagesSocketModel) : RoomOTOStatusReadedMessages {
        return new RoomOTOStatusReadedMessages(
            model.messagesToClean,
            model.idUser
        );
    }
    getRoomStatusAdminsFromRoomAdminsSocketModel(model: RoomAdminsSocketModel) : RoomStatusAdmins {
        return new RoomStatusAdmins(
            model.idRoom,
            model.administrators
        );
    }

    getConfirmationFileSocketModelOutFromDeviceIdAndBackendCode(deviceId: string, backendCode: string) : ConfirmationFileSocketModelOut {
        return new ConfirmationFileSocketModelOut(
            deviceId,
            backendCode
        )
    }

}

