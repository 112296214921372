import {Injectable} from "@angular/core";
import {SessionsRepository} from "../../core/repositories/sessions/sessions.repository";
import {Observable} from "rxjs";
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../core/domain/StandardError";
import {SessionElementList} from "./SessionElementList";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import {map} from "rxjs/operators";
import {getSessionElementListFromSession, getTableShowerModelFromSessionsElementList} from "./sessions.mappers";
import {pipe} from "fp-ts/lib/pipeable";
import {Ord, ordNumber} from "fp-ts/lib/Ord";
import {Session} from "../../core/domain/Session";
import {eqNumber} from "fp-ts/lib/Eq";
import {TableShowerModel} from "../../table-shower/TableShowerModel";
import {ThemeService} from "../../themes/theme.service";


@Injectable({
    providedIn: 'root'
})
export class SessionsService {


    constructor(
        private sessionsRepository: SessionsRepository,
        private themeService: ThemeService
    )  {}

    getSessions() : Observable<Either<StandardError, TableShowerModel>> {
        return this.sessionsRepository.getAllSessions().pipe(
            map(E.map(s => pipe(
                s,
                A.sort(<Ord<Session>>{
                    equals: (x, y) => eqNumber.equals(y.createdAt.getTime(), x.createdAt.getTime()),
                    compare: (x, y) => ordNumber.compare(y.createdAt.getTime(), x.createdAt.getTime())
                }),
                A.map(getSessionElementListFromSession),
                s => getTableShowerModelFromSessionsElementList(s, this.themeService.showInactiveSessions())
            )))
        )
    }
}
