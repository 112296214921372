import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {VisualizerType} from "./VisualizerType";
import {LocaleService} from "../locales/locale.service";
import {UrlResourceModel} from "../url-resource-generator/UrlResourceModel";
import {SocketsAuthService} from "../sockets-auth/sockets-auth.service";
import {option as O} from "fp-ts";
import {pipe} from "fp-ts/lib/pipeable";

@Component({
    selector: 'app-video-visualizer',
    templateUrl: './video-visualizer.component.html',
    styleUrls: ['./video-visualizer.component.scss']
})
export class VideoVisualizerComponent implements OnInit, OnDestroy {

    @Input()
    title: string;

    @Input()
    videoResource: UrlResourceModel;

    @Input()
    format?: string;

    @Input()
    confirmDialog: boolean = false;

    @Input()
    typeFile: VisualizerType = "video";

    @Output()
    confirmOrCancel = new EventEmitter<boolean>();

    constructor(
        public activeModal: NgbActiveModal,
        private localeService: LocaleService,
        private socketsAuthService: SocketsAuthService
    ) {
    }

    ngOnInit() {
        // if (!this.format) this.format = "video/mp4";
        console.log(this.videoResource)
        pipe(
            this.videoResource.codes,
            O.fold(
                () => {},
                ({requestId, deviceId}) => {
                    this.socketsAuthService.addDeviceCode(requestId, deviceId)
                }
            )
        )

    }

    onClickConfirm() {
        this.confirmOrCancel.emit(true)
    }
    onClickCancel() {
        this.confirmOrCancel.emit(false);
    }

    ngOnDestroy(): void {
        pipe(
            this.videoResource.codes,
            O.fold(
                () => {},
                ({requestId, deviceId}) => {
                    this.socketsAuthService.removeDeviceCode(requestId)
                }
            )
        )
    }
}
