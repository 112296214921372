

export class RoomModifiedSocketModel {
    readonly idRoom: number;
    readonly byIdUser: number;
    readonly date: string;
    readonly _id: string;

    readonly fields : {
        name: string | undefined,
        image: "true" | undefined
    };


    constructor(idRoom: number, byIdUser: number, date: string, id: string, fields: { name: string | undefined; image: "true" | undefined }) {
        this.idRoom = idRoom;
        this.byIdUser = byIdUser;
        this.date = date;
        this._id = id;
        this.fields = fields;
    }
}
