import {Injectable} from '@angular/core';
import {pipe} from "fp-ts/lib/pipeable";
import {NetworkClient} from '../core/NetworkClient';
import {Observable} from 'rxjs';
import {IGetRoomsIdrMessagesResponseModel} from './models/IGetRoomsIdrMessagesResponseModel';
import {RequestType} from '../core/RequestType';
import {IGetUsersIdMessagesRangeResponseModel} from './models/IGetUsersIdMessagesRangeResponseModel';
import {IGetRoomsIdrFiles} from './models/IGetRoomsIdrFiles';
import {IGetUsersIdFiles} from './models/IGetUsersIdFiles';
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../../domain/StandardError";
import {Option} from "fp-ts/lib/Option";
import {IGetRoomsIdrHistoricFromApiModel} from "./models/IGetRoomsIdrHistoricFromApiModel";
import {getUnixDateFromDate} from "../../../../utils/dates.transformers";
import {IGetUsersIduHistoricFromApiModel} from "./models/IGetUsersIduHistoricFromApiModel";
import {MessageRoomApiModel} from "./models/MessageRoomApiModel";
import {MessageOTOApiModel} from "./models/MessageOTOApiModel";
import {GetRoomsIdrIdmInfoApiModel} from "./models/GetRoomsIdrIdmInfoApiModel";
import {option as O} from "fp-ts";
import {getUrlFileOneToOneFromParams, getUrlFileRoomFromParams} from "../../../../utils/urls.utils";
import {PostRoomsMessagesResendmessageRequestModel} from "./models/PostRoomsMessagesResendmessageRequestModel";
import {SocketsAuthService} from "../../../../sockets-auth/sockets-auth.service";
import * as uuid from "uuid";

@Injectable({
    providedIn: 'root'
})
export class MessagesApihelper {

    constructor(
        private networkClient: NetworkClient,
        private socketsAuthService: SocketsAuthService
    ) {}

    private static readonly NUM_FIRSTS_MESSAGES = 1;

    getFirstsMessagesFromRoomByIdRoom(idRoom: number) : Observable<Either<StandardError, IGetRoomsIdrMessagesResponseModel>> {
        return this.networkClient.setRequest<{}, IGetRoomsIdrMessagesResponseModel>(
            "/rooms/" + idRoom + "/messages",
            {},
            RequestType.GET
        );
    }

    getMessagePageFromRoomByIdRoomNumberPage(idRoom: number, pageNumber: number) : Observable<Either<StandardError, IGetRoomsIdrMessagesResponseModel>> {
        return this.networkClient.setRequest<{}, IGetRoomsIdrMessagesResponseModel>(
            "/rooms/" + idRoom + "/messages?page=" + pageNumber,
            {},
            RequestType.GET
        );
    }

    getNumberMessagesFromRoomByIdRoomNumberMessages(idRoom: number, numberMessages: number) : Observable<Either<StandardError, IGetRoomsIdrMessagesResponseModel>> {
        return this.networkClient.setRequest<{}, IGetRoomsIdrMessagesResponseModel>(
            '/rooms/' + idRoom + '/messages?amount=' + numberMessages,
            {},
            RequestType.GET
        );
    }

    getFirstsMessagesFromOTOByIdUser(idUser: number) : Observable<Either<StandardError, IGetUsersIdMessagesRangeResponseModel>> {
        return this.networkClient.setRequest<{}, IGetUsersIdMessagesRangeResponseModel>(
            "/users/" + idUser + "/messages",
            {},
            RequestType.GET
        );
    }

    getMessagePageFromOTOByIdUserNumberPage(idUser: number, pageNumber: number) : Observable<Either<StandardError, IGetUsersIdMessagesRangeResponseModel>> {
        return this.networkClient.setRequest<{}, IGetUsersIdMessagesRangeResponseModel>(
            "/users/" + idUser + "/messages?page=" + pageNumber,
            {},
            RequestType.GET
        );
    }


    postRoomsIdrSendfile(idRoom: number, file: Blob, text: string, thumbnail: string, internalId: string, idMessageReply: Option<string>) : Observable<Either<StandardError, {_id:string}>> {
        const l : {key: string, value: Blob | string }[] = [
            {key: "file", value: file},
            {key: "message", value: text},
            {key: "internalId", value: internalId}
            // {key: "thumbnail", value: thumbnail}
        ];

        const nl = pipe(
            idMessageReply,
            O.fold(
                () => l,
                id => [
                    ...l,
                    {
                        key: "idMessageReply", value: id
                    }
                ]
            )
        );

        // console.log({text: "postRoomsIdrSendfile", req: l});
        return this.networkClient.setRequestWithFormData<{_id:string}>(
            '/rooms/' + idRoom + '/sendfile',
            nl,
            RequestType.POST
        );
        // l.push({key: "file", value: file});
    }

    postUsersIdSendfile(idUser: number, file: Blob, text: string, thumbnail: string, internalId: string, idMessageReply: Option<string>) : Observable<Either<StandardError, {_id:string}>> {
        const l : {key: string, value: Blob | string }[] = [
            {key: "file", value: file},
            {key: "message", value: text},
            {key: "internalId", value: internalId}
            // {key: "thumbnail", value: thumbnail}
        ];

        const nl = pipe(
            idMessageReply,
            O.fold(
                () => l,
                id => [
                    ...l,
                    {
                        key: "idMessageReply", value: id
                    }
                ]
            )
        );

        // console.log({text: "postRoomsIdrSendfile", req: l});
        return this.networkClient.setRequestWithFormData<{_id:string}>(
            '/users/' + idUser + '/sendfile',
            nl,
            RequestType.POST
        );
        // l.push({key: "file", value: file});
    }

    getRoomsIdGetfilemessageIdm(idRoom: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        const url = getUrlFileRoomFromParams(idRoom, idMessage)
        const deviceId = uuid.v4();
        const requestId = uuid.v4();
        this.socketsAuthService.addDeviceCode(requestId, deviceId);
        return this.networkClient.setRequestWithFileEither<{}>(
            `${url}?deviceid=${deviceId}&requestid=${requestId}`,
            {},
            RequestType.GET,
        ).pipe(
        )
    }

    getUsersIdGetfilemessageIdm(idUser: number, idMessage: string) : Observable<Either<StandardError, Blob>> {
        const url = getUrlFileOneToOneFromParams(idUser, idMessage);
        const deviceId = uuid.v4();
        const requestId = uuid.v4();
        this.socketsAuthService.addDeviceCode(requestId, deviceId);
        return this.networkClient.setRequestWithFileEither<{}>(
            // '/users/' + idUser + '/getfilemessage/' + idMessage,
            `${url}?deviceid=${deviceId}&requestid=${requestId}`,
            {},
            RequestType.GET
        ).pipe(
            // map(blob => ({image: blob, exists: true})),
        );
    }

    getRoomsIdrFiles(idRoom: number, pageNumber: number) : Observable<Either<StandardError, IGetRoomsIdrFiles>> {
        return this.networkClient.setRequest<{}, IGetRoomsIdrFiles>(
            '/rooms/' + idRoom + '/allfiles?page=' + pageNumber + '&type=all',
            {},
            RequestType.GET
        );
    }

    getUsersIdFiles(idUser: number, pageNumber: number) : Observable<Either<StandardError, IGetUsersIdFiles>> {
        return this.networkClient.setRequest<{}, IGetUsersIdFiles> (
            '/users/' + idUser + '/allfiles?page=' + pageNumber + '&type=all',
            {},
            RequestType.GET
        );
    }

    getRoomsIdrHistoricFrom(idRoom: number, fromDate: Date) : Observable<Either<StandardError, IGetRoomsIdrHistoricFromApiModel>> {
        return this.networkClient.setRequest<{}, IGetRoomsIdrHistoricFromApiModel>(
            '/rooms/' + idRoom + '/historic?from=' + (getUnixDateFromDate(fromDate) - 5),
            {},
            RequestType.GET
        );
    }

    getUsersIduHistoricFrom(idUser: number, fromDate: Date) : Observable<Either<StandardError, IGetUsersIduHistoricFromApiModel>> {
        return this.networkClient.setRequest<{}, IGetUsersIduHistoricFromApiModel>(
            '/users/' + idUser + '/historic?from=' + (getUnixDateFromDate(fromDate) - 5),
            {},
            RequestType.GET
        );
    }

    deleteMessageRoom(idRoom: number, idMessage: string) : Observable<Either<StandardError, MessageRoomApiModel>> {
        return this.networkClient.setRequest<{}, MessageRoomApiModel>(
            '/rooms/' + idRoom + '/' + idMessage,
            {},
            RequestType.DELETE
        );
    }
    deleteMessageOTO(idUser: number, idMessage: string) : Observable<Either<StandardError, MessageOTOApiModel>> {
        return this.networkClient.setRequest<{}, MessageOTOApiModel>(
            '/users/' + idUser + '/' + idMessage,
            {},
            RequestType.DELETE
        );
    }
    getMessageUserStatus(idRoom: number, idMessage: string) : Observable<Either<StandardError, GetRoomsIdrIdmInfoApiModel>> {
        return this.networkClient.setRequest<{}, GetRoomsIdrIdmInfoApiModel>(
            '/rooms/' + idRoom + '/' + idMessage + '/info',
            {},
            RequestType.GET
        );
    }

    putRoomsIdrUpper(idRoom: number, date: number) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{date: number}, {}>(
            '/rooms/' + idRoom + '/upper',
            {date: date},
            RequestType.PUT
        );
    }

    postRoomsMessagesResendMessage(data: PostRoomsMessagesResendmessageRequestModel) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<PostRoomsMessagesResendmessageRequestModel, {}>(
            '/rooms/messages/resendmessage',
            data,
            RequestType.POST
        )
    }

}
