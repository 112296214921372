

export class MessageUserStatus {
    readonly notReceived: number[];
    readonly received: {
        idUser: number;
        date: Date;
    }[];
    readonly readed: {
        idUser: number;
        date: Date,
    }[];


    constructor(notReceived: number[], received: { idUser: number; date: Date }[], readed: { idUser: number; date: Date }[]) {
        this.notReceived = notReceived;
        this.received = received;
        this.readed = readed;
    }
}
