import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, combineLatest, interval, merge, Observable, of, Subject} from "rxjs";
import {AppService} from "../../../chatmodule/app.service";
import {AppStateNamespaceFacade} from "../../../shared/core/sources/storage/facade/namespaces/app_state_namespace.facade";
import {LocaleService} from "../../../shared/locales/locale.service";
import {delay, distinct, filter, map, skip, switchMap, take, tap} from "rxjs/operators";
import * as O from "fp-ts/lib/Option";
import {
    AppWithConnectionState,
    initialAppStateNamespaceState
} from "../../../shared/core/sources/storage/state/namespaces/app_state_namespace.state";
import {ResourcesService} from "../../../shared/resources/resources.service";

@Component({
  selector: 'app-connection-detector',
  templateUrl: './connection-detector.component.html',
  styleUrls: ['./connection-detector.component.scss']
})
export class ConnectionDetectorComponent implements OnInit {

    connection: Observable<boolean>;
    progress: Observable<number>;
    refreshing: Observable<boolean>;
    app_loaded: Observable<boolean>;
    cloudNotConnection: Observable<string>;
    timer: Observable<number>;
    reconnecting_error: Observable<boolean>;

    timer_visual: Subject<number>;
    text_timer: Subject<string>;

    nav_connection: Observable<boolean>;

    constructor(
        private appService: AppService,
        private appStateNamespaceFacade: AppStateNamespaceFacade,
        private localeService: LocaleService,
        private resourcesService: ResourcesService
    ) { }

    ngOnInit() {
        this.timer_visual = new BehaviorSubject<number>(initialAppStateNamespaceState.reconnecting_time);
        this.text_timer = new BehaviorSubject("");
        this.timer_visual.asObservable().subscribe(
            t => {
                const str = this.localeService.translate('Server not connection. Retrying in secs seconds.', {sec: t+''});
                this.text_timer.next(str);
            }
        )
        this.connection = this.appStateNamespaceFacade.getAppWithConnectionObservable();
        this.refreshing = this.appStateNamespaceFacade.getAppWithConnectionStateObservable().pipe(
            map(({state, error}) => O.isNone(error) && state !== AppWithConnectionState.RAGDOLL && state !== AppWithConnectionState.COMPLETED)
        );
        this.progress = this.appStateNamespaceFacade.getAppWithConnectionStateObservable().pipe(
            map(({state, error}) => Math.round(state/AppWithConnectionState.COMPLETED*100)),
            tap(pr => console.log({text: "PROGRESS LOADED", pr: pr}))
        );
        this.app_loaded = this.appStateNamespaceFacade.getAppStateAllDataLoadedObservable();
        this.cloudNotConnection = this.resourcesService.getMetaNoConnection();
        this.timer = this.appStateNamespaceFacade.getAppStateReconnectingTimeObservable().pipe(
            tap(v => console.log({text: "timer new value", v: v})),
            switchMap(numb => interval(1000).pipe(
                take(numb),
                map(v => numb-1-v),
            ))
        );
        this.timer.subscribe(
            v => {
                this.timer_visual.next(v);
            }
        );
        // this.reconnecting_error
        this.reconnecting_error = merge(
            this.appStateNamespaceFacade.getAppWithConnectionStateObservable().pipe(
                map(({state, error}) => O.isSome(error) && state !== AppWithConnectionState.RAGDOLL && state !== AppWithConnectionState.COMPLETED),
            ),
            combineLatest([
                this.appStateNamespaceFacade.getAppStateReconnectingTimeObservable(),
                this.appStateNamespaceFacade.getAppStateWithLocalConnectionObservable()
            ]).pipe(
                map(([_, localConnection]) => localConnection && true),
                // skip(1),
                // delay(000),
            )
        ).pipe(
            filter(error => error),
            switchMap(error => merge(
                of(error),
                of(!error).pipe(
                    delay(2000)
                )
            ))
        );
        this.nav_connection = this.appStateNamespaceFacade.getAppStateWithLocalConnectionObservable();
    }

    onClickRetry($event) {
        this.appService.reloadApp().subscribe();
    }
}
