import {Action, createReducer, on} from '@ngrx/store';
import {initialResponsesState, ResponsesState} from '../state/responses.state';
import {
    addUsersToRoomFail,
    addUsersToRoomSuccess, changeDataGroupFail, leaveRoomGroupFail,
    leaveRoomGroupSuccess, leaveRoomOTOFail, leaveRoomOTOSuccess, modifyRoomInfoFail, modifyRoomInfoSuccess,
    onCreateRoomFail,
    onCreateRoomSuccess, onOpenOTOFail, onOpenOTOSuccess
} from '../actions/room.actions';
import {
    cleanResponseAddUsersToRoom,
    cleanResponseCreateRoom,
    cleanResponseGetImageMessageRoom,
    cleanResponseLeaveRoomGroup,
    cleanResponseLeaveRoomOTO, cleanResponseLoadNextPageFiles, cleanResponseLogout,
    cleanResponseModifyOwnData, cleanResponseModifyRoomInfo,
    cleanResponseOpenOto,
    // cleanResponseSendFileRoomGroup,
    // cleanResponseSendFileRoomOTO
} from '../actions/responses.actions';
import {
    onMessageRoomImageToLoad,
    onMessageRoomImageToLoadFail,
    onMessageRoomImageToLoadSuccess,
    onSendMessageOTOFileFail,
    onSendMessageOTOFileSuccess,
    onSendMessageRoomFileFail,
    onSendMessageRoomFileSuccess
} from '../actions/messages.actions';
import {changeOwnDataFail, changeOwnDataSuccess, logoutFail, logoutSuccess} from '../actions/app.actions';
import {onLoadNextPageFilesComplete, onLoadNextPageFilesFail, onLoadNextPageFilesSuccess} from '../actions/filemessages.actions';
import * as O from "fp-ts/lib/Option";


const responsesReducer = createReducer(
    initialResponsesState,
    on(onCreateRoomSuccess, (state, _) => ({
        ...state,
        createRoomLastResponse: {error: O.none}
    })),
    on(onCreateRoomFail, (state, {appError}) => ({
        ...state,
        createRoomLastResponse: {error: O.some(appError)}
    })),
    on(cleanResponseCreateRoom, state => ({
        ...state,
        createRoomLastResponse: undefined
    })),
    on(onMessageRoomImageToLoadSuccess, (state, {idMessage, image}) => ({
        ...state,
        getImageMessageRoom: {image: image, error: O.none}
    })),
    on(onMessageRoomImageToLoadFail, (state, {idMessage, error}) => ({
        ...state,
        getImageMessageRoom: {image: undefined, error: O.some(error)}
    })),
    on(cleanResponseGetImageMessageRoom, state => ({
        ...state,
        getImageMessageRoom: undefined
    })),
    on(addUsersToRoomSuccess, (state, _) => ({
        ...state,
        addUsersToRoomLastResponse: {error: O.none}
    })),
    on(addUsersToRoomFail, (state, {appError}) => ({
        ...state,
        addUsersToRoomLastResponse: {error: O.some(appError)}
    })),
    on(cleanResponseAddUsersToRoom, state => ({
        ...state,
        addUsersToRoomLastResponse: undefined
    })),
    on(leaveRoomGroupSuccess, (state, _) => ({
        ...state,
        leaveRoomGroupLastResponse: {error: O.none}
    })),
    on(leaveRoomGroupFail, (state, {appError}) => ({
        ...state,
        leaveRoomGroupLastResponse: {error: O.some(appError)}
    })),
    on(cleanResponseLeaveRoomGroup, state => ({
        ...state,
        leaveRoomGroupLastResponse: undefined
    })),
    on(leaveRoomOTOSuccess, (state, _) => ({
        ...state,
        leaveRoomOTOLastResponse: {error: O.none}
    })),
    on(leaveRoomOTOFail, (state, {appError}) => ({
        ...state,
        leaveRoomOTOLastResponse: {error: O.some(appError)}
    })),
    on(cleanResponseLeaveRoomOTO, state => ({
        ...state,
        leaveRoomOTOLastResponse: undefined
    })),
    on(changeOwnDataSuccess, (state, _) => ({
        ...state,
        changeOwnDataUser: {error: O.none}
    })),
    on(changeOwnDataFail, (state, {error}) => ({
        ...state,
        changeOwnDataUser: {error: O.some(error)}
    })),
    on(cleanResponseModifyOwnData, state => ({
        ...state,
        changeOwnDataUser: undefined
    })),
    on(onOpenOTOSuccess, (state, _) => ({
        ...state,
        openOneToOneResponse: {error: O.none}
    })),
    on(onOpenOTOFail, (state, {appError}) => ({
        ...state,
        openOneToOneResponse: {error: O.some(appError)}
    })),
    on(cleanResponseOpenOto, state => ({
        ...state,
        openOneToOneResponse: undefined
    })),
    // on(onSendMessageRoomFileSuccess, (state, _) => ({
    //     ...state,
    //     sendFileRoomGroupResponse: {error: O.none}
    // })),
    // on(onSendMessageRoomFileFail, (state, {error}) => ({
    //     ...state,
    //     sendFileRoomGroupResponse: {error: O.some(error)}
    // })),
    // on(cleanResponseSendFileRoomGroup, state => ({
    //     ...state,
    //     sendFileRoomGroupResponse: undefined
    // })),
    // on(onSendMessageOTOFileSuccess, (state, _) => ({
    //     ...state,
    //     sendFileRoomOTOResponse: {error: O.none}
    // })),
    // on(onSendMessageOTOFileFail, (state, {error}) => ({
    //     ...state,
    //     sendFileRoomOTOResponse: {error: O.some(error)}
    // })),
    // on(cleanResponseSendFileRoomOTO, state => ({
    //     ...state,
    //     sendFileRoomOTOResponse: undefined
    // })),
    on(onLoadNextPageFilesSuccess, (state, _) => ({
        ...state,
        loadNextPageFilesResponse: {complete: false, error: O.none}
    })),
    on(onLoadNextPageFilesFail, (state, {appError}) => ({
        ...state,
        loadNextPageFilesResponse: {complete: false, error: O.some(appError)}
    })),
    on(onLoadNextPageFilesComplete, state => ({
        ...state,
        loadNextPageFilesResponse: {complete: true, error: O.none}
    })),
    on(cleanResponseLoadNextPageFiles, state => ({
        ...state,
        loadNextPageFilesResponse: undefined
    })),
    on(logoutSuccess, (state, _) => ({
        ...state,
        logoutResponse: {error: O.none}
    })),
    on(logoutFail, (state, {error}) => ({
        ...state,
        logoutResponse: {error: O.some(error)}
    })),
    on(cleanResponseLogout, state => ({
        ...state,
        logoutResponse: undefined
    })),
    on(modifyRoomInfoSuccess, (state, _) => ({
        ...state,
        modifyRoomInfoResponse: {error: O.none}
    })),
    on(modifyRoomInfoFail, (state, {error}) => ({
        ...state,
        modifyRoomInfoResponse: {error: O.some(error)}
    })),
    on(cleanResponseModifyRoomInfo, state => ({
        ...state,
        modifyRoomInfoResponse: undefined
    }))
);

export function responsesReducerFunction(state: ResponsesState | undefined, action: Action) {
    return responsesReducer(state, action);
}
