import {RoomGroup} from '../../../domain/RoomGroup';
import {IUserRoomGroupState} from '../state/room.state';
import {RoomOTO} from '../../../domain/RoomOTO';


export class PartialRoomOTO {

    readonly roomOTO: RoomOTO;

    readonly nextLastPageToLoad: number;
    readonly numberMessagesLastPage: number;

    readonly fullPagesLoaded: boolean;

    static readonly MESSAGES_BY_PAGE : number = 10;

    readonly messageInputSaved: string;

    constructor(
        roomOTO: RoomOTO,
        // withImageProfileLoaded: boolean,
        nextLastPageToLoad: number,
        numberMessagesLastPage: number,
        fullPagesLoaded: boolean,
        messageInputSaved: string
        // usersRoom: IUserRoomGroupState
    ) {
        this.roomOTO = roomOTO;
        // this.withImageProfileLoaded = withImageProfileLoaded;
        this.nextLastPageToLoad = nextLastPageToLoad;
        this.numberMessagesLastPage = numberMessagesLastPage;
        this.fullPagesLoaded = fullPagesLoaded;
        this.messageInputSaved = messageInputSaved;
        // this.usersRoom = usersRoom;
    }
}
