import {NgbmodalService} from "./ngbmodal.service";
import {PrivacyComponent} from "../../pages/content-pages/privacy/privacy.component";

export class SecprofilemodalService extends NgbmodalService  {
    openLegalTextModal(legalText: string) {
        return super.openDefaultModal(PrivacyComponent, false, [
            {key: "privacy", value: legalText}
        ]);
    }
}
