import {ReplyMessageModel} from "../../sources/remote/globalmodels/ReplyMessageModel";
import {LinkPreviewMessageModel} from "../../sources/remote/globalmodels/LinkPreviewMessageModel";


export class MessageOTOSocketModel {

    constructor(
        readonly idUser: number,
        readonly date: string,
        readonly _id: string,
        readonly toIdUser: number,
        readonly message: string,
        readonly internalId: string,
        readonly thumbnail: string|undefined,
        readonly fileType: string|undefined,
        readonly extension: string|undefined,
        readonly fileDuration: number|undefined,
        readonly replyMessage: ReplyMessageModel|undefined,
        readonly modifiedState: number,
        readonly linkPreview: LinkPreviewMessageModel|undefined,
    ) {
    }
}
