import {createAction, props} from "@ngrx/store";
import {Option} from "fp-ts/lib/Option";
import {AppError} from "../../../remote/globalmodels/AppError";
import {StandardError} from "../../../../domain/StandardError";
import {AppWithConnectionState, FirstAppWithConnectionState} from "../../state/namespaces/app_state_namespace.state";

const TAG = '[Generic App State Actions] ';

export const GenericAppStateActions = {



};


