import {RoomStatusReadedMessages} from "./RoomStatusReadedMessages";


export class RoomOTOStatusReadedMessages extends RoomStatusReadedMessages {
    readonly idUser: number;


    constructor(messagesToClean: number, idUser: number) {
        super(messagesToClean);
        this.idUser = idUser;
    }
}
