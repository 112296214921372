import {Option} from "fp-ts/lib/Option";

export enum DeviceType {
    Android = "Android", IOS = "IOS", Web = "Web"
}

export class Session {

    readonly deviceId: string;
    readonly systemVersion: string;
    readonly appVersion: string;
    readonly deviceModel: string;
    readonly deviceType: DeviceType;
    readonly createdAt: Date;
    readonly lastActive: Option<Date>;
    readonly active: boolean;


    constructor(deviceId: string, systemVersion: string, appVersion: string, deviceModel: string, deviceType: DeviceType, createdAt: Date, lastActive: Option<Date>, active: boolean) {
        this.deviceId = deviceId;
        this.systemVersion = systemVersion;
        this.appVersion = appVersion;
        this.deviceModel = deviceModel;
        this.deviceType = deviceType;
        this.createdAt = createdAt;
        this.active = active;
        this.lastActive = lastActive;
    }
}
