import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SessionsService} from "./utils/sessions.service";
import {SessionElementList} from "./utils/SessionElementList";
import {NgxSpinnerService} from "ngx-spinner";
import {AlertsService} from "../utils/alerts.service";
import * as E from "fp-ts/lib/Either";
import {pipe} from "fp-ts/lib/pipeable";
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TableShowerModel} from "../table-shower/TableShowerModel";

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit, AfterViewInit {

    // sessions : SessionElementList[] = [];

    // menu

    menus = new ReplaySubject<TableShowerModel>();

    loadingSubj = new BehaviorSubject(true);

    constructor(
        private sessionsService: SessionsService,
        private alertsService: AlertsService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
        this.sessionsService.getSessions().subscribe(
            eth => pipe(
                eth,
                E.fold(
                    exc => {
                        this.alertsService.showAlertFromStandardError(exc).then(_ => {})
                        this.loadingSubj.next(false);
                    },
                    sessions => {
                        // this.sessions = sessions;
                        console.log({text: "sessions", sessions: sessions});
                        this.menus.next(sessions);
                        this.loadingSubj.next(false);
                    }
                )
            ),
        )
        this.menus.subscribe(
            m => {
                console.log({text: "menu subject", m: m})
            }
        )
    }

    ngAfterViewInit(): void {

    }

}
