import {Injectable} from "@angular/core";
import {SocketFacade} from "../../../../realtime/socket.facade";
import {NetworkService} from "../../../../connectionhandler/network.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
    ChangedConnectionOfflineLocalAction,
    ChangedConnectionOfflineServerAction, ChangedConnectionOnlineLocalAction,
    ChangedConnectionOnlineServerAction, SetAppStateWithLocalConnectionAction, SetAppStateWithServerConnectionAction
    // ChangedConnectionOfflineAction,
    // ChangedConnectionOnlineAction
} from "../../actions/namespaces/app_state_namespace.actions";
import {endWith, flatMap, map, switchMap, take, tap} from "rxjs/operators";
import {ReloadAppAction} from "../../actions/app_with_connection/meta.actions";
import {nilAction} from "../../actions/app.actions";
import {AppStateNamespaceFacade} from "../../facade/namespaces/app_state_namespace.facade";
import {zip} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class AppStateNamespaceEffects {


    changedConnectionOnlineServerEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangedConnectionOnlineServerAction),
            switchMap(_ => zip(
                this.appStateNameSpaceFacade.getAppStateWithLocalConnection(),
                this.appStateNameSpaceFacade.getAppStateWithServerConnection()
            ).pipe(
                take(1)
            )),
            switchMap(([localConnection, serverConnection]) => {
                if (localConnection && !serverConnection) {
                    return [SetAppStateWithServerConnectionAction({app_state_with_server_connection: true}), ReloadAppAction()];
                } else if (!serverConnection) {
                    return [SetAppStateWithServerConnectionAction({app_state_with_server_connection: true})];
                } else {
                    return [nilAction()];
                }
            })
        )
    );

    changedConnectionOnlineLocalEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangedConnectionOnlineLocalAction),
            switchMap(_ => zip(
                this.appStateNameSpaceFacade.getAppStateWithServerConnection(),
                this.appStateNameSpaceFacade.getAppStateWithLocalConnection(),
            ).pipe(
                take(1)
            )),
            switchMap(([serverConnection, localConnection]) => {
                if (serverConnection && !localConnection) {
                    return [SetAppStateWithLocalConnectionAction({app_state_with_local_connection: true}), ReloadAppAction()];
                } else if (!localConnection) {
                    return [SetAppStateWithLocalConnectionAction({app_state_with_local_connection: true})];
                } else {
                    return [nilAction()];
                }
            })
        )
    );

    changedConnectionOfflineLocalEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangedConnectionOfflineLocalAction),
            switchMap(_ => this.socketFacade.invalidateSocketInstance().pipe(
                endWith(SetAppStateWithLocalConnectionAction({app_state_with_local_connection: false}))
            )),
            // tap(c => console.log({text: "endwith changedConnectionOfflineLocalEffect$",c:c}))
            // endWith()
        )
    );

    changedConnectionOfflineServerEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangedConnectionOfflineServerAction),
            switchMap(_ => this.socketFacade.invalidateSocketInstance().pipe(
                endWith(SetAppStateWithServerConnectionAction({app_state_with_server_connection: false}))
            )),

        )
    );

    constructor(
        private socketFacade: SocketFacade,
        private actions$: Actions,
        private appStateNameSpaceFacade: AppStateNamespaceFacade
        // private networkService: NetworkService
    ) {}
}
