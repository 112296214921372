import {IAppState} from "../../state/app.state";
import {createSelector} from "@ngrx/store";

const metaStateSelect = (state: IAppState) => state.metaState;

export const MetaStateOwnUserDataSelect = createSelector(metaStateSelect, metaState => metaState.ownDataUser);

export const MetaStateOwnImageProfileSelect = createSelector(metaStateSelect, metaState => metaState.ownImageProfile);

    // export const selectedRoomsSelect = createSelector(appSelect, appState => appState.selectedRoom);
