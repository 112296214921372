import {RoomGroup} from '../../../domain/RoomGroup';
import {MessageRoom} from '../../../domain/MessageRoom';
import {RoomOTO} from '../../../domain/RoomOTO';
import {MessageOTO} from '../../../domain/MessageOTO';
import {Option} from "fp-ts/lib/Option";
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";


export function getRoomGroupWithImageProfileByRoomGroup(roomGroup: RoomGroup) : RoomGroup {
    // console.log({text: "getRoomGroupWithImageProfileByRoomGroup", r: roomGroup});
    return new RoomGroup(
        roomGroup.lastActivity,
        roomGroup.messageCount,
        roomGroup.lastMessage,
        roomGroup.membersCount,
        roomGroup.lastModification,
        roomGroup.name,
        roomGroup.description,
        roomGroup.idRoom,
        roomGroup.imageProfileUrl,
        roomGroup.typeRoom,
        roomGroup.last_messages_updated,
        roomGroup.lastClick,
        roomGroup.members_loaded,
        roomGroup.last_members_updated,
        roomGroup.bidirectional
    );
}


export function getRoomGroupWithNewMessageStateByRoomGroup(roomGroup: RoomGroup, messageRoom: MessageRoom, lastMessagesUpdatedOption: Option<Date>) : RoomGroup {

    const lastMessagesUpdated = pipe(
        lastMessagesUpdatedOption,
        O.fold(
            () => roomGroup.last_messages_updated,
            d => d
        )
    );

    const msg = new RoomGroup(
        messageRoom.date,
        roomGroup.messageCount,
        messageRoom,
        roomGroup.membersCount,
        messageRoom.date,
        roomGroup.name,
        roomGroup.description,
        roomGroup.idRoom,
        // roomGroup.imageProfile,
        roomGroup.imageProfileUrl,
        roomGroup.typeRoom,
        // roomGroup.last_messages_updated,
        lastMessagesUpdated,
        roomGroup.lastClick,
        roomGroup.members_loaded,
        roomGroup.last_members_updated,
        roomGroup.bidirectional
    );

    const msgOld = new RoomGroup(
        roomGroup.lastActivity,
        roomGroup.messageCount,
        roomGroup.lastMessage,
        roomGroup.membersCount,
        roomGroup.lastModification,
        roomGroup.name,
        roomGroup.description,
        roomGroup.idRoom,
        roomGroup.imageProfileUrl,
        roomGroup.typeRoom,
        lastMessagesUpdated,
        roomGroup.lastClick,
        roomGroup.members_loaded,
        roomGroup.last_members_updated,
        roomGroup.bidirectional
    );

    if (!roomGroup.lastMessage) return msg;

    if (roomGroup.lastMessage.internalId === messageRoom.internalId && roomGroup.lastMessage.syncState > messageRoom.syncState) {
        return msg;
    }
    if (roomGroup.lastMessage.date.getTime() < messageRoom.date.getTime()) {
        return msg;
    }
    // if (roomGroup.lastMessage.date.getTime() > messageRoom.date.getTime() && !(
    //     roomGroup.lastMessage.internalId === messageRoom.internalId &&
    //         roomGroup.lastMessage.syncState < messageRoom.syncState
    // )) {
    //     return roomGroup;
    // }
    return msgOld;
}

export function getRoomGroupWithMessageStateByRoomGroup(roomGroup: RoomGroup, messageRoom: MessageRoom) : RoomGroup {
    return new RoomGroup(
        roomGroup.lastActivity,
        roomGroup.messageCount,
        messageRoom,
        roomGroup.membersCount,
        roomGroup.lastModification,
        roomGroup.name,
        roomGroup.description,
        roomGroup.idRoom,
        // roomGroup.imageProfile,
        // linkImage,
        roomGroup.imageProfileUrl,
        roomGroup.typeRoom,
        roomGroup.last_messages_updated,
        roomGroup.lastClick,
        roomGroup.members_loaded,
        roomGroup.last_members_updated,
        roomGroup.bidirectional
    );
}


export function getRoomOTOWithNewMessageStateByRoomOTO(roomOTO: RoomOTO, messageOTO: MessageOTO, lastMessagesUpdatedOption: Option<Date>) : RoomOTO {
    // if (roomOTO.lastMessage.date.getTime() > messageOTO.date.getTime()) {
    //     return roomOTO;
    // }
    const lastMessagesUpdated = pipe(
        lastMessagesUpdatedOption,
        O.fold(
            () => roomOTO.last_messages_updated,
            d => d
        )
    );

    const msg = new RoomOTO(
        messageOTO.date,
        roomOTO.messageCount,
        messageOTO,
        roomOTO.idUser,
        lastMessagesUpdated,
        roomOTO.lastClick
    );

    const oldMsg = new RoomOTO(
        roomOTO.lastActivity,
        roomOTO.messageCount,
        roomOTO.lastMessage,
        roomOTO.idUser,
        lastMessagesUpdated,
        roomOTO.lastClick
    );

    if (!roomOTO.lastMessage) return msg;

    if (roomOTO.lastMessage.internalId === messageOTO.internalId && roomOTO.lastMessage.syncState > messageOTO.syncState) {
        return msg;
    }
    if (roomOTO.lastMessage.date.getTime() < messageOTO.date.getTime()) {
        return msg;
    }

    return oldMsg;


}

export function getRoomOTOWithMessageStateByRoomOTO(roomOTO: RoomOTO, messageOTO: MessageOTO) : RoomOTO {
    return new RoomOTO(
        roomOTO.lastActivity,
        roomOTO.messageCount,
        messageOTO,
        roomOTO.idUser,
        roomOTO.last_messages_updated,
        roomOTO.lastClick
    );
}
