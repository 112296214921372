import {Injectable} from '@angular/core';
import {ThemeService} from '../themes/theme.service';
import {AppError} from '../core/sources/remote/globalmodels/AppError';
import Swal, {SweetAlertResult} from 'sweetalert2';
import {StandardError} from "../core/domain/StandardError";
// import swal, {SweetAlertIcon} from 'sweetalert2';


@Injectable({
    providedIn: 'root'
})
export class AlertsService {

    constructor(
        private themeService: ThemeService
    ) {

    }

    showInformationAlert(text: string) : Promise<SweetAlertResult> {
        return Swal.fire({
            title: "Info",
            text: text,
            type: "info"
        })
    }

    showConfirmOrNotAlert(title: string, text: string, textButtonConfirm: string, textButtonCancel: string) : Promise<SweetAlertResult> {
        // export function confirmCancelButton() {
        return Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0CC27E',
            cancelButtonColor: '#FF586B',
            confirmButtonText: textButtonConfirm,
            cancelButtonText: textButtonCancel,
            confirmButtonClass: 'btn btn-success btn-raised mr-5',
            cancelButtonClass: 'btn btn-danger btn-raised',
            buttonsStyling: false
        })
    }
        //     .then(function () {
        //     swal(
        //         'Deleted!',
        //         'Your imaginary file has been deleted.',
        //         'success'
        //     )
        // }, function (dismiss) {
        //     // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
        //     if (dismiss === 'cancel') {
        //         swal(
        //             'Cancelled',
        //             'Your imaginary file is safe :)',
        //             'error'
        //         )
        //     }
        // })

        // const swalWithBootstrapButtons = Swal.mixin({
        //     customClass: {
        //         'confirmButton': 'btn btn-success',
        //         'cancelButton': 'btn btn-danger'
        //     },
        //     buttonsStyling: false
        // })
        //
        // swalWithBootstrapButtons.fire({
        //     title: 'Are you sure?',
        //     text: "You won't be able to revert this!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: 'Yes, delete it!',
        //     cancelButtonText: 'No, cancel!',
        //     reverseButtons: true
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         swalWithBootstrapButtons.fire(
        //             'Deleted!',
        //             'Your file has been deleted.',
        //             'success'
        //         )
        //     } else if (
        //         /* Read more about handling dismissals below */
        //         result.dismiss === swal.DismissReason.cancel
        //     ) {
        //         swalWithBootstrapButtons.fire(
        //             'Cancelled',
        //             'Your imaginary file is safe :)',
        //             'error'
        //         )
        //     }
        // })



    showAlertFromIAppError(error: AppError) : Promise<SweetAlertResult> {
        // return swal.fire({
        //     title: "Error",
        //     text: "Error: " +error.code + "; " + this.themeService.getMessageFromIAppError(error),
        //     icon: 'error'
        //     SweetAlertIcon.
        // });
        return Swal.fire({
            title: "Error",
            text: "Error: " +error.code + "; " + this.themeService.getMessageFromIAppError(error),
            type: 'error'
        })
        // return new Promise<any>((resolve, reject) => {
        //    alert("Error: " + error.code + "; " + this.themeService.getMessageFromIAppError(error));
        //    resolve();
        // });
        // return swal({
        //     title: "Error",
        //     text: "Error: " + error.code + "; " + this.themeService.getMessageFromIAppError(error),
        //     type: 'error'
        // });

        // })
    }

    showAlertFromStandardError(e: StandardError) : Promise<SweetAlertResult> {
        console.log({text: "showAlertFromStandardError", error: e})
        return Swal.fire({
            title: "Error",
            text: "Error: " + e.code + "; " + e.message,
            type: 'error'
        })
    }

    showAlertWithTextError(error: string) : Promise<any> {
        return Swal.fire({
            title: "Error",
            // text: "Error: " +error.code + "; " + this.themeService.getMessageFromIAppError(error),
            text: error,
            type: 'error'
        })
    }

}
