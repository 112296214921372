import {MessageOTO} from '../../../domain/MessageOTO';
import {SynchronizationState} from '../../../domain/SynchronizationState';
import {MessageRoom} from '../../../domain/MessageRoom';
import {Message} from '../../../domain/Message';
import {FileMessage} from '../../../domain/FileMessage';

//
// export function changeSynchronizationStateToSendedNotSynchronizedByMessageOTO(message: MessageOTO) : MessageOTO {
//     return new MessageOTO(
//         message.idMessage,
//         message.idUser,
//         message.date,
//         message.text,
//         message.idUserOTO,
//         message.internalId,
//         SynchronizationState.NOT_SYNCHRONIZED_SENDED,
//         message.thumbnail,
//         message.fileType,
//         message.extension,
//         message.linkResource,
//         message.messageReply,
//         message.modifiedState,
//         message.linkPreview
//     );
// }
//
// export function changeSynchronizationStateToSendedNotSynchronizedWithIdMessageByMessageOTO(message: MessageOTO, idMessage: string) : MessageOTO {
//     return new MessageOTO(
//         // message.idMessage,
//         idMessage,
//         message.idUser,
//         message.date,
//         message.text,
//         message.idUserOTO,
//         message.internalId,
//         SynchronizationState.NOT_SYNCHRONIZED_SENDED,
//         message.thumbnail,
//         message.fileType,
//         message.extension,
//         message.linkResource,
//         message.messageReply,
//         message.modifiedState,
//         message.linkPreview
//     );
// }


// export function changeSynchronizationStateToSendedNotSynchronizedByMessageRoom(message: MessageRoom) : MessageRoom {
//     return new MessageRoom(
//         message.idMessage,
//         message.idUser,
//         message.date,
//         message.text,
//         message.idRoom,
//         message.internalId,
//         SynchronizationState.NOT_SYNCHRONIZED_SENDED,
//         message.thumbnail,
//         message.fileType,
//         message.extension,
//         message.linkResource,
//         message.messageReply,
//         message.modifiedState,
//         message.linkPreview
//     );
// }
// export function changeSynchronizationStateToSendedNotSynchronizedWithIdMessageByMessageRoom(message: MessageRoom, idMessage: string) : MessageRoom {
//     return new MessageRoom(
//         idMessage,
//         message.idUser,
//         message.date,
//         message.text,
//         message.idRoom,
//         message.internalId,
//         SynchronizationState.NOT_SYNCHRONIZED_SENDED,
//         message.thumbnail,
//         message.fileType,
//         message.extension,
//         message.linkResource,
//         message.messageReply,
//         message.modifiedState,
//         message.linkPreview
//     );
// }


export function getFileMessageFromMessage(message: Message) : FileMessage {
    return new FileMessage(
        message.idMessage,
        message.date,
        message.idUser,
        message.extension,
        message.text,
        message.thumbnail,
        message.fileType
    );
}
