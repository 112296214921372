import {RoomApiModel} from '../../sources/remote/rooms/models/RoomApiModel';
import {RoomGroup} from '../../domain/RoomGroup';
import {UserApiModel} from '../../sources/remote/users/models/UserApiModel';
import {User} from '../../domain/User';
// import {getGenderFromNumber} from './utils.mapper';
import {getDateFromBackendDate} from './dates.mapper';
import {PartialUserApiModel} from '../../sources/remote/users/models/PartialUserApiModel';
import {PartialUser} from "../../sources/storage/entities/PartialUser";
import {Injectable} from "@angular/core";
import {LinkGenerators} from "../../domain/images/link.generators";
import {LocaleService} from "../../../locales/locale.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class UsersMapper {
    constructor(
       private linkGenerators: LinkGenerators,
       private localeService: LocaleService
    ) {}

    getUserByUserApiModel(userApiModel: UserApiModel, idUser: number, color: string) : Observable<User> {
        return this.linkGenerators.getUserImageLinkFromIdUser(idUser).pipe(
            map(link => new User(
                userApiModel.name,
                userApiModel.surname,
                idUser,
                color,
                userApiModel.additionalInfo !== null ? userApiModel.additionalInfo : this.localeService.translate("No specified", {}),
                link
            ))
        );
    }


    getUserByPartialUserApiModel(partialUserApiModel: PartialUserApiModel, color: string) : Observable<User> {
        return this.linkGenerators.getUserImageLinkFromIdUser(partialUserApiModel.idUser).pipe(
            map(link => new User(
                partialUserApiModel.name,
                partialUserApiModel.surname,
                partialUserApiModel.idUser,
                color,
                partialUserApiModel.additionalInfo !== null ? partialUserApiModel.additionalInfo : this.localeService.translate("No specified", {}),
                link
            ))
        );

    }

    getPartialUserFromUser(user: User) : PartialUser {
        return new PartialUser(
            user,
            // false,
            // false
        );
    }
}


