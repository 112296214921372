import {IAppState} from "../../state/app.state";
import {adapterFileMessage} from "../../adapters/filemessages.adapters";
import {createSelector, select} from "@ngrx/store";


export const selectAppStateNamespaceState = (state: IAppState) => state.appNamespaceState;

// const AppStateNamespaceSelectors = adapterFileMessage.getSelectors();


export const FirstAppWithConnectionSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.firstAppWithConnectionState
);

export const AppWithConnectionStateSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.appWithConnectionState
);
//app_state_all_data_loaded
export const AppStateAllDataLoadedSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.app_state_all_data_loaded
);

export const AppWithConnectionSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.app_state_with_local_connection && state.app_state_with_server_connection
);

export const AppStateWithLocalConnectionSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.app_state_with_local_connection
);

export const AppStateWithServerConnectionSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.app_state_with_server_connection
);

export const AppStateReconnectingTimeSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.reconnecting_time
);

export const ActiveProcessUserValidationSelect = createSelector(
    selectAppStateNamespaceState,
    state => state.active_process_user_validation
);
