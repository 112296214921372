import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {RoomGroup} from '../../../domain/RoomGroup';
import {RoomOTO} from '../../../domain/RoomOTO';
import {PartialRoomGroup} from '../entities/PartialRoomGroup';
import {UserRoom} from '../../../domain/UserRoom';
import {PartialRoomOTO} from '../entities/PartialRoomOTO';


export function sortByDate(a: Date, b: Date) : number {
    return -a.getTime() + b.getTime();
}

export const adapterRoomGroup : EntityAdapter<PartialRoomGroup> = createEntityAdapter<PartialRoomGroup>({
    selectId: (u: PartialRoomGroup) => u.roomGroup.idRoom,
    sortComparer: (a: PartialRoomGroup, b: PartialRoomGroup) => sortByDate(a.roomGroup.lastActivity, b.roomGroup.lastActivity)
});

export const adapterUserRoom : EntityAdapter<UserRoom> = createEntityAdapter<UserRoom>({
    selectId: userRoom => userRoom.idUser
});


export const adapterRoomOTO: EntityAdapter<PartialRoomOTO> = createEntityAdapter<PartialRoomOTO>({
    selectId: (u: PartialRoomOTO) => u.roomOTO.idUser,
    sortComparer: (a: PartialRoomOTO, b: PartialRoomOTO) => sortByDate(a.roomOTO.lastActivity, b.roomOTO.lastActivity)
});

// adapterRoomGroup.
