import {IGetUsersMeResponseModel} from '../../sources/remote/meta/models/IGetUsersMeResponseModel';
import {OwnDataUserApiModel} from '../../sources/remote/meta/models/OwnDataUserApiModel';
import {OwnDataUser} from '../../domain/OwnDataUser';
import {getDateFromBackendDate} from './dates.mapper';
import {option as O} from "fp-ts";


export function getOwnDataUserFromOwnDataUserApiModel(iGetUsersMe: OwnDataUserApiModel) {
    return new OwnDataUser(
        iGetUsersMe.idUser,
        iGetUsersMe.idClient,
        iGetUsersMe.loginIdentifier,
        iGetUsersMe.name,
        iGetUsersMe.surname,
        iGetUsersMe.additionalInfo,
        iGetUsersMe.checkEmailNotificationsActivated === 1,
        O.fromNullable(iGetUsersMe.emailContact),
        iGetUsersMe.emailNotifications === 1,
        iGetUsersMe.videosActivated === 1,
        iGetUsersMe.voiceActivated === 1,
        iGetUsersMe.hideGroupCreationButton === 1
    );
}
