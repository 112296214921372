import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {RoomGroup} from "../../../domain/RoomGroup";
import {adapterRoomGroup, adapterRoomOTO, adapterUserRoom} from '../adapters/rooms.adapters';
import {IRoomGroupState, IRoomOTOState} from '../state/room.state';
import {PartialRoomGroup} from '../entities/PartialRoomGroup';
import {PartialRoomOTO} from '../entities/PartialRoomOTO';
import {none, some} from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import {pipe} from "fp-ts/lib/pipeable";

// export const getRoomsGroupSelect = (state: IRoomGroupState) => state.entities;
// export const getRoomsOTOSelect = (state: IRoomOTOState) => state.entities;
// export const getSelectedRoomSelect = (state: IAppState) => state.rooms.selectedRoom;



// --------------------------------------------------------------------------------------------
// -------------------------------------Rooms selectors----------------------------------------
// --------------------------------------------------------------------------------------------


export const selectRoomGroupsState = createFeatureSelector<IRoomGroupState>('roomGroups');


const RoomGroupsSelectors = adapterRoomGroup.getSelectors();
const UsersRoomSelectors = adapterUserRoom.getSelectors();


export const getRoomsGroupSelect = createSelector(
    selectRoomGroupsState,
    RoomGroupsSelectors.selectAll
);


export const getRoomsGroupDicSelect = createSelector(
    selectRoomGroupsState,
    RoomGroupsSelectors.selectEntities
);



export const getRoomGroupByIdRoomSelect = (idRoom: number) => createSelector(
    getRoomsGroupDicSelect,
    entities => entities[idRoom]
);

// export const getRoomFieldsByIdRoomSelect = (idRoom: number)
export function getRoomFieldsByIdRoomSelect<K extends keyof PartialRoomGroup, KK extends keyof RoomGroup>(
    idRoom: number,
    f: K[],
    ff: KK[]
) {
    return createSelector(
        getRoomsGroupDicSelect,
        entities => {
            const r = entities[idRoom];
            const pick = pipe(
                f,
                A.map(f => ({[f]: r[f]})),
                A.reduce({}, (acc, v) => ({...acc, ...v}))
            ) as any;
            const dPick = pipe(
                ff,
                A.map(f => ({[f]: r.roomGroup[f]})),
                A.reduce({}, (acc, v) => ({...acc, ...v}))
            ) as any;
            return {partialFields: pick as Pick<PartialRoomGroup, K>, roomFields: dPick as Pick<RoomGroup, KK>}
        }
    )
}

export const getRoomNameImageByIdRoomSelect = (idRoom: number) => createSelector(
    getRoomsGroupDicSelect,
    entities => ({name: entities[idRoom].roomGroup.name, url: entities[idRoom].roomGroup.imageProfileUrl, membersLoaded: entities[idRoom].roomGroup.members_loaded})
)

export const getUsersRoomGroupDicByIdRoomSelect = (idRoom: number) => createSelector(
    getRoomGroupByIdRoomSelect(idRoom),
    partialRoomGroup => partialRoomGroup.usersRoom
);

export const getUsersRoomGroupByIdRoomSelect = (idRoom : number) => createSelector(
    getUsersRoomGroupDicByIdRoomSelect(idRoom),
    UsersRoomSelectors.selectAll
);

export const getUserRoomByIdRoomDic = (idRoom: number) => createSelector(
    getUsersRoomGroupDicByIdRoomSelect(idRoom),
    UsersRoomSelectors.selectEntities
);

export const getUserRoomByIdUserIdRoom = (idRoom: number, idUser: number) => createSelector(
    getUserRoomByIdRoomDic(idRoom),
    entities => {
        console.log({text: "getUserRoomByIdUserIdRoom", idRoom: idRoom, idUser: idUser, entities: entities});
        const v = entities[idUser];
        if (v === undefined) return none;
        else return some(v);
    }
);

export const getCurrentPageLoadedByIdRoom = (idRoom: number) => createSelector(
    getRoomGroupByIdRoomSelect(idRoom),
    partialRoomGroup => partialRoomGroup.nextLastPageToLoad
);

export const getLastSavedMessageInputByIdRoom = (idRoom: number) => createSelector(
    getRoomGroupByIdRoomSelect(idRoom),
    partialRoomGroup => partialRoomGroup.messageInputSaved
);


// export const getIdRoomsWithUserAsMember = (idUser: number) => createSelector(
//     getRoomsGroupSelect,
//     rooms => pipe(
//         rooms,
//         A.filter(r => select(getUsersRoomGroupDicByIdRoomSelect(r.roomGroup.idRoom)))
//     )
//     // A.filter(r => getUsersRoomGroupDicByIdRoomSelect(r.roomGroup.idRoom))
// )

// --------------------------------------------------------------------------------------------
// -------------------------------------OTOs selectors-----------------------------------------
// --------------------------------------------------------------------------------------------

export const selectRoomOTOsState = createFeatureSelector<IRoomOTOState>('roomOTOs');
const RoomOTOsSelectors = adapterRoomOTO.getSelectors();

export const getRoomsOTOSelect = createSelector(
    selectRoomOTOsState,
    RoomOTOsSelectors.selectAll
);

export const getRoomsOTODicSelect = createSelector(
    selectRoomOTOsState,
    RoomOTOsSelectors.selectEntities
);

export const getRoomOTOByIdUserSelect = (idUser: number) => createSelector(
    getRoomsOTODicSelect,
    entities => entities[idUser]
);

export const getCurrentPageLoadedByIdUser = (idUser: number) => createSelector(
    getRoomOTOByIdUserSelect(idUser),
    partialRoomOTO => partialRoomOTO.nextLastPageToLoad
);

export const getRoomsOTOByIdUsersSelect = (idUsers: number[]) => createSelector(
    selectRoomOTOsState,
    ents => {
        return pipe(
            idUsers,
            A.map(id => ents.entities[id]),
            A.filter(e => e !== undefined)
        )
    }
);

export const getLastSavedMessageInputByIdUserOTO = (idUserOTO: number) => createSelector(
    getRoomOTOByIdUserSelect(idUserOTO),
    partialRoomGroup => partialRoomGroup.messageInputSaved
);



// --------------------------------------------------------------------------------------------
// -------------------------------------Generic selectors-----------------------------------------
// --------------------------------------------------------------------------------------------

export const getNumberMessagesDistinctByRoomsSelect = createSelector(
    getRoomsGroupSelect,
    getRoomsOTOSelect,
    (allGroups: PartialRoomGroup[], allOtos: PartialRoomOTO[]) : number => {
        return allGroups.reduce((acc, e) => e.roomGroup.messageCount > 0 ? acc + 1 : acc , 0) +
            allOtos.reduce((acc, e) => e.roomOTO.messageCount > 0 ? acc + 1 : acc, 0);
    }
);


