

export enum DOC_ORIENTATION {
    Up = 1,
    Down = 3,
    Right = 6,
    Left = 8,
    UpMirrored = 2,
    DownMirrored = 4,
    LeftMirrored = 5,
    RightMirrored = 7,
    NotJpeg = -1,
    NotDefined = -2
}
