import {NetworkClient} from '../core/NetworkClient';
import {Injectable} from '@angular/core';
import {EMPTY, empty, Observable, of} from 'rxjs';
import {IGetUsersIdResponseModel} from './models/IGetUsersIdResponseModel';
import {RequestType} from '../core/RequestType';
import {IGetUsersListResponseModel} from './models/IGetUsersListResponseModel';
import {catchError, isEmpty, map} from 'rxjs/operators';
import {AppError} from '../globalmodels/AppError';
import {StandardError} from "../../../domain/StandardError";
import {Either} from "fp-ts/lib/Either";
import {Option} from "fp-ts/lib/Option";
import {getUnixDateFromDate} from "../../../../utils/dates.transformers";

@Injectable({
    providedIn: 'root'
})
export class UsersApihelper {
    constructor(
        private networkClient: NetworkClient
    ) {}

    // getUserInfoFromIdUser(idUser: number) : Observable<IGetUsersIdResponseModel> {
    //     return this.networkClient.setRequest<{},IGetUsersIdResponseModel>(
    //         '/users/' + idUser,
    //         {},
    //         RequestType.GET
    //     );
    // }


    getAllUsers() : Observable<Either<StandardError, IGetUsersListResponseModel>> {
        return this.networkClient.setRequest<{},IGetUsersListResponseModel>(
            '/users/list',
            {},
            RequestType.GET
        );
    }

    getUsersFromDate(date: Date) : Observable<Either<StandardError, IGetUsersListResponseModel>> {
        return this.networkClient.setRequest<{},IGetUsersListResponseModel>(
            '/users/list?changes=' + getUnixDateFromDate(date),
            {},
            RequestType.GET
        );
    }

    getUsersIdImageprofile(idUser: number) : Observable<Option<Blob>> {
        return this.networkClient.setRequestWithFile<{}>(
            '/users/' + idUser + '/imageprofile',
            {},
            RequestType.GET
        ).pipe(
            // map(blob => [blob, true] as [Blob,boolean]),
            // catchError(err => {
            //     console.log("Error catched on users apihelper");
            //     console.log({isAppError: err instanceof AppError, error: err, errorcode: err.code});
            //     if (err instanceof AppError && err.code === 422) {
            //         console.log("App error catched");
            //         return of([new Blob(), false] as [Blob, boolean]);
            //     }
            //
            //     throw err;
            // })
            // // map(v => v as Blob|undefined)
        );
    }

    getUsersIdImageprofilePath(idUser: number) : string {
        return '/users/' + idUser + '/imageprofile';
    }
}
