import { Injectable } from "@angular/core";
import { APILoginHelper } from "../../sources/remote/login/APILoginHelper";
import { HttpHeadersManager } from "../../sources/remote/core/headers/HttpHeadersManager";
import { concat, EMPTY, from, Observable, of, zip } from "rxjs";
import { IPostLoginUserRequestModel } from "../../sources/remote/login/models/IPostLoginUserRequestModel";
import { endWith, flatMap, map, switchMap, tap } from "rxjs/operators";
import { LoggedState } from "../../sources/remote/core/headers/LoggedState";
import { FcmService } from "../../../fcm/fcm.service";
import * as E from "fp-ts/lib/Either";
import { StandardError } from "../../domain/StandardError";
import { Either, Right } from "fp-ts/lib/Either";
import { fromNullable, Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import { ThemeService } from "../../../themes/theme.service";
import * as UAParser from "ua-parser-js";
import * as A from "fp-ts/lib/Array";

@Injectable({
    providedIn: "root",
})
export class LoginRepository {
    private static CLIENT_CODE: string = "FGDS2";
    private p = new UAParser();

    constructor(
        private apiLoginHelper: APILoginHelper,
        private httpHeadersManager: HttpHeadersManager,
        private fcmService: FcmService,
        private themeService: ThemeService // private fcmService: FcmService
    ) {
        console.log({ text: "ua", r: this.p.getResult() });
    }

    getStateLogin(): Observable<LoggedState> {
        return this.httpHeadersManager.getWebState();
    }

    private getStrFromOpts(opts: Option<string>[]): string {
        return pipe(opts, A.compact, (a) => {
            if (a.length === 0) return "Unknown";
            return a.reduce((acc, v) => acc + " " + v);
        });
    }

    loginUser(
        stLogin: Option<{ email: string; password: string }>,
        oauth2: Option<{ code: string }>,
        externalLogin?: Option<{
            token: string;
            loginIdentifier: string;
            idClient: number;
        }>
    ): Observable<Either<StandardError, boolean>> {
        const cc: string = LoginRepository.CLIENT_CODE;

        const sysVers = this.getStrFromOpts([
            fromNullable(this.p.getBrowser().name),
            fromNullable(this.p.getBrowser().version),
        ]);
        const devModel = this.getStrFromOpts([
            fromNullable(this.p.getOS().name),
            fromNullable(this.p.getOS().version),
        ]);

        return this.fcmService.getFcmInfo().pipe(
            switchMap(({ fcmToken, deviceId }) =>
                this.apiLoginHelper.login(<IPostLoginUserRequestModel>{
                    ...pipe(
                        stLogin,
                        O.fold(
                            () => ({}),
                            ({ email, password }) => ({
                                loginIdentifier: email,
                                password: password,
                            })
                        )
                    ),
                    ...pipe(
                        oauth2,
                        O.fold(
                            () => ({}),
                            ({ code }) => ({ code: code })
                        )
                    ),
                    ...pipe(
                        externalLogin || O.none,
                        O.fold(
                            () => ({}),
                            ({ token, loginIdentifier, idClient }) => ({
                                externalToken: token,
                                loginIdentifier,
                                idClient,
                            })
                        )
                    ),
                    clientCode: cc,
                    deviceId: deviceId,
                    token: fcmToken,
                    deviceType: "Web",
                    systemVersion: sysVers,
                    appVersion: this.themeService.getVersion(),
                    deviceModel: devModel,
                })
            ),
            switchMap(
                E.fold(
                    (error) =>
                        of(E.left(error) as Either<StandardError, boolean>),
                    (response) => {
                        // let o : Observable<Either<StandardError, boolean>>;
                        if (response.requiredRegister) {
                            return concat(
                                this.httpHeadersManager.setPostregisterState(
                                    response.authToken
                                ),
                                from(of(E.right(response.requiredRegister)))
                            );
                        } else {
                            return concat(
                                this.httpHeadersManager.setLoggedState(
                                    response.authToken
                                ),
                                from(of(E.right(response.requiredRegister)))
                            );
                        }
                        // return o.pipe(endWith(E.right(response.requiredRegister)));
                    }
                )
            )
        );
    }
}
