import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';

import {FullLayoutComponent} from "./layouts/full/full-layout.component";
import {ContentLayoutComponent} from "./layouts/content/content-layout.component";

import {Full_ROUTES} from "./shared/routes/full-layout.routes";
import {CONTENT_ROUTES} from "./shared/routes/content-layout.routes";

import {AuthGuard} from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'xat',
        pathMatch: 'full',
    },

    // {
    //     path: '',
    //     redirectTo: 'recover',
    //     pathMatch: 'full'
    // },
    // {
    //     path: '',
    //     redirectTo: 'profile',
    //     pathMatch: 'full'
    // },
    // { path: '/login', module: ContentPagesModule },
    {
        path: '',
        component: FullLayoutComponent,
        data: {title: 'full Views'},
        children: Full_ROUTES,
        canActivate: [AuthGuard],
        // pathMatch: 'full'
    },
    {
        path: '',
        component: ContentLayoutComponent,
        data: {title: 'content Views'},
        children: CONTENT_ROUTES
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    }
    // { path: '**', redirectTo: 'login', component: ContentLayoutComponent, children: CONTENT_ROUTES }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
