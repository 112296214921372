import {Action, createReducer, on} from '@ngrx/store';
import {IDiscoversState, initialDiscoversState} from '../state/discovers.state';
import {
    joinDiscoverSuccess, OnDiscoverDeletedProcessedAction,
    OnDiscoverModifiedAction,
    OnDiscoverModifiedProcessedAction,
    OnNewDiscoverAction,
    OnNewDiscoverProcessedAction,
    // loadDiscoversSuccess,
    quitDiscoverSuccess
} from '../actions/discovers.actions';
import {adapterDiscovers} from '../adapters/discovers.adapters';
import {Discover} from '../../../domain/Discover';
import {Update} from '@ngrx/entity';
import {RefreshListDiscoversAction, SaveListDiscoversAction} from "../actions/generic/discovers.actions";
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";


const discoversReducer = createReducer(
    initialDiscoversState,
    // on(loadDiscoversSuccess, (state, {discovers}) => {
    //     return adapterDiscovers.addAll(discovers, state);
    // }),
    on(joinDiscoverSuccess, (state, {room, idDiscover}) => {
        return adapterDiscovers.updateOne(<Update<Discover>>{
            id: idDiscover,
            changes: {
                // ...state.entities[idDiscover],
                vinculated: true
            }
        }, state);
    }),
    on(quitDiscoverSuccess, (state, {idDiscover, idRoom}) => {
        return adapterDiscovers.updateOne(<Update<Discover>>{
            id: idDiscover,
            changes: {
                // ...state.entities[idDiscover],
                vinculated: false
            }
        }, state);
    }),
    // NEW
    on(SaveListDiscoversAction, (state, {discovers}) => adapterDiscovers.addAll(discovers, state)),
    on(RefreshListDiscoversAction, (state, {all, deleted}) => {
        const st = adapterDiscovers.removeMany([...deleted, ...all.map(d => d.idDiscover)], state);
        return adapterDiscovers.addMany(all, st);
    }),
    on(OnNewDiscoverProcessedAction, (state, {discover}) => adapterDiscovers.addOne(discover, state)),
    on(OnDiscoverModifiedProcessedAction, (state, {status, discoverField}) => {
        const d = state.entities[status.idDiscover];

        return adapterDiscovers.updateOne(<Update<Discover>>{
            id: status.idDiscover,
            changes: {
                // ...d,
                image: pipe(
                    discoverField,
                    O.fold(
                        () => d.image,
                        ({discoverImage}) => discoverImage
                    )
                ),
                position: pipe(
                    status.fields.position,
                    O.fold(
                        () => d.position,
                        p => p
                    )
                ),
                name: pipe(
                    status.fields.name,
                    O.fold(
                        () => d.name,
                        n => n
                    )
                )
            }
        }, state);
    }),
    on(OnDiscoverDeletedProcessedAction, (state, {idDiscover}) => adapterDiscovers.removeOne(idDiscover, state))
);


export function discoversReducerFunction(state: IDiscoversState | undefined, action: Action) {
    return discoversReducer(state, action);
}
