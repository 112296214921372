import {createAction, props} from "@ngrx/store";
import {StandardError} from "../../../../domain/StandardError";
import {MessageRoom} from "../../../../domain/MessageRoom";
import {Option} from "fp-ts/lib/Option";
import {MessageOTO} from "../../../../domain/MessageOTO";


const TAG = "[Synchronize Messages] ";

export const SynchronizeMessagesActions = {
    SYNCHRONIZE_MESSAGES_ROOMS: TAG + 'Synchronize Messages Rooms',
    SYNCHRONIZE_MESSAGES_ROOMS_FAIL: TAG + 'Synchronize Messages Rooms Fail',
    SYNCHRONIZE_MESSAGES_ROOMS_SUCCESS: TAG + 'Synchronize Messages Rooms Success',
    SYNCHRONIZE_NEXT_ROOM: TAG + 'Synchronize Next Room',
    PROCESS_FINAL_SYNCHRONIZATION: TAG + 'Process Final Synchronization',

    NEW_MESSAGES_COUNT_ROOM_GROUP: TAG + 'New Messages Count Room Group',
    NEW_MESSAGES_ROOM_GROUP: TAG + 'New Messages Room Group',

    NEW_MESSAGES_COUNT_ROOM_OTO: TAG + 'New Messages Count Room OTO',
    NEW_MESSAGES_ROOM_OTO: TAG + 'New Messages Room OTO'
};

export const SynchronizeMessagesRoomsAction = createAction(
    SynchronizeMessagesActions.SYNCHRONIZE_MESSAGES_ROOMS
);

export const SynchronizeMessagesRoomsFailAction = createAction(
    SynchronizeMessagesActions.SYNCHRONIZE_MESSAGES_ROOMS_FAIL,
    props<{errors: StandardError[]}>()
);

export const SynchronizeMessagesRoomsSuccessAction = createAction(
    SynchronizeMessagesActions.SYNCHRONIZE_MESSAGES_ROOMS_SUCCESS
);

export const SynchronizeNextRoomAction = createAction(
    SynchronizeMessagesActions.SYNCHRONIZE_NEXT_ROOM,
    props<{data_inici_proces: Date, LIST_ERRORS: [number,0|1][]}>()
);

export const ProcessFinalSynchronizationAction = createAction(
    SynchronizeMessagesActions.PROCESS_FINAL_SYNCHRONIZATION,
    props<{data_inici_proces: Date, LIST_ERRORS: [number,0|1][]}>()
);
export const NewMessagesCountRoomGroupAction = createAction(
    SynchronizeMessagesActions.NEW_MESSAGES_COUNT_ROOM_GROUP,
    props<{idRoom: number, uniqueNewMessages: number, lastMessage: Option<MessageRoom>, lastMessagesUpdated: Date}>()
);
export const NewMessagesRoomGroupAction = createAction(
    SynchronizeMessagesActions.NEW_MESSAGES_ROOM_GROUP,
    props<{messages: MessageRoom[], idRoom: number}>()
);
export const NewMessagesCountRoomOTOAction = createAction(
    SynchronizeMessagesActions.NEW_MESSAGES_COUNT_ROOM_OTO,
    props<{idUser: number, uniqueNewMessages: number, lastMessage: Option<MessageOTO>, lastMessagesUpdated: Date}>()
);
export const NewMessagesRoomOTOAction = createAction(
    SynchronizeMessagesActions.NEW_MESSAGES_ROOM_OTO,
    props<{messages: MessageOTO[], idUser: number}>()
);

// NEW_MESSAGES_COUNT_ROOM_OTO: TAG + 'New Messages Count Room OTO',
//     NEW_MESSAGES_ROOM_OTO: TAG + 'New Messages Room OTO'
