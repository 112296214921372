import {Injectable} from '@angular/core';
import {NetworkClient} from '../core/NetworkClient';
import {Observable} from 'rxjs';
import {IDiscoverApiModel} from './models/IDiscoverApiModel';
import {RequestType} from '../core/RequestType';
import {RoomApiModel} from '../rooms/models/RoomApiModel';
import {IRoomAlllistRoomApiModel} from '../rooms/models/IRoomAlllistRoomApiModel';
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../../domain/StandardError";
import {Option} from "fp-ts/lib/Option";
import {getUnixDateFromDate} from "../../../../utils/dates.transformers";

@Injectable({
    providedIn: 'root'
})
export class DiscoversApihelper {

    constructor(
        private networkClient: NetworkClient
    ) {}

    getDiscoversList() : Observable<Either<StandardError, {all: IDiscoverApiModel[]}>> {
        return this.networkClient.setRequest<{}, {all: IDiscoverApiModel[]}>(
            "/discovers/list",
            {},
            RequestType.GET
        );
    }

    getDiscoversListChanges(date: Date) : Observable<Either<StandardError, {all: IDiscoverApiModel[], deleted: number[]}>> {
        return this.networkClient.setRequest<{}, {all: IDiscoverApiModel[], deleted: number[]}>(
            "/discovers/list?changes=" + getUnixDateFromDate(date),
            {},
            RequestType.GET
        );
    }

    getDiscoversIddImageprofile(idDiscover: number) : Observable<Option<Blob>> {
        return this.networkClient.setRequestWithFile<{}>(
            '/discovers/' + idDiscover + '/imageprofile',
            {},
            RequestType.GET
        );
    }

    postDiscoversIddJoin(idDiscover: number) : Observable<Either<StandardError, IRoomAlllistRoomApiModel>> {
        return this.networkClient.setRequest<{},IRoomAlllistRoomApiModel>(
            '/discovers/' + idDiscover + '/join',
            {},
            RequestType.POST
        );
    }

    deleteDiscoversIddQuit(idDiscover: number) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{}, {}>(
            '/discovers/' + idDiscover + '/quit',
            {},
            RequestType.DELETE
        );
    }
}
