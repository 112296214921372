


export class ConfirmationFileSocketModelOut {

    constructor(
        readonly idDevice: string,
        readonly idBackendRequest: string
    ) {
    }

}
