import {createAction} from '@ngrx/store';


export const ResponsesActions = {
    CLEAN_RESPONSE_CREATE_ROOM: '[Responses] Clean Response Create Room',
    CLEAN_RESPONSE_GET_IMAGE_MESSAGE_ROOM: '[Responses] Clean Response Get Image Message Room',
    CLEAN_RESPONSE_ADD_USERS_TO_ROOM: '[Responses] Clean Response Add Users To Room',
    CLEAN_RESPONSE_LEAVE_ROOM_GROUP: '[Responses] Clean Response Leave Room Group',
    CLEAN_RESPONSE_LEAVE_ROOM_OTO: '[Responses] Clean Response Leave Room OTO',
    CLEAN_RESPONSE_MODIFY_OWN_DATA: '[Responses] Clean Response Modify Own Data',
    CLEAN_RESPONSE_OPEN_OTO: '[Responses] Clean Response Open OTO',
    // CLEAN_RESPONSE_SEND_FILE_ROOM_GROUP: '[Responses] Clean Response Send File Room Group',
    // CLEAN_RESPONSE_SEND_FILE_ROOM_OTO: '[Responses] Clean Response Send File Room OTO',
    CLEAN_RESPONSE_LOAD_NEXT_PAGE_FILES: '[Responses] Clean Response Load Next Page Files',
    CLEAN_RESPONSE_LOGOUT: '[Responses] Clean Response Logout',
    CLEAN_RESPONSE_MODIFY_ROOM_INFO: '[Responses] Clean Response Modify Room Info'
};

export const cleanResponseCreateRoom = createAction(ResponsesActions.CLEAN_RESPONSE_CREATE_ROOM);
export const cleanResponseGetImageMessageRoom = createAction(ResponsesActions.CLEAN_RESPONSE_GET_IMAGE_MESSAGE_ROOM);
export const cleanResponseAddUsersToRoom = createAction(ResponsesActions.CLEAN_RESPONSE_ADD_USERS_TO_ROOM);
export const cleanResponseLeaveRoomGroup = createAction(ResponsesActions.CLEAN_RESPONSE_LEAVE_ROOM_GROUP);
export const cleanResponseLeaveRoomOTO = createAction(ResponsesActions.CLEAN_RESPONSE_LEAVE_ROOM_OTO);
export const cleanResponseModifyOwnData = createAction(ResponsesActions.CLEAN_RESPONSE_MODIFY_OWN_DATA);
export const cleanResponseOpenOto = createAction(ResponsesActions.CLEAN_RESPONSE_OPEN_OTO);

// export const cleanResponseSendFileRoomGroup = createAction(ResponsesActions.CLEAN_RESPONSE_SEND_FILE_ROOM_GROUP);
// export const cleanResponseSendFileRoomOTO = createAction(ResponsesActions.CLEAN_RESPONSE_SEND_FILE_ROOM_OTO);

export const cleanResponseLoadNextPageFiles = createAction(ResponsesActions.CLEAN_RESPONSE_LOAD_NEXT_PAGE_FILES);
export const cleanResponseLogout = createAction(ResponsesActions.CLEAN_RESPONSE_LOGOUT);
export const cleanResponseModifyRoomInfo = createAction(ResponsesActions.CLEAN_RESPONSE_MODIFY_ROOM_INFO);

