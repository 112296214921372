import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MetaRepository } from "../../../../repositories/meta/meta.repository";
import { UsersRepository } from "../../../../repositories/users/users.repository";
import { Action, Store } from "@ngrx/store";
import { IAppState } from "../../state/app.state";
import { SocketFacade } from "../../../../realtime/socket.facade";
import { AppFacade } from "../../facade/app.facade";
import { FcmFacade } from "../../../../../fcm/fcm.facade";
import { HttpHeadersManager } from "../../../remote/core/headers/HttpHeadersManager";
import { endWith, map, switchMap, take, tap } from "rxjs/operators";
import {
    LoadAppAction,
    LoadAppSuccessAction,
    LoadInitFirebaseAction,
    LoadInitFirebaseErrorAction,
    LoadInitFirebaseSuccessAction,
    LoadInitNetworkClientAction,
    LoadListDiscoversAction,
    LoadListDiscoversErrorAction,
    LoadListDiscoversSuccessAction,
    LoadListRoomsAction,
    LoadListRoomsErrorAction,
    LoadListRoomsSuccessAction,
    LoadListUsersAction,
    LoadListUsersErrorAction,
    LoadListUsersSuccessAction,
    LoadOpenSocketAction,
    LoadOpenSocketErrorAction,
    LoadOpenSocketSucccessAction,
    LoadPersonalInfoAction,
    LoadPersonalInfoErrorAction,
    LoadPersonalInfoSuccessAction,
    LoadResourcesAction,
    LoadResourcesErrorAction,
    LoadResourcesSuccessAction,
} from "../../actions/first_app_with_connection/meta.actions";
import // ConnectionChangedAction,
// SetAllDataLoadedAction,
// SetFirstAppWithConnectionStateAction
"../../actions/generic/app_state.actions";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import * as A from "fp-ts/lib/Array";
import { SavePersonalInfoAction } from "../../actions/generic/meta.actions";
// import {ownDataUserSelect} from "../../selectors/app.selectors";
import { SaveListUsersAction } from "../../actions/generic/users.actions";
import {
    SaveDateUpdateDiscoversAction,
    SaveDateUpdateRoomsAction,
    SaveDateUpdateUsersAction,
} from "../../actions/generic/dates_updates.actions";
import {
    AddListenerChatMessageRoomAction,
    AddListenerChatMessageUserAction,
    AddListenerConfirmationFileAction,
    AddListenerDisconnectedAction,
    AddListenerDiscoverDeletedAction,
    AddListenerDiscoverModifiedAction,
    AddListenerJoinedRoomAction,
    AddListenerNewDiscoverAction,
    AddListenerNewUserAction,
    AddListenerOneToOneDeletedAction,
    AddListenerOtoMessageModifiedAction,
    AddListenerOTOReadedMessagesAction,
    AddListenerQuitRoomAction,
    AddListenerRoomAdminsAction,
    AddListenerRoomMessageModifiedAction,
    AddListenerRoomModifiedAction,
    AddListenerRoomReadedMessagesAction,
    AddListenerUserDeletedAction,
    AddListenerUserModifiedAction,
} from "../../actions/sockets/sockets.actions";
import { RoomsRepository } from "../../../../repositories/rooms/rooms.repository";
import { SaveListRoomsAction } from "../../actions/generic/rooms.actions";
import { DiscoversRepository } from "../../../../repositories/discovers/discovers.repository";
import { SaveListDiscoversAction } from "../../actions/generic/discovers.actions";
import { FirstAppWithConnectionState } from "../../state/namespaces/app_state_namespace.state";
import { MetaStateOwnUserDataSelect } from "../../selectors/generic/meta.selectors";
import { MetaFacade } from "../../facade/generic/meta.facade";
import { NetworkService } from "../../../../connectionhandler/network.service";
import { nilAction } from "../../actions/app.actions";
import {
    ChangedConnectionOfflineServerAction,
    ChangedConnectionOnlineLocalAction,
    ChangedConnectionOnlineServerAction,
    SetAllDataLoadedAction,
    SetFirstAppWithConnectionStateAction,
} from "../../actions/namespaces/app_state_namespace.actions";
import { ResourcesRepository } from "../../../../repositories/meta/resources.repository";
import {
    SaveBlobChatEditGroupAction,
    SaveBlobChatLoadMoreGalleryAction,
    SaveBlobDiscoversCheckAction,
    SaveBlobDiscoversIconAction,
    SaveBlobMessagesErrorIconAction,
    SaveBlobMessagesNotSendedNotSynchronizedIconAction,
    SaveBlobMessagesPdfPlaceholderAction,
    SaveBlobMessagesSendedNotSynchronizedIconAction,
    SaveBlobMessagesSendedSynchronizedIconAction,
    SaveBlobMessagesVideosPlaceholderAction,
    SaveBlobMetaNoConnectionAction,
    SaveBlobMetaNoImageProfileAction,
    SaveBlobRetryIconAction,
    SaveBlobScriptsNotificationSidebarAction,
} from "../../actions/namespaces/resources_namespace.action";
import { of, zip } from "rxjs";
import { ExecuteInitialAllProcessesAction } from "../../actions/principal_screen_app/initial_processes_execution.actions";
import { LoadUserListAllRoomsAction } from "../../actions/app_with_connection/meta.actions";

@Injectable({
    providedIn: "root",
})
export class MetaEffects {
    loadApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadAppAction),
            switchMap((_) => [LoadResourcesAction()])
        )
    );

    // loadAppFail$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(LoadAppErrorAction),
    //         flatMap(error => [])
    //     )
    // );

    loadAppSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadAppSuccessAction),
            switchMap((_) => [
                LoadInitNetworkClientAction(),
                SetAllDataLoadedAction({ all_data_loaded: true }),
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.COMPLETED,
                    error: O.none,
                }),
                ExecuteInitialAllProcessesAction(),
            ])
        )
    );

    loadResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadResourcesAction),
            switchMap((_) => this.resourcesRepository.getLocalResourcePack()),
            switchMap(
                E.fold(
                    (err) =>
                        [LoadResourcesErrorAction({ error: err })] as Action[],
                    (pack) => [LoadResourcesSuccessAction({ resources: pack })]
                )
            )
        )
    );

    loadResourcesError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadResourcesErrorAction),
            switchMap(({ error }) => [
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.RESOURCES_LOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadResourcesSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadResourcesSuccessAction),
            switchMap(({ resources }) => [
                SaveBlobMetaNoImageProfileAction({
                    image: resources.meta_no_image_profile,
                }),
                SaveBlobMetaNoConnectionAction({
                    image: resources.meta_no_connection,
                }),
                SaveBlobDiscoversIconAction({
                    image: resources.discovers_icon,
                }),
                SaveBlobDiscoversCheckAction({
                    image: resources.discovers_check,
                }),
                SaveBlobChatLoadMoreGalleryAction({
                    image: resources.chat_load_more_gallery,
                }),
                SaveBlobChatEditGroupAction({
                    image: resources.chat_edit_group,
                }),
                SaveBlobMessagesNotSendedNotSynchronizedIconAction({
                    image: resources.messages_not_synchronized_not_sended_icon,
                }),
                SaveBlobMessagesSendedNotSynchronizedIconAction({
                    image: resources.messages_synchronized_not_sended_icon,
                }),
                SaveBlobMessagesSendedSynchronizedIconAction({
                    image: resources.messages_synchronized_sended_icon,
                }),
                SaveBlobMessagesErrorIconAction({
                    image: resources.messages_error_icon,
                }),
                SaveBlobMessagesPdfPlaceholderAction({
                    image: resources.messages_pdf_placeholder,
                }),
                SaveBlobScriptsNotificationSidebarAction({
                    image: resources.scripts_notification_sidebar,
                }),
                SaveBlobMessagesVideosPlaceholderAction({
                    image: resources.messages_videos_placeholder,
                }),
                SaveBlobRetryIconAction({ image: resources.meta_retry_icon }),
                LoadPersonalInfoAction(),
            ])
        )
    );

    loadPersonalInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadPersonalInfoAction),
            switchMap((_) =>
                this.metaRepository.getOwnData().pipe(
                    switchMap(
                        E.fold(
                            (err) => of(E.left(err)),
                            (own) =>
                                this.userRepository
                                    .getImageProfileFromUserByIdUser(own.idUser)
                                    .pipe(
                                        map((opt) =>
                                            E.right({
                                                ownData: own,
                                                image: opt,
                                            })
                                        )
                                    )
                        )
                    )
                )
            ),
            switchMap(
                E.fold(
                    (err) =>
                        [
                            LoadPersonalInfoErrorAction({ error: err }),
                        ] as Action[],
                    ({ ownData, image }) => [
                        LoadPersonalInfoSuccessAction({
                            info: ownData,
                            image: image,
                        }),
                    ]
                )
            )
        )
    );

    loadPersonalInfoError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadPersonalInfoErrorAction),
            // tap(erro => console.log("PERSONAL INFO ERROR")),
            switchMap(({ error }) => [
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.PERSONAL_INFO_LOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadPersonalInfoSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadPersonalInfoSuccessAction),
            switchMap(({ info, image }) => [
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.PERSONAL_INFO_LOADED,
                    error: O.none,
                }),
                SavePersonalInfoAction({ info: info, image: image }),
                LoadOpenSocketAction(),
            ])
        )
    );

    loadOpenSocket$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadOpenSocketAction),
            switchMap((_) => this.metaFacade.getOwnDataUser()),
            switchMap((ownDataUser) =>
                this.socketFacade.startSocket(
                    ownDataUser.idClient,
                    ownDataUser.idUser
                )
            ),
            switchMap(
                E.fold(
                    (err) => [LoadOpenSocketErrorAction({ error: err })],
                    (_) => [LoadOpenSocketSucccessAction()]
                )
            )
        )
    );

    loadOpenSocketError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadOpenSocketErrorAction),
            switchMap(({ error }) => [
                // ConnectionChangedAction({with_connection: false}),
                ChangedConnectionOfflineServerAction(),
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.SOCKET_OPEN,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadOpenSocketSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadOpenSocketSucccessAction),
            switchMap((_) => [
                // ConnectionChangedAction({with_connection: true}),
                ChangedConnectionOnlineServerAction(),
                AddListenerDisconnectedAction(),
                LoadInitFirebaseAction(),
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.SOCKET_OPEN,
                    error: O.none,
                }),
            ])
        )
    );

    loadInitFirebase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadInitFirebaseAction),
            switchMap((_) => this.fcmFacade.initFcm()),
            switchMap(
                E.fold(
                    (error) => [LoadInitFirebaseErrorAction({ error: error })],
                    (_) => [LoadInitFirebaseSuccessAction()]
                )
            )
        )
    );

    loadInitFirebaseError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadInitFirebaseErrorAction),
            switchMap(({ error }) => [
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.INIT_FIREBASE,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadInitFirebaseSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadInitFirebaseSuccessAction),
            switchMap((_) => [
                LoadListUsersAction(),
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.INIT_FIREBASE,
                    error: O.none,
                }),
            ])
        )
    );

    loadListUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListUsersAction),
            switchMap((_) => this.userRepository.getAllUsers()),
            switchMap(
                E.fold(
                    (error) =>
                        [
                            LoadListUsersErrorAction({ error: error }),
                        ] as Action[],
                    (users) => [LoadListUsersSuccessAction({ users: users })]
                )
            )
        )
    );

    loadListUsersError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListUsersErrorAction),
            switchMap(({ error }) => [
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.USERS_LOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadListUsersSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListUsersSuccessAction),
            switchMap(({ users }) => [
                SaveListUsersAction({ users: users }),
                SaveDateUpdateUsersAction({ date: new Date() }),
                AddListenerUserModifiedAction(),
                AddListenerNewUserAction(),
                AddListenerUserDeletedAction(),
                LoadListRoomsAction(),
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.USERS_LOADED,
                    error: O.none,
                }),
            ])
        )
    );

    loadListRooms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListRoomsAction),
            switchMap((_) => this.roomRepository.getRoomsAll()),
            switchMap(
                E.fold(
                    (err) =>
                        [LoadListRoomsErrorAction({ error: err })] as Action[],
                    ({ rooms, otos }) => [
                        LoadListRoomsSuccessAction({
                            rooms: rooms,
                            otos: otos,
                        }),
                    ]
                )
            )
        )
    );

    loadListRoomsError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListRoomsErrorAction),
            switchMap(({ error }) => [
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.ROOMS_LOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadListRoomsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListRoomsSuccessAction),
            switchMap(({ rooms, otos }) => [
                SaveListRoomsAction({ rooms: rooms, otos: otos }),
                SaveDateUpdateRoomsAction({ date: new Date() }),
                LoadUserListAllRoomsAction(),
                AddListenerChatMessageRoomAction(),
                AddListenerChatMessageUserAction(),
                AddListenerJoinedRoomAction(),
                AddListenerQuitRoomAction(),
                AddListenerRoomModifiedAction(),
                AddListenerRoomReadedMessagesAction(),
                AddListenerOTOReadedMessagesAction(),
                AddListenerRoomAdminsAction(),
                AddListenerOneToOneDeletedAction(),
                AddListenerRoomMessageModifiedAction(),
                AddListenerOtoMessageModifiedAction(),
                AddListenerConfirmationFileAction(),
                LoadListDiscoversAction(),
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.ROOMS_LOADED,
                    error: O.none,
                }),
            ])
        )
    );

    loadListDiscovers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListDiscoversAction),
            switchMap((_) => this.discoverRepository.getAllDiscovers()),
            switchMap(
                E.fold(
                    (error) =>
                        [
                            LoadListDiscoversErrorAction({ error: error }),
                        ] as Action[],
                    (discovers) => [
                        LoadListDiscoversSuccessAction({
                            discovers: discovers,
                        }),
                    ]
                )
            )
        )
    );

    loadListDiscoversError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListDiscoversErrorAction),
            switchMap(({ error }) => [
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.DISCOVERS_LOADED,
                    error: O.some(error),
                }),
            ])
        )
    );

    loadListDiscoversSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadListDiscoversSuccessAction),
            switchMap(({ discovers }) => [
                SaveListDiscoversAction({ discovers: discovers }),
                SaveDateUpdateDiscoversAction({ date: new Date() }),
                AddListenerNewDiscoverAction(),
                AddListenerDiscoverModifiedAction(),
                AddListenerDiscoverDeletedAction(),
                SetFirstAppWithConnectionStateAction({
                    state: FirstAppWithConnectionState.DISCOVERS_LOADED,
                    error: O.none,
                }),
                LoadAppSuccessAction(),
            ])
        )
    );

    loadInitNetworkClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadInitNetworkClientAction),
            switchMap((_) => this.networkService.initNetworkHandler()),
            endWith(nilAction())
        )
    );

    // flatMap(_ => this.store.select(ownDataUserSelect).pipe(take(1))),
    // flatMap(ownDataUser => concat(
    //         this.socketFacade.initSockets(ownDataUser.idClient).pipe(
    //         catchError(error => {
    //     if (error instanceof ConnectErrorSocketException)
    //     return this.appFacade.dispatchSocketConnectionError(error);
    //     throw error;
    // }),
    // map(_ => openSocketSuccess())
    // ),
    // of(openSocketSuccess())
    // )
    // )

    // LOAD_APP: TAG + 'Load App',
    // LOAD_APP_FAIL: TAG + 'Load App Fail',
    // LOAD_APP_SUCCESS: TAG + 'Load App Success',
    // // Activar:
    // //  - GenericAppStateSetAllDataLoaded a true
    //
    // LOAD_PERSONAL_INFO: TAG + 'Load Personal Info',
    // LOAD_PERSONAL_INFO_ERROR: TAG + 'Load Personal Info Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction amb el state de personal i el error
    // LOAD_PERSONAL_INFO_SUCCESS: TAG + 'Load Personal Info Success', // <-- aquest activa SAVE_PERSONAL_INFO i modifica progrés de l'aplicació
    // // Activar:
    // //  - SAVE_PERSONAL_INFO
    // //  - SetFirstAppWithConnectionStateAction
    //
    // LOAD_OPEN_SOCKET: TAG + 'Load Open Socket',
    // LOAD_OPEN_SOCKET_ERROR: TAG + 'Load Open Socket Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // LOAD_OPEN_SOCKET_SUCCESS: TAG + 'Load Open Socket Success',// <-- aquest activa les altres tres accions i modifica progrés aplicació, llença també següent estat
    // // Activar:
    // //  - GenericConnectionConnectionChangedAction a true
    // //  - SetFirstAppWithConnectionStateAction
    //
    // LOAD_LIST_USERS: TAG + 'Load List Users',
    // LOAD_LIST_USERS_ERROR: TAG + 'Load List Users Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // LOAD_LIST_USERS_SUCCESS: TAG + 'Load List Users Success',
    // // Activar:
    // //  - SAVE_LIST_USERS
    // //  - GenericDatesUpdatesSaveDateUpdateUsersAction
    // //  - ADD_LISTENER_USER_MODIFIED
    // //  - ADD_LISTENER_NEW_USER
    // //  - SetFirstAppWithConnectionStateAction
    //
    //
    // LOAD_LIST_ROOMS: TAG + 'Load List Rooms',
    // LOAD_LIST_ROOMS_ERROR: TAG + 'Load List Rooms Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // LOAD_LIST_ROOMS_SUCCESS: TAG + 'Load List Rooms Success',
    // // Activar:
    // //  - SAVE_LIST_ROOMS
    // //  - GenericDatesUpdatesSaveDataUpdateRoomsAction
    // //  - ADD_LISTENER_CHAT_MESSAGE_ROOM
    // //     ADD_LISTENER_CHAT_MESSAGE_USER
    // //     ADD_LISTENER_JOINED_ROOM
    // //     ADD_LISTENER_QUIT_ROOM
    // //     ADD_LISTENER_ROOM_MODIFIED
    // //     ADD_LISTENER_ROOM_READED_MESSAGES
    // //     ADD_LISTENER_OTO_READED_MESSAGES
    // //  - SetFirstAppWithConnectionStateAction
    //
    // LOAD_LIST_DISCOVERS: TAG + 'Load List Discovers',
    // LOAD_LIST_DISCOVERS_ERROR: TAG + 'Load List Discovers Error',
    // // Activar:
    // //  - SetFirstAppWithConnectionStateAction
    // LOAD_LIST_DISCOVERS_SUCCESS: TAG + 'Load List Discovers Success',
    // // Activar:
    // //  - SAVE_LIST_DISCOVERS
    // //  - GenericDatesUpdatesSaveDataUpdateDiscoversAction
    // //  - SetFirstAppWithConnectionStateAction

    // logout$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(logout),
    //         flatMap(_ => this.fcmFacade.proceedLogout()),
    //         // endWith(this.fcmFacade.getDeviceId()),
    //         // flatMap(o => o as Observable<string>),
    //         // flatMap(_ => this.fcmFacade.getDeviceId()),
    //         flatMap(_ => this.metaRepository.logout()),
    //         // flatMap(res => this.)
    //         catchError(err => {
    //             // console.log({text: "error catched onMessageRoomImageToLoadEffect$", error: err});
    //             if (err instanceof HttpErrorResponse) {
    //                 return of(new AppError(JSON.stringify(err.error)));
    //             } else if (err instanceof AppError) {
    //                 return of(err);
    //             }
    //             throw err;
    //         }),
    //         map(res => {
    //             if (res instanceof AppError) {
    //                 return logoutFail({error: res});
    //             } else {
    //                 return logoutSuccess();
    //             }
    //         })
    //     )
    // );

    constructor(
        private actions$: Actions,
        private metaRepository: MetaRepository,
        private userRepository: UsersRepository,
        private store: Store<IAppState>,
        private socketFacade: SocketFacade,
        private appFacade: AppFacade,
        private fcmFacade: FcmFacade,
        private httpHeadersManager: HttpHeadersManager,
        private roomRepository: RoomsRepository,
        private discoverRepository: DiscoversRepository,
        private metaFacade: MetaFacade,
        private networkService: NetworkService,
        private resourcesRepository: ResourcesRepository
    ) {}
}
