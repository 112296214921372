import {createAction, props} from '@ngrx/store';
import {MessageRoom} from '../../../domain/MessageRoom';
import {MessageOTO} from '../../../domain/MessageOTO';
import {AppError} from '../../remote/globalmodels/AppError';
import {StandardError} from "../../../domain/StandardError";
import {Option} from "fp-ts/lib/Option";
import {create} from "domain";
import {AckMsgSocketModel} from "../../../realtime/models/AckMsgSocketModel";


export const MessageActions = {
    // LOAD_FIRSTS_MESSAGES_ALL_ROOMS: '[Message Room] Load Firsts Messages All Rooms',
    // LOAD_FIRSTS_MESSAGES_ALL_ROOMS_SUCCESS: '[Message Room] Load Firsts Messages All Rooms Success',
    // LOAD_FIRSTS_MESSAGES_ALL_OTOS: '[Message OTO] Load Firsts Messages All OTOS',
    // LOAD_FIRSTS_MESSAGES_ALL_OTOS_SUCCESS: '[Message OTO] Load Firsts Messages All OTOS Success',

    ON_NEW_MESSAGE_ROOM: '[Message Room] On New Message Room',
    ON_NEW_MODIFICATION_MESSAGE_ROOM: '[Message Room] On New Modification Message Room',

    ON_DELETE_MESSAGE_ROOM: '[Message Room] On Delete Message Room',
    ON_DELETE_MESSAGE_ROOM_SUCCESS: '[Message Room] On Delete Message Room Success',
    ON_DELETE_MESSAGE_ROOM_FAIL: '[Message Room] On Delete Message Room Fail',

    ON_DELETE_MESSAGE_ROOM_LOCAL: '[Message Room] On Delete Message Room Local',


    // LOAD_FIRST_PAGE_MESSAGES_ROOM: '[Message Room] Load First Page Messages Room',
    // LOAD_FIRST_PAGE_MESSAGES_ROOM_SUCCESS: '[Message Room] Load First Page Messages Room Success',


    LOAD_NEXT_PAGE_MESSAGES_ROOM: '[Message Room] Load Next Page Messages Room',
    LOAD_NEXT_PAGE_MESSAGES_ROOM_SUCCESS: '[Message Room] Load Next Page Messages Room Success',
    // LOAD_NEXT_PAGE_MESSAGES_ROOM_FAIL: '[Message Room] Load Next Page Messages Room Fail',
    CLEAN_NEXT_PAGE_MESSAGES_ROOM: '[Message Room] Clean Next Page Messages Room',

    ON_SEND_MESSAGE_ROOM: '[Message Room] On Send Message Room',
    ON_SEND_MESSAGE_ROOM_SUCCESS: '[Message Room] On Send Message Room Success',
    ON_SEND_MESSAGE_ROOM_FAIL: '[Message Room] On Send Message Room Fail',


    ON_SEND_MESSAGE_ROOM_FILE: '[Message Room] On Send Message Room File',
    ON_SEND_MESSAGE_ROOM_FILE_SUCCESS: '[Message Room] On Send Message Room File Success',
    ON_SEND_MESSAGE_ROOM_FILE_FAIL: '[Message Room] On Send Message Room File Fail',


    ON_MESSAGE_ROOM_IMAGE_TO_LOAD: '[Message Room] On Message Room Image To Load',
    ON_MESSAGE_ROOM_IMAGE_TO_LOAD_SUCCESS: '[Message Room] On Message Room Image To Load Success',
    ON_MESSAGE_ROOM_IMAGE_TO_LOAD_FAIL: '[Message Room] On Message Room Image To Load Fail',

    LOAD_REMAIN_MESSAGES_ROOM: '[Message Room] Load Remain Messages Room',
    LOAD_REMAIN_MESSAGES_ROOM_SUCCESS: '[Message Room] Load Remain Messages Room Success',

    CLEAN_MESSAGES_TO_READ_ROOM: '[Message Room] Clean Messages To Read Room',

    LOAD_FROM_DATE_MESSAGE_ROOM: '[Message Room] Load From Date Message Room',
    LOAD_FROM_DATE_MESSAGE_ROOM_SUCCESS: '[Message Room] Load From Date Message Room Success',




    ON_NEW_MESSAGE_OTO: '[Message OTO] On New Message OTO',
    ON_NEW_MODIFICATION_MESSAGE_OTO: '[Message OTO] On New Modification Message OTO',

    ON_DELETE_MESSAGE_OTO: '[Message OTO] On Delete Message OTO',
    ON_DELETE_MESSAGE_OTO_SUCCESS: '[Message OTO] On Delete Message OTO Success',
    ON_DELETE_MESSAGE_OTO_FAIL: '[Message OTO] On Delete Message OTO Fail',

    ON_DELETE_MESSAGE_OTO_LOCAL: '[Message OTO] On Delete Message OTO Local',
    // onNewModificationMessageOTO
    // ON_NEW_MESSAGE_OTO_SUCCESS: '[Message OTO] On New Message OTO Success',


    // LOAD_FIRST_PAGE_MESSAGES_OTO: '[Message OTO] Load First Page Messages OTO',
    // LOAD_FIRST_PAGE_MESSAGES_OTO_SUCCESS: '[Message OTO] Load First Page Messages OTO Success',

    LOAD_NEXT_PAGE_MESSAGES_OTO: '[Message OTO] Load Next Page Messages OTO',
    LOAD_NEXT_PAGE_MESSAGES_OTO_SUCCESS: '[Message OTO] Load Next Page Messages OTO Success',
    CLEAN_NEXT_PAGE_MESSAGES_OTO: '[Message OTO] Clean Next Page Messages OTO',

    ON_SEND_MESSAGE_OTO: '[Message OTO] On Send Message OTO',
    ON_SEND_MESSAGE_OTO_SUCCESS: '[Message OTO] On Send Message OTO Success',
    ON_SEND_MESSAGE_OTO_FAIL: '[Message OTO] On Send Message OTO Fail',

    ON_SEND_MESSAGE_OTO_FILE: '[Message OTO] On Send Message OTO File',
    ON_SEND_MESSAGE_OTO_FILE_SUCCESS: '[Message OTO] On Send Message OTO File Success',
    ON_SEND_MESSAGE_OTO_FILE_FAIL: '[Message OTO] On Send Message OTO File Fail',

    ON_MESSAGE_OTO_IMAGE_TO_LOAD: '[Message Room] On Message OTO Image To Load',
    ON_MESSAGE_OTO_IMAGE_TO_LOAD_SUCCESS: '[Message Room] On Message OTO Image To Load Success',
    ON_MESSAGE_OTO_IMAGE_TO_LOAD_FAIL: '[Message Room] On Message OTO Image To Load Fail',


    LOAD_FROM_DATE_MESSAGE_OTO: '[Message OTO] Load From Date Message OTO',
    LOAD_FROM_DATE_MESSAGE_OTO_SUCCESS: '[Message OTO] Load From Date Message OTO Success',

    RETRY_SEND_MESSAGE_OTO: '[Message OTO] Retry Send Message OTO',
    RETRY_SEND_MESSAGE_ROOM: '[Message Room] Retry Send Message Room',

    CANCEL_SEND_FILE_MESSAGE_ROOM: '[Message Room] Cancel Send File Message Room',
    CANCEL_SEND_FILE_MESSAGE_OTO: '[Message OTO] Cancel Send File Message OTO',

    ON_ACK_MESSAGE_RECEIVED: '[Message Room] On Ack Message Received',

};

// export const CancelSendFileMessageRoomAction = createAction()
// export const CancelSendFileMessageOTOAction = createAction()


// export const loadFirstsMessagesAllRooms = createAction(MessageActions.LOAD_FIRSTS_MESSAGES_ALL_ROOMS);
// export const loadFirstsMessagesAllRoomsSuccess = createAction(
//     MessageActions.LOAD_FIRSTS_MESSAGES_ALL_ROOMS_SUCCESS,
//     props<{messages: MessageRoom[]}>()
    // props<{groups: {idRoom: number, messages: MessageRoom[]}[]}>()
// );


// export const loadFirstsMessagesAllOTOS = createAction(MessageActions.LOAD_FIRSTS_MESSAGES_ALL_OTOS);
// export const loadFirstsMessagesAllOTOSSuccess = createAction(
//     MessageActions.LOAD_FIRSTS_MESSAGES_ALL_OTOS_SUCCESS,
//     props<{otos: {idUser: number, messages: MessageOTO[]}[]}>()
// );

export const onMessageRoomImageToLoadSuccess = createAction(
    MessageActions.ON_MESSAGE_ROOM_IMAGE_TO_LOAD_SUCCESS,
    props<{idMessage: string, image: Option<Blob>}>()
);

export const onMessageRoomImageToLoadFail = createAction(
    MessageActions.ON_MESSAGE_ROOM_IMAGE_TO_LOAD_FAIL,
    props<{idMessage: string, error: StandardError}>()
);



// --------------------------------------------------------------------------------------------------
// --------------------------------------------- GROUPS ---------------------------------------------
// --------------------------------------------------------------------------------------------------

// export const loadFirstPageMessagesGroup = createAction(
//     MessageActions.LOAD_FIRST_PAGE_MESSAGES_ROOM,
//     props<{idRoom: number}>()
// );
//
// export const loadFirstPageMessagesGroupSuccess = createAction(
//     MessageActions.LOAD_FIRST_PAGE_MESSAGES_ROOM_SUCCESS,
//     props<{idRoom: number, messages: MessageRoom[]}>()
// );

export const onNewMessageRoom = createAction(
    MessageActions.ON_NEW_MESSAGE_ROOM,
    props<{message: MessageRoom}>()
);
// ON_NEW_MODIFICATION_MESSAGE_ROOM
export const onNewModificationMessageRoomAction = createAction(
    MessageActions.ON_NEW_MODIFICATION_MESSAGE_ROOM,
    props<{message: MessageRoom}>()
);

export const onDeleteMessageRoomAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_ROOM,
    props<{idRoom: number, idMessage: string}>()
);
export const onDeleteMessageRoomSuccessAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_ROOM_SUCCESS,
    props<{message: MessageRoom}>()
);
export const onDeleteMessageRoomFailAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_ROOM_FAIL,
    props<{idRoom: number, idMessage: string}>()
);

export const onDeleteMessageRoomLocalAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_ROOM_LOCAL,
    props<{idRoom: number, idMessage: string}>()
)
// ON_DELETE_MESSAGE_ROOM: '[Message Room] On Delete Message Room',
// ON_DELETE_MESSAGE_ROOM_SUCCESS: '[Message Room] On Delete Message Room Success',


export const onSendMessageRoom = createAction(
    MessageActions.ON_SEND_MESSAGE_ROOM,
    props<{message: MessageRoom}>()
);

export const onSendMessageRoomSuccess = createAction(
    MessageActions.ON_SEND_MESSAGE_ROOM_SUCCESS,
    props<{message: MessageRoom}>()
);

export const onSendMessageRoomFail = createAction(
    MessageActions.ON_SEND_MESSAGE_ROOM_FAIL,
    props<{error: StandardError, message: MessageRoom}>()
)




export const onSendMessageRoomFile = createAction(
    MessageActions.ON_SEND_MESSAGE_ROOM_FILE,
    // props<{text: string, file: Blob, thumbnail: string, idRoom: number}>()
    props<{message: MessageRoom, file: Blob, base64Thumbnail: string}>()
);

export const onSendMessageRoomFileSuccess = createAction(
    MessageActions.ON_SEND_MESSAGE_ROOM_FILE_SUCCESS,
    props<{message: MessageRoom}>()
);

export const onSendMessageRoomFileFail = createAction(
    MessageActions.ON_SEND_MESSAGE_ROOM_FILE_FAIL,
    props<{error: StandardError, message: MessageRoom}>()
);

export const loadNextPageMessagesRoom = createAction(
    MessageActions.LOAD_NEXT_PAGE_MESSAGES_ROOM,
    props<{idRoom: number, nbPages: number}>()
);
export const loadNextPageMessagesRoomSuccess = createAction(
    MessageActions.LOAD_NEXT_PAGE_MESSAGES_ROOM_SUCCESS,
    props<{idRoom: number, messages: MessageRoom[]}>()
);

export const cleanNextPageMessagesRoom = createAction(
    MessageActions.CLEAN_NEXT_PAGE_MESSAGES_ROOM,
    props<{idRoom: number}>()
);

export const onMessageRoomImageToLoad = createAction(
    MessageActions.ON_MESSAGE_ROOM_IMAGE_TO_LOAD,
    props<{idMessage: string, idRoom: number}>()
);
// LOAD_REMAIN_MESSAGES_ROOM: '[Message Room] Load Remain Messages Room',
//     LOAD_REMAIN_MESSAGES_ROOM_SUCCESS: '[Message Room] Load Remain Messages Room Success',
export const LoadRemainMessagesRoomAction = createAction(
    MessageActions.LOAD_REMAIN_MESSAGES_ROOM,
    props<{idRoom: number}>()
);
export const LoadRemainMessagesRoomSuccessAction = createAction(
    MessageActions.LOAD_REMAIN_MESSAGES_ROOM_SUCCESS,
    props<{idRoom: number, messages: MessageRoom[]}>()
);
//CLEAN_MESSAGES_TO_READ_ROOM
export const CleanMessagesToReadRoomAction = createAction(
    MessageActions.CLEAN_MESSAGES_TO_READ_ROOM
);

// LOAD_FROM_DATE_MESSAGE_ROOM: '[Message Room] Load From Date Message Room',
//     LOAD_FROM_DATE_MESSAGE_ROOM_SUCCESS: '[Message Room] Load From Date Message Room Success',
export const LoadFromDateMessageRoomAction = createAction(
    MessageActions.LOAD_FROM_DATE_MESSAGE_ROOM,
    props<{idRoom: number, date: Date}>()
);
export const LoadFromDateMessageRoomSuccessAction = createAction(
    MessageActions.LOAD_FROM_DATE_MESSAGE_ROOM_SUCCESS,
    props<{idRoom: number, messages: MessageRoom[]}>()
);


// --------------------------------------------------------------------------------------------------
// --------------------------------------------- OTOS -----------------------------------------------
// --------------------------------------------------------------------------------------------------

// export const loadFirstPageMessagesOTO = createAction(
//     MessageActions.LOAD_FIRST_PAGE_MESSAGES_OTO,
//     props<{idUser: number}>()
// );
//
// export const loadFirstPageMessagesOTOSuccess = createAction(
//     MessageActions.LOAD_FIRST_PAGE_MESSAGES_OTO_SUCCESS,
//     props<{idUser: number, messages: MessageOTO[]}>()
// );

export const onNewMessageOTO = createAction(
    MessageActions.ON_NEW_MESSAGE_OTO,
    props<{message: MessageOTO}>()
);
export const onNewModificationMessageOTOAction = createAction(
    MessageActions.ON_NEW_MODIFICATION_MESSAGE_OTO,
    props<{message: MessageOTO}>()
);

export const onDeleteMessageOTOAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_OTO,
    props<{idUser: number, idMessage: string}>()
);
export const onDeleteMessageOTOSuccessAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_OTO_SUCCESS,
    props<{message: MessageOTO}>()
);
export const onDeleteMessageOTOFailAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_OTO_FAIL,
    props<{idUser: number, idMessage: string}>()
);
export const onDeleteMessageOTOLocalAction = createAction(
    MessageActions.ON_DELETE_MESSAGE_OTO_LOCAL,
    props<{idUser: number, idMessage: string}>()
)
// export const onNewMessageOTOSuccess = createAction(
//     MessageActions.ON_NEW_MESSAGE_OTO_SUCCESS,
//     props<{message: MessageOTO}>()
// );

export const onSendMessageOTO = createAction(
    MessageActions.ON_SEND_MESSAGE_OTO,
    props<{message: MessageOTO}>()
);

export const onSendMessageOTOSuccess = createAction(
    MessageActions.ON_SEND_MESSAGE_OTO_SUCCESS,
    props<{message: MessageOTO}>()
);

export const onSendMessageOTOFail = createAction(
    MessageActions.ON_SEND_MESSAGE_OTO_FAIL,
    props<{error: StandardError, message: MessageOTO}>()
);

export const onSendMessageOTOFile = createAction(
    MessageActions.ON_SEND_MESSAGE_OTO_FILE,
    // props<{text: string, file: Blob, thumbnail: string, idUser: number}>()
    props<{message: MessageOTO, file: Blob, base64Thumbnail: string}>()
);

export const onSendMessageOTOFileSuccess = createAction(
    MessageActions.ON_SEND_MESSAGE_OTO_FILE_SUCCESS,
    // props<{idMessage: string}>()
    props<{message: MessageOTO}>()
);

export const onSendMessageOTOFileFail = createAction(
    MessageActions.ON_SEND_MESSAGE_OTO_FILE_FAIL,
    props<{error: StandardError, message: MessageOTO}>()
);

export const loadNextPageMessagesOTO = createAction(
    MessageActions.LOAD_NEXT_PAGE_MESSAGES_OTO,
    props<{idUser: number, nbPages: number}>()
);

export const loadNextPageMessagesOTOSuccess = createAction(
    MessageActions.LOAD_NEXT_PAGE_MESSAGES_OTO_SUCCESS,
    props<{idUser: number, messages: MessageOTO[]}>()
);

export const cleanNextPageMessagesOTO = createAction(
    MessageActions.CLEAN_NEXT_PAGE_MESSAGES_OTO,
    props<{idUser: number}>()
);

export const onMessageOTOImageToLoad = createAction(
    MessageActions.ON_MESSAGE_OTO_IMAGE_TO_LOAD,
    props<{idMessage: string, idUser: number}>()
);

export const LoadFromDateMessageOTOAction = createAction(
    MessageActions.LOAD_FROM_DATE_MESSAGE_OTO,
    props<{idUserOTO: number, date: Date}>()
);
export const LoadFromDateMessageOTOSuccessAction = createAction(
    MessageActions.LOAD_FROM_DATE_MESSAGE_OTO_SUCCESS,
    props<{idUserOTO: number, messages: MessageOTO[]}>()
);
export const RetrySendMessageOTOAction = createAction(
    MessageActions.RETRY_SEND_MESSAGE_OTO,
    props<{idUserOTO: number, internalId: string}>()
);
export const RetrySendMessageRoomAction = createAction(
    MessageActions.RETRY_SEND_MESSAGE_ROOM,
    props<{idRoom: number, internalId: string}>()
)

export const CancelSendFileMessageRoomAction = createAction(
    MessageActions.CANCEL_SEND_FILE_MESSAGE_ROOM,
    props<{idRoom: number, internalId: string}>()
);

export const CancelSendFileMessageOTOAction = createAction(
    MessageActions.CANCEL_SEND_FILE_MESSAGE_OTO,
    props<{idUserOTO: number, internalId: string}>()
)

export const OnAckMessageReceivedAction = createAction(
    MessageActions.ON_ACK_MESSAGE_RECEIVED,
    props<{ack: AckMsgSocketModel}>()
);



//
//     LOAD_FROM_DATE_MESSAGE_OTO: '[Message OTO] Load From Date Message OTO',
//     LOAD_FROM_DATE_MESSAGE_OTO_SUCCESS: '[Message OTO] Load From Date Message OTO Success'


// export const onMessageOTOImageToLoadSuccess = createAction(
//     MessageActions.ON_MESSAGE_OTO_IMAGE_TO_LOAD_SUCCESS,
//     props<{idMessage: string, image: Blob, idUser: number}>()
// );
//
// export const onMessageOTOImageToLoadFail = createAction(
//     MessageActions.ON_MESSAGE_OTO_IMAGE_TO_LOAD_FAIL,
//     props<{idMessage: string, error: AppError, idUser: number}>()
// );



// export const onSendMessageRoomFile = createAction(
//     MessageActions.ON_SEND_MESSAGE_ROOM_FILE,
//     props<{text: string, file: Blob, thumbnail: string, idRoom: number}>()
// );
//
// export const onSendMessageRoomFileSuccess = createAction(
//     MessageActions.ON_SEND_MESSAGE_ROOM_FILE_SUCCESS,
//     props<{idMessage: string}>()
// );
