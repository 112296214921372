import {AppError} from '../../remote/globalmodels/AppError';
import {Option} from "fp-ts/lib/Option";
import {StandardError} from "../../../domain/StandardError";


export interface ResponsesState {
    readonly createRoomLastResponse: {error: Option<StandardError>} | undefined,
    readonly getImageMessageRoom: {image: Option<Blob>, error: Option<StandardError>} | undefined,
    readonly addUsersToRoomLastResponse: {error: Option<StandardError> } | undefined,
    readonly leaveRoomGroupLastResponse: {error: Option<StandardError>} | undefined,
    readonly leaveRoomOTOLastResponse: {error: Option<StandardError>} | undefined,
    readonly changeOwnDataUser: {error: Option<StandardError>} | undefined,
    readonly openOneToOneResponse: {error: Option<StandardError>} | undefined,
    // readonly sendFileRoomGroupResponse: {error: Option<StandardError>} | undefined,
    // readonly sendFileRoomOTOResponse: {error: Option<StandardError>} | undefined,
    readonly loadNextPageFilesResponse: {complete: boolean, error: Option<StandardError>} | undefined,
    readonly logoutResponse: {error: Option<StandardError>} | undefined,
    readonly modifyRoomInfoResponse: {error: Option<StandardError>} | undefined
}


export const initialResponsesState : ResponsesState = {
    createRoomLastResponse: undefined,
    getImageMessageRoom: undefined,
    addUsersToRoomLastResponse: undefined,
    leaveRoomGroupLastResponse: undefined,
    leaveRoomOTOLastResponse: undefined,
    changeOwnDataUser: undefined,
    openOneToOneResponse: undefined,
    // sendFileRoomGroupResponse: undefined,
    // sendFileRoomOTOResponse: undefined,
    loadNextPageFilesResponse: undefined,
    logoutResponse: undefined,
    modifyRoomInfoResponse: undefined
};
