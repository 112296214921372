import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {IAppState} from "../../state/app.state";
import {
    DatesUpdatesDiscoversSelect,
    DatesUpdatesListRoomsSelect,
    DatesUpdatesUsersSelect,
    selectDatesUpdatesNamespaceState
} from "../../selectors/namespaces/dates_updates_namespace.selectors";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class DatesUpdatesNamespaceFacade {

    constructor(
        private store: Store<IAppState>
    ) {}

    getDatesUpdatesUsers() : Observable<Date> {
        return this.store.select(DatesUpdatesUsersSelect).pipe(
            take(1)
        );
    }
    getDatesUpdatesListRooms() : Observable<Date> {
        return this.store.select(DatesUpdatesListRoomsSelect).pipe(
            take(1)
        );
    }
    getDatesUpdatesDiscovers() : Observable<Date> {
        return this.store.select(DatesUpdatesDiscoversSelect).pipe(
            take(1)
        )
    }
    //
    // export const DatesUpdatesUsersSelect = createSelector(
    //     selectDatesUpdatesNamespaceState,
    //     state => state.dates_updates_users
    // );
    //
    // export const DatesUpdatesListRoomsSelect = createSelector(
    //     selectDatesUpdatesNamespaceState,
    //     state => state.dates_updates_list_rooms
    // );
    //
    // export const DatesUpdatesDiscoversSelect = createSelector(
    //     selectDatesUpdatesNamespaceState,
    //     state => state.dates_updates_discovers
    // );

}
