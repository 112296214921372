import {Component, Input, OnInit} from '@angular/core';
import {PostregisterRepository} from '../../../shared/core/repositories/postregister/postregister.repository';
import {ActivatedRoute, Router} from '@angular/router';
import * as E from "fp-ts/lib/Either";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LocaleService} from "../../../shared/locales/locale.service";

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

    @Input()
    privacy: string;

    policy: string;

    // policyLoaded: boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        private locale: LocaleService
        // private route: ActivatedRoute,
        // private router: Router,
        // private postregisterRepository: PostregisterRepository,
        // private postregisterRepository: PostregisterRepository
    ) { }

    ngOnInit() {
        this.policy = this.privacy;
        // const token = this.route.snapshot.queryParams.token;
        // this.postregisterRepository.getLegalText().subscribe(
        //     post => {
        //
        //     }
        // )

        // this.policy = this.router.getCurrentNavigation().extras.state['policy'];
        // console.log({text: "Policy string", p: this.policy});
        // this.postregisterRepository.getLegalText().subscribe(
        //     legalText => {
        //         this.policy = legalText;
        //         this.policyLoaded = true;
        //     }
        // );
    }

}
