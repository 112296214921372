import {Injectable} from '@angular/core';
import {AppFacade} from '../shared/core/sources/storage/facade/app.facade';
import {Observable} from 'rxjs';
import {endWith, flatMap, map, switchMap, take, tap} from 'rxjs/operators';
import {ProfileDataBundle} from './models/ProfileDataBundle';
import {AppError} from '../shared/core/sources/remote/globalmodels/AppError';
import {Option} from "fp-ts/lib/Option";
import {FirstAppWithConnectionState} from "../shared/core/sources/storage/state/namespaces/app_state_namespace.state";
import {StandardError} from "../shared/core/domain/StandardError";
import {AppStateNamespaceFacade} from "../shared/core/sources/storage/facade/namespaces/app_state_namespace.facade";
import {MetaFacade} from "../shared/core/sources/storage/facade/generic/meta.facade";
import {blobToSrcImage} from "./mappers/utils.mappers";
import {ResourcesService} from "../shared/resources/resources.service";
import * as O from "fp-ts/lib/Option";

@Injectable({
    providedIn: 'root'
})
export class AppService {


    constructor(
        private appFacadeStore: AppFacade,
        private appStateNamespaceFacade: AppStateNamespaceFacade,
        private metaState: MetaFacade,
        private resourcesService: ResourcesService
    ) {}

    loadApp() : Observable<void> {
        return this.appFacadeStore.loadApp();
    }

    reloadApp() : Observable<never> {
        return this.appFacadeStore.reloadApp();
    }

    getOwnImageProfileObservable() : Observable<string> {
        return this.metaState.getOwnImageProfileObservable().pipe(
            switchMap(O.fold(
                () => this.resourcesService.getMetaNoImageProfile(),
                img => blobToSrcImage(img, src => src as string)
            ))
        );
    }

    getOnActiveSelectedRoomObservable() : Observable<boolean> {
        return this.appFacadeStore.onActiveChangedSelectedRoomObservable();
    }

    getOwnProfileDataBundle() : Observable<ProfileDataBundle> {
        return this.getOwnProfileDataBundleObservable().pipe(
            take(1)
        );
        // return this.metaState.getOwnDataUser().pipe(
        //     flatMap(ownDataUser => this.getOwnImageProfileObservable().pipe(
        //         take(1),
        //         map(image => new ProfileDataBundle(
        //             ownDataUser.name, ownDataUser.surname,
        //             ownDataUser.bio, image, ownDataUser.checkEmailNotificationsActivated,
        //             ownDataUser.emailContact, ownDataUser.emailNotifications,
        //             ownDataUser.videosActivated, ownDataUser.voiceActivated
        //         )),
        //     ))
        // );
    }

    getOwnProfileDataBundleObservable() : Observable<ProfileDataBundle> {
        return this.metaState.getOwnDataUser().pipe(
            flatMap(ownDataUser => this.getOwnImageProfileObservable().pipe(
                map(image => new ProfileDataBundle(
                    ownDataUser.name, ownDataUser.surname,
                    ownDataUser.bio, image, ownDataUser.checkEmailNotificationsActivated,
                    ownDataUser.emailContact, ownDataUser.emailNotifications,
                    ownDataUser.videosActivated, ownDataUser.voiceActivated,
                    ownDataUser.hideGroupCreationButton
                )),
            ))
        );
    }

    modifyOwnDataUser(bio: Option<string>, image: Option<Blob>,
                      emailContact: Option<Option<string>>,
                      emailNotifications: Option<boolean>) : Observable<{error: Option<StandardError>}> {
        return this.appFacadeStore.modifyOwnDataUser(bio, image, emailContact, emailNotifications).pipe(
            endWith(this.appFacadeStore.getModifyOwnDataUserResponseFailOrSuccess()),
            flatMap(p => p as Observable<{error: Option<StandardError>}>)
        );
    }

    logout() : Observable<{error: Option<StandardError>}> {
        return this.appFacadeStore.dispatchLogout().pipe(
            endWith(this.appFacadeStore.getLogoutResponseFailOrSuccess()),
            flatMap(p => p as Observable<{error: Option<StandardError>}>)
        );
    }

    getFirstAppWithConnectionStateObservable() : Observable<{state: FirstAppWithConnectionState, error: Option<StandardError>}> {
        return this.appStateNamespaceFacade.getFirstAppWithConnectionStateObservable();
    }
}
