import {Injectable} from "@angular/core";
import {ImageretrieverLocal} from "../../sources/local/imageretriever.local";
import {Observable, zip} from "rxjs";
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../domain/StandardError";
import {LocalResourcePack} from "../../domain/LocalResourcePack";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import {map, tap} from "rxjs/operators";
import {Option} from "fp-ts/lib/Option";
import {pipe} from "fp-ts/lib/pipeable";
import {LocaleService} from "../../../locales/locale.service";
import {sequenceT} from "fp-ts/lib/Apply";
import * as A from "fp-ts/lib/Array";

@Injectable({
    providedIn: 'root',
})
export class ResourcesRepository {

    constructor(
        private imageRetrieverLocal : ImageretrieverLocal,
        private localeService: LocaleService
    ) {}

    getErrorNotFoundFromOption(obs: Option<Blob>, name: string) : Either<StandardError, Blob> {
        return pipe(
            obs,
            O.fold(
                () => E.left(new StandardError('APP404', this.localeService.translate('APP404NAME',{file: name}))),
                blob => E.right(blob)
            )
        );
    }

    getLocalResourcePack() : Observable<Either<StandardError, LocalResourcePack>> {
        return zip(
            zip(
                this.imageRetrieverLocal.getNoImageProfile().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'NoImageProfile_Resource'))
                ),
                this.imageRetrieverLocal.getNoConnection().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'NoConnection_Resource'))
                ),
                this.imageRetrieverLocal.getDiscoversIcon().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'DiscoversIcon_Resource'))
                ),
                this.imageRetrieverLocal.getDiscoversCheck().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'DiscoversCheck_Resource'))
                ),
                this.imageRetrieverLocal.getChatLoadMoreGallery().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'ChatLoadMoreGallery_Resource'))
                ),
                this.imageRetrieverLocal.getChatEditGroup().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'ChatEditGroup_Resource'))
                ),
            ),
            zip(
                this.imageRetrieverLocal.getMessagesNotSendedNotSynchronizedIcon().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'MessagesNotSendedNotSynchronizedIcon_Resource'))
                ),
                this.imageRetrieverLocal.getMessagesSendedNotSynchronizedIcon().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'MessagesSendedNotSynchronizedIcon_Resource'))
                ),
                this.imageRetrieverLocal.getMessagesSendedSynchronizedIcon().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'MessagesSendedSynchronizedIcon_Resource'))
                ),
                this.imageRetrieverLocal.getMessagesSendedErrorIcon().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'MessagesErrorIcon_Resource'))
                ),
                this.imageRetrieverLocal.getMessagesPdfPlaceHolder().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'MessagesPdfPlaceHolder_Resource'))
                ),
                this.imageRetrieverLocal.getScriptsNotificationSidebar().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'ScriptsNotificationSidebar_Resource'))
                )
            ),
            zip(
                this.imageRetrieverLocal.getMessagesVideoPlaceholder().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'MessaggesVideoPlaceHolder_Resource'))
                ),
                this.imageRetrieverLocal.getMetaRetryIcon().pipe(
                    map(opt => this.getErrorNotFoundFromOption(opt, 'MetaRetryIcon_Resource'))
                )
            )
        ).pipe(
            map(([seq1, seq2, seq3]) => sequenceT(E.either)(
                sequenceT(E.either)(...seq1),
                sequenceT(E.either)(...seq2),
                sequenceT(E.either)(...seq3),
                // sequenceT(E.either)(...seq3)
            )),
            map(E.map(([[a, b, c, d, e, f], [g, h, i, j, k, l], [m, n]]) => new LocalResourcePack(a, b, c, d, e, f, g, h, i, j, k, l, m, n))),
            // tap(pack => console.log({text: "LOCALRESOURCEPACK", pack: pack}))
        );
    }

}
