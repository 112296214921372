import {adapterUser} from '../adapters/users.adapters';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Dictionary} from '@ngrx/entity';
import {PartialUser} from '../entities/PartialUser';
import {IUserState} from '../state/user.state';
import * as A from "fp-ts/lib/Array";
import {pipe} from "fp-ts/lib/pipeable";

export const selectUsersState = createFeatureSelector<IUserState>('users');

const PartialUsersSelectors = adapterUser.getSelectors();


const selectUserEntities = createSelector(
    selectUsersState,
    PartialUsersSelectors.selectEntities
);

export const getUserByIdSelect = createSelector(
    selectUserEntities,
    (entities: Dictionary<PartialUser>, props: {idUser:number}) => {
        // console.log({text: "entities users now", idUser: props.idUser, ents: entities});
        return entities[props.idUser]
    }
);

export const getAllUsersSelect = createSelector(
    selectUsersState,
    PartialUsersSelectors.selectAll
);

export const getFilteredUsersSelect = (filter: (pu: PartialUser) => boolean) => createSelector(
    selectUsersState,
    // usrs => pipe(
    //     usrs.entities,
    //     A.filter(u => filter(u))
    // )
    users => {
        const p = [] as PartialUser[];
        for (const key in users.entities) {
            if (filter(users.entities[key]))
                p.push(users.entities[key]);
        }
        return p;
    }
);

export const getUsersByIdUsersSelect = createSelector(
    selectUserEntities,
    (ents: Dictionary<PartialUser>, idUsers: number[]) => pipe(
        idUsers,
        A.map(id => {
            if (!ents[id]) return {};
            return ({[id]: ents[id]})
        }),
        A.reduce({} as {[x: number]: PartialUser}, (b, a) => ({...b, ...a}))
    )
);
