


export interface ResourcesNamespaceState {
    readonly meta: {
        readonly no_image_profile: Blob;
        readonly no_connection: Blob;
        readonly retry_icon: Blob;
    },
    readonly discovers: {
        readonly icon: Blob;
        readonly check: Blob;
    }
    readonly chat: {
        readonly load_more_gallery: Blob;
        readonly edit_group: Blob;
    },
    readonly messages: {
        readonly not_sended_not_synchronized_icon: Blob,
        readonly sended_not_synchronized_icon: Blob,
        readonly sended_synchronized_icon: Blob,
        readonly error_icon: Blob,
        readonly pdf_placeholder: Blob,
        readonly videos_placeholder: Blob
    },
    readonly scripts: {
        readonly notification_sidebar: Blob
    }
}

export const initialResourcesNamespaceState : ResourcesNamespaceState = {
    meta: {
        no_image_profile: undefined,
        no_connection: undefined,
        retry_icon: undefined
    },
    discovers: {
        icon: undefined,
        check: undefined
    },
    chat: {
        load_more_gallery: undefined,
        edit_group: undefined
    },
    messages: {
        not_sended_not_synchronized_icon: undefined,
        sended_not_synchronized_icon: undefined,
        sended_synchronized_icon: undefined,
        error_icon: undefined,
        pdf_placeholder: undefined,
        videos_placeholder: undefined
    },
    scripts: {
        notification_sidebar: undefined
    }
}
