import {Injectable} from "@angular/core";
import {SocketFacade} from "../../../../realtime/socket.facade";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
    AddListenerChatMessageRoomAction,
    AddListenerChatMessageUserAction, AddListenerConfirmationFileAction,
    AddListenerDisconnectedAction,
    AddListenerDiscoverDeletedAction,
    AddListenerDiscoverModifiedAction,
    AddListenerJoinedRoomAction,
    AddListenerNewDiscoverAction,
    AddListenerNewUserAction,
    AddListenerOneToOneDeletedAction, AddListenerOtoMessageModifiedAction,
    AddListenerOTOReadedMessagesAction,
    AddListenerQuitRoomAction,
    AddListenerRoomAdminsAction, AddListenerRoomMessageModifiedAction,
    AddListenerRoomModifiedAction,
    AddListenerRoomReadedMessagesAction,
    AddListenerUserDeletedAction,
    AddListenerUserModifiedAction
} from "../../actions/sockets/sockets.actions";
import {switchMap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SocketsEffects {

    addListenerUserModified$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerUserModifiedAction),
            switchMap(_ => this.socketFacade.addListenerUserModified()),
        )
    );
    addListenerNewUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerNewUserAction),
            switchMap(_ => this.socketFacade.addListenerNewUser()),
        )
    );
    addListenerUserDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerUserDeletedAction),
            switchMap(_ => this.socketFacade.addListenerUserDeleted())
        )
    );
    addListenerChatMessageRoom$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerChatMessageRoomAction),
            switchMap(_ => this.socketFacade.addListenerMessageRoom())
        )
    );
    addListenerChatMessageUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerChatMessageUserAction),
            switchMap(_ => this.socketFacade.addListenerMessageUser())
        )
    );
    addListenerJoinedRoom$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerJoinedRoomAction),
            switchMap(_ => this.socketFacade.addListenerJoinedRoom())
        )
    );
    addListenerQuitRoom$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerQuitRoomAction),
            switchMap(_ => this.socketFacade.addListenerQuitRoom())
        )
    );
    addListenerRoomModified$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerRoomModifiedAction),
            switchMap(_ => this.socketFacade.addListenerRoomModified())
        )
    );
    addListenerRoomReadedMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerRoomReadedMessagesAction),
            switchMap(_ => this.socketFacade.addListenerRoomReadedMessages())
        )
    );
    addListenerOTOReadedMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerOTOReadedMessagesAction),
            switchMap(_ => this.socketFacade.addListenerOtoReadedMessages())
        )
    );
    addListenerDisconnected$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerDisconnectedAction),
            switchMap(_ => this.socketFacade.addListenerDisconnected())
        )
    );
    addListenerOneToOneDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerOneToOneDeletedAction),
            switchMap(_ => this.socketFacade.addListenerOneToOneDeleted())
        )
    );
    addListenerRoomAdmins$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerRoomAdminsAction),
            switchMap(_ => this.socketFacade.addListenerRoomAdmins())
        )
    );
    addListenerNewDiscover$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerNewDiscoverAction),
            switchMap(_ => this.socketFacade.addListenerNewDiscover())
        )
    );
    addListenerDiscoverModified$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerDiscoverModifiedAction),
            switchMap(_ => this.socketFacade.addListenerDiscoverModified())
        )
    );
    addListenerDiscoverDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerDiscoverDeletedAction),
            switchMap(_ => this.socketFacade.addListenerDiscoverDeleted())
        )
    );
    addListenerRoomMessageModified$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerRoomMessageModifiedAction),
            switchMap(_ => this.socketFacade.addListenerRoomMessageModified())
        )
    );
    addListenerOtoMessageModified$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerOtoMessageModifiedAction),
            switchMap(_ => this.socketFacade.addListenerOtoMessageModified())
        )
    );
    addListenerConfirmationFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddListenerConfirmationFileAction),
            switchMap(_ => this.socketFacade.addListenerConfirmationFile())
        )
    )

    // export const AddListenerUserModifiedAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_USER_MODIFIED
    // );
    // export const AddListenerNewUserAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_NEW_USER
    // );
    // export const AddListenerChatMessageRoomAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_CHAT_MESSAGE_ROOM
    // );
    // export const AddListenerChatMessageUserAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_CHAT_MESSAGE_USER
    // );
    // export const AddListenerJoinedRoomAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_JOINED_ROOM
    // );
    // export const AddListenerQuitRoomAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_QUIT_ROOM
    // );
    // export const AddListenerRoomModifiedAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_ROOM_MODIFIED
    // );
    // export const AddListenerRoomReadedMessagesAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_ROOM_READED_MESSAGES
    // );
    // export const AddListenerOTOReadedMessagesAction = createAction(
    //     SocketsSocketsActions.ADD_LISTENER_OTO_READED_MESSAGES
    // );

    constructor(
        private actions$ : Actions,
        private socketFacade: SocketFacade
    ) {}
}
