import {createAction} from "@ngrx/store";

const TAG = '[Initial Processes Execution Actions] ';

export const InitialProcessesExecutionActions = {
    EXECUTE_INITIAL_ALL_PROCESSES: TAG + 'Execute Initial All Processes'
};

export const ExecuteInitialAllProcessesAction = createAction(
    InitialProcessesExecutionActions.EXECUTE_INITIAL_ALL_PROCESSES
);
