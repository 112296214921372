import * as O from "fp-ts/lib/Option";
import {Option} from "fp-ts/lib/Option";

export abstract class BaseRoomStatus {
    readonly idRoom: number;
    readonly by: number;
    readonly date: Date;
    readonly _id: string;


    constructor(idRoom: number, by: number, date: Date, id: string) {
        this.idRoom = idRoom;
        this.by = by;
        this.date = date;
        this._id = id;
    }
}


export abstract class RoomStatusUser extends BaseRoomStatus {

    readonly from: number;

    // readonly status: TypeStatus;


    constructor(idRoom: number, from: number, by: number, date: Date, id: string) {
        super(idRoom, by, date, id);
        this.from = from;
    }
}

// export enum TypeStatus {
//     JOINED_ROOM, QUIT_ROOM
// }


export class RoomStatusJoined extends RoomStatusUser {

}
export class RoomStatusQuit extends RoomStatusUser {

}

export class RoomStatusModified extends BaseRoomStatus {

    readonly fields: {
        name: Option<string>,
        image: Option<boolean>
    };


    constructor(idRoom: number, by: number, date: Date, id: string, fields: { name: Option<string>; image: Option<boolean> }) {
        super(idRoom, by, date, id);
        this.fields = fields;
    }
}
