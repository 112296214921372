import {Injectable} from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import {from, Observable, of} from 'rxjs';
import {catchError, flatMap, map, switchMap} from 'rxjs/operators';
import {IPushMessaging} from './IPushMessaging';
import * as O from "fp-ts/lib/Option";
import {fromNullable} from "fp-ts/es6/Option";

@Injectable({
    providedIn: 'root'
})
export class MessagingService implements IPushMessaging {


    private subscription;

    constructor(
        private angularFireMessaging: AngularFireMessaging
    ) {
        console.log("INIT MESSAGING SERVICE");
        this.subscription = this.angularFireMessaging.messaging.subscribe(
            _messaging => {
                console.log("SUBSCRIBING MESSAGING")
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
                _messaging.deleteToken = _messaging.deleteToken.bind(_messaging);

                // this.angularFireMessaging.requestPermission.subscribe();
            }
        )
    }


    requestToken() : Observable<string> {
        return from(this.angularFireMessaging.getToken).pipe(
            map(fromNullable),
            catchError(err => {
                console.log({text: "error get token", err: err});
                return of(O.none)
            }),
            switchMap(O.fold(
                () => from(this.angularFireMessaging.requestToken),
                t => of(t)
            ))
        )
        //
        // return from(this.angularFireMessaging.requestToken).pipe(
        //     catchError()
        // );
    }

    onTokenChanges() : Observable<string> {
        return from(this.angularFireMessaging.tokenChanges);
    }

    onNewMessage() : Observable<any> {
        return from(this.angularFireMessaging.messages);
    }

    deleteToken(token: string) : Observable<boolean> {
        return from(this.angularFireMessaging.deleteToken(token));
    }

}
