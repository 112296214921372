
export class FileMessageNotSended {
    readonly internalId: string;
    readonly file: Blob;
    readonly thumbnail: string;


    constructor(internalId: string, file: Blob, thumbnail: string) {
        this.internalId = internalId;
        this.file = file;
        this.thumbnail = thumbnail;
    }
}
