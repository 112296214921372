// import * as striptags from "striptags";

// import {stripHtml} from "string-strip-html";
// import striptags from "striptags";
import stripHtml from "string-strip-html";

export function removeHtmlTagsAndSpecial(str: string) : string {
    const regex = /(<([^>]+)>)|(&nbsp;|&lt;)/ig;
    return str.replace(regex, "");
}

export function isEmptyString(str: string) : boolean {
    return /^\s+$|^$/gi.test(str)
}

export function changeBrForBackSpace(s: string) : string {
    console.log({text: "AFTER OUTPUT TEXT", outputText: s});
    const firstDiv = /(<br[^>]*>)/;
    const regex = /(<br[^>]*>)/gm;
    const regex2 = /(<\/br>)/gm;
    const char = "\n";
    const toSend = s.replace(firstDiv, char)
        .replace(regex, char)
        .replace(regex2, char);
    console.log({text: "AFTER AFTER OUTPUT TEXT", outputText: toSend});
    return toSend;
}

const b_tag = 'b';
const u_tag = 'u';
const i_tag = 'i';
const div_tag = 'div';
const br_tag = 'br';
const ul_tag = "ul";
const li_tag = "li";
// const charBR = String.fromCharCode(10);
//https://codsen.com/os/string-strip-html/
//https://codsen.com/os/string-strip-html/examples/#leave-only-opening-td-tags
export function replaceHTML(s: string) : string {
    console.log({text: "replaceHTML", s: s});
    const withoutAll = stripHtml(
        s,
        {
            ignoreTags: [b_tag, u_tag, i_tag, div_tag, br_tag, ul_tag, li_tag],
        }
    ).result;
    console.log({text: "replaceHTML", withoutAll: withoutAll});
    const final = stripHtml(
        withoutAll,
        {
            ignoreTags: [b_tag, u_tag, i_tag, ul_tag, li_tag],
            cb: ({tag, deleteFrom, deleteTo, rangesArr}) => {
                console.log({text: "replaceHTML tags", tag: tag, deleteFrom: deleteFrom, deleteTo: deleteTo, rangesArr: rangesArr});
                if (tag.name === div_tag || tag.name === br_tag) {
                    return rangesArr.push([
                        deleteFrom,
                        deleteTo,
                        tag.slashPresent ? '' : '\n'
                    ]);
                }
                return rangesArr.push([deleteFrom, deleteTo, '']);
            }
        }
    );
    console.log({text: "replaceHTML", final: final});
    return final.result;

    // console.log({text: "replaceHTML", s: s});
    // const withoutAll = striptags(s, [b_tag, u_tag, i_tag, div_tag, br_tag]);
    // console.log({text: "replaceHTML", withoutAll: withoutAll});
    // const replaceCR = striptags(withoutAll, [div_tag, br_tag], charBR);
    // console.log({text: "replaceHTML", replaceCR: replaceCR});
    // const f = replaceCR.replace(/(&nbsp;)/gi, ' ');
    // console.log({text: "replaceHTML", f: f});
    // return f;
}


export function normalizeString(str: string) : string {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()
}
