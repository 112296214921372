import { option as O } from "fp-ts";
import {
    default_description_postregister,
    default_login_field_shadow,
    default_user_not_found,
    LANGUAGE,
    title_postregister,
} from "./resources.theme";
import { ThemeType } from "./ThemeType";

export const environment = <ThemeType>{
    name: "testing",
    login_logo: "assets/img/logos/logo-zonetacts-big.png",
    header_logo: "assets/img/logos/logo-zonetacts-big.png",
    favicon_logo: "assets/img/logos/logo-zonetacts-big.png",
    // login_logo: "assets/img/logos/logo_chatforce.png",
    // header_logo: "assets/img/logos/logo_chatforce.png",
    // favicon_logo: "assets/img/logos/logo_chatforce_icon.png",
    colors: {
        high: "#0F3977",
        medium: "#0F3977",
        low: "#FFE744",
    },
    encrypted_password: true,
    title_postregister: title_postregister,
    description_postregister: default_description_postregister,
    login_field_shadow: default_login_field_shadow,
    // backend_url_complete: "http://backend-test.zonetacts.com:51777",
    backend_url_complete: "https://backend-test.zonetacts.com",
    api_path: "/api/v2.0",
    lang: LANGUAGE.ES,
    forgotten_password: {
        activated: false,
        text_forgot_password: undefined,
        link: undefined,
    },
    activated_discovers: true,
    discover_icon: "./assets/img/fire-2.png",
    // page_title: "Proves Testing",
    page_title: "Chatprise",
    firebase_config: {
        apiKey: "AIzaSyB5h_CE-N9NrUU_Zat5QfBR2tuz6bW89fY",
        authDomain: "zonetacts-v2-test.firebaseapp.com",
        databaseURL: "https://zonetacts-v2-test.firebaseio.com",
        projectId: "zonetacts-v2-test",
        storageBucket: "zonetacts-v2-test.appspot.com",
        messagingSenderId: "95837114095",
        appId: "1:95837114095:web:7115a8c34d677bf01f951a",
    },
    header_logos: [],
    companyNameTitle: "Testing Zonetacts",

    showPolicy: true,
    welcomeVideo: undefined,
    user_not_found_string: default_user_not_found,
    showFormLogin: true,
    externalLogins: [],
    withSessionsButton: true,
    showInactiveSessions: true,
    production: false,
    defaultLanguage: O.none,
    replyActivated: true,
    changePasswordActivated: true,
    showTextLogin: false,
    textLogin: null,
    stores: {
        AppStore: "chatforce/id1519416278",
        GooglePlay: "com.atlabs.zonetacts.chatforce",
    },
};
