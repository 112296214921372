import {createAction, props} from "@ngrx/store";
import {PartialRoomGroup} from "../../entities/PartialRoomGroup";
import {PartialRoomOTO} from "../../entities/PartialRoomOTO";


const TAG = '[Generic Rooms Actions] ';

export const GenericRoomsActions = {
    SAVE_LIST_ROOMS: TAG + 'Save List Rooms',
    REFRESH_LIST_ROOMS: TAG + 'Refresh List Rooms'
};

export const SaveListRoomsAction = createAction(
    GenericRoomsActions.SAVE_LIST_ROOMS,
    props<{rooms: PartialRoomGroup[], otos: PartialRoomOTO[]}>()
);

export const RefreshListRoomsAction = createAction(
    GenericRoomsActions.REFRESH_LIST_ROOMS,
    props<{all: {rooms: PartialRoomGroup[], otos: PartialRoomOTO[]}, deleted: {rooms: number[], otos: number[]}}>()
);
