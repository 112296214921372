import {Injectable} from "@angular/core";
import {option as O} from "fp-ts";
import {fromNullable, Option} from "fp-ts/lib/Option";
import {pipe} from "fp-ts/lib/pipeable";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {MessageLinkPreview, MessageReply} from "../domain/Message";
import {getDateFromBackendDate} from "../repositories/mappers/dates.mapper";
import {ReplyMessageModel} from "../sources/remote/globalmodels/ReplyMessageModel";
import {BlobTransformers} from "./blob.transformers";
import {getModifiedStateByNumber} from "./commom.mappers";
import {LinkPreviewMessageModel} from "../sources/remote/globalmodels/LinkPreviewMessageModel";


@Injectable({
    providedIn: 'root'
})
export class MessagesMappersUtils {
    constructor(
        private blobTransformers : BlobTransformers,
    ) {}

    private getReplyMessageFromResponse(r: ReplyMessageModel) : Observable<MessageReply> {
        const m = getModifiedStateByNumber(r.modifiedState);
        console.log({text: "getModifiedStateByNumber", n: r.modifiedState, result: m});

        return this.blobTransformers.getObjectWithBlobOptionalFromBase64(r.file, b => new MessageReply(
            r.idUser,
            r.idMessage,
            getDateFromBackendDate(r.date),
            r.text,
            fromNullable(b),
            m
        ));
    }

    getReplyMessageMonad(r: ReplyMessageModel) : Observable<Option<MessageReply>> {
        return pipe(
            fromNullable(r),
            O.fold(
                () => of(O.none),
                r => this.getReplyMessageFromResponse(r).pipe(
                    map(r => O.some(r))
                )
            )
        );
    }

    private getLinkPreviewFromResponse(r: LinkPreviewMessageModel) : Observable<MessageLinkPreview> {
        return pipe(
            O.fromNullable(r.imageBase64),
            O.fold(
                () => of(O.none),
                base64 => this.blobTransformers.getObjectWithBlobOptionalFromBase64(base64, b => O.some(b))
            )
        ).pipe(
            map(optBase64 => new MessageLinkPreview(
                O.fromNullable(r.description),
                r.title,
                r.url,
                r.type,
                O.fromNullable(r.videoUrl),
                optBase64
            ))
        )
    }

    getLinkPreviewMessageMonad(r: LinkPreviewMessageModel) : Observable<Option<MessageLinkPreview>> {
        return pipe(
            fromNullable(r),
            O.fold(
                () => of(O.none),
                r => this.getLinkPreviewFromResponse(r).pipe(
                    map(r => O.some(r))
                )
            )
        );
    }
}
