import {from, Observable, of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class BlobTransformers {

    public constructor(
        private http: HttpClient,
    ) {}


    getObjectWithBlobOptionalFromBase64<T>(base64: string | undefined, provider: (b : Blob | undefined) => T) : Observable<T> {
        if (base64 === undefined)
            return of(provider(undefined));
        return from(fetch(base64)).pipe(
            flatMap(response => from(response.blob())),
            catchError(err => this.http.get('./assets/img/document-error-outline.png', {responseType: 'blob'})),
            map(blobThumbail => provider(blobThumbail))
        );
    }

    getUrlFromBlob(blob: Blob) : string {
        return URL.createObjectURL(blob);
    }
}


