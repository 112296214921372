import {ResponsesState} from '../state/responses.state';
import {createSelector} from '@ngrx/store';
import {IAppState} from '../state/app.state';

const responsesSelect = (state: IAppState) => state.responsesState;

export const createRoomResponseSelect = createSelector(responsesSelect, state => {
    return state.createRoomLastResponse
});

export const getImageMessageRoomResponseSelect = createSelector(responsesSelect, state => state.getImageMessageRoom);

export const addUsersRoomResponseSelect = createSelector(responsesSelect, state => state.addUsersToRoomLastResponse);

export const leaveRoomGroupResponseSelect = createSelector(responsesSelect, state => state.leaveRoomGroupLastResponse);

export const leaveRoomOTOResponseSelect = createSelector(responsesSelect, state => state.leaveRoomOTOLastResponse);

export const changeOwnDataUserResponseSelect = createSelector(responsesSelect, state => state.changeOwnDataUser);

export const openOTOResponseSelect = createSelector(responsesSelect, state => state.openOneToOneResponse);

// export const sendFileRoomGroupSelect = createSelector(responsesSelect, state => state.sendFileRoomGroupResponse);
//
// export const sendFileRoomOTOSelect = createSelector(responsesSelect, state => state.sendFileRoomOTOResponse);

export const loadNextPageFilesSelect = createSelector(responsesSelect, state => state.loadNextPageFilesResponse);

export const logoutResponseSelect = createSelector(responsesSelect, state => state.logoutResponse);

export const modifyRoomInfoResponseSelect = createSelector(responsesSelect, state => state.modifyRoomInfoResponse);
