

export class AppError {

    status: number;
    detail: {
        en: string,
        es: string,
        ca: string
    };
    code: number;

    constructor(json?: string) {
        const err = JSON.parse(json);
        this.status = err.error.status;
        this.detail = err.error.detail;
        this.code = err.error.code;
        // this.error = JSON.parse(json);
    }
}

// export function instanceOfIAppError(o: any) : o is AppError {
//     return 'error' in o && 'status' in o.error && 'detail' in o.error && 'code' in o.error;
// }
