import {createAction, props} from '@ngrx/store';
import {FileMessage} from '../../../domain/FileMessage';
import {AppError} from '../../remote/globalmodels/AppError';
import {StandardError} from "../../../domain/StandardError";

export const FileMessagesActions = {

    // RESET_PAGE_FILES: '[File Message] Reset Page Files',

    LOAD_NEXT_PAGE_FILES: '[File Message] Load Next Page Files',
    LOAD_NEXT_PAGE_FILES_SUCCESS: '[File Message] Load Next Page Files Success',
    LOAD_NEXT_PAGE_FILES_FAIL: '[File Message] Load Next Page Files Fail',
    LOAD_NEXT_PAGE_FILES_COMPLETE: '[File Message] Load Next Page Files Complete',

    ON_NEW_MESSAGE_ON_FILE_SELECTED_ROOM: '[File Message] On New Message On File Selected Room',

};


// export const onResetPageFiles = createAction(
//     FileMessagesActions.RESET_PAGE_FILES
// );

export const onLoadNextPageFiles = createAction(
    FileMessagesActions.LOAD_NEXT_PAGE_FILES
);

export const onLoadNextPageFilesSuccess = createAction(
    FileMessagesActions.LOAD_NEXT_PAGE_FILES_SUCCESS,
    props<{fileMessages: FileMessage[]}>()
);

export const onLoadNextPageFilesFail = createAction(
    FileMessagesActions.LOAD_NEXT_PAGE_FILES_FAIL,
    props<{appError: StandardError}>()
);

export const onLoadNextPageFilesComplete = createAction(
    FileMessagesActions.LOAD_NEXT_PAGE_FILES_COMPLETE
);

export const onNewMessageOnFileSelectedRoom = createAction(
    FileMessagesActions.ON_NEW_MESSAGE_ON_FILE_SELECTED_ROOM,
    props<{fileMessage: FileMessage}>()
);
