import {Message} from './Message';


export abstract class Room <T extends Message> {

    readonly lastActivity: Date;
    readonly messageCount: number;
    readonly lastMessage: T;


    readonly last_messages_updated: Date;
    readonly lastClick: Date;



    constructor(lastActivity: Date,
                messageCount: number,
                lastMessage: T,
                last_messages_updated: Date,
                lastClick: Date
    ) {
        this.lastActivity = lastActivity;
        this.messageCount = messageCount;
        this.lastMessage = lastMessage;
        this.last_messages_updated = last_messages_updated;
        this.lastClick = lastClick;
    }
}
