import {createAction} from "@ngrx/store";

const TAG = '[Sockets Sockets Actions] ';

export const SocketsSocketsActions = {
    // Simplement es cridar a sockets facade sense arguments per afegir l'acció
    //  (que des de facade sockets ja té el actions facade per cridar accions).
    ADD_LISTENER_USER_MODIFIED: TAG + 'Add Listener User Modified',
    ADD_LISTENER_NEW_USER: TAG + 'Add Listener New User',
    ADD_LISTENER_USER_DELETED: TAG + 'Add Listener User Deleted',

    ADD_LISTENER_CHAT_MESSAGE_ROOM: TAG + 'Add Listener Chat Message Room',
    ADD_LISTENER_CHAT_MESSAGE_USER: TAG + 'Add Listener Chat Message User',
    ADD_LISTENER_JOINED_ROOM: TAG + 'Add Listener Joined Room',
    ADD_LISTENER_QUIT_ROOM: TAG + 'Add Listener Quit Room',
    ADD_LISTENER_ROOM_MODIFIED: TAG + 'Add Listener Room Modified',
    ADD_LISTENER_ROOM_READED_MESSAGES: TAG + 'Add Listener Room Readed Messages',
    ADD_LISTENER_OTO_READED_MESSAGES: TAG + 'Add Listener OTO Readed Messages',

    ADD_LISTENER_DISCONNECTED: TAG + 'Add Listener Disconnected',

    ADD_LISTENER_ONE_TO_ONE_DELETED: TAG + 'Add Listener One To One Deleted',
    ADD_LISTENER_ROOM_ADMINS: TAG + 'Add Listener Room Admins',
    ADD_LISTENER_NEW_DISCOVER: TAG + 'Add Listener New Discover',
    ADD_LISTENER_DISCOVER_MODIFIED: TAG + 'Add Listener Discover Modified',
    ADD_LISTENER_DISCOVER_DELETED: TAG + 'Add Listener Discover Deleted',

    ADD_LISTENER_ROOM_MESSAGE_MODIFIED: TAG + 'Add Listener Room Message Modified',
    ADD_LISTENER_OTO_MESSAGE_MODIFIED: TAG + 'Add Listener Oto Message Modified',

    ADD_LISTENER_CONFIRMATION_FILE: TAG + 'Add Listener Confirmation File'


    // Els esdeveniments són:
    //     - "chat message room"
    //     - "chat message user"
    //     - "joined room"
    //     - "quit room"
    //     - "room-modified"
    //     - "room-readed-messages"
    //     - "oto-readed-messages"
};

export const AddListenerUserModifiedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_USER_MODIFIED
);
export const AddListenerNewUserAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_NEW_USER
);
export const AddListenerUserDeletedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_USER_DELETED
);
export const AddListenerChatMessageRoomAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_CHAT_MESSAGE_ROOM
);
export const AddListenerChatMessageUserAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_CHAT_MESSAGE_USER
);
export const AddListenerJoinedRoomAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_JOINED_ROOM
);
export const AddListenerQuitRoomAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_QUIT_ROOM
);
export const AddListenerRoomModifiedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_ROOM_MODIFIED
);
export const AddListenerRoomReadedMessagesAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_ROOM_READED_MESSAGES
);
export const AddListenerOTOReadedMessagesAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_OTO_READED_MESSAGES
);
export const AddListenerDisconnectedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_DISCONNECTED
);
export const AddListenerOneToOneDeletedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_ONE_TO_ONE_DELETED
);
export const AddListenerRoomAdminsAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_ROOM_ADMINS
);
export const AddListenerNewDiscoverAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_NEW_DISCOVER
);
export const AddListenerDiscoverModifiedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_DISCOVER_MODIFIED
);
export const AddListenerDiscoverDeletedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_DISCOVER_DELETED
);
export const AddListenerRoomMessageModifiedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_ROOM_MESSAGE_MODIFIED
);
export const AddListenerOtoMessageModifiedAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_OTO_MESSAGE_MODIFIED
);
export const AddListenerConfirmationFileAction = createAction(
    SocketsSocketsActions.ADD_LISTENER_CONFIRMATION_FILE
);

// ADD_LISTENER_ONE_TO_ONE_DELETED: TAG + 'Add Listener One To One Deleted',
//     ADD_LISTENER_ROOM_ADMINS: TAG + 'Add Listener Room Admins',
//     ADD_LISTENER_NEW_DISCOVER: TAG + 'Add Listener New Discover',
//     ADD_LISTENER_DISCOVER_MODIFIED: TAG + 'Add Listener Discover Modified',
//     ADD_LISTENER_DISCOVER_DELETED: TAG + 'Add Listener Discover Deleted'
