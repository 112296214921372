



export enum FileType {
    IMAGE = "image", FILE = "file", VIDEO = "video", VOICE="voice"
}


export function getFileTypeFromString(t: string) : FileType {
    if (t === FileType.FILE) {
        return FileType.FILE;
    } else if (t === FileType.IMAGE) {
        return FileType.IMAGE;
    } else if (t === FileType.VIDEO) {
        return FileType.VIDEO
    } else if (t === FileType.VOICE) {
        return FileType.VOICE;
    }
    return FileType.FILE;
}
