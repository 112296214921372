import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {VgAPI} from "videogular2/core";
import {UtilsService} from "../../chatmodule/utils.service";
import {SafeUrl} from "@angular/platform-browser";

@Component({
    selector: 'app-voice-visualizer',
    templateUrl: './voice-visualizer.component.html',
    styleUrls: ['./voice-visualizer.component.scss']
})
export class VoiceVisualizerComponent implements OnInit {

    @Input()
    voiceUrl: SafeUrl;

    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    ngOnInit() {
        console.log({text: "Voice URL", url: this.voiceUrl})
    }

    onPlayerReady(api: VgAPI) {
        api.play();
    }
    onError($event) {
        console.log($event);
    }
}
