import { Routes, RouterModule } from "@angular/router";

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: "",
        loadChildren:
            "./pages/content-pages/content-pages.module#ContentPagesModule",
    },
    {
        path: "recover",
        loadChildren:
            "./pages/recover-password/recover-password.module#RecoverPasswordModule",
    },
    {
        path: "profile",
        loadChildren: "./pages/full-pages/full-pages.module#FullPagesModule",
    },
    {
        path: "resetpassword",
        loadChildren:
            "./pages/reset-password/reset-password.module#ResetPasswordModule",
    },
    {
        path: "external",
        loadChildren: "./pages/landings/landings.module#ExternalLandingsModule",
    },
];
