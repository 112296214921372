import { RoomGroup } from "../../../domain/RoomGroup";
import { IUserRoomGroupState } from "../state/room.state";

export class PartialRoomGroup {
    readonly roomGroup: RoomGroup;
    // readonly withImageProfileLoaded: boolean;

    readonly nextLastPageToLoad: number;
    readonly numberMessagesLastPage: number;

    readonly fullPagesLoaded: boolean;

    static readonly MESSAGES_BY_PAGE: number = 10;

    readonly usersRoom: IUserRoomGroupState;

    readonly messageInputSaved: string;

    constructor(
        roomGroup: RoomGroup,
        // withImageProfileLoaded: boolean,
        nextLastPageToLoad: number,
        numberMessagesLastPage: number,
        fullPagesLoaded: boolean,
        usersRoom: IUserRoomGroupState,
        messageInputSaved: string
    ) {
        this.roomGroup = roomGroup;
        // this.withImageProfileLoaded = withImageProfileLoaded;
        this.nextLastPageToLoad = nextLastPageToLoad;
        this.numberMessagesLastPage = numberMessagesLastPage;
        this.fullPagesLoaded = fullPagesLoaded;
        this.usersRoom = usersRoom;
        this.messageInputSaved = messageInputSaved;
    }
}
