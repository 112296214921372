import {Injectable} from '@angular/core';
import {HttpHeadersManager} from './headers/HttpHeadersManager';
import {Observable, of} from 'rxjs';
import {AjaxCaller} from './ajax/AjaxCaller';
import {RequestType} from './RequestType';
import {catchError, flatMap, map, switchMap} from 'rxjs/operators';
import {ThemeService} from '../../../../themes/theme.service';
import * as E from "fp-ts/lib/Either";
import {Either} from "fp-ts/lib/Either";
import {AppError} from "../globalmodels/AppError";
import * as O from "fp-ts/lib/Option";
import {Option} from "fp-ts/lib/Option";
import {StandardError} from "../../../domain/StandardError";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorsTransformers} from "../../../domain/errors/errors.transformers";

@Injectable({
    providedIn: 'root',
})
export class NetworkClient {

    // private static ROOT_PATH: string = "/api/v2.0";

    private getUrl() : string {
        return this.themeService.getBackendUrlApiComplete();
    }

    constructor(
        private httpHeadersManager: HttpHeadersManager,
        private ajaxCaller: AjaxCaller,
        private themeService: ThemeService,
        private errorsTransformers: ErrorsTransformers
    ) {

    }

    private isAppError(body: HttpErrorResponse) : boolean {
        return body.error &&
            body.error.error &&
            body.error.error.status &&
            body.error.error.detail &&
            body.error.error.code;

    }

    private getAppError(body: HttpErrorResponse) : AppError {
        return new AppError(JSON.stringify(body.error));
    }

    private errorCatcher<T>(obs: Observable<T>) : Observable<Either<StandardError, T>> {
        return obs.pipe(
            map(elem => E.right(elem)),
            catchError(error => {
                console.log({text: "error catched", err: error})
                if (error instanceof HttpErrorResponse) {
                    if (this.isAppError(error)) {
                        // return of(E.left(this.errorsTransformers.getStandardErrorFromAppError(this.getAppError(error))))
                        return this.errorsTransformers.getStandardErrorFromAppError(this.getAppError(error)).pipe(
                            map(e => E.left(e))
                        )
                    } else {
                        return of(E.left(this.errorsTransformers.getStandardErrorFromHttpErrorResponse(error)))
                    }
                } else {
                    return of(E.left(this.errorsTransformers.getStandardErrorFromAny(error)));
                }

            })
        )
        // catchError(err => {
        //     if (err instanceof HttpErrorResponse) {
        //         return of()
        //     }
        //     throw err;
        // }),
    }

    setRequestWithCustomHeaders<REQUEST, RESPONSE>(
        endpoint: string,
        headers: {[key in string]: string},
        body: REQUEST,
        type: RequestType
    ) : Observable<Either<StandardError, RESPONSE>> {
        let obs : Observable<RESPONSE>;
        console.log({endpoint: endpoint, headers: headers});
        if (type === RequestType.POST) {
            obs = this.ajaxCaller.post(this.getUrl() + endpoint, body, headers);
        } else if (type === RequestType.GET) {
            obs = this.ajaxCaller.get(this.getUrl() + endpoint, headers);
        } else if (type === RequestType.PUT) {
            obs = this.ajaxCaller.put(this.getUrl() + endpoint, body, headers);
        } else if (type === RequestType.DELETE) {
            obs = this.ajaxCaller.delete(this.getUrl() + endpoint, headers);
        } else if (type === RequestType.HEAD) {
            obs = this.ajaxCaller.head(this.getUrl() + endpoint, headers);
        }
        return this.errorCatcher(obs);
    }


    setRequest<REQUEST, RESPONSE>(endpoint: string, body: REQUEST, type: RequestType) : Observable<Either<StandardError, RESPONSE>> {
        return this.httpHeadersManager.getCurrentHeaders().pipe(
            switchMap(headers => this.setRequestWithCustomHeaders<REQUEST, RESPONSE>(
                endpoint,
                headers,
                body,
                type
            ))
        );
    }

    // setRequest<REQUEST, RESPONSE>(url: string, body: REQUEST)

    setRequestWithFile<REQUEST>(endpoint: string, body: REQUEST, type: RequestType) : Observable<Option<Blob>> {
        return this.httpHeadersManager.getCurrentHeaders().pipe(
            flatMap(headers => this.ajaxCaller.getFile(this.getUrl() + endpoint, headers).pipe(
                map(f => O.some(f))
            )),
            catchError(err => of(O.none))
        );
    }
    setRequestWithFileEither<REQUEST>(endpoint: string, body: REQUEST, type: RequestType) : Observable<Either<StandardError, Blob>> {
        return this.httpHeadersManager.getCurrentHeaders().pipe(
            flatMap(headers => this.ajaxCaller.getFile(this.getUrl() + endpoint, headers).pipe(
                map(f => E.right(f))
            )),
            catchError(err => this.errorsTransformers.getStandardErrorFromAppError(err).pipe(
                map(err => E.left(err))
            ))
        );
    }
    setPlainRequestWithFileEither(url: string) : Observable<Either<StandardError, Blob>> {
        return this.ajaxCaller.getFile(url, {}).pipe(
            map(f => E.right(f)),
            catchError(err => this.errorsTransformers.getStandardErrorFromAppError(err).pipe(
                map(err => E.left(err))
            ))
        )
    }

    setRequestWithFormData<RESPONSE>(endpoint: string, body: {key: string, value: Blob | string }[], type: RequestType) : Observable<Either<StandardError,RESPONSE>> {
        let obs: Observable<RESPONSE>;
        if (type === RequestType.PUT) {
            obs = this.httpHeadersManager.getCurrentHeaders().pipe(
                flatMap(headers => this.ajaxCaller.putFormData(this.getUrl() + endpoint, body, headers))
            );
        } else if (type === RequestType.POST) {
            obs = this.httpHeadersManager.getCurrentHeaders().pipe(
                flatMap(headers => this.ajaxCaller.postFormData(this.getUrl() + endpoint, body, headers))
            );
        }
        return this.errorCatcher(obs);
    }

    setPlainRequest<RESPONSE>(url: string, type: RequestType, body?: string, headers = {}) : Observable<Either<StandardError, RESPONSE>> {
        let obs : Observable<RESPONSE>;
        if (type === RequestType.POST) {
            obs = this.ajaxCaller.post(url, body, headers);
        } else if (type === RequestType.GET) {
            obs = this.ajaxCaller.get(url, headers);
        } else if (type === RequestType.PUT) {
            obs = this.ajaxCaller.put(url, body, headers);
        } else if (type === RequestType.DELETE) {
            obs = this.ajaxCaller.delete(url, headers);
        } else if (type === RequestType.HEAD) {
            obs = this.ajaxCaller.head(url, headers);
        }
        return this.errorCatcher(obs);
    }


    // refreshHeaders() : Observable<never> {
    //     return this.httpHeadersManager.refreshState();
    // }
}
