import {createAction, props} from "@ngrx/store";
import {AppWithConnectionState, FirstAppWithConnectionState} from "../../state/namespaces/app_state_namespace.state";
import {Option} from "fp-ts/lib/Option";
import {StandardError} from "../../../../domain/StandardError";
import {GenericAppStateActions} from "../generic/app_state.actions";


const TAG = '[App State Namespace] ';

export const AppStateNamespaceActions = {
    CHANGED_CONNECTION_ONLINE_LOCAL: TAG + 'Changed Connection Online Local',
    CHANGED_CONNECTION_OFFLINE_LOCAL: TAG + 'Changed Connection Offline Local',
    CHANGED_CONNECTION_ONLINE_SERVER: TAG + 'Changed Connection Online Server',
    CHANGED_CONNECTION_OFFLINE_SERVER: TAG + 'Changed Connection Offline Server',

    // CONNECTION_CHANGED: TAG + 'Connection Changed',
    SET_ALL_DATA_LOADED: TAG + 'Set All Data Loaded',
    SET_FIRST_APP_WITH_CONNECTION_STATE: TAG + 'Set First App With Connection State',
    SET_APP_WITH_CONNECTION_STATE: TAG + 'Set App With Connection State',
    SET_APP_STATE_WITH_LOCAL_CONNECTION: TAG + 'Set App With Local Connection',
    SET_APP_STATE_WITH_SERVER_CONNECTION: TAG + 'Set App With Server Connection',
    ADD_APP_STATE_RECONNECTING_TIME: TAG + 'Add App State Reconnecting Time',
    RESET_APP_STATE_RECONNECTING_TIME: TAG + 'Reset App State Reconnecting Time',
    SET_TRYING_RECONNECTING: TAG + 'Set Trying Reconnecting'
} ;
// readonly app_state_with_local_connection: boolean;
// readonly app_state_with_server_connection: boolean;
export const ChangedConnectionOnlineLocalAction = createAction(
    AppStateNamespaceActions.CHANGED_CONNECTION_ONLINE_LOCAL
);
export const ChangedConnectionOfflineLocalAction = createAction(
    AppStateNamespaceActions.CHANGED_CONNECTION_OFFLINE_LOCAL
);
export const ChangedConnectionOnlineServerAction = createAction(
    AppStateNamespaceActions.CHANGED_CONNECTION_ONLINE_SERVER
);
export const ChangedConnectionOfflineServerAction = createAction(
    AppStateNamespaceActions.CHANGED_CONNECTION_OFFLINE_SERVER
);

// export const ConnectionChangedAction = createAction(
//     AppStateNamespaceActions.CONNECTION_CHANGED,
//     props<{with_connection: boolean}>()
// );

export const SetAllDataLoadedAction = createAction(
    AppStateNamespaceActions.SET_ALL_DATA_LOADED,
    props<{all_data_loaded: boolean}>()
);

export const SetFirstAppWithConnectionStateAction = createAction(
    AppStateNamespaceActions.SET_FIRST_APP_WITH_CONNECTION_STATE,
    props<{state: FirstAppWithConnectionState, error: Option<StandardError>}>()
);

export const SetAppWithConnectionStateAction = createAction(
    AppStateNamespaceActions.SET_APP_WITH_CONNECTION_STATE,
    props<{state: AppWithConnectionState, error: Option<StandardError>}>()
);
export const SetAppStateWithLocalConnectionAction = createAction(
    AppStateNamespaceActions.SET_APP_STATE_WITH_LOCAL_CONNECTION,
    props<{app_state_with_local_connection: boolean}>()
);
export const SetAppStateWithServerConnectionAction = createAction(
    AppStateNamespaceActions.SET_APP_STATE_WITH_SERVER_CONNECTION,
    props<{app_state_with_server_connection: boolean}>()
);
export const AddAppStateReconnectingTimeAction = createAction(
    AppStateNamespaceActions.ADD_APP_STATE_RECONNECTING_TIME
);
export const ResetAppStateReconnectingTimeAction = createAction(
    AppStateNamespaceActions.RESET_APP_STATE_RECONNECTING_TIME
);
// export const SetTryingReconnectingAction = createAction(
//     AppStateNamespaceActions.SET_TRYING_RECONNECTING,
//     props<{trying_reconnecting: boolean}>()
// )
// ADD_APP_STATE_RECONNECTING_TIME: TAG + 'Add App State Reconnecting Time',
//     RESET_APP_STATE_RECONNECTING_TIME: TAG + 'Reset App State Reconnecting Time'
