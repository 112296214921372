import {Injectable, Injector} from '@angular/core';
import {CookieStoreService} from '../localStorage/cookie-store.service';
import {MessagingService} from './messaging.service';
import {catchError, flatMap, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, concat, Observable, of, Subject} from 'rxjs';
import {Guid} from 'guid-typescript';
import {LocalStorageKeyNotFoundException} from '../localStorage/LocalStorageKeyNotFoundException';
import {ThemeService} from '../themes/theme.service';
import {IPushMessaging} from './IPushMessaging';
import {StubmessagingService} from './stubmessaging.service';


@Injectable({
    providedIn: 'root'
})
export class FcmService {

    private static FCM_TAG_TOKEN = "FCM_TOKEN_ZONETACTS";
    private static TAG_DEVICE_ID = "DEVICE_ID_ZONETACTS";

    private onNewFcmTokenSubject = new Subject<string>();

    private messagingService : IPushMessaging;

    private subscriptions = [];

    constructor(
        private storeService: CookieStoreService,
        private themeService: ThemeService,
        private injector: Injector
        // private messagingService: MessagingService
    ) {


        if (themeService.existsFirebaseProject()) {
            this.messagingService = this.injector.get(MessagingService);
        } else {
            this.messagingService = this.injector.get(StubmessagingService);
        }

        console.log("INIT FCM SERVICE", themeService.existsFirebaseProject())

        this.subscriptions.push(
            this.onNewFcmTokenSubject.asObservable().pipe(
                switchMap(token => storeService.putById(FcmService.FCM_TAG_TOKEN, token))
            ).subscribe()
        );

        this.subscriptions.push(
            storeService.existsById(FcmService.TAG_DEVICE_ID).pipe(
                switchMap(exists => {
                    if (exists) {
                        return of({});
                    } else {
                        return storeService.putById(FcmService.TAG_DEVICE_ID, Guid.raw())
                    }
                })
            ).subscribe()
        );

        this.subscriptions.push(
            this.messagingService.onTokenChanges().subscribe(
                token => this.onNewFcmTokenSubject.next(token)
            )
        );

        this.subscriptions.push(
            storeService.existsById(FcmService.FCM_TAG_TOKEN).pipe(
                switchMap(exists => {
                    console.log("REQUESTING TOKEN", exists)
                    if (exists) {
                        return of({});
                    } else {
                        return this.messagingService.requestToken().pipe(
                            tap(t => console.log(t)),
                            tap(token => this.onNewFcmTokenSubject.next(token)),
                            switchMap(token => storeService.putById(FcmService.FCM_TAG_TOKEN, token))
                        );
                        // return messagingService.requestToken().pipe(
                        //     flatMap(token => storeService.putById(FcmService.FCM_TAG_TOKEN, token))
                        // );
                    }
                })
            ).subscribe(
                _ => {},
                err => {
                    console.log({text: "ERROR WHEN REQUESTING THE TOKEN", err: err});
                }
            )
        );

        this.subscriptions.push(
            this.messagingService.onNewMessage().subscribe(
                msg => console.log({text: "push message in infraestructure", m: msg})
            )
        );
    }

    getFcmInfo() : Observable<{fcmToken: string, deviceId: string}> {
        return this.storeService.getById(FcmService.FCM_TAG_TOKEN).pipe(
            catchError(err => {
                if (err instanceof LocalStorageKeyNotFoundException) {
                    return of(" ");
                }
                return of(" ");
            }),
            switchMap(fcmToken => this.storeService.getById(FcmService.TAG_DEVICE_ID).pipe(
                catchError(err => {
                    if (err instanceof LocalStorageKeyNotFoundException) {
                        return of(" ");
                    }
                    throw err;
                }),
                map(deviceId => ({fcmToken: fcmToken, deviceId: deviceId}))
            ))
        );
    }

    getFcmInfoObservable() : Observable<{fcmToken: string, deviceId: string}> {
        return this.onNewFcmTokenSubject.asObservable().pipe(
            switchMap(token => this.storeService.getById(FcmService.TAG_DEVICE_ID).pipe(
                map(deviceId => ({fcmToken: token, deviceId: deviceId}))
            ))
        );
    }

    deleteFcmToken() : Observable<{}> {
        return this.storeService.getById(FcmService.FCM_TAG_TOKEN).pipe(
            catchError(err => {
                return of(" ");
            }),
            switchMap(token => {
                if (token !== " ")
                    return this.messagingService.deleteToken(token);
                else
                    return of({});
            }),
            switchMap(_ => concat(this.storeService.removeById(FcmService.FCM_TAG_TOKEN), of({}))),
            catchError(err => {
                console.log(err);
                return of({});
            })
        );
    }

    getDeviceId() : Observable<string> {
        return this.storeService.getById(FcmService.TAG_DEVICE_ID);
    }
}
