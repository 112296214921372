import {DeviceType, Session} from "../../core/domain/Session";
import {SessionElementList} from "./SessionElementList";
import {
    getDateStringForMessageFileFooterByDate,
    getDateStringOfListByDate
} from "../../../chatmodule/mappers/dates.mappers";
import {TableShowerComponent} from "../../table-shower/table-shower.component";
import {TableShowerModel} from "../../table-shower/TableShowerModel";
import {pipe} from "fp-ts/lib/pipeable";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";


export function getSessionElementListFromSession(s: Session) : SessionElementList {
    const url = s.deviceType === DeviceType.Android ? 'assets/icons/android.webp' :
        (s.deviceType === DeviceType.IOS ?
            'assets/icons/ios.png' :
            'assets/icons/computer.jpg'
        );
    return new SessionElementList(
        s.deviceId,
        url,
        s.systemVersion + ' ' + s.deviceModel,
        s.appVersion,
        getDateStringForMessageFileFooterByDate(s.createdAt),
        pipe(
            s.lastActive,
            O.fold(
                () => "Connected",
                s => getDateStringForMessageFileFooterByDate(s),
            )
        ),
        s.active
    );
}


// readonly deviceTypeUrl: string;
// readonly deviceInfo: string;
// readonly appVersion: string;
// readonly ipOrig: string;
// readonly createdAt: string;
// readonly active: boolean;

export function getTableShowerModelFromSessionsElementList(s: SessionElementList[], appendInactive: boolean) : TableShowerModel {

    const menus : Pick<TableShowerModel, "menus"> = {menus: [
        {
            title: "Active sessions",
            secondTitle: "actives",
            description: "The active sessions",
            columnsHeaders: [
                "Platform", "Last connected", "Device Info", "App Version", "Created At", "Invalidate"
            ],
            rows: pipe(
                s,
                A.filter(s => s.active),
                A.map(({deviceTypeUrl, lastActive, deviceInfo, appVersion, createdAt, deviceId}) => [
                    {type: 'image', info: deviceTypeUrl},
                    {type: 'text', info: lastActive},
                    {type: 'text', info: deviceInfo},
                    {type: 'text', info: appVersion},
                    {type: 'text', info: createdAt},
                    {type: 'buttonImage', info: "assets/icons/25706.png", idButton: deviceId}
                ])
            )
        }
    ]};

    if (appendInactive) {
        menus.menus.push(
            {
                title: "Inactive sessions",
                secondTitle: "inactive",
                description: "The inactive sessions",
                columnsHeaders: [
                    "Platform", "Last connected", "Device Info", "App Version", "Created At"
                ],
                rows: pipe(
                    s,
                    A.filter(s => !s.active),
                    A.map(({deviceTypeUrl, lastActive, deviceInfo, appVersion, createdAt}) => [
                        {type: 'image', info: deviceTypeUrl},
                        {type: 'text', info: lastActive},
                        {type: 'text', info: deviceInfo},
                        {type: 'text', info: appVersion},
                        {type: 'text', info: createdAt}
                    ])
                )
            }
        )
    }

    return {
        title: "Sessions",
        generalDescription: "Sessions description",
        ...menus
    }
}
