import {Component, HostBinding, OnInit} from '@angular/core';
import {ThemeService} from '../themes/theme.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit{
    //Variables
    currentDate : Date = new Date();

    version : string;


    constructor(private themeService: ThemeService) {

    }

    ngOnInit(): void {
        this.version = this.themeService.version;
    }
}
