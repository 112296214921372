import {Option} from "fp-ts/lib/Option";

export class Discover {

    readonly idDiscover: number;
    readonly name: string;
    readonly description: string;
    readonly position: number;
    readonly idRoom: number;
    readonly image: Option<Blob>;
    readonly vinculated: boolean;


    constructor(idDiscover: number, name: string, description: string, position: number, idRoom: number, image: Option<Blob>, vinculated: boolean) {
        this.idDiscover = idDiscover;
        this.name = name;
        this.description = description;
        this.position = position;
        this.idRoom = idRoom;
        this.image = image;
        this.vinculated = vinculated;
    }
}

