import {Injectable} from "@angular/core";
import {ThemeService} from "../../../themes/theme.service";
import {HttpHeadersManager} from "../../sources/remote/core/headers/HttpHeadersManager";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {UsersApihelper} from "../../sources/remote/users/users.apihelper";
import {RoomsApihelper} from "../../sources/remote/rooms/rooms.apihelper";
import {
    getCompleteUrlFileOneToOneFromParams,
    getCompleteUrlFileRoomFromParams,
    getUrlFileOneToOneFromParams
} from "../../../utils/urls.utils";
import {SocketsAuthService} from "../../../sockets-auth/sockets-auth.service";
import {Either} from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/pipeable";
import {either as E} from "fp-ts";


@Injectable({
    providedIn: 'root'
})
export class LinkGenerators {

    constructor(
        private themeService: ThemeService,
        private httpHeadersManager: HttpHeadersManager,
        private usersApiHelper: UsersApihelper,
        private roomsApiHelper: RoomsApihelper,
        private socketsAuthService: SocketsAuthService
    ) {}

    getUserImageLinkFromIdUser(idUser: number) : Observable<string> {
        return this.httpHeadersManager.getAuthorizationToken().pipe(
            map(token => {
                const addr = this.themeService.getBackendUrlApiComplete();
                const path = this.usersApiHelper.getUsersIdImageprofilePath(idUser);
                return addr + path + "?authorization=" + token + "&&" + "date=" + new Date().getTime()
            })
        )
    }

    getRoomImageLinkFromIdRoom(idRoom: number) : Observable<string> {
        return this.httpHeadersManager.getAuthorizationToken().pipe(
            map(token => {
                const addr = this.themeService.getBackendUrlApiComplete();
                const path = this.roomsApiHelper.getRoomsIdrImageprofilePath(idRoom);
                return addr + path + "?authorization=" + token + "&" + "" + new Date().getTime()
            })
        )
    }

    getLinkResourceOneToOneFromMessage(idUser: number, idMessage: string, headInfoOrParams: Either<{}, {deviceId: string, requestId: string}>) : Observable<string> {
        return this.httpHeadersManager.getAuthorizationToken().pipe(
            map(token => {
                const addr = this.themeService.getBackendUrlApiComplete();
                const path = getCompleteUrlFileOneToOneFromParams(addr, idUser, idMessage);
                const param = pipe(
                    headInfoOrParams,
                    E.fold(
                        _ => "headinfo=a",
                        ({deviceId, requestId}) => `deviceid=${deviceId}&requestid=${requestId}`
                    )
                )
                return `${path}?authorization=${token}&${new Date().getTime()}&${param}`;
            })
        )
    }

    getLinkResourceRoomFromMessage(idRoom: number, idMessage: string, headInfoOrParams: Either<{}, {deviceId: string, requestId: string}>) : Observable<string> {
        return this.httpHeadersManager.getAuthorizationToken().pipe(
            map(token => {
                const addr = this.themeService.getBackendUrlApiComplete();
                const path = getCompleteUrlFileRoomFromParams(addr, idRoom, idMessage);
                const param = pipe(
                    headInfoOrParams,
                    E.fold(
                        _ => "headinfo=a",
                        ({deviceId, requestId}) => `deviceid=${deviceId}&requestid=${requestId}`
                    )
                )
                return `${path}?authorization=${token}&${new Date().getTime()}&${param}`;
            })
        )
    }

}
