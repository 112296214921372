import {AppFacade} from '../core/sources/storage/facade/app.facade';
import {FcmService} from './fcm.service';
import {combineLatest, concat, Observable, Subscription} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {StandardError} from "../core/domain/StandardError";
import {Either} from "fp-ts/lib/Either";
import * as E from "fp-ts/lib/Either";

@Injectable({
    providedIn: 'root'
})
export class FcmFacade {

    private fcmInfoSubscription : Subscription = null;

    constructor(
        private appFacade : AppFacade,
        private fcmService: FcmService
    ) {

        // fcmService.getFcmInfoObservable().pipe(flatMap(({fcmToken, deviceId}) => this.appFacade.refreshToken(fcmToken, deviceId)
    }


    initFcm() : Observable<Either<StandardError, {}>> {
        return new Observable<Either<StandardError, {}>>(subscriber => {
            if (this.fcmInfoSubscription !== null) {
                this.fcmInfoSubscription.unsubscribe();
            }
            this.fcmInfoSubscription = this.fcmService.getFcmInfoObservable().pipe(
                flatMap(({fcmToken, deviceId}) => this.appFacade.dispatchRefreshFirebaseToken(fcmToken, deviceId))
            ).subscribe();
            subscriber.next(E.right({}));
            subscriber.complete();
        });
    }

    proceedLogout() : Observable<{}> {
        return combineLatest([
            new Observable<{}>(subscriber => {
                console.log("LOGOUT proceedLogout()");
                if (this.fcmInfoSubscription !== null)
                    this.fcmInfoSubscription.unsubscribe();
                subscriber.next({});
                subscriber.complete();
            }),
            this.fcmService.deleteFcmToken()
        ]);
    }

    getDeviceId() : Observable<string> {
        return this.fcmService.getDeviceId();
    }

}
