




export const InputEvent = {
    MESSAGE_ROOM: "chat message room",
    MESSAGE_USER: "chat message user",
    APP_ERROR: "appError",
    ERROR: "error",
    CONNECTED: "connect",
    JOINED_ROOM: "joined room",
    QUIT_ROOM: "quit room",
    ONE_TO_ONE_DELETED: "one-to-one-deleted",
    ROOM_ADMINS: "room-admins",
    DISCONNECTED: "disconnect",
    ROOM_MODIFIED: "room-modified",
    USER_MODIFIED: "user-modified",
    NEW_USER: "new-user",
    USER_DELETED: "user-deleted",
    ROOM_READED_MESSAGES: "room-readed-messages",
    OTO_READED_MESSAGES: "oto-readed-messages",
    ACK_MSG: "ack-msg",
    NEW_DISCOVER: "new-discover",
    DISCOVER_MODIFIED: "discover-modified",
    DISCOVER_DELETED: "discover-deleted",
    ROOM_MESSAGE_MODIFIED: "room-message-modified",
    OTO_MESSAGE_MODIFIED: "oto-message-modified",
    CONFIRMATION_FILE: "confirmation-file"
};

export const OutputEvent = {
    MESSAGE_ROOM: "chat message room",
    MESSAGE_USER: "chat message user",
    CONFIRMATION_FILE: "confirmation-file"
};
