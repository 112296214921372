import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";

import { SnotifyModule, SnotifyService, ToastDefaults } from "ng-snotify";

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";

import * as $ from "jquery";
import * as croppie from "croppie";
import { LoginRepository } from "./shared/core/repositories/login/login.repository";
import { CookieService } from "ngx-cookie-service";
import { appReducer } from "./shared/core/sources/storage/reducers/app.reducer";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./shared/core/sources/storage/effects/app.effects";
import { RoomEffects } from "./shared/core/sources/storage/effects/room.effects";
import { UserEffects } from "./shared/core/sources/storage/effects/user.effects";
import { MessageEffects } from "./shared/core/sources/storage/effects/message.effects";
import { NgxImageCompressService } from "ngx-image-compress";
import { NgxSpinnerModule } from "ngx-spinner";
import { FilemessagesEffects } from "./shared/core/sources/storage/effects/filemessages.effects";
import { DiscoversEffects } from "./shared/core/sources/storage/effects/discovers.effects";

import { ServiceWorkerModule } from "@angular/service-worker";

import { AngularFireModule } from "@angular/fire";

import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { existsFirebaseProject, selectedTheme } from "./shared/themes/theme";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppService } from "./app.service";
import { MetaEffects } from "./shared/core/sources/storage/effects/first_app_with_connection/meta.effects";
import { SocketsEffects } from "./shared/core/sources/storage/effects/sockets/sockets.effects";
import * as AppWithConnectionEffects from "./shared/core/sources/storage/effects/app_with_connection/meta.effects";
import { AppStateNamespaceEffects } from "./shared/core/sources/storage/effects/namespaces/app_state_namespace.effects";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { InitialProcessesExecutionEffects } from "./shared/core/sources/storage/effects/principal_screen_app/initial_processes_execution.effects";
import { SendMessagesNotSendedEffects } from "./shared/core/sources/storage/effects/principal_screen_app/send_messages_not_sended.effects";
import { SynchronizeMessagesEffects } from "./shared/core/sources/storage/effects/principal_screen_app/synchronize_messages.effects";
import { UserValidationEffects } from "./shared/core/sources/storage/effects/principal_screen_app/user_validation.effects";
import {
    NgcCookieConsentConfig,
    NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { layout, message_literal } from "./CookieModalLiterals";
import { environment } from "../environments/environment";
import { LayoutModule } from "@angular/cdk/layout";
import { MobileComponent } from "./layouts/mobile/mobile.component";

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const importsArray = [
    BrowserAnimationsModule,
    // ChatmoduleModule,
    // AuthGuard,
    // StoreModule.forRoot({}),
    NgxSpinnerModule,
    AppRoutingModule,
    SharedModule,
    SnotifyModule.forRoot(),
    HttpClientModule,
    NgbModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
        },
    }),
    AgmCoreModule.forRoot({
        apiKey: "AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo",
    }),
    // StoreModule.forRoot({room: roomReducerFunction}, {initialState: ge}),
    StoreModule.forRoot(appReducer),
    StoreDevtoolsModule.instrument({
        maxAge: 50,
        // logOnly: environment.production
    }),
    // ...(environment.production ? [] : [StoreDevtoolsModule.instrument({
    //     maxAge: 50,
    //     // logOnly: environment.production
    // })]),
    EffectsModule.forRoot([
        AppEffects,
        RoomEffects,
        UserEffects,
        MessageEffects,
        FilemessagesEffects,
        DiscoversEffects,
        MetaEffects,
        SocketsEffects,
        AppWithConnectionEffects.MetaEffects,
        AppStateNamespaceEffects,
        InitialProcessesExecutionEffects,
        SendMessagesNotSendedEffects,
        SynchronizeMessagesEffects,
        UserValidationEffects,
    ]),
    // CookieModule.forRoot()
    // SweetAlert2Module.forRoot()
    // ServiceWorkerModule.register('assets/js/sw/firebase-messaging-sw.js', { enabled: true} ),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    ServiceWorkerModule.register("firebase-messaging-sw.js", { enabled: true }),
    ScrollingModule,
    LayoutModule,
    // NgxEditorModule
    // AngularEditorModule,
    // NgxEditorModule
    // NgcCookieConsentModule.forRoot(cookieConfig)
    // FilterPipe
];

if (existsFirebaseProject()) {
    console.log("IMPORTING FIREBASE CONFIG");
    console.log({ config: selectedTheme.firebase_config });
    importsArray.push(
        ...[
            AngularFireModule.initializeApp(selectedTheme.firebase_config),
            AngularFireMessagingModule,
        ]
    );
}

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        MobileComponent,
    ],
    imports: importsArray,
    providers: [
        { provide: "SnotifyToastConfig", useValue: ToastDefaults },
        SnotifyService,
        AuthService,
        AuthGuard,
        DragulaService,
        LoginRepository,
        CookieService,
        NgxImageCompressService,
        AppService,
        // AppFacade,
        // MessagingService,
        // FcmService,
        // FcmFacade,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
platformBrowserDynamic().bootstrapModule(AppModule);
// document.addEventListener('DOMContentLoaded', () => {
//     platformBrowserDynamic().bootstrapModule(AppModule)
//         .catch(err => console.log(err));
// });
