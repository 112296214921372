import {Option} from "fp-ts/lib/Option";
import {SynchronizationState} from './SynchronizationState';
import {FileType} from './FileType';
import {pipe} from "fp-ts/lib/pipeable";
import {option as O} from "fp-ts";

export enum ModifiedState {
    NOTHING = 0, MODIFIED = 1, DELETED = 2
}

export class MessageReply {
    idUser: number;
    idMessage: string;
    date: Date;
    text: string;
    file: Option<Blob>;
    modifiedState: ModifiedState;

    constructor(idUser: number, idMessage: string, date: Date, text: string, file: Option<Blob>, modifiedState: ModifiedState) {
        this.idUser = idUser;
        this.idMessage = idMessage;
        this.date = date;
        this.text = text;
        this.file = file;
        this.modifiedState = modifiedState;
    }
}

export class MessageLinkPreview {
    description: Option<string>;
    title: string;
    url: string;
    type: "link"|"video";
    videoUrl: Option<string>;
    image: Option<Blob>;

    constructor(description: Option<string>, title: string, url: string, type: "link" | "video", videoUrl: Option<string>, image: Option<Blob>) {
        this.description = description;
        this.title = title;
        this.url = url;
        this.type = type;
        this.videoUrl = videoUrl;
        this.image = image;
    }
}

export type ErrorMessageFile = {
    error: string,
    file: Option<{
        base64Thumbnail: string,
        file: Blob,
        linkFileBlob: string
    }>
}

export abstract class Message {
    constructor(
        readonly idMessage: string,
        readonly idUser: number,
        readonly date: Date,
        readonly text: string,
        readonly internalId: string,
        readonly syncState: SynchronizationState,
        readonly thumbnail: Blob | undefined,
        readonly fileType: FileType | undefined,
        readonly extension: string | undefined,
        // readonly localLinkResource: string | undefined,
        readonly durationMedia: number | undefined,
        readonly messageReply: Option<MessageReply>,
        readonly modifiedState: ModifiedState,
        readonly linkPreview: Option<MessageLinkPreview>,
        readonly error: Option<ErrorMessageFile>,
        readonly retriesSent: Option<number>
    ) {

    }

    getNextRetriesSent() : number {
        return pipe(
            this.retriesSent,
            O.fold(
                () => 1,
                r => r+1
            )
        )
    }

    isLocalMessage() : boolean {
        return this.syncState === SynchronizationState.FAIL || this.syncState === SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED;
    }

    // abstract changeSynchronizationState<T extends Message>(state: SynchronizationState, id: Option<string>, error: Option<string>) : T
}
