import {Discover} from '../../../domain/Discover';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';


function sortByPosition(a: Discover, b: Discover) : number {
    return a.position - b.position;
}

export const adapterDiscovers : EntityAdapter<Discover> = createEntityAdapter<Discover>({
    selectId: (d: Discover) => d.idDiscover,
    sortComparer: sortByPosition
});

