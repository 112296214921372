import { UsersFacade } from "../core/sources/storage/facade/users.facade";
import { RoomsFacade } from "../core/sources/storage/facade/rooms.facade";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, zip } from "rxjs";
import { ResendElementViewModel } from "./model/ResendElementViewModel";
import { map, switchMap, take, tap } from "rxjs/operators";
import { pipe } from "fp-ts/lib/pipeable";
import { array as A, option as O, either as E } from "fp-ts";
import { PartialRoomOTO } from "../core/sources/storage/entities/PartialRoomOTO";
import { PartialUser } from "../core/sources/storage/entities/PartialUser";
import { PartialRoomGroup } from "../core/sources/storage/entities/PartialRoomGroup";
import { ordString } from "fp-ts/lib/Ord";
import { getNameForUser } from "../core/domain/User";
import { normalizeString } from "../utils/text.utils";
import { MessagesApihelper } from "../core/sources/remote/messages/messages.apihelper";
import { PostRoomsMessagesResendmessageRequestModel } from "../core/sources/remote/messages/models/PostRoomsMessagesResendmessageRequestModel";
import { Either } from "fp-ts/lib/Either";
import { AlertsService } from "../utils/alerts.service";
import { MetaFacade } from "../core/sources/storage/facade/generic/meta.facade";

@Injectable()
export class ResendService {
    rowsRooms: ResendElementViewModel[];
    subjectRowsRooms: BehaviorSubject<ResendElementViewModel[]>;
    rowsUsers: ResendElementViewModel[];
    subjectRowsUsers: BehaviorSubject<ResendElementViewModel[]>;

    // subjectSelectedRows: BehaviorSubject<ResendElementViewModel>

    constructor(
        private readonly usersFacade: UsersFacade,
        private readonly roomsFacade: RoomsFacade,
        private readonly metaFacade: MetaFacade,
        private readonly messagesApiHelper: MessagesApihelper,
        private readonly alertsService: AlertsService
    ) {}

    init() {
        this.subjectRowsRooms = new BehaviorSubject([]);
        this.subjectRowsUsers = new BehaviorSubject([]);
        this.rowsRooms = [];
        this.rowsUsers = [];
        zip(
            this.roomsFacade.getAllRooms(),
            this.usersFacade.getAllUsers(),
            this.metaFacade.getOwnDataUser()
        )
            .pipe(
                // TODO: Delete this once we figure out why the usersRoom are only being loaded after the room has been clicked (check the reducer?)
                tap(([rooms, users, ownDataUser]) => {
                    console.log(
                        "room users",
                        rooms[0]
                            .filter((r) => r.usersRoom.ids.length > 0)
                            .map((r) => {
                                return {
                                    idRoom: r.roomGroup.idRoom,
                                    admin: r.usersRoom.entities[
                                        ownDataUser.idUser
                                    ].authorized,
                                };
                            })
                    );
                    return [rooms, users, ownDataUser];
                }),
                map(([[rooms, otos], users, ownDataUser]) => {
                    const otosWithUsers = pipe(
                        otos,
                        A.map((oto) =>
                            pipe(
                                users,
                                A.findFirst(
                                    (u) => oto.roomOTO.idUser === u.user.idUser
                                ),
                                O.map((u) => ({ u: u, oto: oto }))
                            )
                        ),
                        A.compact
                    );
                    return {
                        rooms: pipe(
                            rooms,
                            A.filter(
                                (r) =>
                                    r.roomGroup.bidirectional ||
                                    (r.usersRoom.entities[ownDataUser.idUser] &&
                                        r.usersRoom.entities[ownDataUser.idUser]
                                            .authorized)
                            )
                        ),
                        otosWithUsers: otosWithUsers,
                        users: users,
                        ownDataUser: ownDataUser,
                    };
                }),
                map(({ rooms, otosWithUsers, users, ownDataUser }) => {
                    const mergedRooms = this.mergeByDate(
                        pipe(
                            rooms,
                            A.map((r) =>
                                ResendElementViewModel.fromPartialRoomGroup(r)
                            )
                        ),
                        pipe(
                            otosWithUsers,
                            A.map(({ u, oto }) =>
                                ResendElementViewModel.fromPartialRoomOTO(
                                    oto,
                                    u.user
                                )
                            )
                        )
                    );
                    const userRows = pipe(
                        users,
                        A.filter(
                            (user) =>
                                otosWithUsers.length === 0 ||
                                !otosWithUsers.some(
                                    (oto) =>
                                        oto.oto.roomOTO.idUser ===
                                        user.user.idUser
                                )
                        ),
                        A.filter(
                            (user) => user.user.idUser !== ownDataUser.idUser
                        ),
                        A.sort({
                            compare: (x, y) =>
                                ordString.compare(
                                    normalizeString(getNameForUser(x.user)),
                                    normalizeString(getNameForUser(y.user))
                                ),
                            equals: (x, y) =>
                                ordString.equals(
                                    normalizeString(getNameForUser(x.user)),
                                    normalizeString(getNameForUser(y.user))
                                ),
                        }),
                        A.map((u) => ResendElementViewModel.fromUser(u.user))
                    );
                    return {
                        rooms: mergedRooms,
                        users: userRows,
                    };
                })
            )
            .subscribe(({ rooms, users }) => {
                this.rowsRooms = rooms;
                this.subjectRowsRooms.next(rooms);
                this.rowsUsers = users;
                this.subjectRowsUsers.next(users);
            });
    }

    select(row: ResendElementViewModel) {
        const value = pipe(
            this.rowsRooms,
            A.findFirst(
                (r) => r.idRoom === row.idRoom && r.oneToOne === r.oneToOne
            ),
            O.toNullable
        );
        if (value) {
            value.setSelected();
            this.subjectRowsRooms.next(this.rowsRooms);
        } else {
            const valueUser = pipe(
                this.rowsUsers,
                A.findFirst(
                    (r) => r.idRoom === row.idRoom && r.oneToOne === r.oneToOne
                ),
                O.toNullable
            );
            if (valueUser) {
                valueUser.setSelected();
                this.subjectRowsUsers.next(this.rowsUsers);
            }
        }
    }

    // O(2n)
    mergeByDate(
        g1: ResendElementViewModel[],
        g2: ResendElementViewModel[]
    ): ResendElementViewModel[] {
        const list = [];
        let i = 0;
        let j = 0;
        while (i < g1.length && j < g2.length) {
            const v = g1[i].compare(g2[j]);
            if (v === 1) {
                list.push(g1[i]);
                i++;
            } else if (v === -1) {
                list.push(g2[j]);
                j++;
            } else {
                list.push(g1[i]);
                i++;
                list.push(g2[j]);
                j++;
            }
        }
        if (i >= g1.length) {
            while (j < g2.length) {
                list.push(g2[j]);
                j++;
            }
        }
        if (j >= g2.length) {
            while (i < g1.length) {
                list.push(g1[i]);
                i++;
            }
        }
        console.log({ text: "To order", g1: g1, g2: g2, result: list });
        return list;
    }

    onSend(
        fromIdMessage: string,
        fromIdRoom: number,
        oneToOne: boolean
    ): Observable<Either<{}, {}>> {
        const roomsAndUsersSelected = pipe(
            [...this.rowsUsers, ...this.rowsRooms],
            A.filter((r) => r.selected)
        );

        const model: PostRoomsMessagesResendmessageRequestModel = {
            fromIdMessage: fromIdMessage,
            fromIdRoom: fromIdRoom,
            oneToOne: oneToOne ? 1 : 0,
            destinations: pipe(
                roomsAndUsersSelected,
                A.map((r) => ({
                    toIdRoom: r.idRoom,
                    oneToOne: r.oneToOne ? 1 : 0,
                }))
            ),
        };
        return this.messagesApiHelper
            .postRoomsMessagesResendMessage(model)
            .pipe(
                map((result) =>
                    pipe(
                        result,
                        E.fold(
                            (exc) => {
                                this.alertsService.showAlertFromStandardError(
                                    exc
                                );
                                return E.left({});
                            },
                            (_) => E.right(_)
                        )
                    )
                )
            );
    }
}
