import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TableShowerModel} from "./TableShowerModel";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'app-table-shower',
  templateUrl: './table-shower.component.html',
  styleUrls: ['./table-shower.component.scss']
})
export class TableShowerComponent implements OnInit, AfterViewInit, OnChanges {
    // @Input()
    // title: string;
    //
    // @Input()
    // generalDescription: string;
    //
    // @Input()
    // menus: {
    //     title: string;
    //     secondTitle: string;
    //     description: string;
    //     // tabId: string;
    //     columnsHeaders: string[];
    //     rows: string[][];
    // }[];
    @Input()
    model: Observable<TableShowerModel>;

    @Output()
    buttonPressed = new EventEmitter<string>();

    show = new BehaviorSubject(false);

    constructor() { }

    ngOnInit() {
        this.show.next(true);


        // jQuery('#cliModalClose').click(function() {
        //     $(".cli-modal").removeClass("cli-blowup");
        // });

    }

    onButtonPressed(id: string) {
        this.buttonPressed.emit(id);
    }

    ngAfterViewInit(): void {

        console.log({text: "after view init table-shower", model: this.model})

        $('.cli-tab-header').on("click", function(e) {
            if(!(jQuery(e.target).hasClass('cli-slider') || jQuery(e.target).hasClass('cli-user-preference-checkbox'))) {
                if (jQuery(this).hasClass("cli-tab-active")) {
                    jQuery(this).removeClass("cli-tab-active");
                    jQuery(this)
                        .siblings(".cli-tab-content")
                        .slideUp(200);
                } else {
                    jQuery(".cli-tab-header").removeClass("cli-tab-active");
                    jQuery(this).addClass("cli-tab-active");
                    jQuery(".cli-tab-content").slideUp(200);
                    jQuery(this)
                        .siblings(".cli-tab-content")
                        .slideDown(200);
                }
            }
        });
        this.model.subscribe(
            menus => {
                // for (const tab of menus.menus) {

                // }
            }
        )


        jQuery('#openpopup').click(function() {
            $(".cli-modal").addClass("cli-blowup");
        });

    }

    ngOnChanges(changes: SimpleChanges): void {

    }



}
