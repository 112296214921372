// import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from "./environments/environment";
// import { environment } from './environments/environment';

if (environment.production) {
    console.log("Production enabled")
    try {
        enableProdMode();
    } catch (e) {
        console.log("Error prod mode", e)
    }
    window.console.log = function () {
    };
    window.console.log = function (...args) {
    }
    console.log = function () {
    }
    console.log = function (...args) {
    }

}

platformBrowserDynamic().bootstrapModule(AppModule);
