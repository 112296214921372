import {
    initialRoomGroupState,
    initialRoomOTOState,
    IRoomGroupState,
    IRoomOTOState,
} from "../state/room.state";
import { Action, createReducer, on } from "@ngrx/store";
import {
    addUsersToRoomSuccess,
    cleanMessagesNotReadedRoomGroup,
    cleanMessagesNotReadedRoomGroupSuccess,
    cleanMessagesNotReadedRoomOTO,
    cleanMessagesNotReadedRoomOTOSuccess,
    deleteUsersFromRoomSuccess,
    getUsersRoom,
    getUsersRoomSuccess,
    increaseMessagesNotReadedByOneRoomGroup,
    increaseMessagesNotReadedByOneRoomOTO,
    leaveRoomGroupSuccess,
    leaveRoomOTOSuccess,
    // loadAllImageRoomsGroups,
    // loadAllImageRoomsGroupsSuccess,
    // loadOneImageRoomGroup,
    // loadOneImageRoomGroupSuccess,
    // loadRoomsAllSuccess,
    modifyRoomInfoSuccess,
    // loadRoomsGroups,
    // loadRoomsGroupsSuccess,
    // loadRoomsOtos,
    // loadRoomsOtosSuccess,
    newRoomGetted,
    onCreateRoom,
    onCreateRoomSuccess,
    OnMessageInputUpdatedOTOAction,
    OnMessageInputUpdatedRoomAction,
    OnModifyAdminsRoomAction,
    onModifyImageRoom,
    onModifyNameRoom,
    onOpenOTOSuccess,
    onQuitUserRoom,
    selectRoomSuccess,
    UpdateLastMessageOTOAction,
    UpdateLastMessageRoomAction,
} from "../actions/room.actions";
import {
    adapterRoomGroup,
    adapterRoomOTO,
    adapterUserRoom,
} from "../adapters/rooms.adapters";
import { PartialRoomGroup } from "../entities/PartialRoomGroup";
import { Update } from "@ngrx/entity";
import {
    LoadFromDateMessageOTOSuccessAction,
    LoadFromDateMessageRoomSuccessAction,
    // loadFirstPageMessagesGroupSuccess, loadFirstPageMessagesOTOSuccess,
    // loadFirstsMessagesAllOTOSSuccess,
    // loadFirstsMessagesAllRoomsSuccess,
    loadNextPageMessagesOTOSuccess,
    loadNextPageMessagesRoomSuccess,
    LoadRemainMessagesRoomSuccessAction,
    onDeleteMessageOTOFailAction,
    onDeleteMessageOTOLocalAction,
    onDeleteMessageOTOSuccessAction,
    onDeleteMessageRoomSuccessAction,
    onNewMessageOTO,
    onNewMessageRoom,
    onNewModificationMessageOTOAction,
    onNewModificationMessageRoomAction,
    onSendMessageOTO,
    onSendMessageOTOFile,
    onSendMessageOTOSuccess,
    onSendMessageRoom,
    onSendMessageRoomFile,
    onSendMessageRoomFileSuccess,
    onSendMessageRoomSuccess,
} from "../actions/messages.actions";
import {
    getRoomGroupWithMessageStateByRoomGroup,
    getRoomGroupWithNewMessageStateByRoomGroup,
    getRoomOTOWithMessageStateByRoomOTO,
    getRoomOTOWithNewMessageStateByRoomOTO,
} from "../mappers/rooms.mappers";
import { RoomGroup } from "../../../domain/RoomGroup";
import { MessageRoom } from "../../../domain/MessageRoom";
import { RoomOTO } from "../../../domain/RoomOTO";
import { PartialRoomOTO } from "../entities/PartialRoomOTO";
import { UserRoom } from "../../../domain/UserRoom";
import { pipe } from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import * as T from "fp-ts/lib/These";
import * as A from "fp-ts/lib/Array";
import {
    RefreshListRoomsAction,
    SaveListRoomsAction,
} from "../actions/generic/rooms.actions";
import { RefreshListUsersAction } from "../actions/generic/users.actions";
import { eqNumber } from "fp-ts/lib/Eq";
import { MessageOTO } from "../../../domain/MessageOTO";
import { Option } from "fp-ts/lib/Option";
import {
    NewMessagesCountRoomGroupAction,
    NewMessagesCountRoomOTOAction,
} from "../actions/principal_screen_app/synchronize_messages.actions";
import { adapterUser } from "../adapters/users.adapters";
import { OnDiscoverModifiedProcessedAction } from "../actions/discovers.actions";
import { adapterMessageRoom } from "../adapters/messages.adapters";

function getStateNewMessageRoomGroup(
    state: IRoomGroupState,
    message: MessageRoom
): IRoomGroupState {
    const room: RoomGroup = getRoomGroupWithNewMessageStateByRoomGroup(
        state.entities[message.idRoom].roomGroup,
        message,
        O.none
    );
    const partialRoom: PartialRoomGroup = state.entities[message.idRoom];

    let currMsgs = partialRoom.numberMessagesLastPage + 1;

    let nextPage = partialRoom.nextLastPageToLoad;
    if (currMsgs % PartialRoomGroup.MESSAGES_BY_PAGE === 0) {
        nextPage++;
        currMsgs = 0;
    }

    // console.log({text: "reducer onNewMessageRoom", antNextPage: partialRoom.nextLastPageToLoad, nextPage: nextPage, currMsgs: currMsgs});

    return adapterRoomGroup.updateOne(
        <Update<PartialRoomGroup>>{
            id: message.idRoom,
            changes: {
                // ...state.entities[message.idRoom],
                roomGroup: room,
                nextLastPageToLoad: nextPage,
                numberMessagesLastPage: currMsgs,
            },
        },
        state
    );
}

function getStateNewUniqueMessagesRoomGroup(
    state: IRoomGroupState,
    idRoom: number,
    uniqueMessages: number,
    lastMessage: Option<MessageRoom>,
    lastUpdatedMessages: Date
): IRoomGroupState {
    return pipe(
        lastMessage,
        O.fold(
            () =>
                adapterRoomGroup.updateOne(
                    <Update<PartialRoomGroup>>{
                        id: idRoom,
                        changes: {
                            // ...state.entities[idRoom],
                            roomGroup: {
                                ...state.entities[idRoom].roomGroup,
                                last_messages_updated: lastUpdatedMessages,
                            },
                        },
                    },
                    state
                ),
            (msg) => {
                const room: RoomGroup =
                    getRoomGroupWithNewMessageStateByRoomGroup(
                        state.entities[msg.idRoom].roomGroup,
                        msg,
                        O.some(lastUpdatedMessages)
                    );
                const nbMsgs = uniqueMessages;

                const partialRoom: PartialRoomGroup =
                    state.entities[msg.idRoom];
                const nextPage =
                    Math.floor(nbMsgs / PartialRoomGroup.MESSAGES_BY_PAGE) +
                    partialRoom.nextLastPageToLoad;
                const currMsgs =
                    (partialRoom.numberMessagesLastPage + nbMsgs) %
                    PartialRoomGroup.MESSAGES_BY_PAGE;
                return adapterRoomGroup.addOne(
                    new PartialRoomGroup(
                        room,
                        nextPage,
                        currMsgs,
                        partialRoom.fullPagesLoaded,
                        partialRoom.usersRoom,
                        partialRoom.messageInputSaved
                    ),
                    adapterRoomGroup.removeOne(idRoom, state)
                );
                // return adapterRoomGroup.updateOne(<Update<PartialRoomGroup>>{
                //     id: idRoom,
                //     changes: {
                //         // ...state.entities[msg.idRoom],
                //         roomGroup: room,
                //         // roomGroup: {
                //         //     ...state.entities[msg.idRoom].roomGroup,
                //         //     last_messages_updated: lastUpdatedMessages
                //         // },
                //         nextLastPageToLoad: nextPage,
                //         numberMessagesLastPage: currMsgs,
                //
                //     }
                // }, state);
            }
        )
    );
}

//
// function getStateNewMessagesRoomGroup(state: IRoomGroupState, uniqueMessages: number) : IRoomGroupState {
//     const msgsOrdered = messages.sort((a, b) => a.date.getTime() - b.date.getTime());
//     const msg = A.head(msgsOrdered);
//     return pipe(
//         msg,
//         O.fold(
//             () => state,
//             msg => {
//                 const room : RoomGroup = getRoomGroupWithNewMessageStateByRoomGroup(state.entities[msg.idRoom].roomGroup, msg);
//                 const nbMsgs = uniqueMessages;
//
//                 const partialRoom: PartialRoomGroup = state.entities[msg.idRoom];
//                 const nextPage = Math.floor(nbMsgs/PartialRoomGroup.MESSAGES_BY_PAGE) + partialRoom.nextLastPageToLoad;
//                 const currMsgs = (partialRoom.numberMessagesLastPage + nbMsgs) % PartialRoomGroup.MESSAGES_BY_PAGE;
//
//                 return adapterRoomGroup.updateOne(<Update<PartialRoomGroup>>{
//                     id: msg.idRoom,
//                     changes: {
//                         ...state.entities[msg.idRoom],
//                         roomGroup: room,
//                         nextLastPageToLoad: nextPage,
//                         numberMessagesLastPage: currMsgs
//                     }
//                 }, state);
//             }
//         )
//     )
//     // const room : RoomGroup = state.entities[];
// }

function getStateNewMessageRoomOTO(
    state: IRoomOTOState,
    message: MessageOTO
): IRoomOTOState {
    const room: RoomOTO = getRoomOTOWithNewMessageStateByRoomOTO(
        state.entities[message.idUserOTO].roomOTO,
        message,
        O.none
    );
    const partialRoom: PartialRoomOTO = state.entities[message.idUserOTO];

    let currMsgs = partialRoom.numberMessagesLastPage;

    let nextPage = partialRoom.nextLastPageToLoad;
    if ((currMsgs + 1) % PartialRoomOTO.MESSAGES_BY_PAGE === 0) {
        nextPage++;
        currMsgs = 0;
    }

    return adapterRoomOTO.updateOne(
        <Update<PartialRoomOTO>>{
            id: message.idUserOTO,
            changes: {
                // ...state.entities[message.idUser],
                roomOTO: room,
                nextLastPageToLoad: nextPage,
                numberMessagesLastPage: currMsgs,
            },
        },
        state
    );
}

function getStateNewUniqueMessagesRoomOTO(
    state: IRoomOTOState,
    idUser: number,
    uniqueMessages: number,
    lastMessage: Option<MessageOTO>,
    lastUpdatedMessages: Date
): IRoomOTOState {
    return pipe(
        lastMessage,
        O.fold(
            () =>
                adapterRoomOTO.updateOne(
                    <Update<PartialRoomOTO>>{
                        id: idUser,
                        changes: {
                            // ...state.entities[idUser],
                            roomOTO: {
                                ...state.entities[idUser].roomOTO,
                                last_messages_updated: lastUpdatedMessages,
                            },
                        },
                    },
                    state
                ),
            (msg) => {
                const room: RoomOTO = getRoomOTOWithNewMessageStateByRoomOTO(
                    state.entities[msg.idUserOTO].roomOTO,
                    msg,
                    O.some(lastUpdatedMessages)
                );
                const nbMsgs = uniqueMessages;

                const partialRoom: PartialRoomOTO =
                    state.entities[msg.idUserOTO];
                const nextPage =
                    Math.floor(nbMsgs / PartialRoomGroup.MESSAGES_BY_PAGE) +
                    partialRoom.nextLastPageToLoad;
                const currMsgs =
                    (partialRoom.numberMessagesLastPage + nbMsgs) %
                    PartialRoomGroup.MESSAGES_BY_PAGE;

                return adapterRoomOTO.updateOne(
                    <Update<PartialRoomOTO>>{
                        id: msg.idUserOTO,
                        changes: {
                            // ...state.entities[msg.idUser],
                            roomOTO: room,
                            // roomOTO: {
                            //     ...state.entities[msg.idUser].roomOTO,
                            // last_messages_updated: lastUpdatedMessages
                            // },
                            nextLastPageToLoad: nextPage,
                            numberMessagesLastPage: currMsgs,
                        },
                    },
                    state
                );
            }
        )
    );
}

const roomGroupReducer = createReducer(
    initialRoomGroupState,
    on(newRoomGetted, (state, { newRoom }) => {
        return adapterRoomGroup.addOne(newRoom, state);
    }),

    on(addUsersToRoomSuccess, (state, { idRoom, idUsers }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    usersRoom: adapterUserRoom.addMany(
                        idUsers.map((idUser) => new UserRoom(idUser, false)),
                        state.entities[idRoom].usersRoom
                    ),
                },
            },
            state
        );
    }),

    on(deleteUsersFromRoomSuccess, (state, { idRoom, idUsers }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    usersRoom: adapterUserRoom.removeMany(
                        idUsers,
                        state.entities[idRoom].usersRoom
                    ),
                },
            },
            state
        );
    }),

    on(leaveRoomGroupSuccess, (state, { idRoom }) => {
        return adapterRoomGroup.removeOne(idRoom, state);
    }),
    on(onQuitUserRoom, (state, { idRoom, idUser }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    usersRoom: adapterUserRoom.removeOne(
                        idUser,
                        state.entities[idRoom].usersRoom
                    ),
                },
            },
            state
        );
    }),

    on(onNewMessageRoom, (state, { message }) => {
        return getStateNewMessageRoomGroup(state, message);
    }),
    on(
        onNewModificationMessageRoomAction,
        onDeleteMessageRoomSuccessAction,
        (state, { message }) => {
            if (state.entities[message.idRoom]) {
                const messageRoom =
                    state.entities[message.idRoom].roomGroup.lastMessage;
                if (
                    messageRoom &&
                    messageRoom.idMessage === message.idMessage
                ) {
                    return adapterRoomGroup.updateOne(
                        <Update<PartialRoomGroup>>{
                            id: message.idRoom,
                            changes: {
                                // ...state.entities[message.idRoom],
                                roomGroup: {
                                    ...state.entities[message.idRoom].roomGroup,
                                    lastMessage: message,
                                },
                            },
                        },
                        state
                    );
                } else {
                    // Si no té missatge la sala o bé no és el mateix missatge, no fer res.
                    return state;
                }
            } else {
                // No fer res si no existeix la sala.
                return state;
            }
        }
    ),
    on(UpdateLastMessageRoomAction, (state, { message, idRoom }) => {
        if (state.entities[idRoom]) {
            return adapterRoomGroup.updateOne(
                <Update<PartialRoomGroup>>{
                    id: idRoom,
                    changes: {
                        // ...state.entities[idRoom],
                        roomGroup: {
                            ...state.entities[idRoom].roomGroup,
                            lastMessage: O.toNullable(message),
                        },
                    },
                },
                state
            );
        }
        return state;
    }),
    on(onSendMessageRoom, (state, { message }) => {
        return getStateNewMessageRoomGroup(state, message);
    }),
    on(onSendMessageRoomSuccess, (state, { message }) => {
        return getStateNewMessageRoomGroup(state, message);
    }),
    on(onSendMessageRoomFile, (state, { message }) => {
        return getStateNewMessageRoomGroup(state, message);
    }),
    // on(onSendMessageRoomFileSuccess, (state, {message}) => getStateNewMessageRoomGroup(state, message)),
    on(
        loadNextPageMessagesRoomSuccess,
        /*loadFirstPageMessagesGroupSuccess,*/ (
            state,
            { idRoom, messages }
        ) => {
            const entity: PartialRoomGroup = state.entities[idRoom];

            const fullLoadedPages: boolean =
                messages.length < PartialRoomGroup.MESSAGES_BY_PAGE;
            const numMsgs: number =
                messages.length % PartialRoomGroup.MESSAGES_BY_PAGE;
            const nbPages = Math.ceil(
                messages.length / PartialRoomGroup.MESSAGES_BY_PAGE
            );

            return adapterRoomGroup.updateOne(
                <Update<PartialRoomGroup>>{
                    id: idRoom,
                    changes: {
                        // ...entity,
                        fullPagesLoaded: fullLoadedPages,
                        numberMessagesLastPage: numMsgs,
                        nextLastPageToLoad: entity.nextLastPageToLoad + nbPages,
                    },
                },
                state
            );
        }
    ),
    on(getUsersRoom, (state) => {
        return state;
    }),
    on(getUsersRoomSuccess, (state, { idRoom, usersRoom, dateLoaded }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    usersRoom: adapterUserRoom.addAll(
                        usersRoom,
                        state.entities[idRoom].usersRoom
                    ),
                    roomGroup: {
                        ...state.entities[idRoom].roomGroup,
                        members_loaded: true,
                        last_members_updated: dateLoaded,
                    },
                },
            },
            state
        );
    }),
    // on(cleanMessagesNotReadedRoomGroup, (state, {idRoom}) => {
    //     return adapterRoomGroup.updateOne(<Update<PartialRoomGroup>>{
    //         id: idRoom,
    //         changes: {
    //             ...state.entities[idRoom],
    //             roomGroup: {
    //                 ...state.entities[idRoom].roomGroup,
    //                 messageCount: 0
    //             }
    //         }
    //     }, state);
    // }),
    on(
        cleanMessagesNotReadedRoomGroup,
        cleanMessagesNotReadedRoomGroupSuccess,
        (state, { entity }) => {
            const idRoom = entity.idRoom;
            return adapterRoomGroup.updateOne(
                <Update<PartialRoomGroup>>{
                    id: idRoom,
                    changes: {
                        // ...state.entities[idRoom],
                        roomGroup: {
                            ...state.entities[idRoom].roomGroup,
                            messageCount: Math.max(
                                state.entities[idRoom].roomGroup.messageCount -
                                    entity.messagesToClean,
                                0
                            ),
                        },
                    },
                },
                state
            );
        }
    ),
    on(increaseMessagesNotReadedByOneRoomGroup, (state, { idRoom }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    roomGroup: {
                        ...state.entities[idRoom].roomGroup,
                        messageCount:
                            state.entities[idRoom].roomGroup.messageCount + 1,
                    },
                },
            },
            state
        );
    }),
    on(modifyRoomInfoSuccess, (state, { idRoom, info }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    // withImageProfileLoaded: T.isRight(info) || T.isBoth(info) || state.entities[idRoom].withImageProfileLoaded,
                    roomGroup: {
                        ...state.entities[idRoom].roomGroup,
                        ...pipe(
                            info,
                            T.fold(
                                ({ name, description }) => ({
                                    name: name,
                                    description: description,
                                    imageProfileUrl:
                                        state.entities[idRoom].roomGroup
                                            .imageProfileUrl,
                                }),
                                (img) => ({
                                    name: state.entities[idRoom].roomGroup.name,
                                    description:
                                        state.entities[idRoom].roomGroup
                                            .description,
                                    imageProfileUrl: img,
                                }),
                                ({ name, description }, img) => ({
                                    name: name,
                                    description: description,
                                    imageProfileUrl: img,
                                })
                            )
                        ),
                    },
                },
            },
            state
        );
    }),
    on(onModifyNameRoom, (state, { idRoom, name }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    roomGroup: {
                        ...state.entities[idRoom].roomGroup,
                        name: name,
                    },
                },
            },
            state
        );
    }),
    on(onModifyImageRoom, (state, { idRoom, image }) => {
        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    // ...state.entities[idRoom],
                    withImageProfileLoaded: true,
                    roomGroup: {
                        ...state.entities[idRoom].roomGroup,
                        imageProfileUrl: image,
                    },
                },
            },
            state
        );
    }),
    // NEW
    on(SaveListRoomsAction, (state, { rooms }) => {
        return adapterRoomGroup.addAll(rooms, state);
    }),
    on(RefreshListRoomsAction, (state, { all, deleted }) => {
        const st = adapterRoomGroup.removeMany(deleted.rooms, state);
        const allIds = all.rooms.map((r) => r.roomGroup.idRoom);
        const existsIds = allIds.filter((id) => !!state.entities[id]);

        const updates = pipe(
            all.rooms,
            A.filter((r) => existsIds.some((id) => id === r.roomGroup.idRoom)),
            A.map((r) => {
                const old = state.entities[r.roomGroup.idRoom];
                const oldGr = old.roomGroup;
                const newRoom = new PartialRoomGroup(
                    new RoomGroup(
                        r.roomGroup.lastActivity,
                        r.roomGroup.messageCount,
                        r.roomGroup.lastMessage,
                        r.roomGroup.membersCount,
                        r.roomGroup.lastModification,
                        r.roomGroup.name,
                        r.roomGroup.description,
                        oldGr.idRoom,
                        // oldGr.imageProfileUrl,
                        r.roomGroup.imageProfileUrl,
                        oldGr.typeRoom,
                        oldGr.last_messages_updated,
                        oldGr.lastClick,
                        oldGr.members_loaded,
                        oldGr.last_members_updated,
                        oldGr.bidirectional
                    ),
                    old.nextLastPageToLoad,
                    old.numberMessagesLastPage,
                    old.fullPagesLoaded,
                    old.usersRoom,
                    old.messageInputSaved
                );
                return newRoom;
            })
        );

        const newRooms = all.rooms.filter(
            (r) => !existsIds.some((id) => r.roomGroup.idRoom === id)
        );
        return adapterRoomGroup.addMany(
            [...updates, ...newRooms],
            adapterRoomGroup.removeMany(
                updates.map((r) => r.roomGroup.idRoom),
                st
            )
        );
    }),
    on(
        NewMessagesCountRoomGroupAction,
        (
            state,
            { idRoom, uniqueNewMessages, lastMessage, lastMessagesUpdated }
        ) => {
            return getStateNewUniqueMessagesRoomGroup(
                state,
                idRoom,
                uniqueNewMessages,
                lastMessage,
                lastMessagesUpdated
            );
        }
    ),
    on(OnDiscoverModifiedProcessedAction, (state, { status, roomField }) =>
        pipe(
            status.fields.room,
            O.fold(
                () => state,
                ({ idRoom, fields }) => {
                    if (!state.entities[idRoom]) return state;

                    return adapterRoomGroup.updateOne(
                        <Update<PartialRoomGroup>>{
                            id: idRoom,
                            changes: {
                                // ...state.entities[idRoom],
                                roomGroup: {
                                    ...state.entities[idRoom].roomGroup,
                                    name: pipe(
                                        fields.name,
                                        O.fold(
                                            () =>
                                                state.entities[idRoom].roomGroup
                                                    .name,
                                            (n) => n
                                        )
                                    ),
                                    imageProfileUrl: pipe(
                                        roomField,
                                        O.fold(
                                            () =>
                                                state.entities[idRoom].roomGroup
                                                    .imageProfileUrl,
                                            ({ profileImage }) => profileImage
                                        )
                                    ),
                                },
                            },
                        },
                        state
                    );
                }
            )
        )
    ),
    on(OnModifyAdminsRoomAction, (state, { status }) => {
        if (!state.entities[status.idRoom]) {
            return state;
        }
        const ids = state.entities[status.idRoom].usersRoom.ids as number[];

        const usersRooms = adapterUserRoom.updateMany(
            ids.map((u: number) => {
                const userRoom =
                    state.entities[status.idRoom].usersRoom.entities[u];
                return <Update<UserRoom>>{
                    id: u,
                    changes: {
                        // ...userRoom,
                        authorized: status.idUsers.some(
                            (id) => id === userRoom.idUser
                        ),
                    },
                };
            }),
            state.entities[status.idRoom].usersRoom
        );

        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: status.idRoom,
                changes: {
                    // ...state.entities[status.idRoom],
                    usersRoom: usersRooms,
                },
            },
            state
        );
    }),
    on(LoadFromDateMessageRoomSuccessAction, (state, { idRoom, messages }) => {
        const nextLastPageToLoad = Math.floor(
            messages.length / PartialRoomGroup.MESSAGES_BY_PAGE
        );
        const messagesInPage =
            messages.length % PartialRoomGroup.MESSAGES_BY_PAGE;

        // const r = state.entities[idRoom];
        // const numNewMsgs = Math.abs(((r.nextLastPageToLoad - 1)*PartialRoomGroup.MESSAGES_BY_PAGE + r.numberMessagesLastPage) - messages.length);
        // const nextLastPageToLoad = Math.floor(numNewMsgs/PartialRoomGroup.MESSAGES_BY_PAGE) + 1;
        // const messagesInPage = numNewMsgs%PartialRoomGroup.MESSAGES_BY_PAGE;

        return adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    nextLastPageToLoad: nextLastPageToLoad,
                    numberMessagesLastPage: messagesInPage,
                },
            },
            state
        );
    }),
    on(OnMessageInputUpdatedRoomAction, (state, { idRoom, message }) =>
        adapterRoomGroup.updateOne(
            <Update<PartialRoomGroup>>{
                id: idRoom,
                changes: {
                    messageInputSaved: message,
                },
            },
            state
        )
    )

    // on(NewMessagesCountRoomGroupAction, (state, {uniqueNewMessages, lastMessage, lastMessagesUpdated}) => getStateNewUniqueMessagesRoomGroup(state, uniqueNewMessages, lastMessage, lastMessagesUpdated))
);

export function roomGroupReducerFunction(
    state: IRoomGroupState | undefined,
    action: Action
) {
    return roomGroupReducer(state, action);
}

const roomOTOReducer = createReducer(
    initialRoomOTOState,
    // on(loadRoomsOtos, state => ({...state})),
    // on(loadRoomsOtosSuccess, (state, {otoRooms}) => adapterRoomOTO.addAll(otoRooms, state)),

    // on(loadRoomsAllSuccess, (state, {groupRooms, otoRooms}) => adapterRoomOTO.addAll(otoRooms, state)),

    on(onNewMessageOTO, (state, { message }) => {
        if (state.entities[message.idUserOTO] === undefined) {
            const room: RoomOTO = new RoomOTO(
                message.date,
                0,
                message,
                message.idUserOTO,
                message.date,
                message.date
            );
            const partialRoom: PartialRoomOTO = new PartialRoomOTO(
                room,
                1,
                1,
                true,
                ""
            );
            return adapterRoomOTO.addOne(partialRoom, state);
        } else {
            return getStateNewMessageRoomOTO(state, message);
        }
    }),
    on(
        onNewModificationMessageOTOAction,
        onDeleteMessageOTOSuccessAction,
        (state, { message }) => {
            if (state.entities[message.idUserOTO]) {
                const messageRoom =
                    state.entities[message.idUserOTO].roomOTO.lastMessage;
                if (
                    messageRoom &&
                    messageRoom.idMessage === message.idMessage
                ) {
                    return adapterRoomOTO.updateOne(
                        <Update<PartialRoomOTO>>{
                            id: message.idUserOTO,
                            changes: {
                                // ...state.entities[message.idUser],
                                roomOTO: {
                                    ...state.entities[message.idUserOTO]
                                        .roomOTO,
                                    lastMessage: message,
                                },
                            },
                        },
                        state
                    );
                } else {
                    // Si no té missatge la sala o bé no és el mateix missatge, no fer res.
                    return state;
                }
            } else {
                // No fer res si no existeix la sala.
                return state;
            }
        }
    ),
    on(UpdateLastMessageOTOAction, (state, { message, idUserOTO }) => {
        if (state.entities[idUserOTO]) {
            return adapterRoomOTO.updateOne(
                <Update<PartialRoomOTO>>{
                    id: idUserOTO,
                    changes: {
                        roomOTO: {
                            ...state.entities[idUserOTO].roomOTO,
                            lastMessage: O.toNullable(message),
                        },
                    },
                },
                state
            );
        }
        return state;
    }),
    on(onSendMessageOTO, (state, { message }) =>
        getStateNewMessageRoomOTO(state, message)
    ),
    on(onSendMessageOTOSuccess, (state, { message }) =>
        getStateNewMessageRoomOTO(state, message)
    ),
    on(onSendMessageOTOFile, (state, { message }) =>
        getStateNewMessageRoomOTO(state, message)
    ),
    on(
        loadNextPageMessagesOTOSuccess,
        /*loadFirstPageMessagesOTOSuccess,*/ (state, { idUser, messages }) => {
            const entity: PartialRoomOTO = state.entities[idUser];

            const fullLoadedPages: boolean =
                messages.length < PartialRoomOTO.MESSAGES_BY_PAGE;
            const numMsgs: number =
                messages.length % PartialRoomOTO.MESSAGES_BY_PAGE;
            const pagesLoaded = Math.ceil(
                messages.length / PartialRoomOTO.MESSAGES_BY_PAGE
            );

            return adapterRoomOTO.updateOne(
                <Update<PartialRoomOTO>>{
                    id: idUser,
                    changes: {
                        // ...entity,
                        fullPagesLoaded: fullLoadedPages,
                        numberMessagesLastPage: numMsgs,
                        nextLastPageToLoad:
                            entity.nextLastPageToLoad + pagesLoaded,
                    },
                },
                state
            );
        }
    ),
    // on(cleanMessagesNotReadedRoomOTO, (state, {idUser}) => {
    //     if (!state.ids.some(id => idUser === id))
    //         return state;
    //     return adapterRoomOTO.updateOne(<Update<PartialRoomOTO>>{
    //         id: idUser,
    //         changes: {
    //             ...state.entities[idUser],
    //             roomOTO: {
    //                 ...state.entities[idUser].roomOTO,
    //                 messageCount: 0
    //             }
    //         }
    //     }, state)
    // }),
    on(
        cleanMessagesNotReadedRoomOTO,
        cleanMessagesNotReadedRoomOTOSuccess,
        (state, { entity }) => {
            const idUser = entity.idUser;
            if (!state.ids.some((id) => entity.idUser === id)) return state;
            return adapterRoomOTO.updateOne(
                <Update<PartialRoomOTO>>{
                    id: idUser,
                    changes: {
                        // ...state.entities[idUser],
                        roomOTO: {
                            ...state.entities[idUser].roomOTO,
                            messageCount: Math.max(
                                state.entities[idUser].roomOTO.messageCount -
                                    entity.messagesToClean,
                                0
                            ),
                        },
                    },
                },
                state
            );
        }
    ),
    on(onOpenOTOSuccess, (state, { newOTO }) =>
        adapterRoomOTO.addOne(newOTO, state)
    ),
    on(leaveRoomOTOSuccess, (state, { idUser }) =>
        adapterRoomOTO.removeOne(idUser, state)
    ),
    on(increaseMessagesNotReadedByOneRoomOTO, (state, { idUser }) =>
        adapterRoomOTO.updateOne(
            <Update<PartialRoomOTO>>{
                id: idUser,
                changes: {
                    // ...state.entities[idUser],
                    roomOTO: {
                        ...state.entities[idUser].roomOTO,
                        messageCount:
                            state.entities[idUser].roomOTO.messageCount + 1,
                    },
                },
            },
            state
        )
    ),
    // on(onCreateRoom, (state, _ ) => state),
    // on(onCreateRoomSuccess, state => state)
    // NEW
    on(SaveListRoomsAction, (state, { otos }) =>
        adapterRoomOTO.addAll(otos, state)
    ),
    on(RefreshListUsersAction, (state, { deleted }) =>
        adapterRoomOTO.removeMany(deleted, state)
    ),
    // on(RefreshListRoomsAction, (state, {all, deleted}) => {
    //     const st = adapterRoomOTO.removeMany([...deleted.otos, ...all.otos.map(oto => oto.roomOTO.idUser)], state);
    //     return adapterRoomOTO.addMany(all.otos, st);
    // }),
    on(
        NewMessagesCountRoomOTOAction,
        (
            state,
            { idUser, uniqueNewMessages, lastMessage, lastMessagesUpdated }
        ) =>
            getStateNewUniqueMessagesRoomOTO(
                state,
                idUser,
                uniqueNewMessages,
                lastMessage,
                lastMessagesUpdated
            )
    ),
    on(RefreshListRoomsAction, (state, { all, deleted }) => {
        const st = adapterRoomOTO.removeMany(deleted.otos, state);
        const allIds = all.otos.map((r) => r.roomOTO.idUser);
        const existsIds = allIds.filter((id) => !!state.entities[id]);

        const updates = pipe(
            all.otos,
            A.filter((r) => existsIds.some((id) => id === r.roomOTO.idUser)),
            A.map((r) => {
                const old = state.entities[r.roomOTO.idUser];
                const oldGr = old.roomOTO;
                const newRoom = new PartialRoomOTO(
                    new RoomOTO(
                        r.roomOTO.lastActivity,
                        r.roomOTO.messageCount,
                        r.roomOTO.lastMessage,
                        // r.roomGroup.membersCount,
                        // r.roomGroup.lastModification,
                        // r.roomGroup.name,
                        // r.roomGroup.description,
                        oldGr.idUser,
                        // oldGr.imageProfileUrl,
                        // oldGr.typeRoom,
                        oldGr.last_messages_updated,
                        oldGr.lastClick
                        // oldGr.members_loaded,
                        // oldGr.last_members_updated
                    ),
                    old.nextLastPageToLoad,
                    old.numberMessagesLastPage,
                    old.fullPagesLoaded,
                    old.messageInputSaved
                    // old.usersRoom
                );
                return newRoom;
            })
        );

        // console.log({text: "updates messages oto!", upd: updates});

        const newRooms = all.otos.filter(
            (r) => !existsIds.some((id) => r.roomOTO.idUser === id)
        );
        return adapterRoomOTO.addMany(
            [...updates, ...newRooms],
            adapterRoomOTO.removeMany(
                updates.map((r) => r.roomOTO.idUser),
                st
            )
        );
    }),
    on(
        LoadFromDateMessageOTOSuccessAction,
        (state, { idUserOTO, messages }) => {
            // const r = state.entities[idUserOTO];

            const nextLastPageToLoad = Math.floor(
                messages.length / PartialRoomOTO.MESSAGES_BY_PAGE
            );
            const messagesInPage =
                messages.length % PartialRoomOTO.MESSAGES_BY_PAGE;

            // const numNewMsgs = Math.abs(((r.nextLastPageToLoad - 1)*PartialRoomOTO.MESSAGES_BY_PAGE + r.numberMessagesLastPage) - messages.length);
            // const nextLastPageToLoad = Math.floor(numNewMsgs/PartialRoomOTO.MESSAGES_BY_PAGE) + 1;
            // const messagesInPage = numNewMsgs%PartialRoomOTO.MESSAGES_BY_PAGE;

            return adapterRoomOTO.updateOne(
                <Update<PartialRoomOTO>>{
                    id: idUserOTO,
                    changes: {
                        nextLastPageToLoad: nextLastPageToLoad,
                        numberMessagesLastPage: messagesInPage,
                    },
                },
                state
            );
        }
    ),
    on(OnMessageInputUpdatedOTOAction, (state, { idUserOTO, message }) =>
        adapterRoomOTO.updateOne(
            <Update<PartialRoomOTO>>{
                id: idUserOTO,
                changes: {
                    messageInputSaved: message,
                },
            },
            state
        )
    )
);

export function roomOTOReducerFunction(
    state: IRoomOTOState | undefined,
    action: Action
) {
    return roomOTOReducer(state, action);
}
