import {NetworkClient} from '../core/NetworkClient';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IGetUsersMeRoomsResponseModel} from './models/IGetUsersMeRoomsResponseModel';
import {RequestType} from '../core/RequestType';
import {IGetUsersMeOneToOneResponseModel} from './models/IGetUsersMeOneToOneResponseModel';
import {catchError, map, tap} from 'rxjs/operators';
import {AppError} from '../globalmodels/AppError';
import {IGetRoomsIdrUserlistResponseModel} from './models/IGetRoomsIdrUserlistResponseModel';
import {RoomApiModel} from './models/RoomApiModel';
import {IGetUsersMeAlllistroomResponseModel} from './models/IGetUsersMeAlllistroomResponseModel';
import {Option} from "fp-ts/lib/Option";
import {array} from "fp-ts/lib/Array";
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option"
import {Either} from "fp-ts/lib/Either";
import * as E from "fp-ts/lib/Either";
import {These} from "fp-ts/lib/These";
import * as T from "fp-ts/lib/These";
import {IRoomAlllistRoomApiModel} from "./models/IRoomAlllistRoomApiModel";
import {StandardError} from "../../../domain/StandardError";
import {IGetUsersMeAlllistroomChangesResponseModel} from "./models/IGetUsersMeAlllistroomChangesResponseModel";
import {getUnixDateFromDate} from "../../../../utils/dates.transformers";

@Injectable({
    providedIn: 'root'
})
export class RoomsApihelper {

    constructor(
        private networkClient: NetworkClient
    ) {}


    getRooms() : Observable<Either<StandardError, IGetUsersMeRoomsResponseModel>> {
        return this.networkClient.setRequest<{}, IGetUsersMeRoomsResponseModel>(
            "/users/me/rooms",
            {},
            RequestType.GET
        );
    }


    getOneToOnes() : Observable<Either<StandardError, IGetUsersMeOneToOneResponseModel>> {
        return this.networkClient.setRequest<{}, IGetUsersMeOneToOneResponseModel>(
            "/users/me/onetoone",
            {},
            RequestType.GET
        );
    }

    getUsersMeAlllistroom() : Observable<Either<StandardError, IGetUsersMeAlllistroomResponseModel>> {
        return this.networkClient.setRequest<{}, IGetUsersMeAlllistroomResponseModel>(
            '/users/me/alllistroom',
            {},
            RequestType.GET
        );
    }

    getUsersMeAlllistroomChanges(date: Date) : Observable<Either<StandardError, IGetUsersMeAlllistroomChangesResponseModel>> {
        return this.networkClient.setRequest<{}, IGetUsersMeAlllistroomChangesResponseModel>(
            '/users/me/alllistroom/changes?from=' + getUnixDateFromDate(date),
            {},
            RequestType.GET
        );
    }

    getImageProfileRoomByIdRoom(idRoom: number) : Observable<Option<Blob>> {
        return this.networkClient.setRequestWithFile<{}>(
            '/rooms/' + idRoom + '/imageprofile',
            {},
            RequestType.GET
        );
    }

    getRoomsIdrImageprofilePath(idRoom: number) : string {
        return '/rooms/' + idRoom + '/imageprofile';
    }

    getRoomUserListByIdRoom(idRoom: number) : Observable<Either<StandardError, IGetRoomsIdrUserlistResponseModel>> {
        return this.networkClient.setRequest<{}, IGetRoomsIdrUserlistResponseModel>(
            '/rooms/' + idRoom + '/userlist',
            {},
            RequestType.GET
        );
    }

    putRoomsIdrReadMessages(idRoom: number, dateAction: Date, messagesToClean: number) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{dateAction: number, messagesToClean: number},{}>(
            '/rooms/' + idRoom + '/readmessages',
            {
                dateAction: getUnixDateFromDate(dateAction),
                messagesToClean: messagesToClean
            },
            RequestType.PUT
        ).pipe(
            // tap(_ => console.log({text: "readed messages of room", idRoom: idRoom}))
        );
    }

    putUsersIdReadMessages(idUser: number, dateAction: Date, messagesToClean: number) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{dateAction: number, messagesToClean: number},{}>(
            '/users/' + idUser + '/readmessages',
            {
                dateAction: getUnixDateFromDate(dateAction),
                messagesToClean: messagesToClean
            },
            RequestType.PUT
        )
    }


    getRoomsIdr(idRoom: number) : Observable<Either<StandardError, IRoomAlllistRoomApiModel>> {
        return this.networkClient.setRequest<{},IRoomAlllistRoomApiModel>(
            '/rooms/' + idRoom,
            {},
            RequestType.GET
        );
    }

    // postRoomsUserCreateroom(name: string, description: string) : Observable<{id: number}> {
    //     return this.networkClient.setRequest<{name:string,description: string},{id: number}>(
    //         '/rooms/user/createroom',
    //         {name: name, description: description},
    //         RequestType.POST
    //     );
    // }
    postRoomsUserCreateroom(name: string, description: string, participants: number[], file: Option<Blob>) : Observable<Either<StandardError, {id: number}>> {

        const l : {key: string, value: Blob | string }[] = [
            {key: "room_description", value: description},
            {key: "room_name", value: name},
            {key: "room_participants", value: JSON.stringify(participants)},
        ];
        // if (file !== undefined)
        pipe(
            file,
            O.fold(
                () => {},
                file => l.push({key: "file", value: file})
            )
        );


        // console.log({text: "postRoomsUserCreateRoom", l: l});

        return this.networkClient.setRequestWithFormData<{id:number}>(
            '/rooms/user/createroom',
            l,
            RequestType.POST
        );
    }


    postUsersIdOpenchat(idUser: number) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{},{}>(
            '/users/' + idUser + '/openchat',
            {},
            RequestType.POST
        );
    }

    deleteRoomsLeaveroomIdr(idRoom: number) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{},{}>(
            '/rooms/leaveroom/' + idRoom,
            {},
            RequestType.DELETE
        ).pipe(
            // tap(r => console.log({text: "response leave room", r: r}))
        );
    }

    deleteUsersIdOnetoone(idUser: number) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{},{}>(
            '/users/' + idUser + '/onetoone',
            {},
            RequestType.DELETE
        );
    }

    postRoomsIdrAddusers(idRoom: number, idUsers: number[]) : Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{users: number[]},{}>(
            '/rooms/' + idRoom + '/addusers',
            {users: idUsers},
            RequestType.POST
        );
    }


    putRoomsIdr(idRoom: number, info: These<{name: string, description: string}, Blob>) : Observable<Either<StandardError, {}>> {

        const nl : {key: string, value: Blob | string }[] = pipe(
            info,
            T.fold(
                ({name, description}) => [
                    {key: "description", value: description},
                    {key: "name", value: name}
                ],
                //@ts-ignore
                f => [{key: "file", value: f}],
                //@ts-ignore
                ({name, description}, f) => [
                    {key: "description", value: description},
                    {key: "name", value: name},
                    {key: "file", value: f}
                ]
            )
        );

        return this.networkClient.setRequestWithFormData(
            '/rooms/' + idRoom,
            nl,
            RequestType.PUT
        );
    }

    // postRoomsIdrAdduserId(idRoom: number, idUser: number) : Observable<{}> {
    //     return this.networkClient.setRequest<{},{}>(
    //         '/rooms/' + idRoom + '/adduser/' + idUser,
    //         {},
    //         RequestType.POST
    //     );
    // }

}
