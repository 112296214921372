import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {NgxSummernoteModule} from "ngx-summernote";
import { UiSwitchModule } from 'ngx-ui-switch';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "../layouts/navbar/navbar.component";
import { NotificationSidebarComponent } from '../chatmodule/chat-shell/notification-sidebar/notification-sidebar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {ToggleFullscreenDirective} from './directives/toggle-fullscreen.directive';
import {LoginRepository} from './core/repositories/login/login.repository';
import {AjaxCaller} from './core/sources/remote/core/ajax/AjaxCaller';
import {HttpHeadersManager} from './core/sources/remote/core/headers/HttpHeadersManager';
import {NetworkClient} from './core/sources/remote/core/NetworkClient';
import {CookieStoreService} from './localStorage/cookie-store.service';
import {APILoginHelper} from './core/sources/remote/login/APILoginHelper';
import {CookieService} from 'ngx-cookie-service';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { ProfileComponent } from './profile/profile.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DiscoverComponent } from './discover/discover.component';
import {NgbmodalService} from './utils/ngbmodal.service';
import {ProfilemodalService} from './utils/profilemodal.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CompreserService} from './utils/compreser.service';
import {FcmFacade} from './fcm/fcm.facade';
import {FcmService} from './fcm/fcm.service';
import { VideoViewerComponent } from './video-viewer/video-viewer.component';
import { ConnectionDetectorComponent } from '../layouts/navbar/connection-detector/connection-detector.component';
import {ProgressBarComponent} from "../layouts/progress-bar/progress-bar.component";
import {NotConnectionComponent} from "../layouts/not-connection/not-connection.component";
import { ImageVisualizerComponent } from './image-visualizer/image-visualizer.component';
import { ButtonOfflineableComponent } from './button-offlineable/button-offlineable.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ProfilePostregisterComponent } from './profile-postregister/profile-postregister.component';
import {SecprofilemodalService} from "./utils/secprofilemodal.service";
import { SessionsComponent } from './sessions/sessions.component';
import { TableShowerComponent } from './table-shower/table-shower.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import {AngularEditorModule} from "@kolkov/angular-editor";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import { UserListComponent } from './user-list/user-list.component';
import { MessageInfoComponent } from '../chatmodule/chat-shell/message-info/message-info.component';
import { LanguageEditorComponent } from './language-editor/language-editor.component';
import { VideoVisualizerComponent } from './video-visualizer/video-visualizer.component';
import {VgCoreModule} from "videogular2/core";
import {VgOverlayPlayModule} from "videogular2/overlay-play";
import {VgBufferingModule} from "videogular2/buffering";
import {VgControlsModule} from "videogular2/controls";
import { VoiceVisualizerComponent } from './voice-visualizer/voice-visualizer.component';
// import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {PdfViewerModule} from "ng2-pdf-viewer";
import { ResendComponent } from './resend/resend.component';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ResendService} from "./resend/resend.service";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {ResourceGetterService} from "./url-resource-generator/resource-getter.service";


@NgModule({
    exports: [
        CommonModule,//de angular
        FooterComponent,//el footer
        NavbarComponent,//La barra de arriba del todo con el logo de zonetacts y la foto de perfil
        // NotificationSidebarComponent,//la ventana de usuarios y archivos
        NgbModule,//de node
        TranslateModule,//traducir, de node
        UiSwitchModule,//de node
        SidebarComponent, //el lightbox de las personas y ficheros de un grupo
        UploadImageComponent, //para subir una imagen
        ToggleFullscreenDirective,
        ProfileComponent, //el perfil de usuario
        DiscoverComponent,
        VideoViewerComponent,
        // ImageVisualizerDirective,
        ProgressBarComponent,
        NotConnectionComponent,
        ImageVisualizerComponent,
        ButtonOfflineableComponent,
        LoadingSpinnerComponent,
        SessionsComponent,
        TextEditorComponent,
        UserListComponent,
        LanguageEditorComponent,
        VideoVisualizerComponent
        // NotificationSidebarComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        UiSwitchModule,
        LightboxModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxSpinnerModule,
        AngularEditorModule,
        PickerModule,
        NgxSummernoteModule,
        VgCoreModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        VgControlsModule,
        // NgxExtendedPdfViewerModule,
        PdfViewerModule,
        NgxDatatableModule,
        InfiniteScrollModule,
        ScrollingModule
        // AngularEditorModule,
        // SweetAlert2Module,
        // SweetAlert2Module.forChild({})
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        // NotificationSidebarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        UploadImageComponent,
        ProfileComponent,
        DiscoverComponent,
        VideoViewerComponent,
        // ImageVisualizerDirective,
        ConnectionDetectorComponent,
        ProgressBarComponent,
        NotConnectionComponent,
        ImageVisualizerComponent,
        ButtonOfflineableComponent,
        LoadingSpinnerComponent,
        ProfilePostregisterComponent,
        SessionsComponent,
        TableShowerComponent,
        TextEditorComponent,
        UserListComponent,
        LanguageEditorComponent,
        VideoVisualizerComponent,
        VoiceVisualizerComponent,
        ResendComponent,

        // MessageInfoComponent
        // NotificationSidebarComponent
    ],
    providers: [
        LoginRepository,
        AjaxCaller,
        HttpHeadersManager,
        NetworkClient,
        CookieStoreService,
        APILoginHelper,
        CookieService,
        NgbmodalService,
        ProfilemodalService,
        CompreserService,
        SecprofilemodalService,
        ResendService,
        ResourceGetterService
        // AuthGuard
    ],
    entryComponents: [
        ProfileComponent,
        DiscoverComponent,
        VideoViewerComponent,
        ProfilePostregisterComponent,
        SessionsComponent,
        TextEditorComponent,
        UserListComponent,
        LanguageEditorComponent,
        VideoVisualizerComponent,
        VoiceVisualizerComponent,
        ResendComponent
        // MessageInfoComponent
    ]
})
export class SharedModule { }
