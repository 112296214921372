import {MessageRoom} from '../../../domain/MessageRoom';
import {IMessageOTOState, IMessageRoomState} from '../state/message.state';
import {MessageOTO} from '../../../domain/MessageOTO';
import {LastEvent} from '../state/utils/LastEvent';

export class PagedRoomOTOMessages {
    readonly messagesOTOState: IMessageOTOState;

    readonly idUser: number;

    readonly lastEvent: LastEvent;
    readonly lastMessages: MessageOTO[];

    constructor(
                messagesOTOState: IMessageOTOState,
                idUser: number,
                lastEvent: LastEvent,
                lastMessages: MessageOTO[]
    ) {
        this.messagesOTOState = messagesOTOState;
        this.idUser = idUser;
        this.lastEvent = lastEvent;
        this.lastMessages = lastMessages;
    }
}
