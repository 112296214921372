import {createAction, props} from "@ngrx/store";


const TAG = '[Resources Namespace] ';

export const ResourcesNamespaceActions = {
    SAVE_BLOB_META_NO_IMAGE_PROFILE: TAG + 'Save Blob Meta No Image Profile',
    SAVE_BLOB_META_NO_CONNECTION: TAG + 'Save Blob Meta No Connection',
    SAVE_BLOB_DISCOVERS_ICON: TAG + 'Save Blob Discovers Icon',
    SAVE_BLOB_DISCOVERS_CHECK: TAG + 'Save Blob Discovers Check',
    SAVE_BLOB_CHAT_LOAD_MORE_GALLERY: TAG + 'Save Blob Chat Load More Gallery',
    SAVE_BLOB_CHAT_EDIT_GROUP: TAG + 'Save Blob Chat Edit Group',
    SAVE_BLOB_MESSAGES_NOT_SENDED_NOT_SYNCHRONIZED_ICON: TAG + 'Save Blob Messages Not Sended Not Synchronized Icon',
    SAVE_BLOB_MESSAGES_SENDED_NOT_SYNCHRONIZED_ICON: TAG + 'Save Blob Messages Sended Not Synchronized Icon',
    SAVE_BLOB_MESSAGES_SENDED_SYNCHRONIZED_ICON: TAG + 'Save Blob Messages Sended Synchronized Icon',
    SAVE_BLOB_MESSAGES_ERROR_ICON: TAG + 'Save Blob Messages Error Icon',
    SAVE_BLOB_MESSAGES_PDF_PLACEHOLDER: TAG + 'Save Blob Messages Pdf Placeholder',
    SAVE_BLOB_SCRIPTS_NOTIFICATION_SIDEBAR: TAG + 'Save Blob Scripts Notification Sidebar',
    SAVE_BLOB_MESSAGES_VIDEO_PLACEHOLDER: TAG + 'Save Blob Messages Video Placeholder',
    SAVE_BLOB_META_RETRY_ICON: TAG + 'Save Blob Meta Retry Icon'
};

export const SaveBlobMetaNoImageProfileAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_META_NO_IMAGE_PROFILE,
    props<{image: Blob}>()
);
export const SaveBlobMetaNoConnectionAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_META_NO_CONNECTION,
    props<{image: Blob}>()
);
export const SaveBlobDiscoversIconAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_DISCOVERS_ICON,
    props<{image: Blob}>()
);
export const SaveBlobDiscoversCheckAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_DISCOVERS_CHECK,
    props<{image: Blob}>()
);
export const SaveBlobChatLoadMoreGalleryAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_CHAT_LOAD_MORE_GALLERY,
    props<{image: Blob}>()
);
export const SaveBlobChatEditGroupAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_CHAT_EDIT_GROUP,
    props<{image: Blob}>()
);
export const SaveBlobMessagesNotSendedNotSynchronizedIconAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_MESSAGES_NOT_SENDED_NOT_SYNCHRONIZED_ICON,
    props<{image: Blob}>()
);
export const SaveBlobMessagesSendedNotSynchronizedIconAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_MESSAGES_SENDED_NOT_SYNCHRONIZED_ICON,
    props<{image: Blob}>()
);
export const SaveBlobMessagesSendedSynchronizedIconAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_MESSAGES_SENDED_SYNCHRONIZED_ICON,
    props<{image: Blob}>()
);
export const SaveBlobMessagesErrorIconAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_MESSAGES_ERROR_ICON,
    props<{image: Blob}>()
);
export const SaveBlobMessagesPdfPlaceholderAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_MESSAGES_PDF_PLACEHOLDER,
    props<{image: Blob}>()
);
//SAVE_BLOB_SCRIPTS_NOTIFICATION_SIDEBAR
export const SaveBlobScriptsNotificationSidebarAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_SCRIPTS_NOTIFICATION_SIDEBAR,
    props<{image: Blob}>()
);
export const SaveBlobMessagesVideosPlaceholderAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_MESSAGES_VIDEO_PLACEHOLDER,
    props<{image: Blob}>()
);
export const SaveBlobRetryIconAction = createAction(
    ResourcesNamespaceActions.SAVE_BLOB_META_RETRY_ICON,
    props<{image: Blob}>()
)
