import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {IAppState} from '../state/app.state';
import {Observable} from 'rxjs';
import {changeOwnData, logout, onDisconnectSocket, refreshFirebaseToken} from '../actions/app.actions';
import {
    // loadedAppSelect, ownDataUserSelect, ownImageProfileSelect,
    selectedRoomsSelect
} from '../selectors/app.selectors';
import {OwnDataUser} from '../../../domain/OwnDataUser';
import {filter, finalize, map, take} from 'rxjs/operators';
import {ConnectErrorSocketException} from '../../../realtime/exceptions/ConnectErrorSocketException';
import {AppError} from '../../remote/globalmodels/AppError';
import {changeOwnDataUserResponseSelect, logoutResponseSelect} from '../selectors/responses.selectors';
import {cleanResponseLogout, cleanResponseModifyOwnData} from '../actions/responses.actions';
import {Option} from "fp-ts/lib/Option";
import {LoadAppAction} from "../actions/first_app_with_connection/meta.actions";
import {StandardError} from "../../../domain/StandardError";
import {ReloadAppAction} from "../actions/app_with_connection/meta.actions";


@Injectable({
    providedIn: 'root'
})
export class AppFacade {

    constructor(
        private store: Store<IAppState>
    ) {}

    loadApp() : Observable<void> {
        return new Observable<void>(subscriber => {
            console.log("LOADING APP DISPATCH ACTION");
            this.store.dispatch(LoadAppAction());
            subscriber.complete();
        });
    }

    reloadApp() : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(ReloadAppAction());
            s.complete();
        })
    }

    // getLoadedAppObservable(): Observable<boolean> {
    //     return this.store.pipe(
    //         select(loadedAppSelect)
    //     );
    // }

    // dispatchSocketConnectionError(error: ConnectErrorSocketException) : Observable<void> {
    //     return new Observable<void>(subscriber => {
    //         this.store.dispatch(openSocketError());
    //         subscriber.complete();
    //     })
    // }


    // getOwnDataUser() : Observable<OwnDataUser> {
    //     return this.store.pipe(
    //         select(ownDataUserSelect),
    //         take(1)
    //     );
    // }

    // getOwnDataUserObservable() : Observable<OwnDataUser> {
    //     return this.store.pipe(
    //         select(ownDataUserSelect)
    //     );
    // }

    // getOwnImageProfileObservable() : Observable<Blob | undefined> {
    //     return this.store.pipe(
    //         select(ownImageProfileSelect)
    //     )
    // }

    // socketDisconnectedAction() : Observable<void> {
    //     return new Observable<void>(subscriber => {
    //         this.store.dispatch(onDisconnectSocket());
    //         subscriber.complete();
    //     });
    // }

    onActiveChangedSelectedRoomObservable() : Observable<boolean> {
        return this.store.pipe(
            select(selectedRoomsSelect),
            map(c => {
                console.log({text: "changed chat", chat: c});
                return c !== undefined && (c.idRoomGroupSelected !== undefined || c.idUserGroupSelected !== undefined)
            })
        );
    }

    modifyOwnDataUser(bio: Option<string>, image: Option<Blob>,
                      emailContact: Option<Option<string>>,
                      emailNotifications: Option<boolean>) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(changeOwnData({bio: bio, image: image, emailContact: emailContact, emailNotifications: emailNotifications}));
            subscriber.complete();
        });
    }

    getModifyOwnDataUserResponseFailOrSuccess() : Observable<{error: Option<StandardError>}> {
        return this.store.select(changeOwnDataUserResponseSelect).pipe(
            filter(r => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseModifyOwnData()))
        );
    }

    dispatchLogout() : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(logout());
            subscriber.complete();
        })
    }

    getLogoutResponseFailOrSuccess() : Observable<{error: Option<StandardError>}> {
        return this.store.select(logoutResponseSelect).pipe(
            filter(r => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseLogout))
        );
    }

    dispatchRefreshFirebaseToken(fcmToken: string, deviceId: string) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(refreshFirebaseToken({fcmToken: fcmToken, deviceId: deviceId}));
            subscriber.complete();
        });
    }
}
