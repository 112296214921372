import {Injectable} from "@angular/core";
import {IAppState} from "../../state/app.state";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {FileMessageNotSended} from "../../../../domain/FileMessageNotSended";
import {getFileMessageNotSendedByInternalIdSelect} from "../../selectors/messages/filemessagenotsended.selectors";
import {take} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class FileMessagesNotSendedFacade {

    constructor(
        private store: Store<IAppState>
    ) {}

    getFileMessageNotSendedByInternalId(internalId: string) : Observable<FileMessageNotSended> {
        return this.store.select(getFileMessageNotSendedByInternalIdSelect(internalId)).pipe(take(1));
    }


}
