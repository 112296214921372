import {createAction, props} from "@ngrx/store";
// import {StandardError} from "../../../remote/globalmodels/StandardError";
import {OwnDataUser} from "../../../../domain/OwnDataUser";
import {User} from "../../../../domain/User";
import {Room} from "../../../../domain/Room";
import {RoomGroup} from "../../../../domain/RoomGroup";
import {RoomOTO} from "../../../../domain/RoomOTO";
import {Discover} from "../../../../domain/Discover";
import {MetaActions} from "../app.actions";
import {StandardError} from "../../../../domain/StandardError";
import {PartialUser} from "../../entities/PartialUser";
import {PartialRoomGroup} from "../../entities/PartialRoomGroup";
import {PartialRoomOTO} from "../../entities/PartialRoomOTO";
import {LocalResourcePack} from "../../../../domain/LocalResourcePack";
import {Option} from "fp-ts/lib/Option";

const TAG = '[First App With Connection Meta] ';

export const FirstAppWithConnectionMetaActions = {
    LOAD_APP: TAG + 'Load App',
    LOAD_APP_FAIL: TAG + 'Load App Fail',
    LOAD_APP_SUCCESS: TAG + 'Load App Success',
    // Activar:
    //  - GenericAppStateSetAllDataLoaded a true

    LOAD_RESOURCES: TAG + 'Load Resources',
    LOAD_RESOURCES_ERROR: TAG + 'Load Resources Error',
    LOAD_RESOURCES_SUCCESS: TAG + 'Load Resources Success',

    LOAD_PERSONAL_INFO: TAG + 'Load Personal Info',
    LOAD_PERSONAL_INFO_ERROR: TAG + 'Load Personal Info Error',
    // Activar:
    //  - SetFirstAppWithConnectionStateAction amb el state de personal i el error
    LOAD_PERSONAL_INFO_SUCCESS: TAG + 'Load Personal Info Success', // <-- aquest activa SAVE_PERSONAL_INFO i modifica progrés de l'aplicació
    // Activar:
    //  - SAVE_PERSONAL_INFO
    //  - SetFirstAppWithConnectionStateAction



    LOAD_OPEN_SOCKET: TAG + 'Load Open Socket',
    LOAD_OPEN_SOCKET_ERROR: TAG + 'Load Open Socket Error',
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    LOAD_OPEN_SOCKET_SUCCESS: TAG + 'Load Open Socket Success',// <-- aquest activa les altres tres accions i modifica progrés aplicació, llença també següent estat
    // Activar:
    //  - GenericConnectionConnectionChangedAction a true
    //  - SetFirstAppWithConnectionStateAction

    LOAD_INIT_FIREBASE: TAG + 'Load Init Firebase',
    LOAD_INIT_FIREBASE_ERROR: TAG + 'Load Init Firebase Error',
    LOAD_INIT_FIREBASE_SUCCESS: TAG + 'Load Init Firebase Success',

    LOAD_LIST_USERS: TAG + 'Load List Users',
    LOAD_LIST_USERS_ERROR: TAG + 'Load List Users Error',
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    LOAD_LIST_USERS_SUCCESS: TAG + 'Load List Users Success',
    // Activar:
    //  - SAVE_LIST_USERS
    //  - GenericDatesUpdatesSaveDateUpdateUsersAction
    //  - ADD_LISTENER_USER_MODIFIED
    //  - ADD_LISTENER_NEW_USER
    //  - SetFirstAppWithConnectionStateAction


    LOAD_LIST_ROOMS: TAG + 'Load List Rooms',
    LOAD_LIST_ROOMS_ERROR: TAG + 'Load List Rooms Error',
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    LOAD_LIST_ROOMS_SUCCESS: TAG + 'Load List Rooms Success',
    // Activar:
    //  - SAVE_LIST_ROOMS
    //  - GenericDatesUpdatesSaveDataUpdateRoomsAction
    //  - ADD_LISTENER_CHAT_MESSAGE_ROOM
    //     ADD_LISTENER_CHAT_MESSAGE_USER
    //     ADD_LISTENER_JOINED_ROOM
    //     ADD_LISTENER_QUIT_ROOM
    //     ADD_LISTENER_ROOM_MODIFIED
    //     ADD_LISTENER_ROOM_READED_MESSAGES
    //     ADD_LISTENER_OTO_READED_MESSAGES
    //  - SetFirstAppWithConnectionStateAction

    LOAD_LIST_DISCOVERS: TAG + 'Load List Discovers',
    LOAD_LIST_DISCOVERS_ERROR: TAG + 'Load List Discovers Error',
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    LOAD_LIST_DISCOVERS_SUCCESS: TAG + 'Load List Discovers Success',
    // Activar:
    //  - SAVE_LIST_DISCOVERS
    //  - GenericDatesUpdatesSaveDataUpdateDiscoversAction
    //  - SetFirstAppWithConnectionStateAction




    LOAD_INIT_NETWORK_CLIENT: TAG + 'Load Init Network Client', // Al final de tot ja que activa ja coses de la següent fase

};

export const LoadAppAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_APP
);
export const LoadStandardErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_APP_FAIL,
    props<{error: StandardError}>()
);
export const LoadAppSuccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_APP_SUCCESS
    // props<{}>()
);

// LOAD_RESOURCES: TAG + 'Load Resources',
//     LOAD_RESOURCES_ERROR: TAG + 'Load Resources Error',
//     LOAD_RESOURCES_SUCCESS: TAG + 'Load Resources Success',
export const LoadResourcesAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_RESOURCES,
);
export const LoadResourcesErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_RESOURCES_ERROR,
    props<{error: StandardError}>()
);
export const LoadResourcesSuccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_RESOURCES_SUCCESS,
    props<{resources: LocalResourcePack}>()
);


export const LoadPersonalInfoAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_PERSONAL_INFO
);
export const LoadPersonalInfoErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_PERSONAL_INFO_ERROR,
    props<{error: StandardError}>()
);
export const LoadPersonalInfoSuccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_PERSONAL_INFO_SUCCESS,
    props<{info: OwnDataUser, image: Option<Blob>}>()
);
export const LoadOpenSocketAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_OPEN_SOCKET,
);
export const LoadOpenSocketErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_OPEN_SOCKET_ERROR,
    props<{error: StandardError}>()
);
export const LoadOpenSocketSucccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_OPEN_SOCKET_SUCCESS
);
export const LoadInitFirebaseAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_INIT_FIREBASE
);
export const LoadInitFirebaseErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_INIT_FIREBASE_ERROR,
    props<{error: StandardError}>()
);
export const LoadInitFirebaseSuccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_INIT_FIREBASE_SUCCESS
);

// LOAD_OPEN_SOCKET: TAG + 'Load Open Socket',
//     LOAD_OPEN_SOCKET_ERROR: TAG + 'Load Open Socket Error',
//     // Activar:
//     //  - SetFirstAppWithConnectionStateAction
//     LOAD_OPEN_SOCKET_SUCCESS: TAG + 'Load Open Socket Success',// <-- aquest activa les altres tres accions i modifica progrés aplicació, llença també següent estat
export const LoadListUsersAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_USERS
);
export const LoadListUsersErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_USERS_ERROR,
    props<{error: StandardError}>()
);
export const LoadListUsersSuccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_USERS_SUCCESS,
    props<{users: PartialUser[]}>()
);
export const LoadListRoomsAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_ROOMS
);
export const LoadListRoomsErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_USERS_ERROR,
    props<{error: StandardError}>()
);
export const LoadListRoomsSuccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_ROOMS_SUCCESS,
    props<{rooms: PartialRoomGroup[], otos: PartialRoomOTO[]}>()
);
export const LoadListDiscoversAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_DISCOVERS
);
export const LoadListDiscoversErrorAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_DISCOVERS_ERROR,
    props<{error: StandardError}>()
);
export const LoadListDiscoversSuccessAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_LIST_DISCOVERS_SUCCESS,
    props<{discovers: Discover[]}>()
);

export const LoadInitNetworkClientAction = createAction(
    FirstAppWithConnectionMetaActions.LOAD_INIT_NETWORK_CLIENT
);
