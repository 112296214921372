import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { UsersFacade } from "../core/sources/storage/facade/users.facade";
import { RoomsFacade } from "../core/sources/storage/facade/rooms.facade";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ResendService } from "./resend.service";
import { ResendElementViewModel } from "./model/ResendElementViewModel";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { combineLatest } from "rxjs";
import { normalizeString } from "../utils/text.utils";
import { array as A, option as O, either as E } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../locales/locale.service";

@Component({
    selector: "app-resend",
    templateUrl: "./resend.component.html",
    styleUrls: ["./resend.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ResendComponent implements OnInit {
    // rowsRoomsFiltered: ResendElementViewModel[];
    // rowsRooms: ResendElementViewModel[];
    // rowsUsersFiltered: ResendElementViewModel[];
    // rowsUsers: ResendElementViewModel[];
    rows: ResendElementViewModel[];
    tempData: ResendElementViewModel[];
    rowsFiltered: ResendElementViewModel[];

    loading = false;

    hasSelection = false;
    selectedString = "";

    searchValue = "";

    public ColumnMode = ColumnMode;

    @Input()
    fromIdRoom: number;

    @Input()
    oneToOne: boolean;

    @Input()
    fromIdMessage: string;

    constructor(
        public activeModal: NgbActiveModal,
        private readonly resendService: ResendService,
        readonly localeService: LocaleService
    ) {
        console.log("resend component constructor");
    }

    ngOnInit() {
        this.resendService.init();

        combineLatest([
            this.resendService.subjectRowsRooms,
            this.resendService.subjectRowsUsers,
        ]).subscribe(([rooms, users]) => {
            this.rows = [
                ResendElementViewModel.fromHeader(
                    this.localeService.translate("rooms_resend", {})
                ),
                ...rooms,
                ResendElementViewModel.fromHeader(
                    this.localeService.translate("users_resend", {})
                ),
                ...users,
            ];
            this.tempData = this.rows;
            this.rowsFiltered = this.rows;
            this.filterByString(this.searchValue);

            this.setSelectedString();
        });
    }

    setSelectedString() {
        const selectedRows = pipe(
            this.rows,
            A.filter((r) => r.selected)
        );
        this.hasSelection = selectedRows.length !== 0;
        this.selectedString = pipe(
            selectedRows,
            A.foldLeft(
                () => "",
                (head, tail) => {
                    const resultTail = pipe(
                        tail,
                        A.reduce("", (acc, v) => `${acc}, ${v.name}`)
                    );
                    return `${head.name}${resultTail}`;
                }
            )
        );
    }

    selectRow(row: ResendElementViewModel) {
        if (!row.isHeader) {
            this.resendService.select(row);
        }
    }

    filterUpdate(event) {
        const val = event.target.value.toLowerCase();
        this.searchValue = val;
        this.filterByString(val);
    }

    filterByString(str: string) {
        // filter our data
        const temp = this.tempData.filter(function (d) {
            if (d.isHeader) return true;
            // return d.full_name.toLowerCase().indexOf(val) !== -1 || !val;
            const fullString = `${d.name} ${d.description}`;
            const criteria = normalizeString(str);

            return (
                normalizeString(fullString).indexOf(criteria) !== -1 ||
                !criteria
            );
        });

        // update the rows
        this.rowsFiltered = temp;
        // Whenever the filter changes, always go back to the first page
        // this.table.offset = 0;
    }

    confirm() {
        if (!this.loading) {
            this.loading = true;
            this.resendService
                .onSend(this.fromIdMessage, this.fromIdRoom, this.oneToOne)
                .subscribe((result) =>
                    pipe(
                        result,
                        E.fold(
                            (_) => {
                                this.loading = false;
                            },
                            (_) => {
                                this.loading = false;
                                this.activeModal.dismiss();
                            }
                        )
                    )
                );
        }
    }
}
