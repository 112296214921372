import {Discover} from '../../shared/core/domain/Discover';
import {Observable} from 'rxjs';
import {DiscoverElement} from '../models/DiscoverElement';
import {blobToSrcImage} from './utils.mappers';
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";

export function getDiscoverElementFromDiscover(discover: Discover) : Observable<DiscoverElement> {
    const img = pipe(
        discover.image,
        O.fold(
            () => undefined,
            img => img
        )
    );
    return blobToSrcImage(img, src => new DiscoverElement(
        discover.name,
        src as string,
        true,
        discover.vinculated,
        discover.idDiscover
    ));
}
