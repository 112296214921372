import {Option} from "fp-ts/lib/Option";


export class OwnDataUser {
    readonly idUser: number;
    readonly idClient: number;
    readonly loginIdentifier: string;
    readonly name: string;
    readonly surname: string;
    readonly bio: string;
    readonly checkEmailNotificationsActivated: boolean;
    readonly emailContact: Option<string>;
    readonly emailNotifications: boolean;
    readonly videosActivated: boolean;
    readonly voiceActivated: boolean;
    readonly hideGroupCreationButton: boolean;


    constructor(idUser: number, idClient: number, loginIdentifier: string, name: string,
                surname: string, bio: string, checkEmailNotificationsActivated: boolean,
                emailContact: Option<string>, emailNotifications: boolean, videosActivated: boolean,
                voiceActivated: boolean, hideGroupCreationButton: boolean) {
        this.idUser = idUser;
        this.idClient = idClient;
        this.loginIdentifier = loginIdentifier;
        this.name = name;
        this.surname = surname;
        this.bio = bio;
        this.checkEmailNotificationsActivated = checkEmailNotificationsActivated;
        this.emailContact = emailContact;
        this.emailNotifications = emailNotifications;
        this.videosActivated = videosActivated;
        this.voiceActivated = voiceActivated;
        this.hideGroupCreationButton = hideGroupCreationButton;
    }
}
