import {ThemeType} from "../../../environments/ThemeType";
import {environment} from "../../../environments/environment";


export const selectedTheme: ThemeType = environment;
export const version: string = '2.5.0';

export function existsFirebaseProject() : boolean {
    return selectedTheme.firebase_config !== undefined;
}

