import {createAction, props} from "@ngrx/store";


const TAG = '[Generic Dates Updates] ';

export const GenericDatesUpdatesActions = {
    SAVE_DATE_UPDATE_USERS: TAG + 'Save Date Update Users',
    SAVE_DATE_UPDATE_ROOMS: TAG + 'Save Date Update Rooms',
    SAVE_DATE_UPDATE_DISCOVERS: TAG + 'Save Date Update Discovers'
};

export const SaveDateUpdateUsersAction = createAction(
    GenericDatesUpdatesActions.SAVE_DATE_UPDATE_USERS,
    props<{date: Date}>()
);

export const SaveDateUpdateRoomsAction = createAction(
    GenericDatesUpdatesActions.SAVE_DATE_UPDATE_ROOMS,
    props<{date: Date}>()
);

export const SaveDateUpdateDiscoversAction = createAction(
    GenericDatesUpdatesActions.SAVE_DATE_UPDATE_DISCOVERS,
    props<{date: Date}>()
);
