import {Room} from './Room';
import {Message} from './Message';
import {MessageRoom} from "./MessageRoom";

export enum RoomGroupType {
    GROUP = 'group',
    DISCOVER = 'discover'
};

export class RoomGroup extends Room<MessageRoom> {

    readonly membersCount: number;
    readonly lastModification: Date;
    readonly name: string;
    readonly description: string;
    readonly idRoom: number;

    readonly imageProfileUrl: string;

    readonly typeRoom: RoomGroupType;

    readonly members_loaded: boolean;
    readonly last_members_updated: Date;

    readonly bidirectional: boolean;

    constructor(lastActivity: Date,
                messageCount: number,
                lastMessage: MessageRoom,
                membersCount: number,
                lastModification: Date,
                name: string,
                description: string,
                idRoom: number,
                // imageProfile: Blob,
                imageProfileUrl: string,
                typeRoom: RoomGroupType,
                last_messages_updated: Date,
                lastClick: Date,
                members_loaded: boolean,
                last_members_updated: Date,
                bidirectional: boolean
    ) {
        super(lastActivity, messageCount, lastMessage, last_messages_updated, lastClick);
        this.membersCount = membersCount;
        this.lastModification = lastModification;
        this.name = name;
        this.description = description;
        this.idRoom = idRoom;
        this.imageProfileUrl = imageProfileUrl;
        this.typeRoom = typeRoom;
        this.members_loaded = members_loaded;
        this.last_members_updated = last_members_updated;
        this.bidirectional = bidirectional;
    }
}
