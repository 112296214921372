import {Observable} from 'rxjs';
import {LoggedState} from './LoggedState';


export abstract class HttpHeaders {

    abstract getAuthorizationToken() : Observable<string>;
    abstract getHeaders() : Observable<JSON>;
    abstract getLoggedState() : LoggedState;
}
