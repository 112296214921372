import {Option} from "fp-ts/lib/Option";


export class DiscoverModifiedSocketModel {

    readonly idDiscover: number;
    readonly date: string;
    readonly fields: {
        name?: string;
        image?: "true";
        position?: number;
        room?: {
            idRoom: number;
            fields: {
                name?: string;
                image?: "true";
            }
        }
    };


    constructor(idDiscover: number, date: string, fields: { name?: string; image?: "true"; position?: number; room?: { idRoom: number; fields: { name?: string; image?: "true" } } }) {
        this.idDiscover = idDiscover;
        this.date = date;
        this.fields = fields;
    }
}
