import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {IAppState} from "../../state/app.state";
import {Observable} from "rxjs";
import {OwnDataUser} from "../../../../domain/OwnDataUser";
import {take} from "rxjs/operators";
import {MetaStateOwnImageProfileSelect, MetaStateOwnUserDataSelect} from "../../selectors/generic/meta.selectors";
import {Option} from "fp-ts/lib/Option";

@Injectable({
    providedIn: 'root'
})
export class MetaFacade {

    constructor(
        private store: Store<IAppState>
    ) {}

    getOwnDataUser() : Observable<OwnDataUser> {
        return this.store.pipe(
            select(MetaStateOwnUserDataSelect),
            take(1)
        );
    }

    getOwnDataUserObservable() : Observable<OwnDataUser> {
        return this.store.pipe(
            select(MetaStateOwnUserDataSelect),
        );
    }

    getOwnImageProfileObservable() : Observable<Option<Blob>> {
        return this.store.pipe(
            select(MetaStateOwnImageProfileSelect)
        );
    }

}
