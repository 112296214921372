import {AngularEditorConfig} from "@kolkov/angular-editor/lib/config";

export const textEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '500',
    minHeight: '500',
    maxHeight: '500',
    width: '500',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    toolbarHiddenButtons: [
        [
            // 'undo',
            // 'redo',
            // 'bold',
            // 'italic',
            // 'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            // 'insertUnorderedList',
            'insertOrderedList',
            'heading',
            'fontName'
        ],
        [
            'fontSize',
            'textColor',
            'backgroundColor',
            'customClasses',
            'link',
            'unlink',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
            // 'removeFormat',
            'toggleEditorMode'
        ]
    ],
    // fonts: [
    //     {class: 'arial', name: 'Arial'},
    //     {class: 'times-new-roman', name: 'Times New Roman'},
    //     {class: 'calibri', name: 'Calibri'},
    //     {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    // ],
    // customClasses: [
    //     {
    //         name: 'quote',
    //         class: 'quote',
    //     },
    //     {
    //         name: 'redText',
    //         class: 'redText'
    //     },
    //     {
    //         name: 'titleText',
    //         class: 'titleText',
    //         tag: 'h1',
    //     },
    //     {
    //         name: 'CLASE DE PRUEBA',
    //         class: "btn btn-raised btn-info",
    //         tag: '<button style="btn btn-raised btn-info"></button>'
    //     }
    // ],
    // uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    // toolbarHiddenButtons: [
    //     ['bold', 'italic'],
    //     ['fontSize']
    // ]
};

export const plainTextEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20',
    minHeight: '20',
    maxHeight: '20',
    width: '50',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    outline: false,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    toolbarHiddenButtons: [
        [
            'undo',
            'redo',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'insertUnorderedList',
            'insertOrderedList',
            'heading',
            'fontName'
        ],
        [
            'fontSize',
            'textColor',
            'backgroundColor',
            'customClasses',
            'link',
            'unlink',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
            'removeFormat',
            'toggleEditorMode'
        ]
    ],
    // fonts: [
    //     {class: 'arial', name: 'Arial'},
    //     {class: 'times-new-roman', name: 'Times New Roman'},
    //     {class: 'calibri', name: 'Calibri'},
    //     {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    // ],
    customClasses: [
        // {
        //     name: 'quote',
        //     class: 'quote',
        // },
        // {
        //     name: 'redText',
        //     class: 'redText'
        // },
        // {
        //     name: 'titleText',
        //     class: 'titleText',
        //     tag: 'h1',
        // },
        // {
        //     name: 'CLASE DE PRUEBA',
        //     class: "btn btn-raised btn-info",
        //     tag: '<button style="btn btn-raised btn-info"></button>'
        // }
    ],
    // uploadUrl: 'v1/image',
    // uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    // toolbarHiddenButtons: [
    //     ['bold', 'italic'],
    //     ['fontSize']
    // ]
};
