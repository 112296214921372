import {Observable} from "rxjs";
import {ResourcesNamespaceFacade} from "../core/sources/storage/facade/namespaces/resources_namespace.facade";
import {Injectable} from "@angular/core";
import {map, switchMap, tap} from "rxjs/operators";
import {blobToSrcImage} from "../../chatmodule/mappers/utils.mappers";
import {UtilsService} from "../../chatmodule/utils.service";

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {

    constructor(
        private resourcesNamespaceFacade: ResourcesNamespaceFacade,
        private utilsService: UtilsService
    ) {}

    private blobToSrcLink(b: Blob) : Observable<string> {
        return blobToSrcImage(b, src => {
            return src as string
        });
    }

    getMetaNoImageProfile() : Observable<string> {
        return this.resourcesNamespaceFacade.getMetaNoImageProfile().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getMetaNoConnection() : Observable<string> {
        return this.resourcesNamespaceFacade.getMetaNoConnection().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getDiscoversIcon() : Observable<string> {
        return this.resourcesNamespaceFacade.getDiscoversIcon().pipe(
            // tap(res => console.log({text: "DISCOVERS ICON RESOURCE", img: res})),
            switchMap(b => this.blobToSrcLink(b)),
            // map(b => this.utilsService.generateDownloadLinkFromBlob(b)),
            // map(url => url.url),
            // tap(res => console.log({text: "DISCOVERS ICON RESOURCE", img: res})),
        );
    }
    getDiscoversCheck() : Observable<string> {
        return this.resourcesNamespaceFacade.getDiscoversCheck().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getChatLoadMoreGallery() : Observable<string> {
        return this.resourcesNamespaceFacade.getChatLoadMoreGallery().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getChatEditGroup() : Observable<string> {
        return this.resourcesNamespaceFacade.getChatEditGroup().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getMessagesNotSendedNotSynchronizedIcon() : Observable<string> {
        return this.resourcesNamespaceFacade.getMessagesNotSendedNotSynchronizedIcon().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getMessagesSendedNotSynchronizedIcon() : Observable<string> {
        return this.resourcesNamespaceFacade.getMessagesSendedNotSynchronizedIcon().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getMessagesSendedSynchronizedIcon() : Observable<string> {
        return this.resourcesNamespaceFacade.getMessagesSendedSynchronizedIcon().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getMessagesErrorIcon() : Observable<string> {
        return this.resourcesNamespaceFacade.getMessagesErrorIcon().pipe(
            switchMap(b => this.blobToSrcLink(b))
        );
    }
    getMessagesPdfPlaceholder() : Observable<string> {
        return this.resourcesNamespaceFacade.getMessagesPdfPlaceholder().pipe(
            switchMap(b => this.blobToSrcLink(b))
        )
    }
    getScriptsNotificationSidebar() : Observable<string> {
        return this.resourcesNamespaceFacade.getScriptsNotificationSidebar().pipe(
            map(b => this.utilsService.generateDownloadLinkFromBlob(b))
        )
    }
    getMessagesVideoPlaceholder() : Observable<string> {
        return this.resourcesNamespaceFacade.getMessagesVideoPlaceholder().pipe(
            map(b => this.utilsService.generateDownloadLinkFromBlob(b))
        )
    }
    getRetryIcon() : Observable<string> {
        return this.resourcesNamespaceFacade.getRetryIcon().pipe(
            tap(b => console.log({text: "blob resource retry icon", f: b})),
            switchMap(b => this.blobToSrcLink(b))
        )
    }
}
