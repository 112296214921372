import {Injectable} from '@angular/core';
import {IAppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {fileMessagesSelect, pagedFileMessagesNextPageSelect} from '../selectors/filemessages.selectors';
// import {PagedFileMessages} from '../entities/PagedFileMessages';
import {FileMessage} from '../../../domain/FileMessage';
import {AppError} from '../../remote/globalmodels/AppError';
import {loadNextPageFilesSelect} from '../selectors/responses.selectors';
import {filter, finalize, take} from 'rxjs/operators';
import {cleanResponseLoadNextPageFiles} from '../actions/responses.actions';
import {onLoadNextPageFiles} from '../actions/filemessages.actions';
import {IPagedFileMessages} from '../entities/PagedFileMessages';
import {Option} from "fp-ts/lib/Option";
import {StandardError} from "../../../domain/StandardError";


@Injectable({
    providedIn: 'root'
})
export class FileMessagesFacade {
    constructor(
        private store : Store<IPagedFileMessages>,
        private appStore: Store<IAppState>,
    ) {}

    getNextPageToLoad() : Observable<{fullPagesLoaded: boolean, nextPageToLoad: number}> {
        return this.appStore.select(pagedFileMessagesNextPageSelect).pipe(take(1));
    }

    getFileMessagesObservable() : Observable<FileMessage[]> {
        return this.appStore.select(fileMessagesSelect);
    }

    getNextPageToLoadFailOrSuccess() : Observable<{complete: boolean, error: Option<StandardError>}> {
        return this.appStore.select(loadNextPageFilesSelect).pipe(
            filter(r => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseLoadNextPageFiles()))
        );
    }

    loadNextPage() : Observable<never> {
        return new Observable<never>(subscriber => {
            this.appStore.dispatch(onLoadNextPageFiles());
            subscriber.complete();
        });
    }

}
// getSendNewMessageFileOTOFailOrSuccess() : Observable<{fail: boolean, error: AppError}> {
//     return this.appStore.select(sendFileRoomOTOSelect).pipe(
//         filter(r => r !== undefined),
//         take(1),
//         finalize(() => this.store.dispatch(cleanResponseSendFileRoomOTO()))
//     );
// }
