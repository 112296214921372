import {Option} from "fp-ts/lib/Option";


export class DiscoverStatus {
    readonly idDiscover: number;
    readonly date: Date;
    readonly fields: {
        name: Option<string>;
        image: Option<"true">,
        position: Option<number>;
        room: Option<{
            idRoom: number;
            fields: {
                name: Option<string>;
                image: Option<"true">;
            }
        }>
    };


    constructor(idDiscover: number, date: Date, fields: { name: Option<string>; image: Option<"true">; position: Option<number>; room: Option<{ idRoom: number; fields: { name: Option<string>; image: Option<"true"> } }> }) {
        this.idDiscover = idDiscover;
        this.date = date;
        this.fields = fields;
    }
}
