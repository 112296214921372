


export class NewUserSocketModel {
    readonly idUser: number;
    readonly active: 0 | 1;
    readonly lastActivity: string;
    readonly name: string;
    readonly surname: string;
    readonly additionalInfo: string;


    constructor(idUser: number, active: 0 | 1, lastActivity: string, name: string, surname: string, additionalInfo: string) {
        this.idUser = idUser;
        this.active = active;
        this.lastActivity = lastActivity;
        this.name = name;
        this.surname = surname;
        this.additionalInfo = additionalInfo;
    }
}
