import {IAppState} from '../state/app.state';
import {adapterDiscovers} from '../adapters/discovers.adapters';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IDiscoversState} from '../state/discovers.state';

// export const selectDiscoversState = (state: IAppState) => state.discoversState;
export const selectDiscoversState = createFeatureSelector<IDiscoversState>('discoversState');
const DiscoversSelectors = adapterDiscovers.getSelectors();

export const getDiscoversSelect = createSelector(
    selectDiscoversState,
    DiscoversSelectors.selectAll
);

export const getDiscoverByIdDiscoverSelect = (idDiscover: number) => createSelector(
    selectDiscoversState,
    DiscoversSelectors.selectEntities,
    entities => entities.entities[idDiscover]
);
