import {createAction, props, Store} from '@ngrx/store';
import {IAppState} from '../state/app.state';
import {Observable} from 'rxjs';
import {Discover} from '../../../domain/Discover';
import {getDiscoverByIdDiscoverSelect, getDiscoversSelect} from '../selectors/discovers.selectors';
import {map, take} from 'rxjs/operators';
import {
    DiscoverActions,
    joinDiscover, OnDiscoverDeletedAction,
    OnDiscoverModifiedAction,
    OnNewDiscoverAction,
    quitDiscover
} from '../actions/discovers.actions';
import {Injectable} from '@angular/core';
import {Option} from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import {RoomGroup} from "../../../domain/RoomGroup";
import {DiscoverStatus} from "../../../domain/DiscoverStatus";

@Injectable({
    providedIn: 'root'
})
export class DiscoversFacade {

    constructor(
        private store: Store<IAppState>
    ) {}

    getDiscoverByIdDiscover(idDiscover: number) : Observable<Discover> {
        return this.store.select(getDiscoverByIdDiscoverSelect(idDiscover)).pipe(take(1));
    }

    getAllDiscoversObservable() : Observable<Discover[]> {
        return this.store.select(getDiscoversSelect);
    }

    getDiscoverByIdRoom(idRoom: number) : Observable<Discover> {
        return this.store.select(getDiscoversSelect).pipe(
            take(1),
            map(discovers => {
                const d = discovers.find(d => d.idRoom === idRoom);
                if (d === undefined)
                    throw new Error("getDiscoverByIdRoom(idRoom: number) : Observable<Discover>, not found by idRoom: " + idRoom);
                return d;
            })
        )
    };

    getDiscoverByIdRoomOption(idRoom: number) : Observable<Option<Discover>> {
        return this.store.select(getDiscoversSelect).pipe(
            take(1),
            map(A.findFirst(d => d.idRoom === idRoom))
        );
    }

    joinDiscoverByIdDiscover(idDiscover: number) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(joinDiscover({idDiscover: idDiscover}));
            subscriber.complete();
        });
    }

    quitDiscoverByIdDiscover(idDiscover: number) : Observable<void> {
        return new Observable<void>(subscriber => {
            this.store.dispatch(quitDiscover( {idDiscover: idDiscover}));
            subscriber.complete();
        })
    }
    dispatchOnNewDiscoverAction(discover: Discover, room: Option<RoomGroup>) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(OnNewDiscoverAction({discover: discover, room: room}));
            s.complete();
        })
    }
    dispatchOnDiscoverModifiedAction(status: DiscoverStatus) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(OnDiscoverModifiedAction({status: status}));
            s.complete();
        })
    }
    dispatchOnDiscoverDeletedAction(idDiscover: number) : Observable<never> {
        return new Observable<never>(s => {
            this.store.dispatch(OnDiscoverDeletedAction({idDiscover: idDiscover}));
            s.complete();
        })
    }

}
// export const OnNewDiscoverAction = createAction(
//     DiscoverActions.ON_NEW_DISCOVER,
//     props<{discover: Discover, room: Option<RoomGroup>}>()
// );
// export const OnDiscoverModifiedAction = createAction(
//     DiscoverActions.ON_DISCOVER_MODIFIED,
//     props<{status: DiscoverStatus}>()
// );
// export const OnDiscoverDeletedAction = createAction(
//     DiscoverActions.ON_DISCOVER_DELETED,
//     props<{idDiscover: number}>()
// );
