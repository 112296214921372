import {Action, createReducer, on} from '@ngrx/store';
import {initialUserState, IUserState} from '../state/user.state';
import {User} from '../../../domain/User';
import {adapterUser} from '../adapters/users.adapters';
import {
    // getImageProfileUser, getImageProfileUserNotFound,
    // getImageProfileUserSuccess, loadAllImageProfilesUsersWithOTO, loadAllImageProfilesUsersWithOTOSuccess,
    // loadAllPartialUsers,
    // loadAllPartialUsersSuccess,
    onModifyUserAdditionalInfo,
    onModifyUserImage,
    onModifyUserName,
    onModifyUserSurname,
    onNewUser,
    onUserDeletedSuccess
} from '../actions/user.actions';
import {Update} from '@ngrx/entity';
import {PartialUser} from '../entities/PartialUser';
import {RefreshListUsersAction, SaveListUsersAction} from "../actions/generic/users.actions";


const userReducer = createReducer(
    initialUserState,

    on(onNewUser, (state, {partialUser}) => adapterUser.addOne(partialUser, state)),
    on(onModifyUserAdditionalInfo, (state, {idUser, additionalInfo}) => adapterUser.updateOne(<Update<PartialUser>>{
        id: idUser,
        changes: {
            // ...state.entities[idUser],
            user: {
                ...state.entities[idUser].user,
                additionalInfo: additionalInfo
            }
        }
    }, state)),
    on(onModifyUserImage, (state, {idUser, image}) => adapterUser.updateOne(<Update<PartialUser>>{
        id: idUser,
        changes: {
            // ...state.entities[idUser],
            withImageProfileLoaded: true,
            user: {
                ...state.entities[idUser].user,
                imageProfileUrl: image
            }
        }
    }, state)),
    on(onUserDeletedSuccess, (state, {idUser}) => adapterUser.removeOne(idUser, state)),
    // NEW
    on(SaveListUsersAction, (state, {users}) => adapterUser.addAll(users, state)),
    on(RefreshListUsersAction, (state, {all, deleted}) => {
        const st = adapterUser.removeMany([...deleted, ...all.map(u => u.user.idUser)], state);
        return adapterUser.addMany(all, st);
    }),
    on(onModifyUserName, (state, {idUser, name}) => adapterUser.updateOne(<Update<PartialUser>>{
        id: idUser,
        changes: {
            // ...state.entities[idUser],
            user: {
                ...state.entities[idUser].user,
                name: name
            }
        }
    }, state)),
    on(onModifyUserSurname, (state, {idUser, surname}) => adapterUser.updateOne(<Update<PartialUser>>{
        id: idUser,
        changes: {
            // ...state.entities[idUser],
            user: {
                ...state.entities[idUser].user,
                surname: surname
            }
        }
    }, state))
);

export function userReducerFunction(state: IUserState | undefined, action: Action) {
    return userReducer(state, action);
}
