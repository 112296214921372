import {Option} from "fp-ts/lib/Option";


export class UserStatus {

    readonly idUser: number;
    readonly date: Date;
    readonly _id: string;
    readonly fields: {
        additionalInfo: Option<string>,
        image: Option<boolean>,
        name: Option<string>,
        surname: Option<string>,
    };


    // constructor(idUser: number, date: Date, id: string, fields: { additionalInfo: Option<string>; image: Option<boolean> }) {
    //     this.idUser = idUser;
    //     this.date = date;
    //     this._id = id;
    //     this.fields = fields;
    // }

    constructor(idUser: number, date: Date, id: string, fields: { additionalInfo: Option<string>; image: Option<boolean>; name: Option<string>; surname: Option<string> }) {
        this.idUser = idUser;
        this.date = date;
        this._id = id;
        this.fields = fields;
    }
}
