import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as E from "fp-ts/lib/Either";
import {Either} from "fp-ts/lib/Either";
import {pipe} from "fp-ts/lib/pipeable";
import {MessageOTO} from "../../../domain/MessageOTO";
import {MessageRoom} from "../../../domain/MessageRoom";
import {StandardError} from "../../../domain/StandardError";
import {MessagesRepository} from '../../../repositories/messages/messages.repository';
import {Action, Store} from '@ngrx/store';
import {IRoomGroupState, IRoomOTOState} from '../state/room.state';
import {
    CancelSendFileMessageOTOAction,
    CancelSendFileMessageRoomAction,
    LoadFromDateMessageOTOAction,
    LoadFromDateMessageOTOSuccessAction,
    LoadFromDateMessageRoomAction,
    LoadFromDateMessageRoomSuccessAction,
    loadNextPageMessagesOTO,
    loadNextPageMessagesOTOSuccess,
    loadNextPageMessagesRoom,
    loadNextPageMessagesRoomSuccess,
    LoadRemainMessagesRoomAction,
    LoadRemainMessagesRoomSuccessAction, OnAckMessageReceivedAction,
    onDeleteMessageOTOAction,
    onDeleteMessageOTOFailAction, onDeleteMessageOTOLocalAction,
    onDeleteMessageRoomAction,
    onDeleteMessageRoomFailAction, onDeleteMessageRoomLocalAction,
    onMessageOTOImageToLoad,
    onMessageRoomImageToLoad,
    onMessageRoomImageToLoadFail,
    onMessageRoomImageToLoadSuccess,
    onNewMessageOTO,
    onNewMessageRoom,
    onSendMessageOTO,
    onSendMessageOTOFail,
    onSendMessageOTOFile,
    onSendMessageOTOFileFail,
    onSendMessageOTOFileSuccess,
    onSendMessageOTOSuccess,
    onSendMessageRoom,
    onSendMessageRoomFail,
    onSendMessageRoomFile,
    onSendMessageRoomFileFail,
    onSendMessageRoomFileSuccess,
    onSendMessageRoomSuccess,
    RetrySendMessageOTOAction,
    RetrySendMessageRoomAction
} from '../actions/messages.actions';
import {catchError, concatMap, defaultIfEmpty, filter, map, switchMap, take, takeUntil, toArray} from 'rxjs/operators';
import {getRoomGroupByIdRoomSelect, getRoomOTOByIdUserSelect} from '../selectors/rooms.selectors';
import {concat, Observable, of} from 'rxjs';
import {RoomsFacade} from '../facade/rooms.facade';
import {RoomType} from '../../../domain/RoomType';
import {
    cleanMessagesNotReadedRoomGroup,
    cleanMessagesNotReadedRoomOTO,
    increaseMessagesNotReadedByOneRoomGroup,
    increaseMessagesNotReadedByOneRoomOTO
} from '../actions/room.actions';
import {nilAction} from '../actions/app.actions';
import {UsersFacade} from '../facade/users.facade';
import {AppFacade} from '../facade/app.facade';
import {SynchronizationState} from '../../../domain/SynchronizationState';
import {getFileMessageFromMessage} from '../mappers/messages.mappers';
import {onNewMessageOnFileSelectedRoom} from '../actions/filemessages.actions';
import * as O from "fp-ts/lib/Option";
import {array as A} from "fp-ts";
import {ErrorsTransformers} from "../../../domain/errors/errors.transformers";
import {MetaFacade} from "../facade/generic/meta.facade";
import {AppStateNamespaceFacade} from "../facade/namespaces/app_state_namespace.facade";
import {MessagesFacade} from "../facade/messages.facade";
import {RoomOTOStatusReadedMessages} from "../../../domain/RoomOTOStatusReadedMessages";
import {RoomGroupStatusReadedMessages} from "../../../domain/RoomGroupStatusReadedMessages";
import {BlobTransformers} from "../../../utils/blob.transformers";


@Injectable({
    providedIn: 'root'
})
export class MessageEffects {

    // --------------------------------------------------------------------------------------------
    // -------------------------------------Rooms effects------------------------------------------
    // --------------------------------------------------------------------------------------------


    loadNextPageMessagesRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNextPageMessagesRoom),
            switchMap(({idRoom, nbPages}) => this.roomsGroupStore.select(getRoomGroupByIdRoomSelect(idRoom)).pipe(take(1)).pipe(
                switchMap(partialRoomGroup => {
                    if (partialRoomGroup.fullPagesLoaded) {
                        return of(nilAction());
                    } else {

                        const obss : Observable<Either<StandardError, MessageRoom[]>>[] = [];
                        for (let i = 0; i < nbPages; i++) {
                            obss.push(
                                this.messagesRepository.getPageMessagesByIdRoomNumberPage(partialRoomGroup.roomGroup.idRoom, partialRoomGroup.nextLastPageToLoad + i)
                            )
                        }
                        return concat(...obss).pipe(
                            toArray(),
                            defaultIfEmpty([] as Either<StandardError, MessageRoom[]>[]),
                            map(eths => pipe(
                                eths,
                                A.array.sequence(E.either),
                                E.map(A.flatten)
                            ))
                        ).pipe(
                            map(E.fold(
                                error => nilAction(),
                                messages => loadNextPageMessagesRoomSuccess({idRoom: partialRoomGroup.roomGroup.idRoom, messages:messages})
                            ))
                        );

                        // return this.messagesRepository.getPageMessagesByIdRoomNumberPage(partialRoomGroup.roomGroup.idRoom, partialRoomGroup.nextLastPageToLoad).pipe(
                        //     switchMap(E.fold(
                        //         error => [nilAction()], // TODO FER LOAD_NEXT_PAGE_MESSAGES_ROOM_FAIL,
                        //         messages => loadNextPageMessagesRoomSuccess({idRoom: partialRoomGroup.roomGroup.idRoom, messages:messages})
                        //     ))
                        // );
                    }
                })
            ))
        )
    );



    onSendMessageRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onSendMessageRoom),
            switchMap(({message}) => this.appStateNamespaceFacade.getAppWithConnection().pipe(
                switchMap(withConnection => {
                    if (withConnection) {
                        return this.messagesRepository.sendNewMessageRoom(message).pipe(
                            map(E.fold(
                                err => onSendMessageRoomFail({
                                    error: err,
                                    message: message.changeSynchronizationState(
                                        SynchronizationState.FAIL,
                                        O.none,
                                        O.some({error: err.message, file: O.none}),
                                        O.some(message.getNextRetriesSent())
                                    )
                                }),
                                _ => onSendMessageRoomSuccess({message: message.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.none, O.none, O.none)})
                            ))
                        )
                    } else {
                        const err = this.errorsTransformers.getStandardErrorConnectionServer();
                        const action = onSendMessageRoomFail({
                            error: err,
                            message: message.changeSynchronizationState(
                                SynchronizationState.FAIL,
                                O.none,
                                O.some({error: err.message, file: O.none}),
                                O.some(message.getNextRetriesSent())
                            )
                        });
                        return [action];
                    //     return of(nilAction());
                    }
                })
            ))
        )
    );

    onNewMessageRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onNewMessageRoom),
            concatMap(({message}) => this.roomFacade.getSelectedRoom().pipe(
                switchMap(([id, type]) => this.metaFacade.getOwnDataUser().pipe(
                    switchMap(ownUser => {
                        if (type === RoomType.GROUP && id+'' === message.idRoom+'') {
                            const actions = [];

                            if (message.thumbnail !== undefined) {
                                actions.push(onNewMessageOnFileSelectedRoom({fileMessage: getFileMessageFromMessage(message)}));
                            }
                            if (message.idUser+'' !== ownUser.idUser+'')
                                actions.push(increaseMessagesNotReadedByOneRoomGroup({idRoom: message.idRoom}));
                            if (!document.hidden)
                                actions.push(cleanMessagesNotReadedRoomGroup({entity: new RoomGroupStatusReadedMessages(1, id)}));
                            return actions;
                        } else if (message.idUser+'' !== ownUser.idUser+'' && !document.hidden) {
                            return [increaseMessagesNotReadedByOneRoomGroup({idRoom: message.idRoom})];
                        } else {
                            return [nilAction()];
                        }
                    })
                ))
            )),
        )
    );

    onDeleteMessageRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onDeleteMessageRoomAction),
            switchMap(({idRoom, idMessage}) => this.messagesFacade.getMessageRoomByIdRoomIdMessageOption(idRoom, idMessage).pipe(
                switchMap(O.fold(
                    () => [onDeleteMessageRoomFailAction({idRoom: idRoom, idMessage: idMessage}) as Action],
                    message => {
                        if (message.isLocalMessage()) {
                            return [onDeleteMessageRoomLocalAction({idRoom: idRoom, idMessage: idMessage}) as Action]
                        } else {
                            return this.appStateNamespaceFacade.getAppWithConnection().pipe(
                                switchMap(withConnection => {
                                    if (withConnection) {
                                        return this.messagesRepository.deleteMessageRoom(idRoom, idMessage).pipe(
                                            map(E.fold(
                                                err => onDeleteMessageRoomFailAction({idRoom: idRoom, idMessage: idMessage}) as Action,
                                                msg => nilAction()
                                            ))
                                        )
                                    } else {
                                        return [onDeleteMessageRoomFailAction({idRoom: idRoom, idMessage: idMessage}) as Action]
                                    }
                                })
                            )
                        }
                    }
                ))
            ))
        )
    );

    loadRemainMessagesRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadRemainMessagesRoomAction),
            switchMap(({idRoom}) => this.roomFacade.getRoomByIdRoomOption(idRoom).pipe(
                switchMap(O.fold(
                    () => of([nilAction()]).pipe(switchMap(a => a)),
                    room => this.messagesRepository.getNumberMessagesByIdRoom(idRoom, room.roomGroup.messageCount).pipe(
                        switchMap(E.fold(
                            err => [nilAction()], // TODO casulística quan es carreguen missatges pendents d'una sala.
                            msgs => [LoadRemainMessagesRoomSuccessAction({idRoom: idRoom, messages: msgs})]
                        ))
                    )
                ))
            ))
        )
    );

    // --------------------------------------------------------------------------------------------
    // -------------------------------------OTOs effects-------------------------------------------
    // --------------------------------------------------------------------------------------------



    loadNextPageMessagesOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNextPageMessagesOTO),
            switchMap(({idUser, nbPages}) => this.roomsOTOSStore.select(getRoomOTOByIdUserSelect(idUser)).pipe(take(1)).pipe(
                switchMap(partialRoomOTO => {
                    if (partialRoomOTO.fullPagesLoaded) {
                        return of(nilAction());
                    } else {

                        const obss : Observable<Either<StandardError, MessageOTO[]>>[] = [];
                        for (let i = 0; i < nbPages; i++) {
                            obss.push(
                                this.messagesRepository.getPageMessagesByIdUserNumberPage(partialRoomOTO.roomOTO.idUser, partialRoomOTO.nextLastPageToLoad + i)
                            )
                        }
                        return concat(...obss).pipe(
                            toArray(),
                            defaultIfEmpty([] as Either<StandardError, MessageOTO[]>[]),
                            map(eths => pipe(
                                eths,
                                A.array.sequence(E.either),
                                E.map(A.flatten)
                            ))
                        ).pipe(
                            map(E.fold(
                                error => nilAction(),
                                messages => loadNextPageMessagesOTOSuccess({idUser: partialRoomOTO.roomOTO.idUser, messages:messages})
                            ))
                        );

                        // return this.messagesRepository.getPageMessagesByIdRoomNumberPage(partialRoomGroup.roomGroup.idRoom, partialRoomGroup.nextLastPageToLoad).pipe(
                        //     switchMap(E.fold(
                        //         error => [nilAction()], // TODO FER LOAD_NEXT_PAGE_MESSAGES_ROOM_FAIL,
                        //         messages => loadNextPageMessagesRoomSuccess({idRoom: partialRoomGroup.roomGroup.idRoom, messages:messages})
                        //     ))
                        // );
                    }

                    // this.messagesRepository.getPageMessagesByIdUserNumberPage(partialRoomOTO.roomOTO.idUser, partialRoomOTO.nextLastPageToLoad).pipe(
                    //     map(E.fold(
                    //         err => nilAction(), // TODO FER LOAD_NEXT_PAGE_MESSAGES_OTO_FAIL
                    //         messages => loadNextPageMessagesOTOSuccess({idUser: partialRoomOTO.roomOTO.idUser, messages: messages})
                    //     ))
                    // )
                })
            )),

        )
    );

    onSendMessageOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onSendMessageOTO),
            concatMap(({message}) => this.appStateNamespaceFacade.getAppWithConnection().pipe(
                switchMap(withConnection => {
                    if (withConnection) {
                        return this.messagesRepository.sendNewMessageOTO(message).pipe(
                            // map(_ => on)
                            map(E.fold(
                                err => onSendMessageOTOFail({
                                    error: err,
                                    message: message.changeSynchronizationState(
                                        SynchronizationState.FAIL,
                                        O.none,
                                        O.some({error: err.message, file: O.none}),
                                        O.some(message.getNextRetriesSent())
                                    )
                                }) as Action,
                                _ => onSendMessageOTOSuccess({message: message.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.none, O.none, O.none)})
                            ))
                        )
                    } else {
                        const err = this.errorsTransformers.getStandardErrorConnectionServer();
                        const action = onSendMessageOTOFail({
                            error: err,
                            message: message.changeSynchronizationState(
                                SynchronizationState.FAIL,
                                O.none,
                                O.some({error: err.message, file: O.none}),
                                O.some(message.getNextRetriesSent())
                            )
                        });
                        return [action]
                    }
                })
            ))
        )
    );

    onNewMessageOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onNewMessageOTO),
            concatMap(({message}) => this.metaFacade.getOwnDataUser().pipe(
                switchMap(userOwn => this.roomFacade.getSelectedRoom().pipe(
                    switchMap(([id, type]) => {
                        const l = [];
                        if (type === RoomType.OTO && id+'' === message.idUserOTO+'') {
                            // return cleanMessagesNotReadedRoomOTO({idUser: id});

                            if (message.thumbnail !== undefined) {
                                l.push(onNewMessageOnFileSelectedRoom({fileMessage: getFileMessageFromMessage(message)}));
                            }
                            if (message.idUser+'' !== userOwn.idUser+'')
                                l.push(increaseMessagesNotReadedByOneRoomOTO({idUser: message.idUserOTO}));
                            if (!document.hidden)
                                l.push(cleanMessagesNotReadedRoomOTO({entity: new RoomOTOStatusReadedMessages(1, id)}));
                            // return nilAction();
                            // l.push(nilAction());
                        } else if (message.idUser+'' !== userOwn.idUser+'') {
                            // return nilAction();
                            l.push(increaseMessagesNotReadedByOneRoomOTO({idUser: message.idUserOTO}));
                        } else {
                            l.push(nilAction());
                        }
                        // if (!user.withImageProfileLoaded)
                        //     l.push(getImageProfileUser({idUser: message.idUser}));
                        return l;
                    })
                ))
            ))
        )
    );

    onDeleteMessageOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onDeleteMessageOTOAction),
            switchMap(({idUser, idMessage}) => this.messagesFacade.getMessageOTOByIdUserIdMessageOption(idUser, idMessage).pipe(
                switchMap(O.fold(
                    () => [onDeleteMessageOTOFailAction({idUser: idUser, idMessage: idMessage}) as Action],
                    message => {
                        if (message.isLocalMessage()) return [onDeleteMessageOTOLocalAction({idUser: idUser, idMessage: idMessage}) as Action];
                        return this.appStateNamespaceFacade.getAppWithConnection().pipe(
                            switchMap(withConnection => {
                                if (withConnection) {
                                    return this.messagesRepository.deleteMessageOTO(idUser, idMessage).pipe(
                                        map(E.fold(
                                            err => onDeleteMessageOTOFailAction({idUser: idUser, idMessage: idMessage}) as Action,
                                            msg => nilAction()
                                        ))
                                    )
                                } else {
                                    return [onDeleteMessageOTOFailAction({idUser: idUser, idMessage: idMessage}) as Action]
                                }
                            })
                        )
                    }
                ))
            ))
        )
    );

    onSendMessageRoomFileEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onSendMessageRoomFile),
            concatMap(({message, file, base64Thumbnail}) => {
                const reply = pipe(
                    message.messageReply,
                    O.map(({idMessage}) => idMessage)
                );
                return this.messagesRepository.sendNewMessageRoomFile(message.text, file, base64Thumbnail, message.idRoom, message.internalId, reply).pipe(
                    takeUntil(this.actions$.pipe(
                        ofType(CancelSendFileMessageRoomAction),
                        filter(({idRoom, internalId}) => idRoom === message.idRoom && internalId === message.internalId)
                    )),
                    map(E.fold(
                        err => onSendMessageRoomFileFail({
                            error: err,
                            message: message.changeSynchronizationState(
                                SynchronizationState.FAIL,
                                O.none,
                                O.some({
                                    error: err.message,
                                    file: O.some({
                                        base64Thumbnail: base64Thumbnail,
                                        file: file,
                                        linkFileBlob: this.blobTransformers.getUrlFromBlob(file)
                                    })
                                }),
                                O.some(message.getNextRetriesSent())
                            )
                        }) as Action,
                        id => onSendMessageRoomFileSuccess({message: message.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.some(id), O.none, O.none)})
                    )),
                    catchError(err => {
                        return of(onSendMessageRoomFileFail({
                            error: StandardError.getCanceledStandardError(),
                            message: message.changeSynchronizationState(
                                SynchronizationState.FAIL,
                                O.none,
                                O.some({
                                    error: err.message,
                                    file: O.some({
                                        base64Thumbnail: base64Thumbnail,
                                        file: file,
                                        linkFileBlob: this.blobTransformers.getUrlFromBlob(file)
                                    })
                                }),
                                O.some(message.getNextRetriesSent())
                            )
                        }))
                    })
                )
            }),
        )
    );

    onSendMessageOTOFileEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onSendMessageOTOFile),
            concatMap(({message, file, base64Thumbnail}) => {
                const reply = pipe(
                    message.messageReply,
                    O.map(({idMessage}) => idMessage)
                );
                return this.messagesRepository.sendNewMessageOTOFile(message.text, file, base64Thumbnail, message.idUserOTO, message.internalId, reply).pipe(
                    takeUntil(this.actions$.pipe(
                        ofType(CancelSendFileMessageOTOAction),
                        filter(({idUserOTO, internalId}) => idUserOTO === message.idUserOTO && internalId === message.internalId)
                    )),
                    map(E.fold(
                        err => onSendMessageOTOFileFail({
                            error: err,
                            message: message.changeSynchronizationState(
                                SynchronizationState.FAIL,
                                O.none,
                                O.some({
                                    error: err.message,
                                    file: O.some({
                                        base64Thumbnail: base64Thumbnail,
                                        file: file,
                                        linkFileBlob: this.blobTransformers.getUrlFromBlob(file)
                                    })
                                }),
                                O.some(message.getNextRetriesSent())
                            )
                        }) as Action,
                        id => onSendMessageOTOFileSuccess({message: message.changeSynchronizationState(SynchronizationState.NOT_SYNCHRONIZED_SENDED, O.some(id), O.none, O.none)})
                    )),
                    catchError(err => {
                        return of(onSendMessageOTOFileFail({
                            error: StandardError.getCanceledStandardError(),
                            message: message.changeSynchronizationState(
                                SynchronizationState.FAIL,
                                O.none,
                                O.some({
                                    error: err.message,
                                    file: O.some({
                                        base64Thumbnail: base64Thumbnail,
                                        file: file,
                                        linkFileBlob: this.blobTransformers.getUrlFromBlob(file)
                                    })
                                }),
                                O.some(message.getNextRetriesSent())
                            )
                        }))
                    })
                )
            }),

        )
    );

    onMessageRoomImageToLoadEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onMessageRoomImageToLoad),
            switchMap(({idRoom, idMessage}) => this.messagesRepository.getFileFromMessageByIdRoomIdMessage(idRoom, idMessage).pipe(
                map(E.fold(
                    err => onMessageRoomImageToLoadFail({error: err, idMessage: idMessage}) as Action,
                    res => onMessageRoomImageToLoadSuccess({image: O.some(res), idMessage: idMessage})
                ))
            )),

        )
    );

    onMessageOTOImageToLoadEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onMessageOTOImageToLoad),
            switchMap(({idUser, idMessage}) => this.messagesRepository.getFileFromMessageByIdUserIdMessage(idUser, idMessage).pipe(
                map(E.fold(
                    err => onMessageRoomImageToLoadFail({error: err, idMessage: idMessage}) as Action,
                    res => onMessageRoomImageToLoadSuccess({image: O.some(res), idMessage: idMessage})
                ))
            )),
        )
    );

    loadFromDateMessageRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadFromDateMessageRoomAction),
            switchMap(({idRoom, date}) => this.messagesRepository.getMessagesHistoricRoomGroupFromDate(idRoom, date).pipe(
                map(E.fold(
                    err => {
                        console.error({text: "Error when trying to load historic ", err: err});
                        throw err;
                    },
                    msgs => LoadFromDateMessageRoomSuccessAction({idRoom: idRoom, messages: msgs})
                ))
            ))
        )
    );

    loadFromDateMessageOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadFromDateMessageOTOAction),
            switchMap(({idUserOTO, date}) => this.messagesRepository.getMessagesHistoricRoomOTOFromDate(idUserOTO, date).pipe(
                map(E.fold(
                    err => {
                        console.error({text: "Error when trying to load historic ", err: err});
                        throw err;
                    },
                    msgs => LoadFromDateMessageOTOSuccessAction({idUserOTO: idUserOTO, messages: msgs})
                ))
            ))
        )
    );


    retrySendMessageOTOActionEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RetrySendMessageOTOAction),
            switchMap(({idUserOTO, internalId}) => this.messagesFacade.getMessageOTOByIdUserIdMessageOption(idUserOTO, internalId).pipe(
                map(O.fold(
                    () => nilAction(),
                    msg => pipe(
                        msg.error,
                        O.fold(
                            () => nilAction(),
                            error => pipe(
                                error.file,
                                O.fold(
                                    () => onSendMessageOTO({
                                        message: msg.changeSynchronizationState(
                                            SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED,
                                            O.none,
                                            O.none,
                                            msg.retriesSent
                                        )
                                    }),
                                    ({file, base64Thumbnail}) => onSendMessageOTOFile({
                                        message: msg.changeSynchronizationState(
                                            SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED,
                                            O.none,
                                            O.none,
                                            msg.retriesSent
                                        ),
                                        file: file,
                                        base64Thumbnail: base64Thumbnail
                                    })
                                )
                            )
                        )
                    )
                ))
            ))
        )
    )

    retrySendMessageRoomActionEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RetrySendMessageRoomAction),
            switchMap(({idRoom, internalId}) => this.messagesFacade.getMessageRoomByIdRoomIdMessageOption(idRoom, internalId).pipe(
                map(O.fold(
                    () => nilAction(),
                    msg => pipe(
                        msg.error,
                        O.fold(
                            () => nilAction(),
                            error => pipe(
                                error.file,
                                O.fold(
                                    () => onSendMessageRoom({
                                        message: msg.changeSynchronizationState(
                                            SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED,
                                            O.none,
                                            O.none,
                                            msg.retriesSent
                                        )
                                    }),
                                    ({file, base64Thumbnail}) => onSendMessageRoomFile({
                                        message: msg.changeSynchronizationState(
                                            SynchronizationState.NOT_SYNCHRONIZED_NOT_SENDED,
                                            O.none,
                                            O.none,
                                            msg.retriesSent
                                        ),
                                        file: file,
                                        base64Thumbnail: base64Thumbnail
                                    })
                                )
                            )
                        )
                    )
                ))
            ))
        )
    )

    // onAckMessageReceivedEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(OnAckMessageReceivedAction),
    //         switchMap(({ack}) => {
    //             this.
    //         })
    //     )
    // )


    constructor(
        private actions$ : Actions,
        private messagesRepository: MessagesRepository,
        private roomsGroupStore: Store<IRoomGroupState>,
        private roomsOTOSStore: Store<IRoomOTOState>,
        private roomFacade: RoomsFacade,
        private usersFacade: UsersFacade,
        private appFacade: AppFacade,
        private errorsTransformers: ErrorsTransformers,
        private metaFacade: MetaFacade,
        private appStateNamespaceFacade: AppStateNamespaceFacade,
        private messagesFacade: MessagesFacade,
        private blobTransformers: BlobTransformers
    ) {}
}
