import {IDiscoverApiModel} from '../../sources/remote/discovers/models/IDiscoverApiModel';
import {Discover} from '../../domain/Discover';
import {Option} from "fp-ts/lib/Option";


export function getDiscoverFromDiscoverApiModelAndImage(discoverApiModel: IDiscoverApiModel, image: Option<Blob>) : Discover {
    return new Discover(
        discoverApiModel.idDiscover,
        discoverApiModel.name,
        discoverApiModel.description,
        discoverApiModel.position,
        discoverApiModel.idRoom,
        image,
        discoverApiModel.vinculated === 'true'
    );
}
