import {Injectable} from '@angular/core';
import {CookieStoreService} from '../../../../../localStorage/cookie-store.service';
import {HttpHeaders} from './HttpHeaders';
import {LoggedHttpHeaders} from './LoggedHttpHeaders';
import {UnloggedHttpHeaders} from './UnloggedHttpHeaders';
import {combineLatest, concat, EMPTY, Observable, of, zip} from 'rxjs';
import {LoggedState} from './LoggedState';
import {flatMap, map, switchMap, tap} from 'rxjs/operators';
import {PostregisterHttpHeaders} from './PostregisterHttpHeaders';

@Injectable({
    providedIn: 'root',
})
export class HttpHeadersManager {

    private currentHeaders : HttpHeaders;

    constructor(
        private tokenService: CookieStoreService
    ) {
        this.setCurrentHeaders().subscribe();
    }

    private setCurrentHeaders() : Observable<never> {
        return zip(
            this.tokenService.existsById(LoggedHttpHeaders.getTokenTag()),
            this.tokenService.existsById(PostregisterHttpHeaders.getTokenTag())
        ).pipe(
            switchMap(([existsApp, existsPost]) => {
                if (existsApp) {
                    this.currentHeaders = new LoggedHttpHeaders(this.tokenService);
                } else if (existsPost) {
                    this.currentHeaders = new PostregisterHttpHeaders(this.tokenService);
                } else {
                    this.currentHeaders = new UnloggedHttpHeaders();
                }
                return EMPTY
            })
        );
    }


    getCurrentHeaders() : Observable<{authorization: string}> {
        return zip(
            this.tokenService.existsById(LoggedHttpHeaders.getTokenTag()),
            this.tokenService.existsById(PostregisterHttpHeaders.getTokenTag())
        ).pipe(
            switchMap(([existsApp, existsPost]) => {
                if (existsApp) {
                    return this.tokenService.getById(LoggedHttpHeaders.getTokenTag());
                } else if (existsPost) {
                    return this.tokenService.getById(PostregisterHttpHeaders.getTokenTag());
                } else {
                    return of("");
                }
            }),
            map(str => ({authorization: str}))
        );
    }


    getWebState() : Observable<LoggedState> {
        return of(this.currentHeaders.getLoggedState());
    }


    setLoggedState(token: string) : Observable<never> {
        return concat(
            this.tokenService.putById(LoggedHttpHeaders.getTokenTag(), token),
            this.tokenService.removeById(PostregisterHttpHeaders.getTokenTag()),
            // new Observable<never>(subscriber => {
            //     this.currentHeaders = new LoggedHttpHeaders(this.tokenService);
            //     subscriber.complete();
            // })
        );
    }

    setUnloggedState() : Observable<never> {
        console.log({text: "setting out unlogged state"})
        return concat(
            this.tokenService.removeById(LoggedHttpHeaders.getTokenTag()),
            this.tokenService.removeById(PostregisterHttpHeaders.getTokenTag()),
            // new Observable<{}>(subscriber => {
            //     this.currentHeaders = new UnloggedHttpHeaders();
            //     console.log("Unlogged http headers setted.")
            //     subscriber.next({});
            //     subscriber.complete();
            // })
        );

    }

    setPostregisterState(token: string) : Observable<never> {
        return concat(
            this.tokenService.removeById(LoggedHttpHeaders.getTokenTag()),
            this.tokenService.putById(PostregisterHttpHeaders.getTokenTag(), token),
            // new Observable<never>(subscriber => {
            //     console.log("SETTING UP THE POSTREGISTER HEADERS" + token)
            //     this.currentHeaders = new PostregisterHttpHeaders(this.tokenService);
            //     subscriber.complete();
            // })
        );
    }

    getAuthorizationToken() : Observable<string> {
        return this.getCurrentHeaders().pipe(
            map(({authorization}) => authorization)
        );
        // return concat(
            // this.setCurrentHeaders(),
            // this.currentHeaders.getAuthorizationToken()
        // );
    }

    // refreshState() : Observable<never> {
    //     return this.setCurrentHeaders();
    // }



}
