import {Action, ActionReducerMap, createReducer, MetaReducer, on} from '@ngrx/store';
import {IAppState, initialAppState} from '../state/app.state';
import {roomGroupReducerFunction, roomOTOReducerFunction} from './room.reducer';
import {userReducerFunction} from './user.reducer';
import {
    changeOwnDataSuccess,
    // loadApp,
    // loadAppSuccess,
    // loadOwnImageProfile,
    // loadOwnImageProfileSuccess,
    // loadOwnUserData,
    // loadOwnUserDataSuccess,
    onDisconnectSocket,
    // openSocket, openSocketError, openSocketSuccess
} from '../actions/app.actions';
import {messageOTOReducerFunction, messageRoomReducerFunction} from './message.reducer';
import {OwnDataUser} from '../../../domain/OwnDataUser';
import {leaveRoomGroupSuccess, leaveRoomOTOSuccess, selectRoom, selectRoomSuccess} from '../actions/room.actions';
import {responsesReducerFunction} from './responses.reducer';
import {fileMessageReducerFunction} from './filemessages.reducer';
import {discoversReducerFunction} from './discovers.reducer';
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import {appStateNamespaceReducerFunction} from "./namespaces/app_state_namespace.reducer";
import {datesUpdatesNamespaceReducerFunction} from "./namespaces/dates_updates_namespace.reducer";
import {metaStateReducerFunction} from "./generic/meta.reducer";
import {resourcesNamespaceReducerFunction} from "./namespaces/resources_namespace.reducer";
import {fileMessagesNotSendedReducerFunction} from "./messages/filemessagesnotsended.reducer";
import {RefreshListRoomsAction} from "../actions/generic/rooms.actions";
import {RefreshListUsersAction} from "../actions/generic/users.actions";


// const loadAppReducer = createReducer(
//     initialAppState.loadedApp,
//     // on(loadApp, state => state),
//     // on(loadAppSuccess, _ => (true))
// );
//
// export function loadAppReducerFunction(state: boolean, action: Action) {
//     return loadAppReducer(state, action);
// }



// const ownDataUserReducer = createReducer(
//     initialAppState.ownDataUser,
//     // on(loadOwnUserData, state => state),
//     // on(loadOwnUserDataSuccess, (_, {ownDataUser}) => ownDataUser),
//     on(changeOwnDataSuccess, (ownDataUser, {bio}) =>
//         new OwnDataUser(
//             ownDataUser.idUser,
//             ownDataUser.idClient,
//             ownDataUser.loginIdentifier,
//             ownDataUser.name,
//             ownDataUser.surname,
//             pipe(
//                 bio,
//                 O.fold(
//                     () => ownDataUser.bio,
//                     bio => bio
//                 )
//             )
//         )
//     )
// );

// export function ownDataUserReducerFunction(state: OwnDataUser | undefined, action: Action) {
//     return ownDataUserReducer(state, action);
// }

// const ownImageProfileReducer = createReducer(
//     initialAppState.ownImageProfile,
//     // on(loadOwnImageProfile, state => state),
//     // on(loadOwnImageProfileSuccess, (_, {ownImageProfile}) => ownImageProfile),
//     on(changeOwnDataSuccess, (oldImage , {image}) => pipe(
//         image,
//         O.fold(
//             () => oldImage,
//             img => img
//         )
//         // image !== undefined ? image : oldImage
//     ))
// );

// const ownImageProfileReducer = createReducer
//
// export function ownImageProfileReducerFunction(state: Blob | undefined, action: Action) {
//     return ownImageProfileReducer(state, action);
// }

// const socketConnectedReducer = createReducer(
//     initialAppState.socketConnected,
//     on(openSocket, state => state),
//     on(openSocketSuccess, _ => true),
//     on(onDisconnectSocket, _ => false)
// );
//
// export function socketConnectedReducerFunction(state: boolean, action: Action) {
//     return socketConnectedReducer(state, action);
// }

// const socketConnectedErrorReducer = createReducer(
//     initialAppState.socketConnectedError,
//     on(openSocketError, _ => true)
// );

// export function socketConnectedErrorReducerFunction(state: boolean, action: Action) {
//     return socketConnectedErrorReducer(state, action);
// }

const selectedRoomReducer = createReducer(
    initialAppState.selectedRoom,
    on(selectRoomSuccess, (_, {idRoom, idUser}) => ({idRoomGroupSelected:idRoom, idUserGroupSelected: idUser})),
    on(leaveRoomGroupSuccess, (state, {idRoom}) => {
        if (state.idRoomGroupSelected === idRoom)
            return ({idRoomGroupSelected: undefined, idUserGroupSelected: undefined});
        return state;
    }),
    on(leaveRoomOTOSuccess, (state, {idUser}) => {
        console.log({text: "appreducer leaveRoomOTOSuccess", state: state, idUser: idUser});
        if (state.idUserGroupSelected === idUser)
            return ({idRoomGroupSelected: undefined, idUserGroupSelected: undefined});
        return state;
    }),
    on(RefreshListRoomsAction, (state, {deleted}) => {
        if (deleted.otos.some(o => o === state.idUserGroupSelected) || deleted.rooms.some(r => r === state.idRoomGroupSelected))
            return ({idRoomGroupSelected: undefined, idUserGroupSelected: undefined});
        return state;
    }),
    on(RefreshListUsersAction, (state, {deleted}) => {
        if (deleted.some(u => u === state.idUserGroupSelected))
            return ({idRoomGroupSelected: undefined, idUserGroupSelected: undefined});
        return state;
    })
);

export function selectedRoomReducerFunction(state: {idRoomGroupSelected:number,idUserGroupSelected:number}, action: Action) {
    return selectedRoomReducer(state, action);
}

export const appReducer: ActionReducerMap<IAppState, any> = {
    roomGroups: roomGroupReducerFunction,
    roomOTOs: roomOTOReducerFunction,
    users: userReducerFunction,
    messageRooms: messageRoomReducerFunction,
    messageOTOs: messageOTOReducerFunction,
    selectedRoom: selectedRoomReducerFunction,
    responsesState: responsesReducerFunction,
    fileMessagesState: fileMessageReducerFunction,
    discoversState: discoversReducerFunction,

    // NEW
    metaState: metaStateReducerFunction,
    appNamespaceState: appStateNamespaceReducerFunction,
    datesUpdatesNamespaceState: datesUpdatesNamespaceReducerFunction,
    resourcesNamespaceState: resourcesNamespaceReducerFunction,
    fileMessageNotSendedState: fileMessagesNotSendedReducerFunction
};
// const appReducer = createReducer(
//     initialAppState
// );
//
//
// export function appReducerFunction()
//
// export const appMetaReducers: MetaReducer<IAppState, Action> = {
//     // router: routerReducer,
//     // rooms: roomReducers
//     // router: routerReducer,
//     // rooms: roomReducers
// };
