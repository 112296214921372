import {createAction, props} from "@ngrx/store";
import {Option} from "fp-ts/lib/Option";


const TAG = "[User Validation] ";

export const UserValidationActions = {

    START_USER_VALIDATION: TAG + 'Start User Validation',

    WAIT_PROCESS: TAG + 'Wait Process',
    // CHECK_CONNECTION: TAG + 'Check Connection',
    CHECK_TOKEN: TAG + 'Check Token',

    RENEW_TOKEN: TAG + 'Renew Token',

    END_USER_VALIDATION: TAG + 'End User Validation',

    SET_ACTIVE_USER_VALIDATION_PROCESS: TAG + 'Set Active User Validation Process',
};


export const UserValidationStartUserValidationAction = createAction(
    UserValidationActions.START_USER_VALIDATION
);
export const UserValidationWaitProcessAction = createAction(
    UserValidationActions.WAIT_PROCESS
);
// export const UserValidationCheckConnectionAction = createAction(
//     UserValidationActions.CHECK_CONNECTION
// );
export const UserValidationRenewTokenAction = createAction(
    UserValidationActions.RENEW_TOKEN,
    props<{token: Option<string>}>()
);
export const UserValidationCheckTokenAction = createAction(
    UserValidationActions.CHECK_TOKEN
);
export const UserValidationEndUserValidationAction = createAction(
    UserValidationActions.END_USER_VALIDATION
);
export const UserValidationSetActiveUserValidationProcessAction = createAction(
    UserValidationActions.SET_ACTIVE_USER_VALIDATION_PROCESS,
    props<{active: boolean}>()
);
