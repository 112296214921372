import {IAppState} from '../state/app.state';
import {createSelector} from '@ngrx/store';


const appSelect = (state: IAppState) => state;

// export const loadedAppSelect = createSelector(appSelect, appState => appState.loadedApp);

// export const ownDataUserSelect = createSelector(appSelect, appState => appState.ownDataUser);

// export const ownImageProfileSelect = createSelector(appSelect, appState => appState.ownImageProfile);

export const selectedRoomsSelect = createSelector(appSelect, appState => appState.selectedRoom);
