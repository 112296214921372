import {Injectable} from '@angular/core';
import {BehaviorSubject, merge, Observable, of, race, Subject} from 'rxjs';
import {MessageRoom} from '../domain/MessageRoom';
import {AppFacade} from '../sources/storage/facade/app.facade';
import {MessagesFacade} from '../sources/storage/facade/messages.facade';
import {SocketClient} from './SocketClient';
import {AppError} from '../sources/remote/globalmodels/AppError';
import {DomainmodelMappers,} from './mappers/domainmodel.mappers';
import {
    getAckMsgSocketModel, getConfirmationFileSocketModel,
    getDiscoverModifiedSocketModel,
    getMessageOTOSocketModelFromObject,
    getMessageRoomSocketModelFromObject,
    getNewDiscoverSocketModel,
    getNewuserSocketModel,
    getOTOReadedMessagesSocketModel,
    getRoomAdminsSocketModel,
    getRoomModifiedSocketModel,
    getRoomReadedMessagesSocketModel,
    getStatusSocketModelFromObject,
    getUserModifiedSocketModel
} from './mappers/socketmodel.mappers';
import {HttpHeadersManager} from '../sources/remote/core/headers/HttpHeadersManager';
import {MessageRoomSocketModel} from './models/MessageRoomSocketModel';
import {catchError, concatMap, filter, flatMap, map, mergeMap, switchMap, take, tap, timeout} from 'rxjs/operators';
import {ConnectErrorSocketException} from './exceptions/ConnectErrorSocketException';
import {InputEvent, OutputEvent} from './socket.events';
import {MessageRoomSocketModelOut} from './models/MessageRoomSocketModelOut';
import {SynchronizationState} from '../domain/SynchronizationState';
import {MessageOTO} from '../domain/MessageOTO';
import {MessageOTOSocketModel} from './models/MessageOTOSocketModel';
import {MessageOTOSocketModelOut} from './models/MessageOTOSocketModelOut';
import {RoomStatusJoined, RoomStatusModified, RoomStatusQuit} from '../domain/RoomStatus';
import {RoomsFacade} from '../sources/storage/facade/rooms.facade';
import {StatusSocketModel} from './models/StatusSocketModel';
import {UserStatus} from "../domain/UserStatus";
import {UsersFacade} from "../sources/storage/facade/users.facade";
import {PartialUser} from "../sources/storage/entities/PartialUser";
import * as E from "fp-ts/lib/Either";
import {Either} from "fp-ts/lib/Either";
import {ErrorsTransformers} from "../domain/errors/errors.transformers";
import {StandardError} from "../domain/StandardError";
import {AppStateNamespaceFacade} from "../sources/storage/facade/namespaces/app_state_namespace.facade";
import {AckMsgSocketModel} from "./models/AckMsgSocketModel";
import {Discover} from "../domain/Discover";
import {RoomGroup} from "../domain/RoomGroup";
import * as O from "fp-ts/lib/Option";
import {Option} from "fp-ts/lib/Option";
import {DiscoverStatus} from "../domain/DiscoverStatus";
import {getDiscoverFromDiscoverApiModelAndImage} from "../repositories/mappers/discovers.mapper";
import {pipe} from "fp-ts/lib/pipeable";
import {RoomsMapper} from "../repositories/mappers/rooms.mapper";
import {RoomGroupStatusReadedMessages} from "../domain/RoomGroupStatusReadedMessages";
import {RoomOTOStatusReadedMessages} from "../domain/RoomOTOStatusReadedMessages";
import {RoomStatusAdmins} from "../domain/RoomStatusAdmins";
import {DiscoversFacade} from "../sources/storage/facade/discovers.facade";
import {ConfirmationFileSocketModel} from "./models/ConfirmationFileSocketModel";
import {SocketsAuthService} from "../../sockets-auth/sockets-auth.service";

@Injectable({
    providedIn: 'root'
})
export class SocketFacade {

    // crear subjects para que cuando se interrumpa conexión, las subsripciones de estos
    //  no se interrumpan.

    // cada vez que se haga un init sockets se ha de renovar suscripciones socket -> subjects

    // Subject por cada evento.
    // - Room message
    // - On disconnect
    // -


    private roomMessageSubject : Subject<{msg: MessageRoomSocketModel, sync: SynchronizationState}>;
    private otoMessageSubject: Subject<{msg: MessageOTOSocketModel, sync: SynchronizationState}>;

    private joinedRoomSubject : Subject<RoomStatusJoined>;
    private quitRoomSubject : Subject<RoomStatusQuit>;
    private oneToOneDeletedSubject : Subject<{idUser: number}>;
    private roomModifiedSubject : Subject<RoomStatusModified>;
    private roomAdminsSubject : Subject<RoomStatusAdmins>;

    private roomReadedMessagesSubject : Subject<RoomGroupStatusReadedMessages>;
    private otoReadedMessagesSubject : Subject<RoomOTOStatusReadedMessages>;

    private userModifiedSubject : Subject<UserStatus>;
    private newUserSubject : Subject<PartialUser>;
    private userDeletedSubject : Subject<number>;

    private newDiscoverSubject : Subject<{discover: Discover, participation: Option<RoomGroup>}>;
    private discoverModifiedSubject : Subject<DiscoverStatus>;
    private discoverDeletedSubject : Subject<number>;

    private roomMessageModifiedSubject : Subject<MessageRoomSocketModel>;
    private otoMessageModifiedSubject : Subject<MessageOTOSocketModel>;

    private confirmationFileSubject: Subject<ConfirmationFileSocketModel>;


    private appErrorSubject: Subject<AppError>;
    private errorSubject: Subject<{error: any}>;

    private connectedSubject: Subject<boolean>;
    private lastEventConnectedSubject : BehaviorSubject<boolean>;

    private disconnectedSubject: Subject<boolean>;

    private ackMsgSubject: Subject<AckMsgSocketModel>;

    private ownIdUser: number;

    constructor(
        private roomsFacade: RoomsFacade,
        private messagesFacade : MessagesFacade,
        private socketClient: SocketClient,
        private appFacade : AppFacade,
        private httpHeadersManager : HttpHeadersManager,
        private domainModelMappers : DomainmodelMappers,
        private usersFacade: UsersFacade,
        private errorsTransformers: ErrorsTransformers,
        private appStateNamespaceFacade: AppStateNamespaceFacade,
        private roomsMapper : RoomsMapper,
        private discoversFacade : DiscoversFacade,
        private socketsAuthService: SocketsAuthService
    ) {
        this.initSubjects();
        this.setSubscriptionsSubjects();
    }

    private initSubjects() {
        this.roomMessageSubject =
            new Subject<{msg: MessageRoomSocketModel, sync: SynchronizationState}>();
        this.otoMessageSubject =
            new Subject<{msg: MessageOTOSocketModel, sync: SynchronizationState}>();

        this.joinedRoomSubject = new Subject<RoomStatusJoined>();
        this.quitRoomSubject = new Subject<RoomStatusQuit>();
        this.roomModifiedSubject = new Subject<RoomStatusModified>();
        this.roomAdminsSubject = new Subject<RoomStatusAdmins>();
        this.oneToOneDeletedSubject = new Subject();

        this.roomReadedMessagesSubject = new Subject<RoomGroupStatusReadedMessages>();
        this.otoReadedMessagesSubject = new Subject<RoomOTOStatusReadedMessages>();

        this.userModifiedSubject = new Subject<UserStatus>();
        this.newUserSubject = new Subject<PartialUser>();
        this.userDeletedSubject = new Subject<number>();

        this.newDiscoverSubject = new Subject<{discover: Discover, participation: Option<RoomGroup>}>();
        this.discoverModifiedSubject = new Subject<DiscoverStatus>();
        this.discoverDeletedSubject = new Subject<number>();

        this.roomMessageModifiedSubject = new Subject<MessageRoomSocketModel>();
        this.otoMessageModifiedSubject = new Subject<MessageOTOSocketModel>();

        this.appErrorSubject = new Subject<AppError>();
        this.errorSubject = new Subject();

        this.connectedSubject = new Subject();
        this.lastEventConnectedSubject = new BehaviorSubject(false);

        this.disconnectedSubject = new Subject();

        this.ackMsgSubject = new Subject();

        this.confirmationFileSubject = new Subject();
    }

    public startSocket(idClient: number, idUser: number) : Observable<Either<StandardError, {}>> {
        this.ownIdUser = idUser;
        return this.httpHeadersManager.getCurrentHeaders().pipe(
            tap(_ => {
                this.initSubjects();
                this.setSubscriptionsSubjects();
            }),
            flatMap(headers => race(

                this.errorSubject.asObservable(),
                this.connectedSubject.asObservable(),
                new Observable<boolean>(subscriber => {
                    this.socketClient.initSocket(
                        idClient,
                        [
                            {event: InputEvent.ERROR, f: this.getOnErrorFunction()},
                            {event: InputEvent.CONNECTED, f: this.getOnConnectedFunction()},
                            {event: InputEvent.ACK_MSG, f: this.getOnAckMsgFunction()},
                            {event: InputEvent.APP_ERROR, f: this.getOnAppErrorFunction()},
                            // {event: InputEvent.NEW_DISCOVER, f: this.getOnNewDiscoverFunction()},
                            // {event: InputEvent.DISCOVER_MODIFIED, f: this.getOnDiscoverModifiedFunction()},
                            // {event: InputEvent.DISCOVER_DELETED, f: this.getOnDiscoverDeletedFunction()}
                        ],
                        headers
                    );
                    // subscriber.next(true);
                    // subscriber.complete();
                })
            )),
            flatMap(p => new Observable<Either<StandardError, {}>>(subscriber => {
                if (typeof p === "boolean") {
                    subscriber.next(E.right({}));
                } else {
                    subscriber.next(E.left(this.errorsTransformers.getStandardErrorFromConnectErrorSocketException(new ConnectErrorSocketException(p.error))));
                }
                subscriber.complete();
            }))
        );
    }

    public invalidateSocketInstance() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.invalidateSocketInstance();
            s.complete();
        })
    }

    public addListenerMessageRoom() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.MESSAGE_ROOM, this.getOnMessageRoomFunction());
            s.complete();
        })
    }
    public addListenerMessageUser() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.MESSAGE_USER, this.getOnMessageOtoFunction());
            s.complete();
        })
    }
    public addListenerJoinedRoom() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.JOINED_ROOM, this.getOnJoinedStatusRoomFunction());
            s.complete();
        })
    }
    // public addListenerDisconnected()
    public addListenerQuitRoom() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.QUIT_ROOM, this.getOnQuitStatusRoomFunction());
            s.complete();
        })
    }
    public addListenerRoomModified() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.ROOM_MODIFIED, this.getOnModifiedStatusRoomFunction());
            s.complete();
        })
    }
    public addListenerUserModified() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.USER_MODIFIED, this.getOnUserModifiedStatusFunction());
            s.complete();
        });
    }
    public addListenerNewUser() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.NEW_USER, this.getOnNewUserFunction());
            s.complete();
        })
    }
    public addListenerUserDeleted() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.USER_DELETED, this.getOnUserDeletedFunction());
            s.complete();
        })
    }
    public addListenerRoomReadedMessages() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.ROOM_READED_MESSAGES, this.getOnRoomReadedMessagesFunction());
            s.complete();
        })
    }
    public addListenerOtoReadedMessages() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.OTO_READED_MESSAGES, this.getOnOtoReadedMessagesFunction());
            s.complete();
        })
    }
    public addListenerDisconnected() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.DISCONNECTED, this.getOnDisconnectedFunction());
            s.complete();
        })
    }
    public addListenerNewDiscover() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.NEW_DISCOVER, this.getOnNewDiscoverFunction());
            s.complete();
        });
    }
    public addListenerDiscoverModified() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.DISCOVER_MODIFIED, this.getOnDiscoverModifiedFunction());
            s.complete();
        })
    }
    public addListenerDiscoverDeleted() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.DISCOVER_DELETED, this.getOnDiscoverDeletedFunction());
            s.complete();
        })
    }
    public addListenerRoomAdmins() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.ROOM_ADMINS, this.getOnRoomAdminsFunction());
            s.complete();
        })
    }
    public addListenerOneToOneDeleted() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.ONE_TO_ONE_DELETED, this.getOnOneToOneDeletedFunction());
            s.complete();
        })
    }
    public addListenerRoomMessageModified() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.ROOM_MESSAGE_MODIFIED, this.getRoomMessageModifiedFunction());
            s.complete();
        })
    }
    public addListenerOtoMessageModified() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.OTO_MESSAGE_MODIFIED, this.getOtoMessageModifiedFunction());
            s.complete();
        });
    }
    public addListenerConfirmationFile() : Observable<never> {
        return new Observable<never>(s => {
            this.socketClient.assignEventSocket(InputEvent.CONFIRMATION_FILE, this.getConfirmationFileFunction());
            s.complete();
        })
    }

    // public initSockets(idClient: number) : Observable<void> {
    //
    //     return concat(
    //         this.httpHeadersManager.getCurrentHeaders().pipe(
    //             flatMap(headers => race(
    //                 new Observable<boolean>(subscriber => {
    //                     this.socketClient.initSocket(
    //                         idClient,
    //                         [
    //                                 {event: InputEvent.MESSAGE_ROOM, f: this.getOnMessageRoomFunction()},
    //                                 {event: InputEvent.APP_ERROR, f: this.getOnAppErrorFunction()},
    //                                 {event: InputEvent.ERROR, f: this.getOnErrorFunction()},
    //                                 {event: InputEvent.CONNECTED, f: this.getOnConnectedFunction()},
    //                                 {event: InputEvent.MESSAGE_USER, f: this.getOnMessageOtoFunction()},
    //                                 {event: InputEvent.JOINED_ROOM, f: this.getOnJoinedStatusRoomFunction()},
    //                                 {event: InputEvent.DISCONNECTED, f: this.getOnDisconnectedFunction()},
    //                                 {event: InputEvent.QUIT_ROOM, f: this.getOnQuitStatusRoomFunction()},
    //                                 {event: InputEvent.ROOM_MODIFIED, f: this.getOnModifiedStatusRoomFunction()},
    //                                 {event: InputEvent.USER_MODIFIED, f: this.getOnUserModifiedStatusFunction()},
    //                                 {event: InputEvent.NEW_USER, f: this.getOnNewUserFunction()},
    //                                 {event: InputEvent.USER_DELETED, f: this.getOnUserDeletedFunction()},
    //                                 {event: InputEvent.ROOM_READED_MESSAGES, f: this.getOnRoomReadedMessagesFunction()},
    //                                 {event: InputEvent.OTO_READED_MESSAGES, f: this.getOnOtoReadedMessagesFunction()}
    //                             ],
    //                             headers
    //                         );
    //                         // subscriber.next(true);
    //                         subscriber.complete();
    //                 }),
    //                 this.errorSubject.asObservable(),
    //                 this.connectedSubject.asObservable()
    //             )),
    //             flatMap(p => new Observable<void>(subscriber => {
    //                 if (typeof p === "boolean") {
    //                     subscriber.complete();
    //                 } else
    //                     subscriber.error(new ConnectErrorSocketException(p.error));
    //             }))
    //         ),
    //         this.appFacade.getOwnDataUser().pipe(
    //             tap(ownDataUserSelect => this.ownIdUser = ownDataUserSelect.idUser),
    //             map(_ => void 0)
    //         )
    //     );
    //
    //     // return new Observable<void>(subscriber => {
    //     //
    //     // });
    //
    // }


    public sendMessageRoom(messageRoom: MessageRoom) : Observable<Either<StandardError, {}>> {
        const obs1 = new Observable<never>(s => {
            const msg : MessageRoomSocketModelOut = this.domainModelMappers.getMessageRoomSocketModelOutFromMessageRoom(messageRoom);
            this.socketClient.sendObjToSocket(msg, OutputEvent.MESSAGE_ROOM);
            s.complete();
        });

        const obs2 = this.ackMsgSubject.asObservable().pipe(
            filter(ackMsg => ackMsg.internalId === messageRoom.internalId),
            take(1),
            switchMap(ackMsg => {
                if (ackMsg.response === "OK") {
                    return of(E.right({}));
                } else {
                    return this.errorsTransformers.getStandardErrorFromAppError(ackMsg.appError).pipe(
                        map(e => E.left(e))
                    );
                }
            }),
            timeout(15000),
            catchError(err => of(E.left(this.errorsTransformers.getStandardErrorFromAny(err))))
        );

        return merge(obs2, obs1);
    }

    public sendMessageOTO(messageOTO: MessageOTO) : Observable<Either<StandardError, {}>> {

        const obs1 = new Observable<never>(s => {
            const msg: MessageOTOSocketModelOut = this.domainModelMappers.getMessageOTOSocketModelOutFromMessageOTO(messageOTO);
            this.socketClient.sendObjToSocket(msg, OutputEvent.MESSAGE_USER);
            s.complete();
        });

        const obs2 = this.ackMsgSubject.asObservable().pipe(
            filter(ackMsg => ackMsg.internalId === messageOTO.internalId),
            take(1),
            switchMap(ackMsg => {
                if (ackMsg.response === "OK") {
                    // return E.right({});
                    return of(E.right({}));
                } else {
                    return this.errorsTransformers.getStandardErrorFromAppError(ackMsg.appError).pipe(
                        map(e => E.left(e))
                    );
                    // return E.left(
                    //     this.errorsTransformers.getStandardErrorFromAppError(ackMsg.appError)
                    // );
                }
            }),
            timeout(4000),
            catchError(err => of(E.left(this.errorsTransformers.getStandardErrorFromAny(err))))
        );

        return merge(obs2, obs1);

    }
    public sendConfirmationFile(deviceId: string, idBackendRequest: string) : Observable<never> {
        return new Observable<never>(s => {
            const msg = this.domainModelMappers.getConfirmationFileSocketModelOutFromDeviceIdAndBackendCode(deviceId, idBackendRequest);
            this.socketClient.sendObjToSocket(msg, OutputEvent.CONFIRMATION_FILE)
            s.complete();
        })
    }

    private setSubscriptionsSubjects() : void {
        // this.roomMessageSubject.asObservable().subscribe(
        //     messageRoom => this.messagesFacade.onNewMessageRoom(messageRoom)
        // );
        this.roomMessageSubject.asObservable().pipe(
            tap(msg => console.log({text: "chat message room", o: msg})),
            concatMap(({msg, sync}) => this.domainModelMappers.getMessageRoomFromMessageRoomSocketModel(msg, sync)),
            concatMap(msg => this.messagesFacade.onNewMessageRoom(msg))
        ).subscribe();
        this.otoMessageSubject.asObservable().pipe(
            tap(msg => console.log({text: "chat message user", o: msg})),
            concatMap(({msg, sync}) => this.domainModelMappers.getMessageOTOFromMessageOTOSocketModel(msg, sync, this.ownIdUser)),
            concatMap(msg => this.messagesFacade.onNewMessageOTO(msg))
        ).subscribe();

        this.joinedRoomSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onJoinedNewRoomByRoomStatus", event: event})),
            concatMap(event => this.roomsFacade.onJoinedNewRoomByRoomStatus(event)),

        ).subscribe();

        this.quitRoomSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onQuitRoomByRoomStatus", event: event})),
            concatMap(event => this.roomsFacade.onQuitRoomByRoomStatus(event))
        ).subscribe();

        this.roomModifiedSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onModifiedRoomByRoomStatus", event: event})),
            concatMap(event => this.roomsFacade.statusModifiedRoomNotify(event))
        ).subscribe();

        this.userModifiedSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onUserModifiedByUserStatus", event: event})),
            concatMap(event => this.usersFacade.statusUserModifiedNotify(event))
        ).subscribe();

        this.newUserSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onNewUser", event: event})),
            concatMap(event => this.usersFacade.dispatchOnNewUser(event))
        ).subscribe();

        this.userDeletedSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onUserDeleted", event: event})),
            concatMap(event => this.usersFacade.dispatchOnUserDeleted(event))
        ).subscribe();

        this.roomReadedMessagesSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onRoomReadedMessages", event: event})),
            concatMap(idRoom => this.roomsFacade.onReadedRoomMessagesDispatch(idRoom))
        ).subscribe();

        this.otoReadedMessagesSubject.asObservable().pipe(
            tap(event => console.log({text: "socketfacade onOtoReadedMessages", event: event})),
            concatMap(idUser => this.roomsFacade.onReadedOtoMessagesDispatch(idUser))
        ).subscribe();

        this.appErrorSubject.asObservable().pipe()
            .subscribe(
                appErr => console.log({text: "socket app error", appError: appErr}, "color:red")
            );

        this.errorSubject.asObservable().pipe(
            tap(err => console.log({text: "socket error", error: err}))
        )
            .subscribe(
                error => console.log({text: "socket error", error: error}, "color:red")
            );
        this.disconnectedSubject.asObservable().pipe(
            tap(_ => console.log({text: "socketfacade disconnected"})),
            concatMap(_ => this.appStateNamespaceFacade.dispatchChangedConnectionOfflineServerAction())
        ).subscribe();
        this.discoverDeletedSubject.asObservable().pipe(
            tap(v => console.log({text: "discover deleted", event: v})),
            concatMap(v => this.discoversFacade.dispatchOnDiscoverDeletedAction(v))
        ).subscribe();
        this.discoverModifiedSubject.asObservable().pipe(
            tap(v => console.log({text: "discover modified", event: v})),
            concatMap(v => this.discoversFacade.dispatchOnDiscoverModifiedAction(v))
        ).subscribe();
        this.newDiscoverSubject.asObservable().pipe(
            tap(v => console.log({text: "new discover", event: v})),
            concatMap(({discover, participation}) => this.discoversFacade.dispatchOnNewDiscoverAction(discover, participation))
        ).subscribe();
        this.roomAdminsSubject.asObservable().pipe(
            tap(a => console.log({text: "room admins", event: a})),
            concatMap(v => this.roomsFacade.dispatchOnModifyAdminsRoomAction(v))
        ).subscribe();
        this.oneToOneDeletedSubject.asObservable().pipe(
            tap(v => console.log({text: "one to one deleted", event: v})),
            concatMap(v => this.roomsFacade.dispatchOnOneToOneDeletedAction(v.idUser))
        ).subscribe();

        this.roomMessageModifiedSubject.asObservable().pipe(
            tap(v => console.log({text: "room message modified", event: v})),
            concatMap(v => this.domainModelMappers.getMessageRoomFromMessageRoomSocketModel(v, SynchronizationState.SYNCHRONIZED)),
            concatMap(v => this.messagesFacade.onNewModificationMessageRoom(v))
        ).subscribe();

        this.otoMessageModifiedSubject.asObservable().pipe(
            tap(v => console.log({text: "oto message modified", event: v})),
            concatMap(v => this.domainModelMappers.getMessageOTOFromMessageOTOSocketModel(v, SynchronizationState.SYNCHRONIZED, this.ownIdUser)),
            concatMap(v => this.messagesFacade.onNewModificationMessageOTO(v))
        ).subscribe();
        // this.connectedSubject

        this.confirmationFileSubject.asObservable().pipe(
            tap(msg => console.log({text: "confirmation file", event: msg})),
            concatMap(msg => new Observable<never>(s => {
                pipe(
                    this.socketsAuthService.getDeviceCode(msg.idRequest),
                    O.fold(
                        () => {
                            console.log({text: "confirmation file not found"})
                        },
                        deviceCode => {
                            console.log({text: "confirmation file found", elem: deviceCode})
                            this.sendConfirmationFile(deviceCode, msg.idBackendRequest).subscribe();
                        }
                    )
                )
                s.complete()
            }))
        ).subscribe();
    }


    private getOnMessageRoomFunction() : (message : any) => void {
        return message => {
            const msgSockModel : MessageRoomSocketModel = getMessageRoomSocketModelFromObject(message);
            // const msgDomModel : MessageRoom = getMessageRoomFromMessageRoomSocketModel(msgSockModel, SynchronizationState.SYNCHRONIZED);

            console.log({text: "message room received on socket", message: message});

            // this.roomMessageSubject.next(msgDomModel);
            this.roomMessageSubject.next({msg: msgSockModel, sync: SynchronizationState.SYNCHRONIZED});
        }
    }

    private getOnMessageOtoFunction() : (message : any) => void {
        return message => {
            const msgSockModel : MessageOTOSocketModel = getMessageOTOSocketModelFromObject(message);
            // const msgDomModel : MessageOTO = getMessageOTOFromMessageOTOSocketModel(msgSockModel, SynchronizationState.SYNCHRONIZED, this.ownIdUser);

            console.log({text: "message oto received on socket", message: message});

            // this.otoMessageSubject.next(msgDomModel);
            this.otoMessageSubject.next({msg: msgSockModel, sync: SynchronizationState.SYNCHRONIZED});
        };
    }

    private getOnJoinedStatusRoomFunction() : (status : any) => void {
        return status => {
            const stsSockModel : StatusSocketModel = getStatusSocketModelFromObject(status);
            const stsDomModel = this.domainModelMappers.getRoomStatusJoinedFromStatusSocketModel(stsSockModel);
            console.log({text: "joined room status event received on socket", statusOrig: status, statusParsed: stsDomModel});
            this.joinedRoomSubject.next(stsDomModel);
        }
    }

    private getOnQuitStatusRoomFunction() : (status: any) => void {
        return status => {
            const stsSockModel : StatusSocketModel = getStatusSocketModelFromObject(status);
            const stsDomModel = this.domainModelMappers.getRoomStatusQuitFromStatusSocketModel(stsSockModel);
            console.log({text: "quit room status event received on socket", statusOrig: status, statusParsed: stsDomModel});
            this.quitRoomSubject.next(stsDomModel);
        };
    }

    private getOnModifiedStatusRoomFunction() : (status: any) => void {
        return status => {
            const stsSockModel = getRoomModifiedSocketModel(status);
            const stsDomModel = this.domainModelMappers.getRoomStatusModifiedFromRoomModifiedSocketModel(stsSockModel);
            console.log({text: "modified room status event received on socket", statusOrig: status, statusParsed: stsSockModel});
            this.roomModifiedSubject.next(stsDomModel);
        }
    }

    private getOnUserModifiedStatusFunction() : (status: any) => void {
        return status => {
            console.log({text: "modified user status event received on socket", statusOrig: status});
            const stsSockModel = getUserModifiedSocketModel(status);
            const stsDomModel = this.domainModelMappers.getUserStatusFromUserModifiedSocketModel(stsSockModel);
            console.log({text: "modified user status event received on socket", statusOrig: status, statusParsed: stsSockModel});
            this.userModifiedSubject.next(stsDomModel);
        }
    }

    private getOnNewUserFunction() : (status: any) => void {
        return status => {
            console.log({text: "new user status event received on socket", statusOrig: status});
            const stsSockModel = getNewuserSocketModel(status);
            this.domainModelMappers.getPartialUserFromNewUserSocketModel(stsSockModel).subscribe(
                user => {
                    this.newUserSubject.next(user)
                }
            );
            console.log({text: "new user status event received on socket", statusOrig: status, statusParsed: stsSockModel});

        }
    }

    private getOnUserDeletedFunction() : (status: any) => void {
        return status => {
            console.log({text: "deleted user status event received on socket", statusOrig: status});
            const idUser = status.idUser*1;
            console.log({text: "new user status event received on socket", statusOrig: status, statusParsed: idUser});
            this.userDeletedSubject.next(idUser);
        }
    }

    private getOnRoomReadedMessagesFunction() : (status: any) => void {
        return status => {
            console.log({text: "room readed messages event received on socket", statusOrig: status});
            // const idRoom: number = status.idRoom;
            const socketModel = getRoomReadedMessagesSocketModel(status);
            const model = this.domainModelMappers.getRoomGroupStatusReadedMessagesFromRoomReadedMessagesSocketModel(socketModel);
            this.roomReadedMessagesSubject.next(model);
        }
    }

    private getOnOtoReadedMessagesFunction() : (status: any) => void {
        return status => {
            console.log({text: "oto readed messages event received on socket", statusOrig: status});
            // const idUser: number = status.idUser;
            const socketModel = getOTOReadedMessagesSocketModel(status);
            const model = this.domainModelMappers.getRoomOTOStatusReadedMessagesFromOTOReadedMessagesSocketModel(socketModel);
            this.otoReadedMessagesSubject.next(model);
        }
    }

    private getOnAppErrorFunction() : (appError: any) => void {
        return appError => {
            console.log({text: "AppError from sockets", error: appError});
            const appErrModel : AppError = new AppError(appError);
            this.appErrorSubject.next(appErrModel);
        }
    }

    private getOnErrorFunction() : (error : any) => void {
        return error => {
            console.log({text: "Error from sockets", error: error});
            this.errorSubject.next({error: error});
        }
    }

    private getOnConnectedFunction() : (error : any) => void {
        return () => {
            console.log("connected event");
            this.lastEventConnectedSubject.next(true);
            this.connectedSubject.next(true);
        }
    }

    private getOnDisconnectedFunction() : (error : any) => void {
        return () => {
            console.log("disconnected event");
            this.lastEventConnectedSubject.next(false);
            this.disconnectedSubject.next(true);
        }
    }

    private getOnAckMsgFunction() : (msg : any) => void {
        return msg => {
            console.log({text: "Ack msg from sockets", ack: msg});
            const ent = getAckMsgSocketModel(msg);
            console.log({text: "Ack msg from sockets parsed", ent: ent});
            this.ackMsgSubject.next(ent);
        }
    }

    private getOnNewDiscoverFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "New discover from sockets", d: msg});
            const socketModel = getNewDiscoverSocketModel(msg);
            const modelDiscover = getDiscoverFromDiscoverApiModelAndImage(socketModel.discover, O.none);
            const modelRoom = pipe(
                socketModel.room,
                O.fold(
                    () => of(O.none),
                    r => this.roomsMapper.getRoomGroupByIRoomAlllistRoomApiModel(r).pipe(
                        map(O.some)
                    )
                )
            );
            modelRoom.subscribe(
                room => {
                    this.newDiscoverSubject.next({discover: modelDiscover, participation: room})
                }
            );
        }
    }

    private getOnDiscoverModifiedFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "Discover modified from sockets", d: msg});
            const socketModel = getDiscoverModifiedSocketModel(msg);
            this.domainModelMappers.getDiscoverStatusFromDiscoverModifiedSocketModel(socketModel).subscribe(
                m => this.discoverModifiedSubject.next(m)
            )
        };
    }

    private getOnDiscoverDeletedFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "Discover deleted from sockets", d: msg});
            this.discoverDeletedSubject.next(msg.idDiscover);
        }
    }

    private getOnRoomAdminsFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "room admins from sockets", d: msg});
            const socketModel = getRoomAdminsSocketModel(msg);
            const model = this.domainModelMappers.getRoomStatusAdminsFromRoomAdminsSocketModel(socketModel);
            this.roomAdminsSubject.next(model);
        }
    }

    private getOnOneToOneDeletedFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "one to one deleted from sockets", d: msg});

            this.oneToOneDeletedSubject.next({idUser: msg.idUserTarget});
        }
    }

    private getRoomMessageModifiedFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "room message modified from sockets", d: msg});
            const socketModel = getMessageRoomSocketModelFromObject(msg);
            // const model = this.domainModelMappers.getMessageRoomFromMessageRoomSocketModel()
            this.roomMessageModifiedSubject.next(socketModel);
        }
    }

    private getOtoMessageModifiedFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "oto message modified from sockets", d: msg});
            const socketModel = getMessageOTOSocketModelFromObject(msg);
            this.otoMessageModifiedSubject.next(socketModel);
        }
    }

    private getConfirmationFileFunction() : (msg: any) => void {
        return msg => {
            console.log({text: "confirmation file from sockets", d: msg});
            const socketModel = getConfirmationFileSocketModel(msg);
            this.confirmationFileSubject.next(socketModel);
        }
    }

    // private addEventsToSubjects() : Observable<void> {
    //     return new Observable<void>(subscriber => {
    //         this.socketClient.onInputEventAssignCaller("chat message room", this.getMessageRoomFunction());
    //         this.socketClient.onInputEventAssignCaller(InputEvent.APP_ERROR, this.getAppErrorFunction());
    //         subscriber.complete();
    //     });
    // }
    //
    // private getMessageRoomFunction() : (message: any) => void {
    //     return message => {
    //         console.log({text: "message arrived", message: message});
    //         this.roomMessageSubject.next(
    //             getMessageRoomFromMessageRoomSocketModel(
    //                 getMessageRoomSocketModelFromObject(message)
    //             )
    //         )
    //     };
    // }
    //
    // private getAppErrorFunction() : (appError: any) => void {
    //     return appError => {
    //         console.log({text: "App error arrived on socket", appError: appError});
    //     }
    // }
    //
    // private setDisconnectAction() : Observable<void> {
    //     return new Observable<void>(subscriber => {
    //         this.socketClient.onDisconnectEventAssignOnce(event => {
    //             console.log({text: "Disconnected event.", event: event});
    //             this.appFacade.socketDisconnectedAction().subscribe()
    //         });
    //         subscriber.complete();
    //     });
    // }

}
