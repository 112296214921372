import {Action, createAction, createReducer, on, props} from "@ngrx/store";
import {AppStateNamespaceState, initialAppStateNamespaceState} from "../../state/namespaces/app_state_namespace.state";
import {
} from "../../actions/generic/app_state.actions";
import {
    AddAppStateReconnectingTimeAction,
    ResetAppStateReconnectingTimeAction,
    SetAllDataLoadedAction,
    SetAppStateWithLocalConnectionAction, SetAppStateWithServerConnectionAction,
    SetAppWithConnectionStateAction,
    SetFirstAppWithConnectionStateAction
} from "../../actions/namespaces/app_state_namespace.actions";
import {UserValidationSetActiveUserValidationProcessAction} from "../../actions/principal_screen_app/user_validation.actions";

const app_state_namespaceReducer = createReducer(
    initialAppStateNamespaceState,
    on(SetAllDataLoadedAction, (state, {all_data_loaded}) => ({
        ...state,
        app_state_all_data_loaded: all_data_loaded
    })),
    on(SetFirstAppWithConnectionStateAction, (stateAct, {state, error}) => ({
        ...stateAct,
        firstAppWithConnectionState: {
            state: state,
            error: error
        }
    })),
    on(SetAppWithConnectionStateAction, (stateAct, {state, error}) => ({
        ...stateAct,
        appWithConnectionState: {
            state: state,
            error: error
        }
    })),
    on(SetAppStateWithLocalConnectionAction, (state, {app_state_with_local_connection}) => {
        return ({
            ...state,
            app_state_with_local_connection: app_state_with_local_connection
        })
    }),
    on(SetAppStateWithServerConnectionAction, (state, {app_state_with_server_connection}) => ({
        ...state,
        app_state_with_server_connection: app_state_with_server_connection
    })),
    on(AddAppStateReconnectingTimeAction, state => ({
        ...state,
        reconnecting_time: state.reconnecting_time * 2
    })),
    on(ResetAppStateReconnectingTimeAction, state => ({
        ...state,
        reconnecting_time: initialAppStateNamespaceState.reconnecting_time
    })),
    on(UserValidationSetActiveUserValidationProcessAction, (state, {active}) => ({
        ...state,
        active_process_user_validation: active
    }))
);

export function appStateNamespaceReducerFunction(state: AppStateNamespaceState, action: Action) {
    return app_state_namespaceReducer(state, action);
}
