import {Component, Input, OnInit} from '@angular/core';
import {UserListComponentModel} from "./UserListComponentModel";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

    @Input()
    showDate: boolean;

    @Input()
    userList: UserListComponentModel[];

    constructor() { }

    ngOnInit() {}

}
