import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { RoomGroup } from "../../../domain/RoomGroup";
import { IAppState } from "../state/app.state";
import { combineLatest, Observable, race, zip } from "rxjs";
import { RoomOTO } from "../../../domain/RoomOTO";
import {
    combineAll,
    defaultIfEmpty,
    distinctUntilChanged,
    endWith,
    filter,
    finalize,
    flatMap,
    map,
    startWith,
    take,
    tap,
} from "rxjs/operators";
import {
    getCurrentPageLoadedByIdRoom,
    getCurrentPageLoadedByIdUser,
    getLastSavedMessageInputByIdRoom,
    getLastSavedMessageInputByIdUserOTO,
    getNumberMessagesDistinctByRoomsSelect,
    getRoomFieldsByIdRoomSelect,
    getRoomGroupByIdRoomSelect,
    getRoomNameImageByIdRoomSelect,
    getRoomOTOByIdUserSelect,
    getRoomsGroupSelect,
    getRoomsOTOByIdUsersSelect,
    getRoomsOTOSelect,
    getUserRoomByIdUserIdRoom,
    getUsersRoomGroupByIdRoomSelect,
} from "../selectors/rooms.selectors";
import { IRoomGroupState, IRoomOTOState } from "../state/room.state";
import {
    addUsersToRoom,
    cleanMessagesNotReadedRoomGroupSuccess,
    cleanMessagesNotReadedRoomOTOSuccess,
    getUsersRoom,
    leaveRoomGroup,
    leaveRoomOTO,
    leaveRoomOTOSuccess,
    modifyRoomInfo,
    // loadRoomsGroups,
    // loadRoomsOtos,
    onCreateRoom,
    onCreateRoomFail,
    onCreateRoomSuccess,
    onJoinedStatusRoom,
    OnMessageInputUpdatedOTOAction,
    OnMessageInputUpdatedRoomAction,
    OnModifyAdminsRoomAction,
    onModifyStatusRoom,
    onOpenOTO,
    onQuitStatusRoom,
    selectRoom,
} from "../actions/room.actions";
import { PartialRoomGroup } from "../entities/PartialRoomGroup";
import { selectedRoomsSelect } from "../selectors/app.selectors";
import { RoomType } from "../../../domain/RoomType";
import { UserRoom } from "../../../domain/UserRoom";
import { PartialRoomOTO } from "../entities/PartialRoomOTO";
import {
    RoomStatusJoined,
    RoomStatusModified,
    RoomStatusQuit,
} from "../../../domain/RoomStatus";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppError } from "../../remote/globalmodels/AppError";
import {
    addUsersRoomResponseSelect,
    createRoomResponseSelect,
    leaveRoomGroupResponseSelect,
    leaveRoomOTOResponseSelect,
    modifyRoomInfoResponseSelect,
    openOTOResponseSelect,
} from "../selectors/responses.selectors";
import { ResponsesState } from "../state/responses.state";
import {
    cleanResponseAddUsersToRoom,
    cleanResponseCreateRoom,
    cleanResponseLeaveRoomGroup,
    cleanResponseLeaveRoomOTO,
    cleanResponseModifyRoomInfo,
    cleanResponseOpenOto,
} from "../actions/responses.actions";
import { Option } from "fp-ts/lib/Option";
import { fromTask } from "fp-ts-rxjs/lib/Observable";
import { Either } from "fp-ts/lib/Either";
import { These } from "fp-ts/lib/These";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { Room } from "../../../domain/Room";
import { StandardError } from "../../../domain/StandardError";
import { RoomGroupStatusReadedMessages } from "../../../domain/RoomGroupStatusReadedMessages";
import { RoomOTOStatusReadedMessages } from "../../../domain/RoomOTOStatusReadedMessages";
import { RoomStatusAdmins } from "../../../domain/RoomStatusAdmins";

@Injectable({
    providedIn: "root",
})
export class RoomsFacade {
    constructor(
        private store: Store<IAppState>,
        private storeRoomsGroup: Store<IRoomGroupState>,
        private storeRoomsOTO: Store<IRoomOTOState>,
        private storeResponses: Store<ResponsesState>
    ) {}

    createRoom(
        name: string,
        idUsers: number[],
        image: Option<Blob>
    ): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(
                onCreateRoom({ name: name, idUsers: idUsers, image: image })
            );
            subscriber.complete();
        });
    }

    getRoomCreateFailOrSuccess(): Observable<{ error: Option<StandardError> }> {
        return this.store.select(createRoomResponseSelect).pipe(
            filter((r) => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseCreateRoom()))
        );
    }

    openOTO(idUser: number): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(onOpenOTO({ idUser: idUser }));
            subscriber.complete();
        });
    }

    getOpenOTOFailOrSuccess(): Observable<{ error: Option<StandardError> }> {
        return this.store.select(openOTOResponseSelect).pipe(
            filter((r) => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseOpenOto()))
        );
    }

    getUserRoomsByIdRoom(idRoom: number): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(getUsersRoom({ idRoom: idRoom }));
            subscriber.complete();
        });
    }

    isSelectedRoomGroupByIdRoom(idRoom: number): Observable<boolean> {
        return this.store.select(selectedRoomsSelect).pipe(
            map((selects) => selects.idRoomGroupSelected),
            map(
                (idRoomGroupSelected) =>
                    idRoomGroupSelected + "" === idRoom + ""
            ),
            filter((isSelected) => isSelected)
        );
    }

    isSelectedRoomOTOByIdUser(idUser: number): Observable<boolean> {
        return this.store.select(selectedRoomsSelect).pipe(
            // distinctUntilChanged(),
            map((selects) => selects.idUserGroupSelected),
            map(
                (idUserGroupSelected) =>
                    idUserGroupSelected + "" === idUser + ""
            ),
            filter((isSelected) => isSelected)
        );
    }

    selectRoomGroupByIdRoom(idRoom: number): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(
                selectRoom({ idRoom: idRoom, idUser: undefined })
            );
            subscriber.complete();
        });
    }

    selectRoomOTOByIdUser(idUser: number): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(
                selectRoom({ idRoom: undefined, idUser: idUser })
            );
            subscriber.complete();
        });
    }

    getAllRoomsObservable(): Observable<
        [PartialRoomGroup[], PartialRoomOTO[]]
    > {
        return combineLatest([
            this.storeRoomsGroup.pipe(
                select(getRoomsGroupSelect),
                startWith([] as PartialRoomGroup[])
            ),
            this.storeRoomsOTO.pipe(
                select(getRoomsOTOSelect),
                startWith([] as PartialRoomOTO[])
            ),
        ]);
    }

    getAllRooms(): Observable<[PartialRoomGroup[], PartialRoomOTO[]]> {
        return zip(
            this.storeRoomsGroup.pipe(select(getRoomsGroupSelect), take(1)),
            this.storeRoomsOTO.pipe(select(getRoomsOTOSelect), take(1))
        );
    }

    getRoomsOTOObservable(): Observable<PartialRoomOTO[]> {
        return this.storeRoomsOTO.pipe(select(getRoomsOTOSelect));
    }
    getRoomsOTOObservableByIdUsers(
        idUsers: number[]
    ): Observable<PartialRoomOTO[]> {
        return this.storeRoomsOTO.pipe(
            select(getRoomsOTOByIdUsersSelect(idUsers))
        );
    }

    getSelectedRoomNotOptionObservable(): Observable<[number, RoomType]> {
        return this.store
            .select(selectedRoomsSelect)
            .pipe(
                map(
                    (c) =>
                        (c.idUserGroupSelected !== undefined
                            ? [c.idUserGroupSelected, RoomType.OTO]
                            : [c.idRoomGroupSelected, RoomType.GROUP]) as [
                            number,
                            RoomType
                        ]
                )
            );
    }

    getSelectedRoom(): Observable<[number, RoomType]> {
        return this.getSelectedRoomNotOptionObservable().pipe(take(1));
    }

    getSelectedRoomOption(): Observable<Option<[number, RoomType]>> {
        return this.getSelectedRoom().pipe(
            map(([id, t]) => (id === undefined ? O.none : O.some([id, t])))
        );
    }

    getSelectedRoomObservable(): Observable<
        Option<{ id: number; type: RoomType }>
    > {
        return this.store.select(selectedRoomsSelect).pipe(
            map((c) => {
                if (c.idRoomGroupSelected !== undefined)
                    return O.some({
                        id: c.idRoomGroupSelected,
                        type: RoomType.GROUP,
                    });
                if (c.idUserGroupSelected !== undefined)
                    return O.some({
                        id: c.idUserGroupSelected,
                        type: RoomType.OTO,
                    });
                return O.none;
            })
        );
    }

    getNextPageToLoadRoomByIdRoom(idRoom: number): Observable<number> {
        return this.store
            .select(getCurrentPageLoadedByIdRoom(idRoom))
            .pipe(take(1));
    }

    getNextPageToLoadRoomByIdUser(idUser: number): Observable<number> {
        return this.store
            .select(getCurrentPageLoadedByIdUser(idUser))
            .pipe(take(1));
    }

    // getRoomByIdRoom(idRoom: number) : Observable<PartialRoomGroup> {
    //     return this.store.select(getRoomGroupByIdRoomSelect(idRoom)).pipe(take(1));
    // }

    getRoomByIdRoomObservable(idRoom: number): Observable<PartialRoomGroup> {
        return this.store.select(getRoomGroupByIdRoomSelect(idRoom));
    }
    getRoomKeysByIdRoomObservable<
        K extends keyof PartialRoomGroup,
        KK extends keyof RoomGroup
    >(
        idRoom: number,
        f: K[],
        ff: KK[]
    ): Observable<{
        partialFields: Pick<PartialRoomGroup, K>;
        roomFields: Pick<RoomGroup, KK>;
    }> {
        return this.store
            .select(getRoomFieldsByIdRoomSelect(idRoom, f, ff))
            .pipe(distinctUntilChanged());
    }
    getRoomNameAndImageUrlObservable(
        idRoom: number
    ): Observable<{ name: string; url: string; membersLoaded: boolean }> {
        return this.store
            .select(getRoomNameImageByIdRoomSelect(idRoom))
            .pipe(
                distinctUntilChanged(
                    (x, y) =>
                        !(
                            x.name !== y.name ||
                            x.url !== y.url ||
                            x.membersLoaded !== y.membersLoaded
                        )
                )
            );
    }
    //
    // getUsersRoomByIdRoom(idRoom: number) : Observable<UserRoom[]> {
    //     return this.store.select(getUsersRoomGroupByIdRoomSelect(idRoom)).pipe(take(1))
    // }

    getUsersRoomByIdRoomObservable(idRoom: number): Observable<UserRoom[]> {
        return this.store.select(getUsersRoomGroupByIdRoomSelect(idRoom));
    }

    getUsersRoomByIdRoom(idRoom: number): Observable<UserRoom[]> {
        return this.store
            .select(getUsersRoomGroupByIdRoomSelect(idRoom))
            .pipe(take(1));
    }

    getUserRoomByIdRoomIdUserObservable(
        idRoom: number,
        idUser: number
    ): Observable<Option<UserRoom>> {
        return this.store.select(getUserRoomByIdUserIdRoom(idRoom, idUser));
    }

    getUserRoomByIdRoomIdUser(
        idRoom: number,
        idUser: number
    ): Observable<Option<UserRoom>> {
        return this.getUserRoomByIdRoomIdUserObservable(idRoom, idUser).pipe(
            take(1)
        );
    }

    getOTOByIdUserObservable(idUser: number): Observable<PartialRoomOTO> {
        return this.store.select(getRoomOTOByIdUserSelect(idUser));
    }

    existsRoomGroupByIdRoom(idRoom: number): Observable<boolean> {
        return this.store.select(getRoomsGroupSelect).pipe(
            take(1),
            map((rooms) => rooms.some((r) => r.roomGroup.idRoom === idRoom))
        );
    }

    getRoomByIdRoomOption(
        idRoom: number
    ): Observable<Option<PartialRoomGroup>> {
        return this.store
            .select(getRoomsGroupSelect)
            .pipe(
                take(1),
                map(A.findFirst((d) => d.roomGroup.idRoom === idRoom))
            );
    }

    existsOTOByIdUser(idUser: number): Observable<boolean> {
        return this.store.select(getRoomsOTOSelect).pipe(
            take(1),
            map((rooms) => rooms.some((r) => r.roomOTO.idUser === idUser))
        );
    }

    getOTOByIdUser(idUser: number): Observable<Option<PartialRoomOTO>> {
        return this.store
            .select(getRoomsOTOSelect)
            .pipe(
                take(1),
                map(A.findFirst((d) => d.roomOTO.idUser === idUser))
            );
    }

    onJoinedNewRoomByRoomStatus(
        roomStatus: RoomStatusJoined
    ): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(onJoinedStatusRoom({ roomStatus: roomStatus }));
            subscriber.complete();
        });
    }

    onQuitRoomByRoomStatus(roomStatus: RoomStatusQuit): Observable<never> {
        return new Observable<never>((s) => {
            this.store.dispatch(onQuitStatusRoom({ roomStatus: roomStatus }));
            s.complete();
        });
    }

    getAddUsersToRoomFailOrSuccess(): Observable<{
        error: Option<StandardError>;
    }> {
        return this.store.select(addUsersRoomResponseSelect).pipe(
            filter((r) => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseAddUsersToRoom()))
        );
    }

    addUsersToRoom(idRoom: number, idUsers: number[]): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(
                addUsersToRoom({ idRoom: idRoom, idUsers: idUsers })
            );
            subscriber.complete();
        });
    }

    leaveRoomGroup(idRoom: number): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(leaveRoomGroup({ idRoom: idRoom }));
            subscriber.complete();
        });
    }

    getLeaveRoomGroupFailOrSuccess(): Observable<{
        error: Option<StandardError>;
    }> {
        return this.store.select(leaveRoomGroupResponseSelect).pipe(
            filter((r) => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseLeaveRoomGroup()))
        );
    }

    leaveRoomOTO(idUser: number): Observable<void> {
        return new Observable<void>((subscriber) => {
            this.store.dispatch(leaveRoomOTO({ idUser: idUser }));
            subscriber.complete();
        });
    }

    getLeaveRoomOTOFailOrSuccess(): Observable<{
        error: Option<StandardError>;
    }> {
        return this.store.select(leaveRoomOTOResponseSelect).pipe(
            filter((r) => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseLeaveRoomOTO()))
        );
    }

    getNumberMessagesDistinctByRooms(): Observable<number> {
        return this.store.select(getNumberMessagesDistinctByRoomsSelect);
    }

    modifyRoomDataByIdRoom(
        idRoom: number,
        info: These<{ name: string; description: string }, Blob>
    ): Observable<{ error: Option<StandardError> }> {
        return fromTask(
            () =>
                new Promise((resolve) => {
                    this.store.dispatch(
                        modifyRoomInfo({
                            idRoom: idRoom,
                            info: info,
                        })
                    );
                    resolve();
                })
        ).pipe(
            flatMap((_) => this.store.select(modifyRoomInfoResponseSelect)),
            filter((r) => r !== undefined),
            take(1),
            finalize(() => this.store.dispatch(cleanResponseModifyRoomInfo()))
        );
    }

    statusModifiedRoomNotify(status: RoomStatusModified): Observable<never> {
        return new Observable<never>((s) => {
            this.store.dispatch(onModifyStatusRoom({ status: status }));
            s.complete();
        });
    }

    onReadedRoomMessagesDispatch(
        entity: RoomGroupStatusReadedMessages
    ): Observable<never> {
        return new Observable<never>((s) => {
            this.store.dispatch(
                cleanMessagesNotReadedRoomGroupSuccess({ entity: entity })
            );
            s.complete();
        });
    }

    onReadedOtoMessagesDispatch(
        entity: RoomOTOStatusReadedMessages
    ): Observable<never> {
        return new Observable<never>((s) => {
            this.store.dispatch(
                cleanMessagesNotReadedRoomOTOSuccess({ entity: entity })
            );
            s.complete();
        });
    }

    getAllIdRoomWithUserByIdUser(idUser: number): Observable<number[]> {
        return this.store.select(getRoomsGroupSelect).pipe(
            take(1),
            map(
                A.map((room) =>
                    this.store
                        .select(
                            getUsersRoomGroupByIdRoomSelect(
                                room.roomGroup.idRoom
                            )
                        )
                        .pipe(
                            take(1),
                            map(
                                A.filter(
                                    (userRoom) =>
                                        userRoom.idUser * 1 === idUser * 1
                                )
                            ),
                            map((users) => ({
                                idRoom: room.roomGroup.idRoom,
                                users: users,
                            }))
                        )
                )
            ),
            flatMap((obss) => zip(...obss)),
            map(A.filter(({ users }) => users.length > 0)),
            map(A.map(({ idRoom }) => idRoom)),
            defaultIfEmpty([])
        );
    }

    dispatchOnModifyAdminsRoomAction(ent: RoomStatusAdmins): Observable<never> {
        return new Observable<never>((s) => {
            this.store.dispatch(OnModifyAdminsRoomAction({ status: ent }));
            s.complete();
        });
    }
    dispatchOnOneToOneDeletedAction(idUser: number): Observable<never> {
        return new Observable<never>((s) => {
            this.store.dispatch(leaveRoomOTOSuccess({ idUser: idUser }));
            s.complete();
        });
    }

    dispatchOnMessageInputUpdatedRoomAction(
        message: string,
        idRoom: number
    ): void {
        this.store.dispatch(
            OnMessageInputUpdatedRoomAction({
                message: message,
                idRoom: idRoom,
            })
        );
    }

    dispatchOnMessageInputUpdatedOTOAction(
        message: string,
        idUserOTO: number
    ): void {
        this.store.dispatch(
            OnMessageInputUpdatedOTOAction({
                message: message,
                idUserOTO: idUserOTO,
            })
        );
    }

    getMessageInputRoom(idRoom: number): Observable<string> {
        return this.store
            .select(getLastSavedMessageInputByIdRoom(idRoom))
            .pipe(take(1));
    }

    getMessageInputOTO(idUserOTO: number): Observable<string> {
        return this.store
            .select(getLastSavedMessageInputByIdUserOTO(idUserOTO))
            .pipe(take(1));
    }

    // dispatchCleanMessagesNotReadedRoomGroupSuccess(entity: RoomGroupStatusReadedMessages) : Observable<never> {
    //     return new Observable<never>(s => {
    //         this.store.dispatch(cleanMessagesNotReadedRoomGroupSuccess({entity: entity}));
    //         s.complete();
    //     })
    // }
    // dispatchCleanMessagesNotReadedRoomOTOSuccess(entity: RoomOTOStatusReadedMessages) : Observable<never> {
    //     return new Observable<never>(s => {
    //         this.store.dispatch(cleanMessagesNotReadedRoomOTOSuccess({entity: entity}));
    //         s.complete();
    //     })
    // }

    // cleanResponseRoomCreateFailOrSuccess() : Observable<void> {
    //     return new Observable<void>(subscriber => {
    //         this.store.dispatch(cleanResponseCreateRoom);
    //         subscriber.complete();
    //     })
    // }
}
