
import {
    Component,
    OnInit,
    OnChanges,
    SimpleChange,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterContentInit, AfterViewInit, AfterViewChecked, ContentChild
} from '@angular/core';
import * as Croppie from 'croppie';
import {from, Observable, of, Subject} from 'rxjs';
import {LocaleService} from "../locales/locale.service";



@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss'],

})
export class UploadImageComponent implements OnInit, AfterViewInit {

    @Input()
    // image: string = "assets/img/group-unknown.png";
    image: string = undefined;
    // @Input()
    // actionPerformed: Observable<boolean>;
    @Input()
    activatedCambiarImagen : boolean;

    @Input()
    isGroupImage : boolean = true;

    @ViewChild("upload", {static: false})
    uploader: ElementRef;
    @ViewChild("final", {static: false})
    final: ElementRef;

    // @ViewChild("imgSrc", {read: ElementRef, static: false})
    // imgSrc: ElementRef;



    // public imagePath;
    public imgURL : Subject<string>;
    // imageURL: string;

    public message: string;

    @Output()
    imageSelected: EventEmitter<string> = new EventEmitter();

    @Output()
    imageCropped: EventEmitter<string> = new EventEmitter();

    imgPlaceholder: boolean;

    public c: Croppie;

    constructor(
        private localeService: LocaleService
    ) {

    }

    onEmitSrcImageEmit(elem: ElementRef) {
        this.c = new Croppie(elem.nativeElement, {
            viewport: { width: 200, height: 200, type: "square" },
            boundary: { width: 200, height: 200 },
            showZoomer: false,
            enableOrientation: true,
            mouseWheelZoom: true,
            enableExif: true
        });
    }

    ngOnInit() {
        this.imgURL = new Subject();
        // console.log({text: "image on upload-image.component ", i: this.image});
        // console.log({text: "image upload image component", i})




        // if (this.imgPlaceholder) {
            // this.imgURL = of(this.image);
            // this.imgURL.next(this.image);
        // } else {
            // this.imgURL = this.isGroupImage ? of("assets/img/group-unknown.png") : of("assets/img/portrait/sinpersona.jpg");
            // if(this.isGroupImage) {
            //     this.imgURL.next("assets/img/group-unknown.png");
            // } else {
            //     this.imgURL.next("assets/img/portrait/sinpersona.jpg");
            // }
        // }
        // this.c = new Croppie(this.imgSrc.nativeElement, {
        //     viewport: { width: 200, height: 200, type: "square" },
        //     boundary: { width: 200, height: 200 },
        //     showZoomer: false,
        //     enableOrientation: true,
        //     mouseWheelZoom: true,
        //     enableExif: true
        // });
    }

    ngAfterViewInit(): void {
        this.imgPlaceholder = this.image !== undefined;

        if (!this.imgPlaceholder) {
            if(this.isGroupImage) {
                this.image = "assets/img/group-unknown.png";
                this.imgURL.next("assets/img/group-unknown.png");
            } else {
                this.image = "assets/img/portrait/sinpersona.jpg";
            }
        }
        this.imgURL.next(this.image);
        from(this.c.bind({url: this.image})).subscribe();

    }


    convertImage() {
        this.c.result({type:'base64',size: 'viewport',format:'png',quality:1,circle:false })
            .then(crop => {
                console.log(crop);
                this.imageCropped.emit(crop);
            })
    }

    selectImg() {
        this.uploader.nativeElement.click();
        //this.hola = "adios click";
        /*

        Te dejo comentado el código para recibir el crop. Como formato deja png ya que las imágenes circulares requieren de
        transparencia y este formato lo permite.

        https://foliotek.github.io/Croppie/

        */


        // console.log(crop.__zone_symbol__value);
    }

    preview(files) {

        if (files.length === 0)
            return;

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = "Solo se admiten imágenes.";
            return;
        }

        const reader = new FileReader();

        reader.onload = _event => {
            // this.imgURL = reader.result as string;
            this.imgURL.next(reader.result as string);
            this.c.bind({
                url: reader.result as string
            }).then(_ => {
                this.imageSelected.emit(reader.result as string);
            });
            console.log(this.c);
        };
        reader.readAsDataURL(files[0]);



    }




}
