import {Injectable} from '@angular/core';
import {HttpHeadersManager} from '../core/sources/remote/core/headers/HttpHeadersManager';
import {Observable, of} from 'rxjs';
import {endWith, flatMap, map} from 'rxjs/operators';
import {LoggedState} from '../core/sources/remote/core/headers/LoggedState';

@Injectable()
export class AuthService {
  token: string;

  constructor(
      private httpHeadersManager: HttpHeadersManager
  ) {}

  // signupUser(email: string, password: string) {//registro
  //   //your code for signing up the new user
  // }
  //
  // signinUser(email: string, password: string) { //login
  //   //your code for checking credentials and getting tokens for for signing in user
  // }

  logout() : Observable<{}> {
    // this.token = null;
      return this.httpHeadersManager.setUnloggedState().pipe(
          // endWith(of({})),
          // flatMap(o => o as Observable<{}>),
      );
  }

  // getToken() {
  //   return this.token;
  // }

  isAuthenticated() : Observable<boolean> {
    // here you can check if user is authenticated or not through his token 
    // return true;
      return this.httpHeadersManager.getWebState().pipe(
          map(state => state === LoggedState.LOGGED)
      )
  }
}
