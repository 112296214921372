// export interface DatesUpdatesState {
//     readonly dates_updates_users: Date;
//     readonly dates_updates_list_rooms: Date;
//     readonly dates_updates_discovers: Date;
// }
//
// const d = new Date();
// export const initialDatesUpdatesState : DatesUpdatesState = {
//     dates_updates_users: d,
//     dates_updates_discovers: d,
//     dates_updates_list_rooms: d
// };


// --- Namespace app_state
// readonly app_state_with_connection: boolean;
// readonly app_state_all_data_loaded: boolean;

import {Option} from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import {StandardError} from "../../../../domain/StandardError";

export enum FirstAppWithConnectionState {
    RAGDOLL = 0,
    RESOURCES_LOADED = 1,
    PERSONAL_INFO_LOADED = 2,
    SOCKET_OPEN = 3, INIT_FIREBASE = 4,
    USERS_LOADED = 5, ROOMS_LOADED = 6,
    DISCOVERS_LOADED = 7, COMPLETED = 8
}

export enum AppWithConnectionState {
    RAGDOLL = 0, PERSONAL_INFO_RELOADED = 1,
    SOCKET_OPEN = 2,
    USERS_RELOADED = 3, ROOMS_RELOADED = 4,
    DISCOVERS_RELOADED = 5, SELECTED_ROOM_USER_LIST_LOADED = 6,
    COMPLETED = 7
}

export interface AppStateNamespaceState {
    // readonly app_state_with_connection: boolean;
    readonly app_state_all_data_loaded: boolean;
    readonly app_state_with_local_connection: boolean;
    readonly app_state_with_server_connection: boolean;
    readonly firstAppWithConnectionState: {
        state: FirstAppWithConnectionState,
        error: Option<StandardError>
    };
    readonly appWithConnectionState: {
        state: AppWithConnectionState,
        error: Option<StandardError>
    },
    readonly reconnecting_time: number;
    readonly active_process_user_validation: boolean;
    // readonly trying_reconnect: boolean;
}

export const initialAppStateNamespaceState : AppStateNamespaceState = {
    app_state_all_data_loaded: false,
    // app_state_with_connection: false,

    app_state_with_local_connection: true,
    app_state_with_server_connection: true,

    firstAppWithConnectionState: {
        state: FirstAppWithConnectionState.RAGDOLL,
        error: O.none
    },
    appWithConnectionState: {
        state: AppWithConnectionState.RAGDOLL,
        error: O.none
    },
    reconnecting_time: 5,
    active_process_user_validation: false
    // trying_reconnect: false
};
