import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {FileMessage} from '../../../domain/FileMessage';
import {Message} from '../../../domain/Message';


export function sortByDateMessage(a: FileMessage, b: FileMessage) : number {
    return -a.date.getTime() + b.date.getTime();
}

export const adapterFileMessage : EntityAdapter<FileMessage> = createEntityAdapter<FileMessage>({
    selectId: (m : FileMessage) => m.idMessage,
    sortComparer: sortByDateMessage
});
