
export class MessageOTOSocketModelOut {
    readonly toIdUser: number;
    readonly message: string;
    readonly internalId: string;
    readonly idMessageReply: string|undefined;


    constructor(toIdUser: number, message: string, internalId: string, idMessageReply: string|undefined) {
        this.toIdUser = toIdUser;
        this.message = message;
        this.internalId = internalId;
        this.idMessageReply = idMessageReply;
    }
}
