import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
    changeOwnData,
    changeOwnDataFail,
    changeOwnDataSuccess,
    // initFirebase,
    // initFirebaseSuccess,
    // loadApp,
    // loadAppSuccess,
    // loadOwnImageProfile,
    // loadOwnImageProfileSuccess,
    // loadOwnUserData,
    // loadOwnUserDataSuccess,
    logout,
    logoutFail,
    logoutSuccess,
    MetaActions,
    nilAction,
    // openSocket,
    // openSocketSuccess,
    refreshFirebaseToken,
    refreshFirebaseTokenFail,
    refreshFirebaseTokenSuccess
} from '../actions/app.actions';
import {catchError, combineAll, concatMap, endWith, exhaustMap, flatMap, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {
    // getImageProfileUser,
    // loadAllImageProfilesUsersWithOTO,
    // loadAllPartialUsers, loadAllPartialUsersSuccess,
    UserActions
} from '../actions/user.actions';
import {
    // loadAllImageRoomsGroups,
    // loadRoomsAll,
    // loadRoomsGroups, loadRoomsOtos,
    RoomActions} from '../actions/room.actions';
import {IAppState} from '../state/app.state';
import {Action, Store} from '@ngrx/store';
import {combineLatest, concat, from, Observable, of} from 'rxjs';
import {
    // loadFirstsMessagesAllOTOS, loadFirstsMessagesAllRooms,
    MessageActions} from '../actions/messages.actions';
import {Message} from '../../../domain/Message';
import {MetaRepository} from '../../../repositories/meta/meta.repository';
import {UsersRepository} from '../../../repositories/users/users.repository';
// import {ownDataUserSelect} from '../selectors/app.selectors';
import {SocketFacade} from '../../../realtime/socket.facade';
import {TypedAction} from '@ngrx/store/src/models';
import {ConnectErrorSocketException} from '../../../realtime/exceptions/ConnectErrorSocketException';
import {AppFacade} from '../facade/app.facade';
import {HttpErrorResponse} from '@angular/common/http';
import {AppError} from '../../remote/globalmodels/AppError';
import {DiscoverActions,
    // loadDiscovers
} from '../actions/discovers.actions';
import {FcmFacade} from '../../../../fcm/fcm.facade';
import {HttpHeadersManager} from "../../remote/core/headers/HttpHeadersManager";
import * as E from "fp-ts/lib/Either";
import {LinkGenerators} from "../../../domain/images/link.generators";
import * as O from "fp-ts/lib/Option";
import {MetaFacade} from "../facade/generic/meta.facade";

@Injectable({
    providedIn: 'root'
})
export class AppEffects {

    logoutSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logoutSuccess),
            concatMap(_ => concat(
                this.httpHeadersManager.setUnloggedState(),
                of({}).pipe(
                    tap(_ => window.location.href ='/login'),
                    map(_ => nilAction())
                )
            ))
        )
    );
    logoutFailEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logoutFail),
            // switchMap(_ => this.httpHeadersManager.setUnloggedState().pipe(
            //     tap(_ => window.location.href ='/login'),
            //     map(_ => nilAction())
            // ))
            concatMap(_ => concat(
                this.httpHeadersManager.setUnloggedState(),
                of({}).pipe(
                    tap(_ => window.location.href ='/login'),
                    map(_ => nilAction())
                )
            ))
        )
    );

    changeOwnDataUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeOwnData),
            concatMap(({bio, image, emailContact, emailNotifications}) => this.metaRepository.changeOwnData(bio, image, emailContact, emailNotifications).pipe(
                switchMap(E.fold(
                    err => of(changeOwnDataFail({error: err})) as Observable<Action>,
                    // res => this.metaFacade.getOwnDataUser().pipe(
                    //     // switchMap(ownData => this.linkGenerators.getUserImageLinkFromIdUser(ownData.idUser)),
                    //     map(link => changeOwnDataSuccess({bio: bio, image: image}))
                    // )
                    res => of(changeOwnDataSuccess({bio: bio, image: image, name: O.none, surname: O.none, emailContact, emailNotifications}))
                ))
            ))
        )
    );



    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logout),
            concatMap(_ => this.fcmFacade.proceedLogout()),
            tap(_ => console.log("LOGOUT FCM SUCCESS")),
            concatMap(_ => this.metaRepository.logout()),
            tap(_ => console.log("LOGOUT BACKEND SUCCESS")),
            map(E.fold(
                err => logoutFail({error: err}),
                _ => logoutSuccess()
            ))
        )
    );

    refreshFirebaseToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(refreshFirebaseToken),
            concatMap(({fcmToken, deviceId}) => this.metaRepository.refreshFirebaseToken(deviceId, fcmToken)),
            map(E.fold(
                err => refreshFirebaseTokenFail({error: err}),
                _ => refreshFirebaseTokenSuccess()
            ))
        )
    );
    constructor(
        private actions$ : Actions,
        private metaRepository: MetaRepository,
        private userRepository: UsersRepository,
        private store: Store<IAppState>,
        private socketFacade: SocketFacade,
        private appFacade : AppFacade,
        private fcmFacade : FcmFacade,
        private httpHeadersManager: HttpHeadersManager,
        private linkGenerators: LinkGenerators,
        private metaFacade: MetaFacade
    ) {

    }
}
