



export function getDateFromBackendDate(date: string) : Date {
    return new Date(date);
}

export function getDateFromUnixDate(d: number) : Date {
    return new Date(d * 1000);
}
