import {Room} from '../../../domain/Room';
import {RouterReducerState} from '@ngrx/router-store';
import {initialRoomGroupState, initialRoomOTOState, IRoomGroupState, IRoomOTOState} from './room.state';
import {initialUserState, IUserState} from './user.state';
import {
    initialPagedRoomGroupMessages, initialPagedRoomOTOMessages, IPagedOTOGroupMessagesState,
    IPagedRoomGroupMessagesState
} from './message.state';
import {OwnDataUser} from '../../../domain/OwnDataUser';
import {initialResponsesState, ResponsesState} from './responses.state';
import {IPagedFileMessages} from '../entities/PagedFileMessages';
import {initialPagedFileMessagesState} from './filemessages.state';
import {IDiscoversState, initialDiscoversState} from './discovers.state';
import {Option} from "fp-ts/lib/Option";
import {AppError} from "../../remote/globalmodels/AppError";
import * as O from "fp-ts/lib/Option";
// import {DatesUpdatesState, initialDatesUpdatesState} from "./dates_updates.state";
import {AppStateNamespaceState, initialAppStateNamespaceState} from "./namespaces/app_state_namespace.state";
import {DatesUpdatesStateNamespaceState, initialDatesUpdatesNamespaceState} from "./namespaces/dates_updates_namespace.state";
import {initialMetaState, MetaState} from "./generic/meta.state";
import {initialResourcesNamespaceState, ResourcesNamespaceState} from "./namespaces/resources_namespace.state";
import {IFileMessageNotSendedState, initialFileMessageNotSendedState} from "./messages/filemessagenotsended.state";
// import {UsersState} from "./users/users.state";


export interface IAppState {
    // readonly router?: RouterReducerState;
    // readonly rooms: IRoomState;

    readonly roomGroups: IRoomGroupState,
    readonly roomOTOs: IRoomOTOState,
    readonly users: IUserState,

    readonly messageRooms: IPagedRoomGroupMessagesState,
    readonly messageOTOs: IPagedOTOGroupMessagesState,
    // readonly loadedApp: boolean,
    // readonly ownDataUser: OwnDataUser,
    // readonly ownImageProfile: Blob | undefined,
    // readonly ownImageProfile: string,
    // readonly socketConnected: boolean,
    // readonly socketConnectedError: boolean,
    readonly selectedRoom: {idRoomGroupSelected: number, idUserGroupSelected: number},
    // readonly idRoomGroupSelected: number,
    // readonly idUserGroupSelected: number,
    // readonly socketConnected: boolean,
    readonly responsesState: ResponsesState,
    readonly fileMessagesState: IPagedFileMessages,
    readonly discoversState: IDiscoversState,

    // ---------------------------------
    // -------- NEW VALUES ARQ V2 ------
    // ---------------------------------

    readonly metaState: MetaState;

    // --- Namespace app_state
    readonly appNamespaceState: AppStateNamespaceState;

    // --- Namespace dates_updates
    readonly datesUpdatesNamespaceState: DatesUpdatesStateNamespaceState;

    readonly resourcesNamespaceState: ResourcesNamespaceState;

    readonly fileMessageNotSendedState: IFileMessageNotSendedState

}



export const initialAppState: IAppState = {
    roomGroups: initialRoomGroupState,
    roomOTOs: initialRoomOTOState,
    users: initialUserState,
    messageRooms: initialPagedRoomGroupMessages,
    messageOTOs: initialPagedRoomOTOMessages,
    // loadedApp: false,
    // ownDataUser: undefined,
    // ownImageProfile: undefined,

    // socketConnected: false,
    // socketConnectedError: false,
    selectedRoom: {idRoomGroupSelected: undefined, idUserGroupSelected: undefined},
    responsesState: initialResponsesState,
    fileMessagesState: initialPagedFileMessagesState(),
    discoversState: initialDiscoversState,

    metaState: initialMetaState,
    appNamespaceState: initialAppStateNamespaceState,
    datesUpdatesNamespaceState: initialDatesUpdatesNamespaceState,
    resourcesNamespaceState: initialResourcesNamespaceState,
    fileMessageNotSendedState: initialFileMessageNotSendedState
};

export function getInitialState(): IAppState {
    return initialAppState
}
