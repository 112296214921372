// import {PagedFileMessages} from '../entities/PagedFileMessages';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapterFileMessage} from '../adapters/filemessages.adapters';
import {IPagedFileMessages} from '../entities/PagedFileMessages';
import {IAppState} from '../state/app.state';
import {getAllMessagesOTOByIdUserState, getPagedMessagesRoomOTOByIdUserSelect} from './messages.selectors';


export const selectFileMessagesState = (state: IAppState) => state.fileMessagesState;

const FileMessageSelectors = adapterFileMessage.getSelectors();

export const pagedFileMessagesNextPageSelect = createSelector(
    selectFileMessagesState,
    (state: IPagedFileMessages) => {
        console.log({text: "pagedFileMessagesNextPageSelect", s: state});
        return ({
            fullPagesLoaded: state.fullPagesLoaded,
            nextPageToLoad: state.nextLastPageToLoad
        })
    }
);

export const fileMessagesStateSelect = createSelector(
    selectFileMessagesState,
    (state: IPagedFileMessages) => state.fileMessagesState
);

export const fileMessagesSelect = createSelector(
    fileMessagesStateSelect,
    FileMessageSelectors.selectAll
);

// export const getAllMessagesOTOByIdUserState = (idUser: number) => createSelector(
//     getPagedMessagesRoomOTOByIdUserSelect(idUser),
//     pagedMessagesOTO => pagedMessagesOTO.messagesOTOState
// );

// export const getMessageOTOByIdUserIdMessage = (idUser: number, idMessage: string) => createSelector(
//     getAllMessagesOTOByIdUserState(idUser),
//     MessageOTOsSelectors.selectEntities,
//     // (s: IMessageRoomState, msgs : Dictionary<MessageRoom>) => msgs[idMessage]
//     entities => entities.entities[idMessage]
// );

export const getFileMessagesEntities = createSelector(
    fileMessagesStateSelect,
    FileMessageSelectors.selectEntities,
);


export const getFileMessageByIdMessageSelect = (idMessage: string) => createSelector(
    getFileMessagesEntities,
    entities => entities[idMessage]
);
