import {IGetUsersMeRoomsResponseModel} from '../../sources/remote/rooms/models/IGetUsersMeRoomsResponseModel';
import {RoomApiModel} from '../../sources/remote/rooms/models/RoomApiModel';
import {getDateFromBackendDate} from './dates.mapper';
import {RoomGroup, RoomGroupType} from '../../domain/RoomGroup';
import {RoomOTO} from '../../domain/RoomOTO';
import {OneToOneApiModel} from '../../sources/remote/rooms/models/OneToOneApiModel';
import {UserRoomResponseModel} from '../../sources/remote/rooms/models/UserRoomResponseModel';
import {UserRoom} from '../../domain/UserRoom';
import {IRoomAlllistRoomApiModel} from '../../sources/remote/rooms/models/IRoomAlllistRoomApiModel';
import {OwnDataUser} from '../../domain/OwnDataUser';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {MessagesMapper} from './messages.mapper';
import {PartialRoomGroup} from "../../sources/storage/entities/PartialRoomGroup";
import {initialUserRoomGroupState, IUserRoomGroupState} from "../../sources/storage/state/room.state";
import {PartialRoomOTO} from "../../sources/storage/entities/PartialRoomOTO";
import {LinkGenerators} from "../../domain/images/link.generators";

@Injectable({
    providedIn: 'root'
})
export class RoomsMapper {

    public constructor(
        private messagesMapper : MessagesMapper,
        private linkGenerators: LinkGenerators
    ) {}

    getRoomGroupTypeByTypeApiModel(type: 1|2|3) : RoomGroupType {
        if (type === 1) {
            return RoomGroupType.GROUP;
        } else if (type === 3) {
            return RoomGroupType.DISCOVER;
        } else {
            throw new Error("export function getRoomGroupTypeByTypeApiModel(type: 1|2|3) : RoomGroupType, not 1 or 3, already: " + type);
        }
    }


    getUserRoomByUserRoomResponseModel(userRoomResponseModel: UserRoomResponseModel) : UserRoom {
        return new UserRoom(
            userRoomResponseModel.idUser,
            userRoomResponseModel.authorized === 1
        );
    }

    getRoomGroupByIRoomAlllistRoomApiModel(roomAllListRoomApiModel: IRoomAlllistRoomApiModel) : Observable<RoomGroup> {

        return this.messagesMapper.getMessageRoomByIRoomAlllistRoomApiModel(roomAllListRoomApiModel.lastMessage).pipe(
            switchMap(message => this.linkGenerators.getRoomImageLinkFromIdRoom(roomAllListRoomApiModel.id).pipe(
                map(link => {
                    const now = new Date();
                    const lastMessageDate = roomAllListRoomApiModel.lastMessage ?
                        getDateFromBackendDate(roomAllListRoomApiModel.lastMessage.date) :
                        new Date();
                    return new RoomGroup(
                        getDateFromBackendDate(roomAllListRoomApiModel.lastActivity),
                        roomAllListRoomApiModel.readCount,
                        message,
                        roomAllListRoomApiModel.membersCount,
                        getDateFromBackendDate(roomAllListRoomApiModel.lastModification),
                        roomAllListRoomApiModel.name,
                        roomAllListRoomApiModel.description,
                        roomAllListRoomApiModel.id,
                        link,
                        this.getRoomGroupTypeByTypeApiModel(roomAllListRoomApiModel.type),
                        now,
                        getDateFromBackendDate(roomAllListRoomApiModel.lastActivity),
                        false,
                        now,
                        roomAllListRoomApiModel.bidirectional === 1
                    )
                })
            ))
        );
    }

    getRoomOTOByIRoomAlllistRoomApiModel(roomAlllistRoomApiModel: IRoomAlllistRoomApiModel, ownDataUser: OwnDataUser) : Observable<RoomOTO> {
        return this.messagesMapper.getMessageOTOByIRoomAlllistRoomApiModel(roomAlllistRoomApiModel.lastMessage, ownDataUser).pipe(
            map(message => {
                const now = new Date();
                const lastMessageDate = roomAlllistRoomApiModel.lastMessage ?
                    getDateFromBackendDate(roomAlllistRoomApiModel.lastMessage.date) :
                    new Date();
                return new RoomOTO(
                    getDateFromBackendDate(roomAlllistRoomApiModel.lastActivity),
                    roomAlllistRoomApiModel.readCount,
                    message,
                    roomAlllistRoomApiModel.id,
                    now,
                    now
                    // getDateFromBackendDate(roomAlllistRoomApiModel.lastActivity)
                );
            })
        );
    }

    getPartialRoomFromRoomGroup(roomGroup: RoomGroup) : PartialRoomGroup {
        return new PartialRoomGroup(
            roomGroup,
            0,
            0,
            false,
            initialUserRoomGroupState,
            ""
        )
    }

    getPartialOtoFromRoomOTO(roomOto: RoomOTO) : PartialRoomOTO {
        return new PartialRoomOTO(
            roomOto,
            0,
            0,
            false,
            ""
        );
    }

    getRoomImageLinkFromIdRoom(idRoom: number) : Observable<string> {
        return this.linkGenerators.getRoomImageLinkFromIdRoom(idRoom);
    }
}



