import {createAction, props} from "@ngrx/store";
import {Discover} from "../../../../domain/Discover";


const TAG = '[Generic Discovers Actions] ';

export const GenericDiscoversActions = {
    SAVE_LIST_DISCOVERS: TAG + 'Save List Discovers',
    REFRESH_LIST_DISCOVERS: TAG + 'Refresh List Discovers'
};

export const SaveListDiscoversAction = createAction(
    GenericDiscoversActions.SAVE_LIST_DISCOVERS,
    props<{discovers: Discover[]}>()
);

export const RefreshListDiscoversAction = createAction(
    GenericDiscoversActions.REFRESH_LIST_DISCOVERS,
    props<{all: Discover[], deleted: number[]}>()
);

