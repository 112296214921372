import {IFileMessagesState} from '../state/filemessages.state';


// export class PagedFileMessages {
//
//     readonly fileMessagesState: IFileMessagesState;
//     readonly nextLastPageToLoad: number;
//     readonly numberMessagesLastPage: number;
//     readonly fullPagesLoaded: boolean;
//     static readonly MESSAGES_BY_PAGE : number = 10;
//
//
//     constructor(fileMessagesState: IFileMessagesState, nextLastPageToLoad: number, numberMessagesLastPage: number, fullPagesLoaded: boolean) {
//         this.fileMessagesState = fileMessagesState;
//         this.nextLastPageToLoad = nextLastPageToLoad;
//         this.numberMessagesLastPage = numberMessagesLastPage;
//         this.fullPagesLoaded = fullPagesLoaded;
//     }
// }

export const MESSAGES_BY_PAGE : number = 10;

export interface IPagedFileMessages {
    readonly fileMessagesState: IFileMessagesState;
    readonly nextLastPageToLoad: number;
    readonly numberMessagesLastPage: number;
    readonly fullPagesLoaded: boolean;
    // static readonly MESSAGES_BY_PAGE : number = 10;
}
