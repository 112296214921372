import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {AppError} from '../../globalmodels/AppError';
import {FormGroup} from "@angular/forms";


@Injectable({
    providedIn: 'root',
})
export class AjaxCaller {

    constructor(
        private http: HttpClient
    ) {}

    post(url: string, body, headers) : Observable<any> {
        console.log(body);

        return this.http.post(url, body, {headers: headers});

    }
    private g(form: FormGroup) {
        this.http.post("", form)
    }

    private static getFormDataFromFields(f: {key: string, value: Blob | string}[]) : FormData {
        const formData = new FormData();
        for (let i = 0; i < f.length; i++) {
            formData.append(f[i].key, f[i].value);
        }
        return formData;
    }

    postFormData(url: string, body: {key: string, value: Blob | string}[], headers) : Observable<any> {
        return this.http.post(url, AjaxCaller.getFormDataFromFields(body), {headers:headers});
    }

    putFormData(url: string, body: {key: string, value: Blob | string}[], headers) : Observable<any> {
        return this.http.put(url, AjaxCaller.getFormDataFromFields(body), {headers:headers});
    }



    head(url: string, headers): Observable<any> {
        return this.http.head(url, {headers: headers});
    }

    get(url: string, headers) : Observable<any> {
        // console.log(headers);
        this.http.get("", {})
        return this.http.get(url, {headers: headers});
    }

    put(url: string, body, headers) : Observable<any> {
        return this.http.put(url, body, {headers:headers});
    }

    delete(url: string, headers) : Observable<any> {
        return this.http.delete(url, {headers:headers});
    }

    getFile(url: string, headers) : Observable<any> {
        return this.http.get(url, {headers: headers, responseType: 'blob'}).pipe(
            catchError(err => {
                return new Observable<any>(subscriber => {
                    const r = new FileReader();
                    r.readAsText(err.error);
                    r.onload = (_) => {
                        subscriber.error(new AppError(r.result as string));
                    }
                });

            })
        );
    }

    // put(url: string, body: JSON, headers: JSON) : Observable<JSON> {
    //     return undefined;
    // }




}
