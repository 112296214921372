import {HttpHeaders} from './HttpHeaders';
import {CookieStoreService} from '../../../../../localStorage/cookie-store.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoggedState} from './LoggedState';


export class LoggedHttpHeaders extends HttpHeaders {

    private static LOGGED_TAG_TOKEN = "LOGGED_TOKEN_ZONETACTS";

    constructor(
        private tokenService: CookieStoreService
    ) {
        super();
    }

    getHeaders() : Observable<JSON> {
        return this.tokenService.getById(LoggedHttpHeaders.LOGGED_TAG_TOKEN)
            .pipe(map(token => <JSON><unknown>{authorization: token}));
    }

    static getTokenTag() : string {
        return this.LOGGED_TAG_TOKEN;
    }

    getLoggedState(): LoggedState {
        return LoggedState.LOGGED;
    }

    getAuthorizationToken(): Observable<string> {
        return this.tokenService.getById(LoggedHttpHeaders.LOGGED_TAG_TOKEN);
    }
}
