

export class RoomStatusAdmins {

    readonly idRoom: number;
    readonly idUsers: number[];


    constructor(idRoom: number, idUsers: number[]) {
        this.idRoom = idRoom;
        this.idUsers = idUsers;
    }
}
