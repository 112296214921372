

export class LocalResourcePack {
    constructor(
        readonly meta_no_image_profile: Blob,
        readonly meta_no_connection: Blob,
        readonly discovers_icon: Blob,
        readonly discovers_check: Blob,
        readonly chat_load_more_gallery: Blob,
        readonly chat_edit_group: Blob,
        readonly messages_not_synchronized_not_sended_icon: Blob,
        readonly messages_synchronized_not_sended_icon: Blob,
        readonly messages_synchronized_sended_icon: Blob,
        readonly messages_error_icon: Blob,
        readonly messages_pdf_placeholder: Blob,
        readonly scripts_notification_sidebar: Blob,
        readonly messages_videos_placeholder: Blob,
        readonly meta_retry_icon: Blob
    ) {

    }
}
