import { createAction, props } from "@ngrx/store";
import { StandardError } from "../../../../domain/StandardError";
import { OwnDataUser } from "../../../../domain/OwnDataUser";
import { PartialUser } from "../../entities/PartialUser";
import { PartialRoomGroup } from "../../entities/PartialRoomGroup";
import { PartialRoomOTO } from "../../entities/PartialRoomOTO";
import { Discover } from "../../../../domain/Discover";
import { Option } from "fp-ts/lib/Option";
import { UserRoom } from "../../../../domain/UserRoom";
import { create } from "domain";

const TAG = "[App With Connection Meta] ";

export const AppWithConnectionMetaActions = {
    RELOAD_APP: TAG + "Reload App",
    RELOAD_APP_FAIL: TAG + "Reload App Fail",
    RELOAD_APP_SUCCESS: TAG + "Reload App Success",
    // Activar:
    //  - GenericAppStateSetAllDataLoaded a true

    RELOAD_PERSONAL_INFO: TAG + "Reload Personal Info",
    RELOAD_PERSONAL_INFO_ERROR: TAG + "Reload Personal Info Error",
    // Activar:
    //  - SetFirstAppWithConnectionStateAction amb el state de personal i el error
    RELOAD_PERSONAL_INFO_SUCCESS: TAG + "Reload Personal Info Success", // <-- aquest activa SAVE_PERSONAL_INFO i modifica progrés de l'aplicació
    // Activar:
    //  - SAVE_PERSONAL_INFO
    //  - SetFirstAppWithConnectionStateAction

    RELOAD_OPEN_SOCKET: TAG + "Reload Open Socket",
    RELOAD_OPEN_SOCKET_ERROR: TAG + "Reload Open Socket Error",
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    RELOAD_OPEN_SOCKET_SUCCESS: TAG + "Reload Open Socket Success", // <-- aquest activa les altres tres accions i modifica progrés aplicació, llença també següent estat
    // Activar:
    //  - GenericConnectionConnectionChangedAction a true
    //  - SetFirstAppWithConnectionStateAction

    RELOAD_LIST_USERS: TAG + "Reload List Users",
    RELOAD_LIST_USERS_ERROR: TAG + "Reload List Users Error",
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    RELOAD_LIST_USERS_SUCCESS: TAG + "Reload List Users Success",
    // Activar:
    //  - RefreshListUsersAction
    //  - GenericDatesUpdatesSaveDateUpdateUsersAction
    //  - ADD_LISTENER_USER_MODIFIED
    //  - ADD_LISTENER_NEW_USER
    //  - SetFirstAppWithConnectionStateAction

    RELOAD_LIST_ROOMS: TAG + "Reload List Rooms",
    RELOAD_LIST_ROOMS_ERROR: TAG + "Reload List Rooms Error",
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    RELOAD_LIST_ROOMS_SUCCESS: TAG + "Reload List Rooms Success",
    // Activar:
    //  - RefreshListRoomsAction
    //  - GenericDatesUpdatesSaveDataUpdateRoomsAction
    //  - ADD_LISTENER_CHAT_MESSAGE_ROOM
    //     ADD_LISTENER_CHAT_MESSAGE_USER
    //     ADD_LISTENER_JOINED_ROOM
    //     ADD_LISTENER_QUIT_ROOM
    //     ADD_LISTENER_ROOM_MODIFIED
    //     ADD_LISTENER_ROOM_READED_MESSAGES
    //     ADD_LISTENER_OTO_READED_MESSAGES
    //  - SetFirstAppWithConnectionStateAction

    RELOAD_LIST_DISCOVERS: TAG + "Reload List Discovers",
    RELOAD_LIST_DISCOVERS_ERROR: TAG + "Reload List Discovers Error",
    // Activar:
    //  - SetFirstAppWithConnectionStateAction
    RELOAD_LIST_DISCOVERS_SUCCESS: TAG + "Reload List Discovers Success",
    // Activar:
    //  - RefreshListDiscoversAction
    //  - GenericDatesUpdatesSaveDataUpdateDiscoversAction
    //  - SetFirstAppWithConnectionStateAction

    LOAD_USER_LIST_ALL_ROOMS: TAG + "Load User List All Rooms",
    LOAD_USER_LIST_ALL_ROOMS_SUCCESS: TAG + "Load User List All Rooms Success",
    LOAD_USER_LIST_ALL_ROOMS_FAIL: TAG + "Load User List All Rooms Fail",

    LOAD_USER_LIST_SELECTED_ROOM: TAG + "Load User List Selected Room",
    LOAD_USER_LIST_SELECTED_ROOM_SUCCESS:
        TAG + "Load User List Selected Room Success",
    LOAD_USER_LIST_SELECTED_ROOM_FAIL:
        TAG + "Load User List Selected Room Fail",
};

export const ReloadAppAction = createAction(
    AppWithConnectionMetaActions.RELOAD_APP
);
export const ReloadStandardErrorAction = createAction(
    AppWithConnectionMetaActions.RELOAD_APP_FAIL,
    props<{ error: StandardError }>()
);
export const ReloadAppSuccessAction = createAction(
    AppWithConnectionMetaActions.RELOAD_APP_SUCCESS
    // props<{}>()
);
export const ReloadPersonalInfoAction = createAction(
    AppWithConnectionMetaActions.RELOAD_PERSONAL_INFO
);
export const ReloadPersonalInfoErrorAction = createAction(
    AppWithConnectionMetaActions.RELOAD_PERSONAL_INFO_ERROR,
    props<{ error: StandardError }>()
);
export const ReloadPersonalInfoSuccessAction = createAction(
    AppWithConnectionMetaActions.RELOAD_PERSONAL_INFO_SUCCESS,
    props<{ info: OwnDataUser; image: Option<Blob> }>()
);
export const ReloadOpenSocketAction = createAction(
    AppWithConnectionMetaActions.RELOAD_OPEN_SOCKET
);
export const ReloadOpenSocketErrorAction = createAction(
    AppWithConnectionMetaActions.RELOAD_OPEN_SOCKET_ERROR,
    props<{ error: StandardError }>()
);
export const ReloadOpenSocketSucccessAction = createAction(
    AppWithConnectionMetaActions.RELOAD_OPEN_SOCKET_SUCCESS
);

// LOAD_OPEN_SOCKET: TAG + 'Load Open Socket',
//     LOAD_OPEN_SOCKET_ERROR: TAG + 'Load Open Socket Error',
//     // Activar:
//     //  - SetFirstAppWithConnectionStateAction
//     LOAD_OPEN_SOCKET_SUCCESS: TAG + 'Load Open Socket Success',// <-- aquest activa les altres tres accions i modifica progrés aplicació, llença també següent estat
export const ReloadListUsersAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_USERS
);
export const ReloadListUsersErrorAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_USERS_ERROR,
    props<{ error: StandardError }>()
);
export const ReloadListUsersSuccessAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_USERS_SUCCESS,
    props<{ all: PartialUser[]; deleted: number[] }>()
);
export const ReloadListRoomsAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_ROOMS
);
export const ReloadListRoomsErrorAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_USERS_ERROR,
    props<{ error: StandardError }>()
);
export const ReloadListRoomsSuccessAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_ROOMS_SUCCESS,
    props<{
        all: { rooms: PartialRoomGroup[]; otos: PartialRoomOTO[] };
        deleted: { rooms: number[]; otos: number[] };
    }>()
);
export const ReloadListDiscoversAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_DISCOVERS
);
export const ReloadListDiscoversErrorAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_DISCOVERS_ERROR,
    props<{ error: StandardError }>()
);

export const LoadUserListAllRoomsAction = createAction(
    AppWithConnectionMetaActions.LOAD_USER_LIST_ALL_ROOMS
);
export const LoadUserListAllRoomsSuccessAction = createAction(
    AppWithConnectionMetaActions.LOAD_USER_LIST_ALL_ROOMS_SUCCESS,
    props<{ rooms: { idRoom: number; userList: UserRoom[] }[] }>()
);
export const LoadUserListAllRoomsFailAction = createAction(
    AppWithConnectionMetaActions.LOAD_USER_LIST_ALL_ROOMS_FAIL,
    props<{ error: StandardError }>()
);

export const LoadUserListSelectedRoomAction = createAction(
    AppWithConnectionMetaActions.LOAD_USER_LIST_SELECTED_ROOM
);
export const LoadUserListSelectedRoomSuccessAction = createAction(
    AppWithConnectionMetaActions.LOAD_USER_LIST_SELECTED_ROOM_SUCCESS,
    props<{ room: Option<{ idRoom: number; userList: UserRoom[] }> }>()
);
export const LoadUserListSelectedRoomFailAction = createAction(
    AppWithConnectionMetaActions.LOAD_USER_LIST_SELECTED_ROOM_FAIL,
    props<{ error: StandardError }>()
);
// LOAD_USER_LIST_SELECTED_ROOM: TAG + 'Load User List Selected Room',
//     LOAD_USER_LIST_SELECTED_ROOM_SUCCESS: TAG + 'Load User List Selected Room Success',
//     LOAD_USER_LIST_SELECTED_ROOM_FAIL: TAG + 'Load User List Selected Room Fail',
export const ReloadListDiscoversSuccessAction = createAction(
    AppWithConnectionMetaActions.RELOAD_LIST_DISCOVERS_SUCCESS,
    props<{ all: Discover[]; deleted: number[] }>()
);
