import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {BehaviorSubject, Observable} from "rxjs";
import {Option} from "fp-ts/es6/Option";
import {ResourcesService} from "../resources/resources.service";

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, AfterViewInit {

    @Input() showSpinner: boolean = true;
    @Input() message: string;
    @Input() withImage: boolean = false;
    // @Input() image: Observable<string>;

    cloudNotConnection: Observable<string>;

    // messageSubs = new BehaviorSubject("");

    constructor(
        // private spinner: NgxSpinnerService
        private resourcesService: ResourcesService
    ) { }


    ngOnInit() {
        this.cloudNotConnection = this.resourcesService.getMetaNoConnection();
    }

    ngAfterViewInit(): void {
        // this.message.subscribe(
        //     next => this.messageSubs.next(next)
        // )
        // this.spinner.show('loadingSpinner');
    }

}
