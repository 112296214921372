import {IAppState} from "../../state/app.state";
import {createSelector, select} from "@ngrx/store";

export const selectDatesUpdatesNamespaceState = (state: IAppState) => state.datesUpdatesNamespaceState;

export const DatesUpdatesUsersSelect = createSelector(
    selectDatesUpdatesNamespaceState,
    state => state.dates_updates_users
);

export const DatesUpdatesListRoomsSelect = createSelector(
    selectDatesUpdatesNamespaceState,
    state => state.dates_updates_list_rooms
);

export const DatesUpdatesDiscoversSelect = createSelector(
    selectDatesUpdatesNamespaceState,
    state => state.dates_updates_discovers
);

// readonly dates_updates_users: Date;
// readonly dates_updates_list_rooms: Date;
// readonly dates_updates_discovers: Date;
