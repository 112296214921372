import { Component, OnInit } from "@angular/core";
import { LocaleService } from "app/shared/locales/locale.service";
import { ThemeService } from "app/shared/themes/theme.service";

@Component({
    selector: "app-mobile",
    templateUrl: "./mobile.component.html",
    styleUrls: ["./mobile.component.scss"],
})
export class MobileComponent implements OnInit {
    constructor(
        private themeService: ThemeService,
        private localeService: LocaleService
    ) {}

    ngOnInit() {}
}
