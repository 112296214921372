import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UsersRepository} from '../../../repositories/users/users.repository';
import {
    // getImageProfileUser,
    // getImageProfileUserNotFound,
    // getImageProfileUserSuccess,
    // loadAllImageProfilesUsersWithOTOSuccess, loadAllPartialUsers,
    // loadAllPartialUsersSuccess,
    onModifyUserAdditionalInfo,
    onModifyUserImage, onModifyUserName,
    onModifyUserStatus, onModifyUserSurname,
    onUserDeleted,
    onUserDeletedSuccess,
    UserActions
} from '../actions/user.actions';
import {concatMap, defaultIfEmpty, filter, flatMap, map, mergeMap, switchMap, take, tap, toArray} from 'rxjs/operators';
import {from, of, zip} from 'rxjs';
import {PartialUser} from '../entities/PartialUser';
import {Store} from '@ngrx/store';
import {IUserState} from '../state/user.state';
import {getUserByIdSelect} from '../selectors/users.selectors';
import {getUserWithImageProfileByUser} from '../mappers/users.mappers';
import {IRoomOTOState} from '../state/room.state';
import {getRoomsOTOSelect} from '../selectors/rooms.selectors';
import {User} from '../../../domain/User';
import {UsersFacade} from '../facade/users.facade';
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import {changeOwnDataSuccess, logoutSuccess, nilAction} from "../actions/app.actions";
import {leaveRoomOTOSuccess, onModifyImageRoom, onModifyNameRoom, onQuitUserRoom} from "../actions/room.actions";
import {AppFacade} from "../facade/app.facade";
import {RoomsFacade} from "../facade/rooms.facade";
import * as A from "fp-ts/lib/Array";
import {LinkGenerators} from "../../../domain/images/link.generators";
import {MetaFacade} from "../facade/generic/meta.facade";


@Injectable({
    providedIn: 'root'
})
export class UserEffects {

    // loadAllUsersEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(loadAllPartialUsers),
    //         // tap(_ => console.log("Cridada action: " + UserActions.LOAD_ALL_PARTIAL_USERS)),
    //         flatMap(_ => this.usersRepository.getAllUsers()),
    //         // tap(users => console.log({text: "USERS: ", users: users})),
    //         // flatMap(users => from(users).pipe(
    //         //     map(user => new PartialUser(user, false, false)),
    //         //     toArray(),
    //         // )),
    //         // map(users => users.map(user => new PartialUser(user, false, false))),
    //
    //         // tap(user => console.log(user)),
    //         // tap(pu => console.log(pu)),
    //         map(partialUsers => loadAllPartialUsersSuccess({partialUsers:partialUsers}))
    //     )
    // );

    //
    // loadAllImageProfilesUsersEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(UserActions.LOAD_ALL_IMAGE_PROFILES_USERS_WITH_OTO),
    //         tap(_ => console.log("Cridada : " + UserActions.LOAD_ALL_IMAGE_PROFILES_USERS_WITH_OTO)),
    //         flatMap(_ => this.roomOTOsStore.select(getRoomsOTOSelect).pipe(take(1))),
    //
    //         flatMap(roomsOTO =>  {
    //             console.log({text: "users loadAllImageProfilesUsersEffect$" , roomsOto: roomsOTO});
    //             if (roomsOTO.length > 0)
    //                 return from(roomsOTO).pipe(
    //                     tap(partialUser => console.log({text: "PARTIAL USER", user: partialUser})),
    //                     flatMap(otoRoom => this.usersStore.select(getUserByIdSelect, {idUser: otoRoom.roomOTO.idUser}).pipe(take(1))),
    //                     tap(partialUser => console.log({text: "PARTIAL USER", user: partialUser})),
    //                     flatMap(partialUser => this.usersRepository.getImageProfileFromUserByIdUser(partialUser.user.idUser).pipe(
    //                         tap(([b,e]) => console.log({msg: "On get image...", exists: e})),
    //                         filter(([blob,exists]) => exists),
    //                         map(([blob,_]) => getUserWithImageProfileByUser(partialUser.user, blob)),
    //
    //                     )),
    //                     tap(rr => console.log({arr: rr, msg: "On group array users"})),
    //                     toArray(),
    //                     map(usersWithBlob => loadAllImageProfilesUsersWithOTOSuccess({users: usersWithBlob}))
    //                 );
    //             else
    //                 return of(loadAllImageProfilesUsersWithOTOSuccess({users: []}));
    //         }),
    //         tap(users => {
    //             console.log({msg: "Users loaded image:", users: users});
    //         }),
    //
    //     )
    // );


    // getImageProfileUserEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         // ofType(UserActions.GET_IMAGE_PROFILE_USER),
    //         ofType(getImageProfileUser),
    //         tap(action => {
    //             console.log(UserActions.GET_IMAGE_PROFILE_USER);
    //             console.log(action);
    //         }),
    //         //@ts-ignore
    //         // flatMap(action => this.usersStore.select(getUserByIdSelect, {idUser:action.idUser}).pipe(take(1))),
    //         flatMap(({idUser}) => this.usersRepository.getImageProfileFromUserByIdUser(idUser).pipe(
    //             flatMap(([blob,exists]) => this.usersFacade.getUserById(idUser).pipe(
    //                 map(user => [getUserWithImageProfileByUser(user.user, blob), exists] as [User, boolean])
    //             )),
    //             // map(([blob,exists]) =>
    //         )),
    //         map(([user,exists]) => {
    //             if (exists)
    //                 return getImageProfileUserSuccess({user: user});
    //             else
    //                 return getImageProfileUserNotFound();
    //         })
    //         // map(toPayload),
    //         // map((action: ))
    //         // mergeMap(action => )
    //     )
    // );

    onModifyUserStatusEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onModifyUserStatus),
            concatMap(({status}) => this.metaFacade.getOwnDataUser().pipe(
                switchMap(ownData => {
                    if (ownData.idUser === status.idUser) {
                        if (O.isSome(status.fields.image)) {
                            return this.usersRepository.getImageProfileFromUserByIdUser(status.idUser).pipe(
                                switchMap(opt => {
                                    // const opt = exists ? O.some(image) : O.none;
                                    return of(changeOwnDataSuccess({bio: status.fields.additionalInfo, image: opt, name: status.fields.name, surname: status.fields.surname, emailContact: O.none, emailNotifications: O.none}));
                                })
                                // switchMap(O.fold(
                                //     () => of(nilAction()) // TODO ACCIÓ QUAN NO EXISTEIX PROPIA IMATGE,
                                //     img => of(changeOwnDataSuccess({bio: status.fields.additionalInfo, image: O.some(img)}))
                                // ))
                                // flatMap(([image, exists]) => {
                                //     const opt = exists ? O.some(image) : O.none;
                                //     return of(changeOwnDataSuccess({bio: status.fields.additionalInfo, image: opt}));
                                // })
                            );
                            // return this.linkGenerators.getUserImageLinkFromIdUser(status.idUser).pipe(
                            //     map(link => changeOwnDataSuccess({bio: status.fields.additionalInfo, image: O.some(link)}))
                            // )
                            // return of(changeOwnDataSuccess({bio: status.fields.additionalInfo, image: status.fields.image}));
                            // return this.usersRepository.getImageProfileFromUserByIdUser(status.idUser).pipe(
                            //     map()
                            //     switchMap(([image, exists]) => {
                            //         const opt = exists ? O.some(image) : O.none;
                            //         return of(changeOwnDataSuccess({bio: status.fields.additionalInfo, image: opt}));
                            //     })
                            // );
                        } else {
                            return of(changeOwnDataSuccess({bio: status.fields.additionalInfo, image: O.none, name: status.fields.name, surname: status.fields.surname, emailContact: O.none, emailNotifications: O.none}));
                        }

                    } else {
                        const obs1 = pipe(
                            status.fields.additionalInfo,
                            O.fold(
                                () => of(nilAction()),
                                info => of(onModifyUserAdditionalInfo({idUser: status.idUser, additionalInfo: info}))
                            )
                        );
                        const obs2 = pipe(
                            status.fields.image,
                            O.fold(
                                () => of(nilAction()),
                                _ => this.linkGenerators.getUserImageLinkFromIdUser(status.idUser).pipe(
                                    map(link => onModifyUserImage({idUser: status.idUser, image: link}))
                                )
                                // _ => this.usersRepository.getImageProfileFromUserByIdUser(status.idUser).pipe(
                                //     map(([image, exists]) => {
                                //         if (exists) return onModifyUserImage({idUser: status.idUser, image: image});
                                //         return nilAction();
                                //     })
                                // )
                            )
                        );
                        const obs3 = pipe(
                            status.fields.name,
                            O.fold(
                                () => of(nilAction()),
                                name => of(onModifyUserName({idUser: status.idUser, name: name}))
                            )
                        );
                        const obs4 = pipe(
                            status.fields.surname,
                            O.fold(
                                () => of(nilAction()),
                                surname => of(onModifyUserSurname({idUser: status.idUser, surname: surname}))
                            )
                        );
                        return zip(obs1, obs2, obs3, obs4).pipe(
                            switchMap(acts => acts)
                        );
                    }
                })
            ))
        )
    );

    onUserDeletedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onUserDeleted),
            concatMap(({idUser}) => this.metaFacade.getOwnDataUser().pipe(
                switchMap(ownDataUser => {
                    if (ownDataUser.idUser*1 === idUser*1) return [logoutSuccess()];
                    return this.roomsFacade.getAllIdRoomWithUserByIdUser(idUser).pipe(
                        map(A.map(idRoom => onQuitUserRoom({idRoom: idRoom, idUser: idUser}))),
                        switchMap(actions => [
                            ...actions,
                            leaveRoomOTOSuccess({idUser: idUser}),
                            onUserDeletedSuccess({idUser: idUser})
                        ])
                    )
                })
            ))
        )
    );

    constructor(
        private actions$: Actions,
        private usersRepository: UsersRepository,
        private usersStore: Store<IUserState>,
        private roomOTOsStore: Store<IRoomOTOState>,
        private usersFacade: UsersFacade,
        private appFacade: AppFacade,
        private metaFacade: MetaFacade,
        private roomsFacade: RoomsFacade,
        private linkGenerators: LinkGenerators
    ) {}
}
