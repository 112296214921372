import { Component, OnInit, ViewContainerRef } from "@angular/core";
declare var croppie: any;
import * as _ from "croppie";
import { LanguageStoreService } from "./shared/languagestore/language-store.service";
import { ThemeService } from "./shared/themes/theme.service";
import { Title } from "@angular/platform-browser";
import { AppService } from "./app.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, tap } from "rxjs/operators";
import { Observable, combineLatest } from "rxjs";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    private mobile$: Observable<boolean>;

    constructor(
        private themeService: ThemeService,
        private titleService: Title,
        private appService: AppService,
        private languageStoreService: LanguageStoreService,
        private breakpointObserver: BreakpointObserver,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        //@ts-ignore
        const lang = navigator.language || navigator.userLanguage;
        console.log({ text: "Browser language", lang: lang });
        this.languageStoreService.defineLanguageI18n(lang).subscribe();
        console.log("Carregat chat shell component.");
        this.titleService.setTitle(this.themeService.getTitle());
        this.appService.setAppFavicon(
            this.themeService.getPathFaviconLogoTheme()
        );

        this.mobile$ = combineLatest([
            this.breakpointObserver
                .observe([
                    Breakpoints.HandsetLandscape,
                    Breakpoints.HandsetPortrait,
                ])
                .pipe(map((result) => result.matches)),
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                // map(() => this.route.snapshot.firstChild.routeConfig.path)
                map(
                    (event) =>
                        (event as NavigationEnd).url
                            .split("/")[1] // ? Remove the first slash
                            .split("?")[0] // ? Remove the query params
                            .split("#")[0] // ? Remove the hash
                )
            ),
        ]).pipe(
            map(([mobile, path]) => {
                const excludedPaths = [
                    "login",
                    "recover",
                    "resetpassword",
                    "external",
                ];
                return mobile && !excludedPaths.includes(path);
            })
        );
    }
}
