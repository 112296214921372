
// import * as i18n from "i18n";
// import * as path from "path";
// import TranslateOptions = i18n.TranslateOptions;
import {Injectable} from "@angular/core";
import {LanguageStoreService} from "../languagestore/language-store.service";
import {ThemeService} from "../themes/theme.service";

// const data: any = require('../../../../assets/i18n/old/es.json');



/**
 * LocaleService
 */
@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    // private i18nProvider;

    private locales : {[key: string]: string};

    constructor(
        private themeService: ThemeService,
        private languageStoreService: LanguageStoreService
    ) {

        // this.locales = require('../../../assets/i18n/' + this.themeService.getLang() + '.json');

        this.languageStoreService.getLanguage().subscribe(
            lang => {
                this.locales = require('../../../assets/i18n/' + lang + '.json');
            }
        )

        // console.log({text: "directory locales", d: path.join('./', 'trads')});
        // console.log({text: "dirname", d: __dirname});

        // i18n.configure({
        //     // locales: ['en', 'es', 'ca'],
        //     defaultLocale: 'ca',
        //     directory: "./assets/i18n",
        //     // register: global
        // });
        // // i18n.setLocale(config.localeSelector);
        // i18n.setLocale(themeService.getLang());
        // console.log({text: "info locales", p: i18n.getLocales(),r: JSON.stringify(i18n.getCatalog()) });
        // this.i18nProvider = i18n;
    }

    private getRegexFromString(str : string) : RegExp {
        return new RegExp('{{' + str + '}}');
    }



    translate(tr: string, args: { [key: string]: string }): string {
        let newStr = this.locales[tr];
        const ks = Object.keys(args);
        for (let i = 0; i < ks.length; i++) {
            newStr = newStr.replace(this.getRegexFromString(ks[i]), args[ks[i]])
        }
        return newStr;


        // return this.i18nProvider.__(tr, args);
    }

}
