import { Injectable } from "@angular/core";
import { Either } from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import { Observable } from "rxjs";
import { StandardError } from "../../../domain/StandardError";
import { NetworkClient } from "../core/NetworkClient";
import { RequestType } from "../core/RequestType";
import { IGetUsersMeResponseModel } from "./models/IGetUsersMeResponseModel";
import { IPostUsersRefreshfirebaseTokenRequestModel } from "./models/IPostUsersRefreshfirebaseTokenRequestModel";
import { IPutUsersMeRequestModel } from "./models/IPutUsersMeRequestModel";

@Injectable({
    providedIn: "root",
})
export class MetaApihelper {
    constructor(private networkClient: NetworkClient) {}

    getUsersMe(): Observable<Either<StandardError, IGetUsersMeResponseModel>> {
        return this.networkClient.setRequest<{}, IGetUsersMeResponseModel>(
            "/users/me",
            {},
            RequestType.GET
        );
    }

    putUsersMe(
        body: IPutUsersMeRequestModel
    ): Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<IPutUsersMeRequestModel, {}>(
            "/users/me",
            body,
            RequestType.PUT
        );
    }

    putUsersMeImageprofile(image: Blob): Observable<Either<StandardError, {}>> {
        return this.networkClient
            .setRequestWithFormData(
                "/users/me/imageprofile",
                [{ key: "file", value: image }],
                RequestType.PUT
            )
            .pipe
            // catchError(e => {
            //     console.log({text: "error on putUsersMeImageprofile", e: e});
            //     return e;
            // })
            ();
    }

    postUsersLogout(): Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{}, {}>(
            "/users/logout",
            // {deviceId: deviceId},
            {},
            RequestType.POST
        );
    }

    postUsersRefreshfirebasetoken(
        deviceId: string,
        token: string
    ): Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<
            IPostUsersRefreshfirebaseTokenRequestModel,
            {}
        >(
            "/users/refreshfirebasetoken",
            { deviceId: deviceId, token: token, deviceType: "Web" },
            RequestType.POST
        );
    }

    putUsersMeModifydata(
        additionalInfo: Option<string>,
        image: Option<Blob>,
        emailContact: Option<Option<string>>,
        emailNotifications: Option<boolean>
    ): Observable<Either<StandardError, {}>> {
        const ai = O.toNullable(additionalInfo);
        const i = O.toNullable(image);
        const e = O.toNullable(emailContact);
        const en = O.toNullable(emailNotifications);

        const str: { key: string; value: string | Blob }[] = [];

        if (ai !== null) str.push({ key: "additionalInfo", value: ai });
        if (i !== null) str.push({ key: "file", value: i });
        if (e !== null) {
            str.push({
                key: "emailContact",
                value: pipe(
                    e,
                    O.fold(
                        () => "",
                        (e) => e
                    )
                ),
            });
        }
        if (en !== null)
            str.push({ key: "emailNotifications", value: en ? "1" : "0" });

        return this.networkClient.setRequestWithFormData(
            "/users/me/modifydata",
            str,
            RequestType.PUT
        );
    }

    getUsersChecktoken(): Observable<
        Either<StandardError, { oldExpired: 0 | 1; token: string }>
    > {
        return this.networkClient.setRequest<
            {},
            { oldExpired: 0 | 1; token: string }
        >("/users/checktoken", {}, RequestType.GET);
    }

    recoverPassword(
        loginIdentifier: string
    ): Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequest<{ loginIdentifier: string }, {}>(
            "/misc/resetpassword",
            {
                loginIdentifier: loginIdentifier,
            },
            RequestType.PUT
        );
    }

    resetPassword(
        password: string,
        token: string
    ): Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequestWithCustomHeaders<
            { password: string },
            {}
        >(
            "/misc/changepassword",
            { authorization: token },
            {
                password: password,
            },
            RequestType.PUT
        );
    }

    validateToken(token: string): Observable<Either<StandardError, {}>> {
        return this.networkClient.setRequestWithCustomHeaders<{}, {}>(
            "/misc/validatetoken",
            { authorization: token },
            {},
            RequestType.GET
        );
    }
}
