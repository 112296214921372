import {AppError} from "../sources/remote/globalmodels/AppError";


export class StandardError {
    readonly code: string;
    readonly message: string;
    readonly appError?: AppError;

    constructor(code: string, message: string, appError?: AppError) {
        this.code = code;
        this.message = message;
        this.appError = appError;
    }

    static getCanceledStandardError() : StandardError {
        return new StandardError(
            "APPCANCELED",
            "Canceled"
        )
    }

}


