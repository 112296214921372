import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Imagecompress} from './imagecompress';
import {from, Observable, of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';
import {DOC_ORIENTATION} from './docorientation';
import * as O from "fp-ts/lib/Option";
import {Option} from "fp-ts/lib/Option";
import {pipe} from "fp-ts/lib/pipeable";
import {FileType} from "../core/domain/FileType";

@Injectable({
    providedIn: 'root'
})
export class CompreserService {

    private render: Renderer2;

    // private static SIZE_IMAGE_MAX = 300000;
    private static MAX_SIDE_PIXELS = 1024;
    // private static SIZE_THUMBNAIL_MAX = 2000;
    private static MAX_THUMBNAIL_SIDE_PIXELS = 100;

    constructor(rendererFactory: RendererFactory2) {
        this.render = rendererFactory.createRenderer(null, null);
    }

    private uploadFile(
        // acceptedMimetypes: string[],
        file: File,
                      onSelectedFileProceed: (f: File) => Promise<boolean>
    ) {
        return Imagecompress.uploadFile(/*this.render, acceptedMimetypes,*/file, onSelectedFileProceed);
    }


    private compressFile(image, orientation, ratio: number = 50, quality: number = 50): Promise<string> {
        return Imagecompress.compress(image, orientation, this.render, ratio, quality);
    }

    public getHeightWidthByImageDataUrlSource(url: string) : Promise<{width: number, height: number}> {
        return Imagecompress.getHeightWidthByImageDataUrlSource(this.render, url);
    }

    public mimetypeIsImage(type: string) : boolean {
        return /(image).+/.test(type);
    }
    public mimetypeIsVideo(type: string) : boolean {
        return /(video).+/.test(type);
    }

    public getFileType(file: File) : FileType {
        if (this.mimetypeIsImage(file.type)) return FileType.IMAGE;
        if (this.mimetypeIsVideo(file.type)) return FileType.VIDEO;
        return FileType.FILE;
        // return file.type.match(/(image).+/).length > 0;
    }

    public getMimeTypeFromBase64(b: string) : string {
        const body = {profilepic:"data:image/png;base64,abcdefghijklmnopqrstuvwxyz0123456789"};
        // const mimeType = b.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        return body.profilepic.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    }



    public getOrientationFromBase64(b: string) : Observable<DOC_ORIENTATION> {
        return new Observable<DOC_ORIENTATION>(subscriber => {
            Imagecompress.getOrientationFromBase64(b, (or: DOC_ORIENTATION) => {
                subscriber.next(or);
                subscriber.complete();
            });
        });
    }


    public compress(image: string, orientation: DOC_ORIENTATION, mimetypeFile: string, apply_compression: boolean, alt_thumbnail : string|undefined, apply_thumbnail_compression : boolean) : Observable<{image: string, thumbnail: string}> {
        const f = (imageCompressed: string, l: number) => {
            // console.log(imageCompressed);
            let thumb;
            if (alt_thumbnail !== undefined) {
                thumb = alt_thumbnail;
            } else {
                thumb = image;
            }

            if (apply_thumbnail_compression) {
                let ratio_coef_thumbnail;
                if (l > CompreserService.MAX_THUMBNAIL_SIDE_PIXELS) {
                    ratio_coef_thumbnail = CompreserService.MAX_THUMBNAIL_SIDE_PIXELS/l;
                } else {
                    ratio_coef_thumbnail = 1;
                }
                return from(this.compressFile(thumb, orientation, ratio_coef_thumbnail*100, 100)).pipe(
                    map(thumbnailCompressed => ({image: imageCompressed, thumbnail: thumbnailCompressed}))
                );
            } else {
                return of({image: imageCompressed, thumbnail: thumb});
            }
        };

        let imageToGetSizes;
        // if (this.fileIsImage(fileSelected)) {
        if (this.mimetypeIsImage(mimetypeFile)) {
            console.log("MIMETYPE IS IMAGE");
            imageToGetSizes = image;
        } else {
            console.log("MIMETYPE IS NOT IMAGE");
            imageToGetSizes = alt_thumbnail;
        }


        return from(this.getHeightWidthByImageDataUrlSource(imageToGetSizes)).pipe(
            flatMap(({width, height}) => {
                const l = Math.max(width, height);
                let ratio_coef;
                if (l > CompreserService.MAX_SIDE_PIXELS) {
                    ratio_coef = CompreserService.MAX_SIDE_PIXELS / l;
                } else {
                    ratio_coef = 1;
                }
                // console.log({text: "SIDES images", w: width, h: height, coef: ratio_coef});
                if (apply_compression) {
                    return from(this.compressFile(image, orientation, ratio_coef*100, 100)).pipe(
                        flatMap(imageCompressed => f(imageCompressed, l))
                    );
                } else {
                    return f(image, l);
                }
            })
        );
    }

    public uploadAndCompress(
                            // mimetypes: string[],
        file: File,
                             onSelectedFileProceed: (f: File) =>
                                 Observable<{
                                     continuePr: boolean,
                                     withCompression: boolean,
                                     thumbnailCompression: boolean,
                                     alternative_thumbnail: string|undefined,
                                     fileType: FileType
                                 }>,
                             ) : Observable<{image: string, thumbnail: string, fileType: FileType, mimeType: string, file: File}> {
        let fileSelected : File;
        let apply_compression: boolean;
        let alt_thumbnail : string|undefined;
        let apply_thumbnail_compression : boolean;
        let fileTypeType: FileType;
        let mimeType: string;

        return from(this.uploadFile(file, (f: File) => {
            return new Promise<boolean>((resolve, reject) => {
                fileSelected = f;
                onSelectedFileProceed(f).subscribe(
                    ({continuePr, withCompression, thumbnailCompression, alternative_thumbnail, fileType}) => {
                        apply_compression = withCompression;
                        alt_thumbnail = alternative_thumbnail;
                        apply_thumbnail_compression = thumbnailCompression;
                        fileTypeType = fileType;
                        // mimeType = this.getMimeTypeFromBase64()
                        mimeType = f.type;
                        resolve(continuePr);
                    },
                    error => {
                        reject(error);
                    }
                );
            })
        })).pipe(
            flatMap(({image, orientation}) => {
                return this.compress(image, orientation, fileSelected.type, apply_compression, alt_thumbnail, apply_thumbnail_compression).pipe(
                    map(all => ({...all, fileType: fileTypeType, mimeType: mimeType, file: fileSelected}))
                );
            }),
        )
    }

    public getFullCompressImage(img: string, applyCompression: boolean, altThumbnail: Option<string>, applyThumbnailCompression: boolean) : Observable<{image: Blob, thumbnail: string}> {
        return this.getOrientationFromBase64(img).pipe(
            flatMap(or => {
                const t = this.getMimeTypeFromBase64(img);
                return this.compress(
                    img,
                    or,
                    t,
                    applyCompression,
                    pipe(
                        altThumbnail,
                        O.fold(
                            () => undefined,
                            str => str
                        )
                    ),
                    applyThumbnailCompression
                );
            }),
            flatMap(({image, thumbnail}) => {
                return from(fetch(image)).pipe(
                    flatMap(res => from(res.blob())),
                    map(imgBlob => ({image: imgBlob, thumbnail: thumbnail}))
                    // flatMap(blobImg => this.roomsService.createRoom(this.roomNameInput.nativeElement.value, this.selectedItems, blobImg))
                )
            })
        );
    }

}
