import {createAction, props} from '@ngrx/store';
import {User} from '../../../domain/User';
import {PartialUser} from '../entities/PartialUser';
import {UserStatus} from "../../../domain/UserStatus";


export const UserActions = {
    // GET_USER_SUCCESS: '[User] Get User Success',
    // GET_ALL_USERS_SUCCESS: '[User] Get All Users Success'
    // LOAD_ALL_PARTIAL_USERS: '[User] Load All Partial Users',
    // LOAD_ALL_PARTIAL_USERS_SUCCESS: '[User] Load All Partial Users Success',
    // LOAD_ALL_IMAGE_PROFILES_USERS_WITH_OTO: ' [User] Load All Image Profiles Users With OTO',
    // LOAD_ALL_IMAGE_PROFILES_USERS_WITH_OTO_SUCCESS: '[User] Load All Image Profiles Users With OTO Success',

    ON_NEW_USER: '[User] On New User',
    ON_USER_DELETED: '[User] On User Deleted',
    ON_USER_DELETED_SUCCESS: '[User] On User Deleted Success',

    // GET_IMAGE_PROFILE_USER: '[User] Get Image Profile User',
    // GET_IMAGE_PROFILE_USER_SUCCESS: '[User] Get Image Profile User Success',
    // GET_IMAGE_PROFILE_USER_NOT_FOUND: '[User] Get Image Profile User Not Found',

    ON_MODIFY_USER_STATUS: '[User] On Modify User Status',
    ON_MODIFY_USER_ADDITIONAL_INFO: '[User] On Modify User Additional Info',
    ON_MODIFY_USER_IMAGE: '[User] On Modify User Image',
    ON_MODIFY_USER_NAME: '[User] On Modify User Name',
    ON_MODIFY_USER_SURNAME: '[User] On Modify User Surname'
};


// export const loadAllPartialUsers = createAction(UserActions.LOAD_ALL_PARTIAL_USERS);
// export const loadAllPartialUsersSuccess = createAction(
//     UserActions.LOAD_ALL_PARTIAL_USERS_SUCCESS,
//     props<{partialUsers: PartialUser[]}>()
// );
//
//
// export const loadAllImageProfilesUsersWithOTO = createAction(UserActions.LOAD_ALL_IMAGE_PROFILES_USERS_WITH_OTO);
// export const loadAllImageProfilesUsersWithOTOSuccess = createAction(
//     UserActions.LOAD_ALL_IMAGE_PROFILES_USERS_WITH_OTO_SUCCESS,
//     props<{users: User[]}>()
// );

export const onNewUser = createAction(
    UserActions.ON_NEW_USER,
    props<{partialUser: PartialUser}>()
);

export const onUserDeleted = createAction(
    UserActions.ON_USER_DELETED,
    props<{idUser: number}>()
);

export const onUserDeletedSuccess = createAction(
    UserActions.ON_USER_DELETED_SUCCESS,
    props<{idUser: number}>()
);

// export const getImageProfileUser = createAction(
//     UserActions.GET_IMAGE_PROFILE_USER,
//     props<{idUser: number}>()
// );
// export const getImageProfileUserSuccess = createAction(
//     UserActions.GET_IMAGE_PROFILE_USER_SUCCESS,
//     props<{user: User}>()
// );
// export const getImageProfileUserNotFound = createAction(
//     UserActions.GET_IMAGE_PROFILE_USER_NOT_FOUND
// );

export const onModifyUserStatus = createAction(
    UserActions.ON_MODIFY_USER_STATUS,
    props<{status: UserStatus}>()
);

export const onModifyUserAdditionalInfo = createAction(
    UserActions.ON_MODIFY_USER_ADDITIONAL_INFO,
    props<{idUser: number, additionalInfo: string}>()
);

export const onModifyUserImage = createAction(
    UserActions.ON_MODIFY_USER_IMAGE,
    props<{idUser: number, image: string}>()
);
export const onModifyUserName = createAction(
    UserActions.ON_MODIFY_USER_NAME,
    props<{idUser: number, name: string}>()
);
export const onModifyUserSurname = createAction(
    UserActions.ON_MODIFY_USER_SURNAME,
    props<{idUser: number, surname: string}>()
);

// export const getUserSuccess = createAction(
//     UserActions.GET_USER_SUCCESS,
//     props<{user: User}>()
// );

// export const loadUsers = createAction(UserActions.LOAD_USERS);
// export const loadUsersSuccess = createAction(
//     UserActions.LOAD_USERS_SUCCESS,
//     props<{users: User[]}>()
// );


// export const getAllUsersSuccess = createAction(
//     UserActions.GET_ALL_USERS_SUCCESS,
//     props<{users: User[]}>()
// );
