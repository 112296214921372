import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    onLoadNextPageFiles,
    onLoadNextPageFilesComplete,
    onLoadNextPageFilesFail,
    onLoadNextPageFilesSuccess
} from '../actions/filemessages.actions';
import {catchError, concatMap, flatMap, switchMap} from 'rxjs/operators';
import {RoomsFacade} from '../facade/rooms.facade';
import {RoomType} from '../../../domain/RoomType';
import {MessagesRepository} from '../../../repositories/messages/messages.repository';
import {FileMessagesFacade} from '../facade/file-messages.facade';
import {nilAction} from '../actions/app.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {AppError} from '../../remote/globalmodels/AppError';
import * as E from "fp-ts/lib/Either";
import {Action} from "@ngrx/store";

@Injectable({
    providedIn: 'root'
})
export class FilemessagesEffects {

    onLoadNextPageFilesEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onLoadNextPageFiles),
            concatMap(() => {
                return this.fileMessagesFacade.getNextPageToLoad().pipe(
                    switchMap(({fullPagesLoaded, nextPageToLoad}) => {
                        if (fullPagesLoaded) {
                            return [onLoadNextPageFilesComplete()];
                        } else {
                            return this.roomFacade.getSelectedRoom().pipe(
                                switchMap(([id, type]) => {
                                    if (type === RoomType.OTO) {
                                        return this.messagesRepository.getPageFileMessageOneToOneByIdUserNumberPage(id, nextPageToLoad);
                                    } else {
                                        return this.messagesRepository.getPageFileMessageRoomByIdRoomNumberPage(id, nextPageToLoad);
                                    }
                                }),
                                switchMap(E.fold(
                                    err => [onLoadNextPageFilesFail({appError: err})] as Action[],
                                    fileMessages => [onLoadNextPageFilesSuccess({fileMessages: fileMessages})]
                                ))
                            );
                        }
                    })
                )
            })
        )
    );


    constructor(
        private actions$ : Actions,
        private roomFacade: RoomsFacade,
        private messagesRepository: MessagesRepository,
        private fileMessagesFacade: FileMessagesFacade
    ){}
}
