import {RoomStatusReadedMessages} from "./RoomStatusReadedMessages";


export class RoomGroupStatusReadedMessages extends RoomStatusReadedMessages {
    readonly idRoom: number;


    constructor(messagesToClean: number, idRoom: number) {
        super(messagesToClean);
        this.idRoom = idRoom;
    }
}
