import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {User} from '../../../domain/User';
import {PartialUser} from '../entities/PartialUser';


export function selectUserIdUser(u: PartialUser): number {
    return u.user.idUser;
}


export const adapterUser : EntityAdapter<PartialUser> = createEntityAdapter<PartialUser>({
    selectId: selectUserIdUser
});
