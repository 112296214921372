import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AppStateNamespaceFacade} from "../core/sources/storage/facade/namespaces/app_state_namespace.facade";
import {LocaleService} from "../locales/locale.service";

@Component({
  selector: 'app-button-offlineable',
  templateUrl: './button-offlineable.component.html',
  styleUrls: ['./button-offlineable.component.scss']
})
export class ButtonOfflineableComponent implements OnInit {

    @Input() text: string;
    @Input() classesCSS: string;
    @Input() enabledCondition: boolean;
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private appStateNamespaceFacade: AppStateNamespaceFacade,
    ) { }

    ngOnInit() {
    }

    emitClick($event) {
        this.onClick.emit($event);
    }

    getTextButton() : Observable<string> {
        return this.appStateNamespaceFacade.getAppWithConnectionObservable().pipe(
            map(withConnection => {
                return this.text +
                    (!withConnection ? " (Offline)" : "")
            })
        )
    }

}
