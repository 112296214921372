import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    addUsersToRoom,
    addUsersToRoomFail,
    addUsersToRoomSuccess,
    cleanMessagesNotReadedRoomGroup,
    cleanMessagesNotReadedRoomGroupSuccess,
    cleanMessagesNotReadedRoomOTO,
    cleanMessagesNotReadedRoomOTOSuccess, deleteUsersFromRoomSuccess,
    getUsersRoom,
    getUsersRoomSuccess,
    leaveRoomGroup,
    leaveRoomGroupFail,
    leaveRoomGroupSuccess,
    leaveRoomOTO,
    leaveRoomOTOFail,
    leaveRoomOTOSuccess,
    // loadAllImageRoomsGroups,
    // loadAllImageRoomsGroupsSuccess,
    // loadOneImageRoomGroup,
    // loadOneImageRoomGroupSuccess,
    // loadRoomsAll,
    // loadRoomsAllSuccess,
    modifyRoomInfo, modifyRoomInfoFail, modifyRoomInfoSuccess,
    newRoomGetted,
    onCreateRoom,
    onCreateRoomFail,
    onCreateRoomSuccess,
    onJoinedStatusRoom, onModifyImageRoom, onModifyNameRoom, onModifyStatusRoom,
    onOpenOTO,
    onOpenOTOFail,
    onOpenOTOSuccess, onQuitStatusRoom,
    RoomActions,
    selectRoom,
    selectRoomSuccess, UpdateLastMessageOTOAction, UpdateLastMessageRoomAction
} from '../actions/room.actions';
import {catchError, concatMap, filter, flatMap, map, mergeMap, switchMap, take, tap, toArray} from 'rxjs/operators';
import {RoomsRepository} from '../../../repositories/rooms/rooms.repository';
import {Action, Store} from '@ngrx/store';
import {from, Observable, of, zip} from 'rxjs';
import {PartialRoomGroup} from '../entities/PartialRoomGroup';
import {initialUserRoomGroupState, IRoomGroupState} from '../state/room.state';
import {getRoomsGroupSelect} from '../selectors/rooms.selectors';
import {getRoomGroupWithImageProfileByRoomGroup} from '../mappers/rooms.mappers';
import {nilAction} from '../actions/app.actions';
import {PartialRoomOTO} from '../entities/PartialRoomOTO';
import {RoomsFacade} from '../facade/rooms.facade';
import {
    loadNextPageMessagesRoom,
    onDeleteMessageOTOLocalAction,
    onDeleteMessageRoomLocalAction
} from '../actions/messages.actions';
import {RoomOTO} from '../../../domain/RoomOTO';
// import {getImageProfileUser} from '../actions/user.actions';
import {AppError} from '../../remote/globalmodels/AppError';
import {HttpErrorResponse} from '@angular/common/http';
import {RoomGroupType} from '../../../domain/RoomGroup';
import {joinDiscoverSuccess, quitDiscover, quitDiscoverSuccess} from '../actions/discovers.actions';
import {DiscoversFacade} from '../facade/discovers.facade';
import * as O from "fp-ts/lib/Option";
import {AppFacade} from "../facade/app.facade";
import {pipe} from 'fp-ts/lib/pipeable';
import * as E from "fp-ts/lib/Either";
import * as T from "fp-ts/lib/These";
import {LinkGenerators} from "../../../domain/images/link.generators";
import {MetaFacade} from "../facade/generic/meta.facade";
import {RoomGroupStatusReadedMessages} from "../../../domain/RoomGroupStatusReadedMessages";
import {RoomOTOStatusReadedMessages} from "../../../domain/RoomOTOStatusReadedMessages";
import {MessagesFacade} from "../facade/messages.facade";

@Injectable({
    providedIn: 'root'
})
export class RoomEffects {



    // loadRoomsAllEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(loadRoomsAll),
    //         take(1),
    //         flatMap(_ => this.roomsRepository.getRoomsAll()),
    //         map(([rGr, rOTO]) => loadRoomsAllSuccess({groupRooms: rGr, otoRooms: rOTO}))
    //     )
    // );


    // loadAllImageProfilesRoomGroupsEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(loadAllImageRoomsGroups),
    //         flatMap(_ => this.roomsStore.select(getRoomsGroupSelect).pipe(take(1))),
    //         flatMap(roomsGroups => {
    //             if (roomsGroups.length > 0)
    //                 return from(roomsGroups).pipe(
    //                     flatMap(roomGroup => this.roomsRepository.getImageProfileRoomGroupByIdRoom(roomGroup.roomGroup.idRoom).pipe(
    //                         filter(([blob, exists]) => exists),
    //                         map(([blob, _]) => getRoomGroupWithImageProfileByRoomGroup(roomGroup.roomGroup, blob))
    //                     )),
    //                     toArray(),
    //                     map(roomGroupsWithBlob => loadAllImageRoomsGroupsSuccess({rooms:roomGroupsWithBlob}))
    //                 );
    //             else
    //                 return of(loadAllImageRoomsGroupsSuccess({rooms: []}));
    //         }),
    //
    //     )
    // );

    // loadOneImageRoomGroupEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(loadOneImageRoomGroup),
    //         flatMap(({idRoom}) => this.roomsRepository.getImageProfileRoomGroupByIdRoom(idRoom).pipe(
    //             map(([blob, exists]) => {
    //                 if (exists) {
    //                     return loadOneImageRoomGroupSuccess({idRoom: idRoom, image: blob});
    //                 } else {
    //                     return nilAction();
    //                 }
    //             })
    //         ))
    //     )
    // );


    getUsersRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUsersRoom),
            concatMap(({idRoom}) => this.roomsRepository.getUserRoomListByIdRoom(idRoom).pipe(
                map(E.fold(
                    err => nilAction(), // TODO FER GET_USERS_ROOM_FAIL
                    userRooms => getUsersRoomSuccess({idRoom: idRoom, usersRoom: userRooms, dateLoaded: new Date()})
                ))
                // map(userRooms => getUsersRoomSuccess({idRoom: idRoom, usersRoom: userRooms}))
            )),
        )
    );

    selectRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectRoom),
            concatMap(({idRoom, idUser}) => {
                if (idRoom !== undefined) {
                    return this.roomsFacade.getRoomByIdRoomOption(idRoom).pipe(
                        switchMap(O.fold(
                            () => [] as Action[],
                            r => [
                                selectRoomSuccess({idRoom: idRoom, idUser:undefined}),
                                cleanMessagesNotReadedRoomGroup({entity: new RoomGroupStatusReadedMessages(r.roomGroup.messageCount, idRoom)}),
                            ]
                        ))
                    )
                } else {
                    return this.roomsFacade.getOTOByIdUser(idUser).pipe(
                        switchMap(O.fold(
                            () => [] as Action[],
                            r => [
                                selectRoomSuccess({idRoom: undefined, idUser:idUser}),
                                cleanMessagesNotReadedRoomOTO({entity: new RoomOTOStatusReadedMessages(r.roomOTO.messageCount, idUser)}),
                            ]
                        ))
                    )
                }
            })
        )
    );


    onOpenOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onOpenOTO),
            concatMap(({idUser}) => this.roomsRepository.openOneToOneByIdUser(idUser).pipe(
                switchMap(E.fold(
                    err => [onOpenOTOFail({appError: err})] as Action[],
                    _ => [
                        // getImageProfileUser({idUser}),
                        onOpenOTOSuccess({newOTO: new PartialRoomOTO(
                            new RoomOTO(
                                new Date(),
                                0,
                                undefined,
                                idUser,
                                new Date(),
                                new Date()
                            ),
                            0,
                            0,
                            true,
                            ""
                        )})
                    ]
                ))
            )),

        )
    );

    onOpenOTOSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onOpenOTOSuccess),
            map(({newOTO}) => selectRoom({idRoom: undefined, idUser: newOTO.roomOTO.idUser}))
            // seleccionar room!!
            // falta fer la part de sockets quan es rep un missatge d'un usuari que no es té
        )
    );

    cleanMessagesRoomGroupEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(cleanMessagesNotReadedRoomGroup),
            concatMap(({entity}) => this.roomsRepository.readMessagesRoomGroupByIdRoom(entity.idRoom, new Date(), entity.messagesToClean)),
                // map(E.fold(
                    // err => nilAction(), // TODO FER CLEAN_MESSAGES_NOT_READED_ROOM_GROUP_FAIL
                    // _ => cleanMessagesNotReadedRoomGroupSuccess({idRoom:idRoom}),
                    // _ => cleanMessagesNotReadedRoomGroupSuccess({idRoom:idRoom})
                // ))
            map(_ => nilAction())
        )
    );

    cleanMessagesRoomOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(cleanMessagesNotReadedRoomOTO),
            concatMap(({entity}) => this.roomsRepository.readMessagesRoomOTOByIdUser(entity.idUser, new Date(), entity.messagesToClean)),
                // map(E.fold(
                //     // err => nilAction(), // TODO FER CLEAN_MESSAGES_NOT_READED_ROOM_OTO_FAIL
                //     err => cleanMessagesNotReadedRoomOTOSuccess({idUser: idUser}),
                //     _ => cleanMessagesNotReadedRoomOTOSuccess({idUser: idUser})
                // ))
                // map(_ => cleanMessagesNotReadedRoomOTOSuccess({idUser: idUser})),
            map(_ => nilAction())
        )
    );

    onJoinedRoomStatusEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onJoinedStatusRoom),
            concatMap(({roomStatus}) => this.roomsFacade.existsRoomGroupByIdRoom(roomStatus.idRoom).pipe(
                switchMap(exists => {
                    if (!exists) {
                        // this.discoversFacade.getDiscoverByIdRoom(roomStatus.idRoom)
                        return this.roomsRepository.getRoomByIdRoom(roomStatus.idRoom).pipe(
                            switchMap(E.fold(
                                error => of({}).pipe(
                                    switchMap(_ => [nilAction()]) // TODO FER ON_JOINED_STATUS_ROOM_FAIL
                                ),
                                room => of(new PartialRoomGroup(
                                    room,
                                    0,
                                    0,
                                    false,
                                    initialUserRoomGroupState,
                                    ""
                                )).pipe(
                                    switchMap(room => this.discoversFacade.getDiscoverByIdRoomOption(room.roomGroup.idRoom).pipe(
                                        switchMap(O.fold(
                                            () =>  [newRoomGetted({newRoom: room})],
                                            discover => [
                                                joinDiscoverSuccess({room: room.roomGroup, idDiscover: discover.idDiscover}),
                                                newRoomGetted({newRoom: room})
                                            ]
                                        ))
                                    ))
                                )
                            ))
                            // map(room => new PartialRoomGroup(
                            //     room,
                            //     false,
                            //     0,
                            //     0,
                            //     false,
                            //     initialUserRoomGroupState)
                            // ),
                            // assumim que sempre tenim tots els discovers
                            // no fa falta incloure l'usuari perquè s'obtindràn quan es faci click

                        );
                    } else {
                        return of(addUsersToRoomSuccess({idRoom: roomStatus.idRoom, idUsers: [roomStatus.from]}));
                    }
                })
            ))
        )
    );

    onQuitRoomStatusEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onQuitStatusRoom),
            concatMap(({roomStatus}) => this.roomsFacade.getRoomByIdRoomOption(roomStatus.idRoom).pipe(
                switchMap(O.fold(
                    () => of(nilAction()),
                    // ets tú el target? llavors ->
                    //      elimina room
                    //      - si la room és discover, també elimina discover
                    // no ets tú el target? llavors ->
                    //      crida deleteUsersToRoomSuccess
                    partialRoomGroup => this.metaFacade.getOwnDataUser().pipe(
                        switchMap(ownData => {
                            if (ownData.idUser === roomStatus.from) {
                                return this.discoversFacade.getDiscoverByIdRoomOption(roomStatus.idRoom).pipe(
                                    switchMap(O.fold(
                                        () => [leaveRoomGroupSuccess({idRoom: roomStatus.idRoom})],
                                        discover => [
                                            quitDiscoverSuccess({idDiscover: discover.idDiscover, idRoom: roomStatus.idRoom}),
                                            leaveRoomGroupSuccess({idRoom: roomStatus.idRoom})
                                        ]
                                    ))
                                )
                            } else {
                                return [deleteUsersFromRoomSuccess({idRoom: roomStatus.idRoom, idUsers: [roomStatus.from]})]
                            }
                        })
                    )
                ))
            ))
        )
    );

    onNewRoomGettedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(newRoomGetted),
            concatMap(({newRoom}) => [
                loadNextPageMessagesRoom({idRoom: newRoom.roomGroup.idRoom, nbPages: 2}),
                // loadOneImageRoomGroup({idRoom: newRoom.roomGroup.idRoom}),
            ])
        )
    );


    onCreateRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onCreateRoom),
            concatMap(({name, idUsers, image}) => this.roomsRepository.createRoomSetImageAndAddUsers(name, "", image, idUsers).pipe(
                // catchError(err => {
                //     console.log({text: "error catched create room", error: err});
                //     if (err instanceof HttpErrorResponse) {
                //         return of(new AppError(JSON.stringify(err.error)));
                //         // return of(err.error as AppError);
                //     }
                //     throw err;
                // }),
                map(E.fold(
                    err => onCreateRoomFail({appError: err}),
                    _ => onCreateRoomSuccess()
                ))
                // map(res => {
                //     console.log({text: "response getted from server", res: res});
                //     if (res instanceof AppError) {
                //         console.log("ENTRAAAA");
                //         return onCreateRoomFail({appError: res});
                //     } else
                //         return ;
                // })
            )),

        )
    );

    addUsersToRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addUsersToRoom),
            concatMap(({idRoom, idUsers}) => this.roomsRepository.addUsersToRoomByIdRoom(idRoom, idUsers).pipe(
                // catchError(err => {
                //     if (err instanceof HttpErrorResponse) {
                //         return of(new AppError(JSON.stringify(err.error)));
                //     }
                //     throw err;
                // }),
                map(E.fold(
                    err => addUsersToRoomFail({appError: err}) as Action,
                    _ => addUsersToRoomSuccess({idRoom: idRoom, idUsers: idUsers})
                ))
                // map(res => {
                //     if (res instanceof AppError) {
                //         return ;
                //     } else {
                //         return addUsersToRoomSuccess({idRoom: idRoom, idUsers: idUsers});
                //     }
                // })
            )),

        )
    );

    leaveRoomGroupEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(leaveRoomGroup),
            concatMap(({idRoom}) => this.roomsFacade.getRoomByIdRoomObservable(idRoom).pipe(
                take(1),
                switchMap(room => {
                    if (room.roomGroup.typeRoom === RoomGroupType.DISCOVER) {
                        return this.discoversFacade.getDiscoverByIdRoom(idRoom).pipe(
                            switchMap(d => [quitDiscover({idDiscover: d.idDiscover})])
                        );
                    } else {
                        return this.roomsRepository.leaveRoomGroupByIdRoom(idRoom).pipe(
                            // catchError(err => {
                            //     if (err instanceof HttpErrorResponse) {
                            //         return of(new AppError(JSON.stringify(err.error)));
                            //     }
                            //     throw err;
                            // }),
                            map(E.fold(
                                err => leaveRoomGroupFail({appError: err}) as Action,
                                _ => leaveRoomGroupSuccess({idRoom: idRoom})
                            ))
                            // map(res => {
                            //     if (res instanceof AppError) {
                            //         return leaveRoomGroupFail({appError: res});
                            //     } else {
                            //         return leaveRoomGroupSuccess({idRoom: idRoom});
                            //     }
                            // })
                        )
                    }
                })
            ))
        )
    );


    leaveRoomOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(leaveRoomOTO),
            concatMap(({idUser}) => this.roomsRepository.clearOneToOneConversationByIdUser(idUser).pipe(
                // catchError(err => {
                //     if (err instanceof HttpErrorResponse) {
                //         return of(new AppError(JSON.stringify(err.error)));
                //     }
                //     throw err;
                // }),
                map(E.fold(
                    err => leaveRoomOTOFail({appError: err}) as Action,
                    _ => leaveRoomOTOSuccess({idUser: idUser})
                ))
                // map(res => {
                //     console.log({text: "mapped of leave room", res: res});
                //     if (res instanceof AppError) {
                //         return leaveRoomOTOFail({appError: res});
                //     } else {
                //         return leaveRoomOTOSuccess({idUser: idUser});
                //     }
                // })
            ))
        )
    );

    modifyRoomInfoEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(modifyRoomInfo),
            concatMap(({idRoom, info}) => this.roomsRepository.modifyRoomInfo(idRoom, info).pipe(
                // catchError(err => {
                //     if (err instanceof HttpErrorResponse) {
                //         return of(new AppError(JSON.stringify(err.error)));
                //     }
                //     throw err;
                // }),
                switchMap(E.fold(
                    err => of(modifyRoomInfoFail({error: err})) as Observable<Action>,
                    _ => this.linkGenerators.getRoomImageLinkFromIdRoom(idRoom).pipe(
                        map(link => {
                            const th = pipe(
                                info,
                                T.fold(
                                    nameDesc => T.left(nameDesc),
                                    _ => T.right(link),
                                    (nameDesc, _) => T.both(nameDesc, link)
                                )
                            );
                            return modifyRoomInfoSuccess({idRoom: idRoom, info: th})
                        })
                    )
                ))
                // map(res => {
                //     if (res instanceof AppError) {
                //         return modifyRoomInfoFail({error: res});
                //     } else {
                //         return modifyRoomInfoSuccess({idRoom: idRoom, info});
                //     }
                // })
            ))
        )
    );

    onModifyStatusRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onModifyStatusRoom),
            concatMap(({status}) => {
                const obs1 = pipe(
                    status.fields.name,
                    O.fold(
                        () => of(nilAction()),
                        name => of(onModifyNameRoom({idRoom: status.idRoom, name: name}))
                    )
                );
                const obs2 = pipe(
                    status.fields.image,
                    O.fold(
                        () => of(nilAction()),
                        _ => this.roomsRepository.getImageProfileRoomGroupByIdRoom(status.idRoom).pipe(
                            switchMap(O.fold(
                                () => of(nilAction()),
                                image => this.linkGenerators.getRoomImageLinkFromIdRoom(status.idRoom).pipe(
                                    map(link => onModifyImageRoom({idRoom: status.idRoom, image: link}))
                                )
                            ))
                        )
                    )
                );
                return zip(obs1, obs2).pipe(
                    switchMap(acts => acts)
                );
            })
        )
    );

    UpdateLastMessageRoomEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onDeleteMessageRoomLocalAction),
            switchMap(({idRoom}) => this.updateLastMessageRoom(idRoom))
        )
    )

    private updateLastMessageRoom(idRoom: number) : Observable<Action> {
        return this.messagesFacade.getLastMessageRoom(idRoom).pipe(
            switchMap(m => [UpdateLastMessageRoomAction({message: m, idRoom: idRoom})])
            // switchMap(O.fold(
            //     () => [nilAction() as Action],
            //     m => [UpdateLastMessageRoomAction({message: m})]
            // ))
        )
    }

    UpdateLastMessageOTOEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onDeleteMessageOTOLocalAction),
            switchMap(({idUser}) => this.updateLastMessageOTO(idUser))
        )
    )

    private updateLastMessageOTO(idUserOTO: number) : Observable<Action> {
        return this.messagesFacade.getLastMessageOTO(idUserOTO).pipe(
            switchMap(m => [UpdateLastMessageOTOAction({message: m, idUserOTO: idUserOTO})])
            // switchMap(O.fold(
            //     () => [nilAction() as Action],
            //     m => [UpdateLastMessageOTOAction({message: m})]
            // ))
        )
    }

    // @Inject()
    constructor(
        private actions$ : Actions,
        // private roomsApiHelper: RoomsApihelper
        private roomsRepository: RoomsRepository,
        private roomsStore: Store<IRoomGroupState>,
        private roomsFacade: RoomsFacade,
        private discoversFacade: DiscoversFacade,
        private appFacade: AppFacade,
        private linkGenerators: LinkGenerators,
        private metaFacade: MetaFacade,
        private messagesFacade: MessagesFacade
        // private store: Store<IAppState>
    ) {

    }

}
