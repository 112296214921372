import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AppStateNamespaceFacade} from "../../facade/namespaces/app_state_namespace.facade";
import {
    UserValidationCheckTokenAction, UserValidationEndUserValidationAction, UserValidationRenewTokenAction,
    UserValidationSetActiveUserValidationProcessAction,
    UserValidationStartUserValidationAction, UserValidationWaitProcessAction
} from "../../actions/principal_screen_app/user_validation.actions";
import {delay, endWith, map, switchMap} from "rxjs/operators";
import {logout, nilAction} from "../../actions/app.actions";
import {of, zip} from "rxjs";
import {MetaRepository} from "../../../../repositories/meta/meta.repository";
import {HttpHeadersManager} from "../../../remote/core/headers/HttpHeadersManager";
import * as E from "fp-ts/lib/Either";
import {Action} from "@ngrx/store";
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import {ReloadAppAction} from "../../actions/app_with_connection/meta.actions";


@Injectable({
    providedIn: 'root'
})
export class UserValidationEffects {


    startUserValidationEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserValidationStartUserValidationAction),
            switchMap(_ => this.appStateNamespaceFacade.getActiveProcessUserValidation()),
            switchMap(active => {
                if (active) return [nilAction()];
                return [
                    UserValidationSetActiveUserValidationProcessAction({active: true}),
                    // UserValidationCheckConnectionAction()
                    UserValidationCheckTokenAction()
                ];
            })
        )
    );

    // checkConnectionEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(UserValidationCheckConnectionAction),
    //         switchMap(_ => this.appStateNamespaceFacade.getAppWithConnection()),
    //         switchMap(connection => {
    //             if (!connection) return [UserValidationEndUserValidationAction()];
    //             return [UserValidationCheckTokenAction()]
    //         })
    //     )
    // );

    waitProcessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserValidationWaitProcessAction),
            delay(60000),
            // switchMap(_ => [UserValidationCheckConnectionAction()])
            switchMap(_ => [UserValidationCheckTokenAction()])
        )
    );

    checkTokenEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserValidationCheckTokenAction),
            switchMap(_ => this.appStateNamespaceFacade.getAppWithConnection()),
            switchMap(connection => {
                if (!connection) return [UserValidationEndUserValidationAction()];
                return this.metaRepository.checkToken().pipe(
                    switchMap(E.fold(
                        err => [
                            UserValidationEndUserValidationAction(),
                            logout()
                        ],
                        token => [
                            UserValidationWaitProcessAction(),
                            UserValidationRenewTokenAction({token: token})
                        ]
                    ))
                );
                // return [UserValidationCheckTokenAction()]
            }),
            // switchMap()
        )
    );

    renewTokenEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserValidationRenewTokenAction),
            switchMap(({token}) => pipe(
                token,
                O.fold(
                    () => of([nilAction()]).pipe(switchMap(e => e)),
                    token => this.httpHeadersManager.setLoggedState(token).pipe(
                        endWith({}),
                        switchMap(_ => [
                            UserValidationEndUserValidationAction(),
                            ReloadAppAction()
                        ])
                    )
                )
            ))
        )
    );

    endUserValidationEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserValidationEndUserValidationAction),
            switchMap(_ => [UserValidationSetActiveUserValidationProcessAction({active: false})])
        )
    );


    constructor(
        private actions$: Actions,
        private appStateNamespaceFacade: AppStateNamespaceFacade,
        private metaRepository: MetaRepository,
        private httpHeadersManager: HttpHeadersManager
    ) {}
}
