import {createAction, props} from "@ngrx/store";
import {OwnDataUser} from "../../../../domain/OwnDataUser";
import {Option} from "fp-ts/lib/Option";

const TAG = '[Generic Meta Actions] ';

export const GenericMetaActions = {
    SAVE_PERSONAL_INFO: TAG + 'Save Personal Info', // <- mai falla, per tant, el success pot activar LOAD_OPEN_SOCKET
};

export const SavePersonalInfoAction = createAction(
    GenericMetaActions.SAVE_PERSONAL_INFO,
    props<{info: OwnDataUser, image: Option<Blob>}>()
);
