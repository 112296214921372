import {Action, createAction, props} from '@ngrx/store';
import {RoomGroup} from '../../../domain/RoomGroup';
import {PartialRoomGroup} from '../entities/PartialRoomGroup';
import {UserRoom} from '../../../domain/UserRoom';
import {PartialRoomOTO} from '../entities/PartialRoomOTO';
import {RoomStatusJoined, RoomStatusModified, RoomStatusQuit} from '../../../domain/RoomStatus';
import {AppError} from '../../remote/globalmodels/AppError';
import {Option} from "fp-ts/lib/Option";
import {Either} from "fp-ts/lib/Either";
import {These} from "fp-ts/lib/These";
import {StandardError} from "../../../domain/StandardError";
import {RoomGroupStatusReadedMessages} from "../../../domain/RoomGroupStatusReadedMessages";
import {RoomOTOStatusReadedMessages} from "../../../domain/RoomOTOStatusReadedMessages";
import {RoomStatusAdmins} from "../../../domain/RoomStatusAdmins";
import {MessageRoom} from "../../../domain/MessageRoom";
import {MessageOTO} from "../../../domain/MessageOTO";


export const RoomActions = {

    // LOAD_ROOMS_ALL: '[Room] Load Rooms All',
    // LOAD_ROOMS_ALL_SUCCESS: '[Room] Load Rooms All Success',

    // LOAD_ALL_IMAGE_ROOMS_GROUPS: '[Room Group] Load All Image Room Groups',
    // LOAD_ALL_IMAGE_ROOMS_GROUPS_SUCCESS: '[Room Group] Load All Image Room Groups Success',

    // LOAD_ONE_IMAGE_ROOM_GROUP: '[Room Group] Load One Image Room Group',
    // LOAD_ONE_IMAGE_ROOM_GROUP_SUCCESS: '[Room Group] Load One Image Room Group Success',

    SELECT_ROOM: '[Room] Select Room',
    SELECT_ROOM_SUCCESS: '[Room] Select Room Success',

    GET_USERS_ROOM: '[Room Group] Get Users Room',
    GET_USERS_ROOM_SUCCESS: '[Room Group] Get Users Room Success',
    GET_USERS_UPDATE_ROOM_SUCCESS: '[Room Group] Get Users Update Room Success',

    CLEAN_MESSAGES_NOT_READED_ROOM_GROUP: '[Room Group] Clean Messages Not Readed Room Group',
    CLEAN_MESSAGES_NOT_READED_ROOM_GROUP_SUCCESS: "[Room Group] Clean Messages Not Readed Room Group Success",

    ADD_USERS_TO_ROOM: '[Room Group] Add Users To Room',
    ADD_USERS_TO_ROOM_SUCCESS: '[Room Group] Add Users To Room Success',
    ADD_USERS_TO_ROOM_FAIL: '[Room Group] Add Users To Room Fail',

    DELETE_USERS_FROM_ROOM_SUCCESS: '[Room Group] Delete Users From Room Success',

    CHANGE_DATA_GROUP: '[Room Group] Change Data Group',
    CHANGE_DATA_GROUP_SUCCESS: '[Room Group] Change Data Group Success',
    CHANGE_DATA_GROUP_FAIL: '[Room Group] Change Data Group Fail',

    LEAVE_ROOM_GROUP: '[Room Group] Leave Room Group',
    LEAVE_ROOM_GROUP_SUCCESS: '[Room Group] Leave Room Group Success',
    LEAVE_ROOM_GROUP_FAIL: '[Room Group] Leave Room Group Fail',

    INCREASE_MESSAGES_NOT_READED_BY_ONE_ROOM_GROUP: '[Room Group] Increase Messages Not Readed By One Room Group',

    CLEAN_MESSAGES_NOT_READED_ROOM_OTO: '[Room OTO] Clean Messages Not Readed Room OTO',
    CLEAN_MESSAGES_NOT_READED_ROOM_OTO_SUCCESS: '[Room OTO] Clean Messages Not Readed Room OTO Success',


    ON_JOINED_STATUS_ROOM: '[Room Group] On Joined Status Room',
    ON_JOINED_STATUS_ROOM_LOADING: '[Room Group] On Joined Status Room Loading',
    NEW_ROOM_GETTED: '[Room Group] On New Room Getted',

    ON_QUIT_STATUS_ROOM: '[Room Group] On Quit Status Room',
    ON_QUIT_USER_ROOM: '[Room Group] On Quit User Room',


    ON_CREATE_ROOM: '[Room Group] On Create Room',
    ON_CREATE_ROOM_SUCCESS: '[Room Group] On create Room Success',
    ON_CREATE_ROOM_FAIL: '[Room Group] On Create Room Fail',

    ON_OPEN_OTO: '[Room OTO] On Open OTO',
    ON_OPEN_OTO_SUCCESS: '[Room OTO] On Open OTO Success',
    ON_OPEN_OTO_FAIL: '[Room OTO] On Open OTO Fail',

    LEAVE_ROOM_OTO: '[Room OTO] Leave Room OTO',
    LEAVE_ROOM_OTO_SUCCESS: '[Room OTO] Leave Room OTO Success',
    LEAVE_ROOM_OTO_FAIL: '[Room OTO] Leave Room OTO Fail',

    INCREASE_MESSAGES_NOT_READED_BY_ONE_ROOM_OTO: '[Room OTO] Increase Messages Not Readed By One Room OTO',

    MODIFY_ROOM_INFO: '[Room Group] Modify Room Info',
    MODIFY_ROOM_INFO_SUCCESS: '[Room Group] Modify Room Info Success',
    MODIFY_ROOM_INFO_FAIL: '[Room Group] Modify Room Info Fail',

    ON_MODIFY_STATUS_ROOM: '[Room Group] On Modify Status Room',
    ON_MODIFY_NAME_ROOM: '[Room Group] On Modify Name Room',
    ON_MODIFY_IMAGE_ROOM: '[Room Group] On Modify Image Room',

    ON_MODIFY_ADMINS_ROOM: '[Room Group] On Modify Admins Room',


    UPDATE_LAST_MESSAGE_ROOM: '[Room Group] Update Last Message Room',
    UPDATE_LAST_MESSAGE_OTO: '[Room OTO] Update Last Message OTO',

    ON_MESSAGE_INPUT_UPDATED_ROOM : '[Room Group] On Message Input Updated Room',
    ON_MESSAGE_INPUT_UPDATED_OTO: '[Room OTO] On Message Input Updated OTO'
    // ON_ONE_TO_ONE_DELETED: '[Room OTO] On One To One Deleted'
};


export const modifyRoomInfo = createAction(
    RoomActions.MODIFY_ROOM_INFO,
    props<{idRoom: number, info: These<{name: string, description: string}, Blob>}>()
);

export const modifyRoomInfoSuccess = createAction(
    RoomActions.MODIFY_ROOM_INFO_SUCCESS,
    props<{idRoom: number, info: These<{name: string, description: string}, string>}>()
);

export const modifyRoomInfoFail = createAction(
    RoomActions.MODIFY_ROOM_INFO_FAIL,
    props<{error: StandardError}>()
);


export const increaseMessagesNotReadedByOneRoomGroup = createAction(
    RoomActions.INCREASE_MESSAGES_NOT_READED_BY_ONE_ROOM_GROUP,
    props<{idRoom: number}>()
);
export const increaseMessagesNotReadedByOneRoomOTO = createAction(
    RoomActions.INCREASE_MESSAGES_NOT_READED_BY_ONE_ROOM_OTO,
    props<{idUser: number}>()
);


export const addUsersToRoom = createAction(
    RoomActions.ADD_USERS_TO_ROOM,
    props<{idRoom: number, idUsers: number[]}>()
);
export const addUsersToRoomSuccess = createAction(
    RoomActions.ADD_USERS_TO_ROOM_SUCCESS,
    props<{idRoom: number, idUsers: number[]}>()
);
export const addUsersToRoomFail = createAction(
    RoomActions.ADD_USERS_TO_ROOM_FAIL,
    props<{appError: StandardError}>()
);

export const deleteUsersFromRoomSuccess = createAction(
    RoomActions.DELETE_USERS_FROM_ROOM_SUCCESS,
    props<{idRoom: number, idUsers: number[]}>()
);


export const changeDataGroup = createAction(
    RoomActions.CHANGE_DATA_GROUP,
    props<{idRoom: number, nameRoom: string, image: Option<Blob>}>()
);
export const changeDataGroupSuccess = createAction(
    RoomActions.CHANGE_DATA_GROUP_SUCCESS
);
export const changeDataGroupFail = createAction(
    RoomActions.CHANGE_DATA_GROUP_FAIL,
    props<{appError: StandardError}>()
);


export const leaveRoomGroup = createAction(
    RoomActions.LEAVE_ROOM_GROUP,
    props<{idRoom: number}>()
);
export const leaveRoomGroupSuccess = createAction(
    RoomActions.LEAVE_ROOM_GROUP_SUCCESS,
    props<{idRoom: number}>()
);
export const leaveRoomGroupFail = createAction(
    RoomActions.LEAVE_ROOM_GROUP_FAIL,
    props<{appError: StandardError}>()
);


// export const loadOneImageRoomGroup = createAction(
//     RoomActions.LOAD_ONE_IMAGE_ROOM_GROUP,
//     props<{idRoom: number}>()
// );
// export const loadOneImageRoomGroupSuccess = createAction(
//     RoomActions.LOAD_ONE_IMAGE_ROOM_GROUP_SUCCESS,
//     props<{idRoom: number, image: Blob}>()
// );

// export const getRooms = createAction(RoomActions.GET_ROOMS);
// export const getRoomsSuccess = createAction(
//     RoomActions.GET_ROOMS_SUCCESS,
//     props<{groupRooms: RoomGroup[]}>()
// );

export const cleanMessagesNotReadedRoomGroup = createAction(
    RoomActions.CLEAN_MESSAGES_NOT_READED_ROOM_GROUP,
    props<{entity: RoomGroupStatusReadedMessages}>()
);
export const cleanMessagesNotReadedRoomGroupSuccess = createAction(
    RoomActions.CLEAN_MESSAGES_NOT_READED_ROOM_GROUP_SUCCESS,
    props<{entity: RoomGroupStatusReadedMessages}>()
);


export const cleanMessagesNotReadedRoomOTO = createAction(
    RoomActions.CLEAN_MESSAGES_NOT_READED_ROOM_OTO,
    props<{entity: RoomOTOStatusReadedMessages}>()
);
export const cleanMessagesNotReadedRoomOTOSuccess = createAction(
    RoomActions.CLEAN_MESSAGES_NOT_READED_ROOM_OTO_SUCCESS,
    props<{entity: RoomOTOStatusReadedMessages}>()
);


// export const loadRoomsGroups = createAction(RoomActions.LOAD_ROOMS_GROUPS);
// export const loadRoomsGroupsSuccess = createAction(
//     RoomActions.LOAD_ROOMS_GROUPS_SUCCESS,
//     props<{groupRooms: PartialRoomGroup[]}>()
// );


// export const getOneToOnes = createAction(RoomActions.GET_ONETOONES);
// export const getOneToOnesSuccess = createAction(
//     RoomActions.GET_ONETOONES_SUCCESS,
//     props<{otoRooms: RoomOTO[]}>()
// );

// export const loadRoomsOtos = createAction(RoomActions.LOAD_ROOMS_OTOS);
// export const loadRoomsOtosSuccess = createAction(
//     RoomActions.LOAD_ROOMS_OTOS_SUCCESS,
//     props<{otoRooms: PartialRoomOTO[]}>()
// );


// export const loadRoomsAll = createAction(
//     RoomActions.LOAD_ROOMS_ALL
// );
// export const loadRoomsAllSuccess = createAction(
//     RoomActions.LOAD_ROOMS_ALL_SUCCESS,
//     props<{otoRooms: PartialRoomOTO[], groupRooms: PartialRoomGroup[]}>()
// );


// export const loadAllImageRoomsGroups = createAction(RoomActions.LOAD_ALL_IMAGE_ROOMS_GROUPS);
// export const loadAllImageRoomsGroupsSuccess = createAction(
//     RoomActions.LOAD_ALL_IMAGE_ROOMS_GROUPS_SUCCESS,
//     props<{rooms: RoomGroup[]}>()
// );


export const selectRoom = createAction(
    RoomActions.SELECT_ROOM,
    props<{idRoom:number|undefined,idUser:number|undefined}>()
);

export const selectRoomSuccess = createAction(
    RoomActions.SELECT_ROOM_SUCCESS,
    props<{idRoom:number|undefined,idUser:number|undefined}>()
);

export const getUsersRoom = createAction(
    RoomActions.GET_USERS_ROOM,
    props<{idRoom: number}>()
);

export const getUsersRoomSuccess = createAction(
    RoomActions.GET_USERS_ROOM_SUCCESS,
    props<{idRoom: number, usersRoom: UserRoom[], dateLoaded: Date}>()
);

// export const getUsersUpdateRoomSuccess = createAction(
//     RoomActions.GET_USERS_UPDATE_ROOM_SUCCESS,
//     props<{idRoom: number, usersRoom: UserRoom[], deleted: number[], dateLoaded: Date}>()
// );


export const onJoinedStatusRoom = createAction(
    RoomActions.ON_JOINED_STATUS_ROOM,
    props<{roomStatus: RoomStatusJoined}>()
);

export const newRoomGetted = createAction(
    RoomActions.NEW_ROOM_GETTED,
    props<{newRoom: PartialRoomGroup}>()
);

export const onQuitStatusRoom = createAction(
    RoomActions.ON_QUIT_STATUS_ROOM,
    props<{roomStatus: RoomStatusQuit}>()
);

export const onQuitUserRoom = createAction(
    RoomActions.ON_QUIT_USER_ROOM,
    props<{idRoom: number, idUser: number}>()
);

export const onCreateRoom = createAction(
    RoomActions.ON_CREATE_ROOM,
    props<{name: string, idUsers: number[], image: Option<Blob>}>()
);

export const onCreateRoomSuccess = createAction(
    RoomActions.ON_CREATE_ROOM_SUCCESS,
);

export const onCreateRoomFail = createAction(
    RoomActions.ON_CREATE_ROOM_FAIL,
    props<{appError: StandardError}>()
);

export const onOpenOTO = createAction(
    RoomActions.ON_OPEN_OTO,
    props<{idUser: number}>()
);

export const onOpenOTOSuccess = createAction(
    RoomActions.ON_OPEN_OTO_SUCCESS,
    props<{newOTO: PartialRoomOTO}>()
);

export const onOpenOTOFail = createAction(
    RoomActions.ON_OPEN_OTO_FAIL,
    props<{appError: StandardError}>()
);

export const leaveRoomOTO = createAction(
    RoomActions.LEAVE_ROOM_OTO,
    props<{idUser: number}>()
);

export const leaveRoomOTOSuccess = createAction(
    RoomActions.LEAVE_ROOM_OTO_SUCCESS,
    props<{idUser: number}>()
);

export const leaveRoomOTOFail = createAction(
    RoomActions.LEAVE_ROOM_OTO_FAIL,
    props<{appError: StandardError}>()
);

export const onModifyStatusRoom = createAction(
    RoomActions.ON_MODIFY_STATUS_ROOM,
    props<{status: RoomStatusModified}>()
);

export const onModifyNameRoom = createAction(
    RoomActions.ON_MODIFY_NAME_ROOM,
    props<{idRoom: number, name: string}>()
);

export const onModifyImageRoom = createAction(
    RoomActions.ON_MODIFY_IMAGE_ROOM,
    props<{idRoom: number, image: string}>()
);

export const OnModifyAdminsRoomAction = createAction(
    RoomActions.ON_MODIFY_ADMINS_ROOM,
    props<{status: RoomStatusAdmins}>()
);

export const UpdateLastMessageRoomAction = createAction(
    RoomActions.UPDATE_LAST_MESSAGE_ROOM,
    props<{message: Option<MessageRoom>, idRoom: number}>()
);

export const UpdateLastMessageOTOAction = createAction(
    RoomActions.UPDATE_LAST_MESSAGE_OTO,
    props<{message: Option<MessageOTO>, idUserOTO: number}>()
);


export const OnMessageInputUpdatedRoomAction = createAction(
    RoomActions.ON_MESSAGE_INPUT_UPDATED_ROOM,
    props<{message: string, idRoom: number}>()
);

export const OnMessageInputUpdatedOTOAction = createAction(
    RoomActions.ON_MESSAGE_INPUT_UPDATED_OTO,
    props<{message: string, idUserOTO: number}>()
)
// export const OnOneToOneDeleteAction = createAction(
//     RoomActions.ON_ONE_TO_ONE_DELETED,
//     props<{idUser: number}>()
// );
// ON_MODIFY_ADMINS_ROOM: '[Room Group] On Modify Admins Room',
//     ON_ONE_TO_ONE_DELETED: '[Room OTO] On One To One Deleted'

// export const leaveRoomGroup = createAction(
//     RoomActions.LEAVE_ROOM_GROUP,
//     props<{idRoom: number}>()
// );
// export const leaveRoomGroupSuccess = createAction(
//     RoomActions.LEAVE_ROOM_GROUP_SUCCESS,
//     props<{idRoom: number}>()
// );
// export const leaveRoomGroupFail = createAction(
//     RoomActions.LEAVE_ROOM_GROUP_FAIL,
//     props<{appError: AppError}>()
// );

// export const loadAllImageRoomsGroups = createAction(RoomActions.)

// export const getRooms = createAction()

// export type RoomActions = GetRooms | GetRoomsSuccess | GetRoom | GetRoomSuccess;
