import {Discover} from "../../domain/Discover";
import {Room} from "../../domain/Room";
import {RoomGroup} from "../../domain/RoomGroup";
import {Option} from "fp-ts/lib/Option";
import {IDiscoverApiModel} from "../../sources/remote/discovers/models/IDiscoverApiModel";
import {IRoomAlllistRoomApiModel} from "../../sources/remote/rooms/models/IRoomAlllistRoomApiModel";


export class NewDiscoverSocketModel {

    readonly discover: IDiscoverApiModel;
    readonly room: Option<IRoomAlllistRoomApiModel>;


    constructor(discover: IDiscoverApiModel, room: Option<IRoomAlllistRoomApiModel>) {
        // this.discover = discover;
        this.discover = discover;
        this.room = room;
    }
}
