import {Action, createReducer, on} from "@ngrx/store";
import {initialMetaState, MetaState} from "../../state/generic/meta.state";
import {SavePersonalInfoAction} from "../../actions/generic/meta.actions";
import {changeOwnDataSuccess} from "../../actions/app.actions";
import {OwnDataUser} from "../../../../domain/OwnDataUser";
import {pipe} from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";


const metaReducer = createReducer(
    initialMetaState,
    on(SavePersonalInfoAction, (state, {info, image}) => ({
        ...state,
        ownDataUser: info,
        ownImageProfile: image
    })),
    on(changeOwnDataSuccess, (state, {bio, image, name, surname, emailContact, emailNotifications}) => ({
        ...state,
        ownDataUser: new OwnDataUser(
            state.ownDataUser.idUser,
            state.ownDataUser.idClient,
            state.ownDataUser.loginIdentifier,
            pipe(
                name,
                O.fold(
                    () => state.ownDataUser.name,
                    name => name
                )
            ),
            pipe(
                surname,
                O.fold(
                    () => state.ownDataUser.surname,
                    surname => surname
                )
            ),
            pipe(
                bio,
                O.fold(
                    () => state.ownDataUser.bio,
                    bio => bio
                )
            ),
            state.ownDataUser.checkEmailNotificationsActivated,
            pipe(
                emailContact,
                O.fold(
                    () => state.ownDataUser.emailContact,
                    emailContact => emailContact
                )
            ),
            pipe(
                emailNotifications,
                O.fold(
                    () => state.ownDataUser.emailNotifications,
                    emailNotifications => emailNotifications
                )
            ),
            state.ownDataUser.videosActivated,
            state.ownDataUser.voiceActivated,
            state.ownDataUser.hideGroupCreationButton
        ),

        // ownDataUser: pipe(
        //     bio,
        //     O.fold(
        //         () => state.ownDataUser,
        //         bio => new OwnDataUser(
        //             state.ownDataUser.idUser,
        //             state.ownDataUser.idClient,
        //             state.ownDataUser.loginIdentifier,
        //             pipe(
        //                 name,
        //                 O.fold(
        //                     () => state.ownDataUser.name,
        //                     name => name
        //                 )
        //             ),
        //             pipe(
        //                 surname,
        //                 O.fold(
        //                     () => state.ownDataUser.surname,
        //                     surname => surname
        //                 )
        //             ),
        //             bio
        //         )
        //     )
        // ),
        ownImageProfile: pipe(
            image,
            O.fold(
                () => state.ownImageProfile,
                image => O.some(image)
            )
        ),
        // name: pipe(
        //     name,
        //     O.fold(
        //         () => state.ownDataUser
        //     )
        // )
    })),
    // on(loadOwnImageProfileSuccess, (_, {ownImageProfile}) => ownImageProfile),
//     on(changeOwnDataSuccess, (oldImage , {image}) => pipe(
//         image,
//         O.fold(
//             () => oldImage,
//             img => img
//         )
//         // image !== undefined ? image : oldImage
//     ))
// );
);

export function metaStateReducerFunction(state: MetaState, action: Action) {
    return metaReducer(state, action);
}
