import {User} from '../../../domain/User';


export class PartialUser {
    readonly user: User;
    // readonly fullLoaded: boolean;
    // readonly withImageProfileLoaded: boolean;


    constructor(user: User/*, fullLoaded: boolean, withImageProfileLoaded: boolean*/) {
        this.user = user;
        // this.fullLoaded = fullLoaded;
        // this.withImageProfileLoaded = withImageProfileLoaded;
    }
}
