import {FileType} from './FileType';


export class FileMessage {
    readonly idMessage: string;
    readonly date: Date;
    readonly idUser: number;
    readonly extension: string;
    readonly message: string;
    readonly thumbnail: Blob;
    readonly type: FileType;


    constructor(idMessage: string, date: Date, idUser: number, extension: string, message: string, thumbnail: Blob, type: FileType) {
        this.idMessage = idMessage;
        this.date = date;
        this.idUser = idUser;
        this.extension = extension;
        this.message = message;
        this.thumbnail = thumbnail;
        this.type = type;
    }
}
