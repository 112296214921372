import {DeviceType} from "../../core/domain/Session";


export class SessionElementList {
    readonly deviceId: string;
    readonly deviceTypeUrl: string;
    readonly deviceInfo: string;
    readonly appVersion: string;
    // readonly ipOrig: string;
    readonly createdAt: string;
    readonly lastActive: string;
    readonly active: boolean;


    constructor(deviceId: string, deviceTypeUrl: string, deviceInfo: string, appVersion: string, createdAt: string, lastActive: string, active: boolean) {
        this.deviceId = deviceId;

        this.deviceTypeUrl = deviceTypeUrl;
        this.deviceInfo = deviceInfo;
        // this.systemVersion = systemVersion;
        this.appVersion = appVersion;
        // this.deviceModel = deviceModel;
        // this.ipOrig = ipOrig;
        this.lastActive = lastActive;
        this.createdAt = createdAt;
        this.active = active;
    }
}
