import {Injectable} from "@angular/core";
import {SessionsApihelper} from "../../sources/remote/sessions/sessions.apihelper";
import {Observable} from "rxjs";
import {Either} from "fp-ts/lib/Either";
import {StandardError} from "../../domain/StandardError";
import {Session} from "../../domain/Session";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import {map} from "rxjs/operators";
import {getSessionFromSessionApiModel} from "../mappers/sessions.mapper";


@Injectable({
    providedIn: 'root',
})
export class SessionsRepository {


    constructor(
        private sessionsApiHelper: SessionsApihelper
    ) {}

    getAllSessions() : Observable<Either<StandardError, Session[]>> {
        return this.sessionsApiHelper.getUsersMeSessions().pipe(
            map(E.map(t => A.map(getSessionFromSessionApiModel)(t.list)))
        )
    }

}
