import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { $ } from 'protractor';
// import {DiscoverElement} from '../../chatmodule/navbar/DiscoverElement';
import {DiscoversService} from '../../chatmodule/discovers.service';
import {DiscoverElement} from '../../chatmodule/models/DiscoverElement';
import {LocaleService} from "../locales/locale.service";
import {ResourcesService} from "../resources/resources.service";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {AppStateNamespaceFacade} from "../core/sources/storage/facade/namespaces/app_state_namespace.facade";
import {AlertsService} from "../utils/alerts.service";
@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DiscoverComponent implements OnInit {

    // discoversElements : DiscoverElement[] = [
    //     {name: "Noticias IBEROSTAR", image: "./assets/img/discovers/noticias.jpg", showTitle: true, vinculated: false},
    //     {name: "Responsabilidad IBEROSTAR", image: "./assets/img/discovers/responsabilitat.png", showTitle: true, vinculated: true},
    //     {name: "WEBINAR IBEROSTAR Dónde nace la magia", image: "./assets/img/discovers/webinar.jpg", showTitle: false, vinculated: false}
    // ];
    discoversElements : DiscoverElement[] = [];
    discoverCheckSrc : Observable<string>;
    // discoversVinculationsActivated : Subject<boolean> = new BehaviorSubject(false);
    discoversVinculationsActivated : Observable<boolean>;

  constructor(
      public activeModal: NgbActiveModal,
      private discoversService: DiscoversService,
      private localeService: LocaleService,
      private resourcesService: ResourcesService,
      private appStateNamespaceFacade : AppStateNamespaceFacade,
      private alertsService: AlertsService
  ) { }

  ngOnInit() {
      this.discoversService.getDiscoverElementListObservable().subscribe(
          l => {
              this.discoversElements = l;
          }
      );
      this.discoverCheckSrc = this.resourcesService.getDiscoversCheck();
      this.discoversVinculationsActivated = this.appStateNamespaceFacade.getAppWithConnectionObservable();
  }

    onClickDiscover(discoverElement: DiscoverElement) {
      this.appStateNamespaceFacade.getAppWithConnection().subscribe(
          withConnection => {
              if (withConnection) {
                  if (discoverElement.vinculated) {
                      if (confirm("Estàs segur de que vols deixar de seguir aquest discover?")) {
                          this.discoversService.quitDiscover(discoverElement.idDiscover).subscribe();
                      }
                  } else {
                      if (confirm("Estàs segur de que et vols vincular en aquest discover?")) {
                          this.discoversService.joinDiscover(discoverElement.idDiscover).subscribe();
                      }
                  }
              } else {
                  this.alertsService.showAlertWithTextError(this.localeService.translate("No connection! Try again.", {})).then(() => {});
              }

          }
      )


    }

  // unfollow(discoverElement: DiscoverElement){
  //   // confirm("¿Está seguro de dejar de seguir esta sala?");
  //     this.discoversService.quitDiscover(discoverElement);
  // }
  //
  //
  // follow(){
  //   confirm("¿Desea seguir a esta sala?");
  // }

}
