import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProfileComponent} from '../profile/profile.component';


export class NgbmodalService extends NgbModal {



    openBlockableModal(content: any, blockedModal: boolean, params: {key: string, value: any}[]): NgbModalRef {
        let ref;
        if (blockedModal) {
            ref = super.open(content,{
                ariaLabelledBy:'modal-basic-title',
                size:'lg',
                windowClass:'modal fade in',backdrop:'static',keyboard:false
            });
        } else {
            ref = super.open(content,{
                ariaLabelledBy:'modal-basic-title',
                size:'lg',
                windowClass:'modal right fade in',backdropClass:'transparent'
            });
        }

        for (let k of params) {
            ref.componentInstance[k.key] = k.value;
        }

        return ref;
    }

    openMediumModal(content: any, blockedModal: boolean, params: {key: string, value: any}[]) : NgbModalRef {
        const ref = super.open(content,{
            ariaLabelledBy:'modal-basic-title',
            size:'lg',
            windowClass:'modal fade in',backdrop:'static',keyboard:!blockedModal
        });
        for (let k of params) {
            ref.componentInstance[k.key] = k.value;
        }
        return ref;
    }


    openDefaultModal(content: any, blockedModal: boolean, params: {key: string, value: any}[]): NgbModalRef {
        let ref;

        ref = super.open(content,{
            ariaLabelledBy:'modal-basic-title',
            // size:<any>'xl',
            windowClass:'xlModal',backdrop:'static',keyboard:true
        });


        for (let k of params) {
            ref.componentInstance[k.key] = k.value;
        }

        return ref;
    }

    openSmallModal(content: any, blockedModal: boolean, params: {key: string, value: any}[]): NgbModalRef {
        const ref = super.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            windowClass:'voiceModal modal fade in',
            // windowClass: 'voiceModal',
            backdropClass:'transparent',
            keyboard:!blockedModal
        })
        for (let k of params) {
            ref.componentInstance[k.key] = k.value;
        }

        return ref;
    }


}
