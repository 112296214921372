import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ExecuteInitialAllProcessesAction} from "../../actions/principal_screen_app/initial_processes_execution.actions";
import {map, switchMap, tap} from "rxjs/operators";
import {SendAllMessagesNotSendedAction} from "../../actions/principal_screen_app/send_messages_not_sended.actions";
import {
    SynchronizeMessagesRoomsAction,
    SynchronizeMessagesRoomsSuccessAction
} from "../../actions/principal_screen_app/synchronize_messages.actions";
import {UserValidationStartUserValidationAction} from "../../actions/principal_screen_app/user_validation.actions";
import {of} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class InitialProcessesExecutionEffects {

    executeInitialAllProcessesEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExecuteInitialAllProcessesAction),
            tap(_ => console.log({text: "ExecuteInitialAllProcessesAction"})),
            switchMap(_ => [
                UserValidationStartUserValidationAction(),
                SendAllMessagesNotSendedAction({sentInternalIds: []}),
                SynchronizeMessagesRoomsAction(),

            ])
        )
    );

    // synchronizeMessagesRoomsSuccessEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(SynchronizeMessagesRoomsSuccessAction),
    //         map(_ => SynchronizeMessagesRoomsAction())
    //     )
    // )

    constructor(
        private actions$: Actions,
    ) {}
}
